// Modules
import { memo } from 'react'
import { PiInfoLight } from 'react-icons/pi'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Icon, IconButton, Tooltip } from '@chakra-ui/react'

const InfoBtn = ({ onClick, iconSize = '24px', tooltipTitle = 'Show trade info', ...args }) => {
  const { isLightThemeSelector } = useCurrentColorTheme()

  return (
    <Tooltip label={tooltipTitle} aria-label={tooltipTitle?.toLowerCase()}>
      <IconButton
        icon={<Icon as={PiInfoLight} w={iconSize} h={iconSize} />}
        onClick={onClick}
        aria-label="info"
        isRound
        size="xs"
        bg={isLightThemeSelector ? '#e2e8f0' : 'rgba(255, 255, 255, 0.08)'}
        _hover={{
          backgroundColor: isLightThemeSelector ? '#d1d9e4' : 'rgba(255, 255, 255, 0.16)',
        }}
        {...args}
      />
    </Tooltip>
  )
}

export default memo(InfoBtn)
