// Modules
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

// Store
import { favouritesCurrencyListSelector } from 'store/actions/FavouritesCurrency/selectors'

function useCheckIsFavouriteTradeItem() {
  const favouritesTrade = useSelector(favouritesCurrencyListSelector)

  const checkIsFavouriteTradeItem = useCallback(
    (id, name) => {
      const find = favouritesTrade?.find((item) => item?.id === id && item?.name === name)
      return !!find
    },
    [favouritesTrade],
  )

  return { checkIsFavouriteTradeItem }
}

export default useCheckIsFavouriteTradeItem
