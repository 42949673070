// Modules
import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Assets
import SubscriptionPlanIcon from 'assets/subscription-plan'

// Languages
import {
  LN_NAVBAR_SUBSCRIPTION_BRONZE,
  LN_NAVBAR_SUBSCRIPTION_GOLD,
  LN_NAVBAR_SUBSCRIPTION_PLATINUM,
  LN_NAVBAR_SUBSCRIPTION_SILVER,
  LN_NAVBAR_SUBSCRIPTION_TITLE,
} from 'locales/constants'

// Components
import { subscriptionPlansConst } from 'components/SubscriptionPlan/constants'

// Store
import { userInfoSelector } from 'store/actions/User/selectors'

// UI
import { Box, Text, Tooltip } from '@chakra-ui/react'

const SubscriptionPlan = ({ width, height, textFontSize = '10px', ...args }) => {
  const { t } = useTranslation()

  const userInfo = useSelector(userInfoSelector)
  const subscription = userInfo?.subscription

  const planColorSwitcher = useMemo(() => {
    switch (subscription?.name) {
      case subscriptionPlansConst.bronze?.title:
        return {
          bg: '#dea573',
          titleBg: '#bf885b',
          titleHighlight: '#9e6c48',
        }
      case subscriptionPlansConst.silver?.title:
        return {
          bg: '#F6F9F9',
          titleBg: '#E2DFF5',
          titleHighlight: '#BEBBDE',
        }
      case subscriptionPlansConst.gold?.title:
        return {
          bg: '#FFD773',
          titleBg: '#FFBE2D',
          titleHighlight: '#FF9B00',
        }
      case subscriptionPlansConst.platinum?.title:
        return {
          bg: '#dbd8db',
          titleBg: '#c9c5c9',
          titleHighlight: '#b8b2b8',
        }
      default:
        return {
          bg: '#dbd8db',
          titleBg: '#c9c5c9',
          titleHighlight: '#b8b2b8',
        }
    }
  }, [subscription?.name, subscription?.id])

  const planTitle = useMemo(() => {
    switch (subscription?.name) {
      case subscriptionPlansConst.bronze?.title:
        return LN_NAVBAR_SUBSCRIPTION_BRONZE
      case subscriptionPlansConst.silver?.title:
        return LN_NAVBAR_SUBSCRIPTION_SILVER
      case subscriptionPlansConst.gold?.title:
        return LN_NAVBAR_SUBSCRIPTION_GOLD
      case subscriptionPlansConst.platinum?.title:
        return LN_NAVBAR_SUBSCRIPTION_PLATINUM
      default:
        return ''
    }
  }, [subscription?.name, subscription?.id])

  if (!subscription) {
    return null
  }

  return (
    <Tooltip label={t(LN_NAVBAR_SUBSCRIPTION_TITLE)} aria-label="subscription">
      <Box position="relative" {...args}>
        <SubscriptionPlanIcon
          fillBg={planColorSwitcher.bg}
          fillTitleBg={planColorSwitcher.titleBg}
          fillTitleHighlight={planColorSwitcher.titleHighlight}
          width={width}
          height={height}
        />
        <Text
          fontSize={textFontSize}
          fontWeight={800}
          top={0}
          position="absolute"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          display="flex"
          color={subscription?.name !== subscriptionPlansConst.bronze.title ? 'gray.800' : 'white'}
        >
          {t(planTitle)}
        </Text>
      </Box>
    </Tooltip>
  )
}

export default memo(SubscriptionPlan)
