// Constants
import { appLanguages, appLanguagesLocalStoreKey } from 'components/LanguageDropdown/constants'
import { sidebarConstants } from 'pages/User/components/Sidebar/constants'

// Constants
import { localStorageActiveUserTradeAccountKey } from 'constants/index'

// Hooks
import { themeColorMode, themeSwitcherKey } from 'hooks/useCurrentColorTheme'

// Utils
import { currencyConst } from 'utils/userBalanceTransform'

// Types
import {
  CONFIRM_EMAIL_ACTION,
  CONFIRM_EMAIL_RESULT,
  GET_COUNTRIES_LIST,
  GET_USER_BALANCE,
  GET_USER_INFO,
  SET_COUNTRIES_LIST,
  SET_SELECTED_APP_LANGUAGE,
  SET_SELECTED_THEME,
  SET_USER_BALANCE,
  SET_USER_CURRENT_TRADING_ACCOUNT,
  SET_USER_DEVICE_IS_MOBILE,
  SET_USER_INFO,
  SET_USER_RATES,
  SET_USER_SIDEBAR_ACTIVE_ITEM,
  SET_USER_TRADING_ACCOUNTS,
} from 'store/actions/User/types'

const initialState = {
  sidebarActiveItem: sidebarConstants.personalInfo,

  userInfo: null,

  loadingUserInfo: false,
  loadingConfirmEmail: false,

  selectedLanguage: localStorage.getItem(appLanguagesLocalStoreKey) || appLanguages?.en,
  selectedTheme: localStorage.getItem(themeSwitcherKey) || themeColorMode.dark,

  countriesList: [],
  countriesListLoading: false,

  userBalance: null,
  userBalanceLoading: true,
  tradingAccounts: [],
  currentTradingAccount: null,
  // JSON.parse(localStorage.getItem(localStorageActiveUserTradeAccountKey)) || null,

  rates: null,
  isMobile: false,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SIDEBAR_ACTIVE_ITEM:
      return {
        ...state,
        sidebarActiveItem: action.payload,
      }
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
        loadingUserInfo: false,
      }
    case GET_USER_INFO:
      return {
        ...state,
        loadingUserInfo: true,
      }
    case CONFIRM_EMAIL_ACTION:
      return {
        ...state,
        loadingConfirmEmail: true,
      }
    case CONFIRM_EMAIL_RESULT:
      return {
        ...state,
        loadingConfirmEmail: false,
      }
    case SET_SELECTED_APP_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.payload,
      }
    case SET_SELECTED_THEME:
      return {
        ...state,
        selectedTheme: action.payload,
      }

    case SET_COUNTRIES_LIST:
      return {
        ...state,
        countriesList: action.payload,
        countriesListLoading: false,
      }
    case GET_COUNTRIES_LIST:
      return {
        ...state,
        countriesListLoading: true,
      }

    // trading accounts
    case SET_USER_BALANCE:
      return {
        ...state,
        userBalance: action.payload,
        userBalanceLoading: false,
      }
    case GET_USER_BALANCE:
      return {
        ...state,
        userBalanceLoading: true,
      }
    case SET_USER_TRADING_ACCOUNTS:
      return {
        ...state,
        tradingAccounts: action.payload,
      }
    case SET_USER_CURRENT_TRADING_ACCOUNT:
      localStorage.setItem(
        localStorageActiveUserTradeAccountKey,
        JSON.stringify(action.payload || {}),
      )

      return {
        ...state,
        currentTradingAccount: action.payload,
      }
    case SET_USER_RATES:
      return {
        ...state,
        rates: action.payload,
      }
    case SET_USER_DEVICE_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      }
    default:
      return state
  }
}
export default userReducer
