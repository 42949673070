// Modules
import { memo, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { MdAreaChart } from 'react-icons/md'

// Languages
import { LN_TRANSACTION_CALC_BTN_SHOW_PROFIT_CALCULATOR } from 'locales/constants'

// Components
import ProfitCalculatorModal from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/ProfitCalculator/ProfitCalculatorModal'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Context
import { MarketCalcContext } from 'context/MarketCalcContext'

// UI
import { Icon, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react'

const ProfitCalculator = () => {
  const { isLightTheme } = useCurrentColorTheme()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { t } = useTranslation()

  const { onSetUnchangeableSocketPriceModal } = useContext(MarketCalcContext)

  const onOpenModal = useCallback(() => {
    onSetUnchangeableSocketPriceModal()
    onOpen()
  }, [onOpen, onSetUnchangeableSocketPriceModal])

  return (
    <>
      <Tooltip
        label={t(LN_TRANSACTION_CALC_BTN_SHOW_PROFIT_CALCULATOR)}
        aria-label="show profit calculator"
      >
        <IconButton
          icon={<Icon as={MdAreaChart} w="16px" h="16px" />}
          onClick={onOpenModal}
          aria-label="open profit calculator"
          variant={isLightTheme ? 'lightBlue' : 'darkBlue'}
          // colorScheme="navy"
          size="md"
        />
      </Tooltip>
      <ProfitCalculatorModal onClose={onClose} isOpen={isOpen} />
    </>
  )
}

export default memo(ProfitCalculator)
