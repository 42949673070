// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_HISTORY_NO_DATA,
  LN_HISTORY_TABLE_DELETE_ALERT_BTN,
  LN_HISTORY_TABLE_DELETE_ALERT_MESSAGE,
} from 'locales/constants'

// Components
import { AlertWarning, TextSm } from 'components'
import LoadingBlock from 'components/LoadingBlock'
import TableHeaderTh from 'pages/Dashboard/components/OrdersBottomBar/ContentBar/TableHeaderTh'
import { sortByListValue } from 'pages/Dashboard/components/OrdersBottomBar/ContentBar/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useOrderBottomInfoContent from 'hooks/useOrderBottomInfoContent'

// Utils
import { getHorizontalScrollbarCss } from 'utils/scrollbarHelper'

// UI
import { Box, Table, TableContainer, Tbody, Thead, Tr } from '@chakra-ui/react'

const ContentTableList = (props) => {
  const { orderList, loading } = props

  const { t } = useTranslation()
  const { isLightTheme } = useCurrentColorTheme()

  const {
    sortedData,
    sortConfig,
    deleteItemAlert,
    isOpenDeleteItem,
    handleSort,
    onCloseDeleteItem,
    headersList,
    getRow,
    onHandleDeleteItemBtn,
  } = useOrderBottomInfoContent(orderList)

  return (
    <Box h="100%" w="100%" position="relative" mt={0.5} p={0} pb="25px">
      <Box>
        {loading ? (
          <LoadingBlock h={100} />
        ) : (
          <TableContainer css={getHorizontalScrollbarCss()}>
            <Table size="sm">
              <Thead>
                <Tr
                  borderTop="1px solid"
                  borderTopColor={isLightTheme ? 'brand.lightAccent' : 'navy.20'}
                >
                  {headersList?.map((header) => (
                    <TableHeaderTh
                      key={header.name}
                      sortBy={sortConfig.key}
                      sortOrder={sortConfig.direction}
                      name={header.name}
                      handleSort={handleSort}
                      title={header.title}
                      fontSize="14px"
                    />
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {!sortedData || sortedData?.length === 0 ? (
                  <TextSm mt={2}>{t(LN_HISTORY_NO_DATA)}</TextSm>
                ) : (
                  sortedData?.map((el) => getRow(el))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <AlertWarning
        isOpen={isOpenDeleteItem}
        onClose={onCloseDeleteItem}
        content={`${t(LN_HISTORY_TABLE_DELETE_ALERT_MESSAGE)}: ${deleteItemAlert?.id}?`}
        actionTitleBtn={t(LN_HISTORY_TABLE_DELETE_ALERT_BTN)}
        onActionBtn={onHandleDeleteItemBtn}
      />
    </Box>
  )
}

export default memo(ContentTableList)
