// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_NAVBAR_MARGIN_INFO_AVAILABLE,
  LN_NAVBAR_MARGIN_INFO_CREDIT,
  LN_NAVBAR_MARGIN_INFO_EQUITY,
  LN_NAVBAR_MARGIN_INFO_LEVEL,
  LN_NAVBAR_MARGIN_INFO_PL,
  LN_NAVBAR_MARGIN_INFO_TITLE,
  LN_NAVBAR_MARGIN_INFO_USAGE,
} from 'locales/constants'

// Components
import { InfoItem, TextXs } from 'components'
import { prettifyValue } from 'pages/Dashboard/components/Navbar/MarginInfo/index'

// UI
import { Flex } from '@chakra-ui/react'

const MarginInfo = (props) => {
  const { levelValue, usageValue, availableValue, equityValue, plValue, getCurrencySymbol } = props
  const { t } = useTranslation()

  return (
    <Flex flexDirection="column">
      <TextXs fontSize="11px" fontWeight={500} pb={1}>
        {t(LN_NAVBAR_MARGIN_INFO_TITLE)}
      </TextXs>
      <Flex gap={3}>
        <InfoItem title={t(LN_NAVBAR_MARGIN_INFO_LEVEL)} value={prettifyValue(levelValue) + '%'} />
        <InfoItem
          title={t(LN_NAVBAR_MARGIN_INFO_USAGE)}
          value={getCurrencySymbol + ' ' + prettifyValue(usageValue)}
        />
        <InfoItem
          title={t(LN_NAVBAR_MARGIN_INFO_AVAILABLE)}
          value={getCurrencySymbol + ' ' + prettifyValue(availableValue)}
        />
        <InfoItem
          title={t(LN_NAVBAR_MARGIN_INFO_EQUITY)}
          value={getCurrencySymbol + ' ' + prettifyValue(equityValue)}
        />
        <InfoItem
          title={t(LN_NAVBAR_MARGIN_INFO_PL)}
          value={getCurrencySymbol + ' ' + prettifyValue(plValue)}
        />
        {/*<InfoItem title={t(LN_NAVBAR_MARGIN_INFO_CREDIT)} value={'€ ' + creditValue} />*/}
      </Flex>
    </Flex>
  )
}

export default memo(MarginInfo)
