// Types
import {
  SET_INFO_MODAL_TRADE_ITEM,
  SET_IS_OPEN_MODAL_INFO,
} from 'store/actions/InfoModalTradeItem/types'

export const setInfoModalTradeItem = (payload) => ({
  type: SET_INFO_MODAL_TRADE_ITEM,
  payload: payload,
})

export const setIsOpenModalInfo = (payload) => ({
  type: SET_IS_OPEN_MODAL_INFO,
  payload: payload,
})
