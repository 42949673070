// Modules
import { memo } from 'react'

// UI
import { Input } from '@chakra-ui/react'

const InputBase = (props) => {
  const { typeOfField = 'text', fieldName, placeholder = '', ...args } = props
  return (
    <Input
      autoComplete="off"
      fontSize="md"
      id={fieldName}
      name={fieldName}
      placeholder={placeholder}
      type={typeOfField}
      variant="filled"
      focusBorderColor="navy.500"
      {...args}
    />
  )
}

export default memo(InputBase)
