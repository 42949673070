// Modules
import { all } from 'redux-saga/effects'

// Store
import { watchAuth } from 'store/sagas/authSaga'
import { watchMainCurrencySidebar } from 'store/sagas/mainCurrencySaga'
import { watchOrderBarCreation } from 'store/sagas/orderBarSaga'
import { watchCurrencyMarketWebSocket } from 'store/sagas/sockets/currencySocketSaga'
import { watchProfitTransactionsWebSocket } from 'store/sagas/sockets/profitTransactionsSocketSaga'
import { watchFollowTransactionsWebSocket } from 'store/sagas/sockets/transactionsSocketSaga'
import { watchTransactionCreation } from 'store/sagas/transactionCreation'
import { watchUserDepositSaga } from 'store/sagas/userDepositSaga'
import { watchUserInfo } from 'store/sagas/userSaga'

import { watchKYCInfo } from './kycSaga'

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchUserInfo(),
    watchMainCurrencySidebar(),
    watchTransactionCreation(),
    watchKYCInfo(),
    watchOrderBarCreation(),
    watchUserDepositSaga(),
    // sockets
    watchCurrencyMarketWebSocket(),
    watchFollowTransactionsWebSocket(),
    watchProfitTransactionsWebSocket(),
  ])
}
