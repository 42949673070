// Modules
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Hooks
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Store
import { getDepositUserHistory } from 'store/actions/UserDeposit/actions'
import { depositHistorySelector } from 'store/actions/UserDeposit/selectors'

export const paginationDefault = { total: 0, page: 1 }
export const paginationPerPage = 20

function useHistoryTablePagination() {
  const dispatch = useDispatch()

  const { userTradingAccountId } = useUserTradingAccountId()

  const depositHistory = useSelector(depositHistorySelector)
  const listPagination = depositHistory?.pagination

  const paginationInfo = useMemo(() => {
    if (listPagination) {
      return listPagination
    } else {
      return paginationDefault
    }
  }, [listPagination])

  const sentRequest = useCallback(
    (pageValue) => {
      if (!userTradingAccountId) return null

      dispatch(
        getDepositUserHistory({
          userTradingAccountId,
          page: pageValue,
        }),
      )
    },
    [dispatch, userTradingAccountId],
  )

  const onNextPage = useCallback(() => {
    sentRequest(Number(paginationInfo.page) + 1)
  }, [sentRequest, paginationInfo])

  const onPreviousPage = useCallback(() => {
    sentRequest(Number(paginationInfo.page) - 1)
  }, [sentRequest, paginationInfo])

  console.log('paginationInfo', paginationInfo)

  return {
    paginationInfo,
    onPreviousPage,
    onNextPage,
    onPage: sentRequest,
  }
}

export default useHistoryTablePagination
