// Modules
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

// Theme
import { mainBgColors } from 'theme/globalStyles'

// Constants
import { defaultProps } from './constants'

// Hooks
import useGraphStructure from 'hooks/Graph/useGraphStructure'
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { getCurrentCurrencyAssertValue } from 'utils/transactionFormulas'

// Store
import { chosenActiveTradeItemSelector } from 'store/actions/ActiveTradeItems/selectors'
import { intervalGraphValueSelector } from 'store/actions/CurrencySocket/selectors'
import { assertListSelector } from 'store/actions/TransactionCreation/selectors'

// Components
import { widget } from '../../charting_library'
import './index.css'

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)')
  const results = regex.exec(window.location.search)
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export const TVChartContainer = () => {
  const chartContainerRef = useRef()

  const chosenActiveTradeItem = useSelector(chosenActiveTradeItemSelector)
  const assertList = useSelector(assertListSelector)
  const { chartApi } = useGraphStructure()
  const { isLightTheme } = useCurrentColorTheme()

  const prepareCurrencyStringForGraph = useCallback((currency) => {
    return _.replace(currency, '-', '') || ''
  }, [])

  const currentCurrencyAssertValue = useMemo(() => {
    return getCurrentCurrencyAssertValue(assertList, chosenActiveTradeItem)
  }, [chosenActiveTradeItem, assertList])

  const currentCurrencySymbolPointValue = useMemo(
    () => Number(currentCurrencyAssertValue?.symbol_point ?? 0) ?? 0,
    [currentCurrencyAssertValue?.symbol_point],
  )

  useEffect(() => {
    const widgetOptions = {
      symbol: chosenActiveTradeItem?.name
        ? prepareCurrencyStringForGraph(chosenActiveTradeItem?.name)
        : defaultProps.symbol,
      datafeed: chartApi,
      // datafeed: Datafeed,
      // datafeed: new window.Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,

      locale: getLanguageFromURL() || 'en',
      disabled_features: defaultProps.disabledFeatures,
      overrides: defaultProps.overrides,
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      toolbar_bg: isLightTheme ? mainBgColors.light : '#2c313d',
      theme: isLightTheme ? 'light' : 'dark',
      custom_formatters: {
        priceFormatterFactory: (symbolInfo, minTick) => {
          return {
            format: (price, signPositive) => {
              return price.toFixed(currentCurrencySymbolPointValue)
            },
          }
        },
      },
    }

    const tvWidget = new widget(widgetOptions)

    // tvWidget.onChartReady(() => {
    //   tvWidget.headerReady().then(() => {
    //     const button = tvWidget.createButton()
    //     button.setAttribute('title', 'Click to show a notification popup')
    //     button.classList.add('apply-common-tooltip')
    //     button.addEventListener('click', () =>
    //       tvWidget.showNoticeDialog({
    //         title: 'Notification',
    //         body: 'TradingView Charting Library API works correctly',
    //         callback: () => {
    //           console.log('Noticed!')
    //         },
    //       }),
    //     )
    //
    //     button.innerHTML = 'Check API'
    //   })
    // })

    return () => {
      tvWidget.remove()
    }
  }, [
    chosenActiveTradeItem,
    prepareCurrencyStringForGraph,
    chartApi,
    currentCurrencySymbolPointValue,
    isLightTheme,
  ])

  return <div ref={chartContainerRef} className="TVChartContainer" />
}
