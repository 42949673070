// Modules
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

// Store
import { setInfoModalTradeItem, setIsOpenModalInfo } from 'store/actions/InfoModalTradeItem/actions'

function useTradeInfoModal() {
  const dispatch = useDispatch()

  const onOpenTradeInfoModal = useCallback(
    (item) => {
      dispatch(setIsOpenModalInfo(true))
      dispatch(setInfoModalTradeItem(item))
    },
    [dispatch],
  )
  const onCloseTradeInfoModal = useCallback(() => {
    dispatch(setIsOpenModalInfo(false))
    dispatch(setInfoModalTradeItem(null))
  }, [dispatch])

  return {
    onOpenTradeInfoModal,
    onCloseTradeInfoModal,
  }
}

export default useTradeInfoModal
