// Modules
import { memo } from 'react'

function OpenTradeIcon(props) {
  const { fill = '#000000', w = 32, h = 32 } = props

  return (
    <svg version="1.1" width={w} height={h} x="0" y="0" viewBox="0 0 48 48">
      <path
        d="M25 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm8.193 23.192a.999.999 0 1 0 1.414 1.414.999.999 0 1 0-1.414-1.414zM14.808 14.808a.999.999 0 1 0-1.414-1.414.999.999 0 1 0 1.414 1.414zm-1.415 19.799a.999.999 0 1 0 1.414-1.414.999.999 0 1 0-1.414 1.414zM24 39a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm17.293-11.293a.999.999 0 0 0 1.414 0l4-4a.999.999 0 1 0-1.414-1.414l-2.32 2.32c.008-.203.027-.417.027-.613 0-10.477-8.523-19-19-19C16.243 5 9.341 9.659 6.415 16.869a1.001 1.001 0 0 0 1.854.752C10.886 11.169 17.062 7 24 7c9.374 0 17 7.626 17 17 0 .189-.005.38-.013.573l-2.28-2.28a.999.999 0 1 0-1.414 1.414zM6.707 20.293a.999.999 0 0 0-1.414 0l-4 4a.999.999 0 1 0 1.414 1.414l2.32-2.32C5.019 23.59 5 23.804 5 24c0 10.477 8.523 19 19 19 7.757 0 14.659-4.659 17.585-11.869a1.001 1.001 0 0 0-1.854-.752C37.114 36.831 30.939 41 24 41c-9.374 0-17-7.626-17-17 0-.189.005-.38.013-.573l2.28 2.28a.999.999 0 1 0 1.414-1.414zm16.91 4.631a1 1 0 0 0 1.09-.217l9.899-9.899a.999.999 0 1 0-1.414-1.414L25 21.586V14a1 1 0 1 0-2 0v10a1 1 0 0 0 .617.924z"
        fill={fill}
        opacity="1"
        data-original={fill}
        className=""
      />
    </svg>
  )
}

export default memo(OpenTradeIcon)
