// Modules
import { useSelector } from 'react-redux'

// Utils
import { currencyConvert } from 'utils/userBalanceTransform'

// Store
import { currentTradingAccountSelector } from 'store/actions/User/selectors'

function useUserTradingAccountId() {
  const currentTradingAccount = useSelector(currentTradingAccountSelector)

  const userTradingAccountId = currentTradingAccount?.trading_account_id
  const userTradingAccountName = currentTradingAccount?.currency?.name

  const userTradingAccountSymbol = currencyConvert(currentTradingAccount?.currency?.name)

  return {
    userTradingAccountId,
    userTradingAccountName,
    userTradingAccountSymbol,
  }
}

export default useUserTradingAccountId
