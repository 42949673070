// Modules
import { lazy, memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { usePagination, useTable } from 'react-table'

// Components
import Pagination from 'components/Table/Pagination'
// Lazy Components
import InfoModal from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/InfoModal'
import TableHistory from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/TableHistory'
import {
  columns,
  statusConstants,
} from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/constants'

// Store
import { setWithdrawalHistory } from 'store/actions/Withdrawal/actions'

const WithdrawalHistory = (props) => {
  const { list } = props

  const dispatch = useDispatch()

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
    pageOptions,
  } = useTable(
    {
      columns: columns,
      data: list ? list : [],
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination,
  )

  const onCancelWithdrawalStatus = useCallback(
    (el) => {
      const transformed = list?.map((c) => {
        if (c?.id === el?.id) {
          return {
            ...c,
            status: statusConstants?.failed,
          }
        }
        return c
      })
      dispatch(setWithdrawalHistory(transformed))
    },
    [list, dispatch],
  )

  return (
    <>
      <TableHistory
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
        onCancelWithdrawalStatus={onCancelWithdrawalStatus}
      />
      <Pagination
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
      <InfoModal onCancelWithdrawalStatus={onCancelWithdrawalStatus} />
    </>
  )
}

export default memo(WithdrawalHistory)
