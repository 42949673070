// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const depositFieldsSelector = createSelector(selfSelector, (self) => self.depositFields)
export const loadingDepositFieldsSelector = createSelector(
  selfSelector,
  (self) => self.loadingDepositFields,
)

export const depositHistorySelector = createSelector(selfSelector, (self) => self.depositHistory)
export const loadingDepositHistorySelector = createSelector(
  selfSelector,
  (self) => self.loadingDepositHistory,
)

export const isDepositModalInfoOpenSelector = createSelector(
  selfSelector,
  (self) => self.isDepositModalInfoOpen,
)
export const modalDepositItemInfoSelector = createSelector(
  selfSelector,
  (self) => self.modalDepositItemInfo,
)
