// Utils
import { formatDigitsWithSpaces } from 'utils/formatDigitsWithSpaces'

export const currencyConst = {
  usd: 'USD',
  eur: 'EUR',
  rub: 'RUB',
}

export const currencyConvert = (val) => {
  switch (val) {
    case currencyConst.usd:
      return '$'
    case currencyConst.eur:
      return '€'
    case currencyConst.rub:
      return '₽'
    default:
      return ''
  }
}

export const userBalanceTransform = (balance, currency) => {
  const balanceConst = balance ? formatDigitsWithSpaces(balance) : '0'
  return balanceConst + ' ' + currencyConvert(currency)
}

export const getUserBalanceNavbar = (balanceValue, currencySymbol) => {
  return currencyConvert(currencySymbol) + ' ' + userBalanceTransform(balanceValue ?? 0)
}
export const getUserBalanceMobileNavbar = (balanceValue, currencySymbol) => {
  return userBalanceTransform(balanceValue ?? 0) + ' ' + currencyConvert(currencySymbol)
}
