// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { BsTrash } from 'react-icons/bs'
import { GoPlus } from 'react-icons/go'
import { RxCross2 } from 'react-icons/rx'

// Languages
import { LN_ADD_NEW_TRADE_MODAL_BTN_TITLE, LN_CLOSE_ALL_TRADES_BTN } from 'locales/constants'

// Components
import ActiveTradeButton from 'pages/Dashboard/components/Main/ActiveTradeBlock/ActiveTradeButton'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Button, Flex, Icon, Tooltip } from '@chakra-ui/react'

const ActiveTradeInnerElements = (props) => {
  const { activeTradeItemsList, chosenActiveTradeItem, onAddNew, onActive, onCloseAll } = props

  const { t } = useTranslation()
  const { isLightTheme, mainTextColor } = useCurrentColorTheme()

  return (
    <>
      <Flex>
        {/*<Tooltip label={t(LN_ADD_NEW_TRADE_MODAL_BTN_TITLE)} aria-label="add new trade">*/}
        {/*  <Button*/}
        {/*    onClick={onAddNew}*/}
        {/*    paddingX={2}*/}
        {/*    paddingY={1}*/}
        {/*    variant={isLightTheme ? 'lightBlue' : 'darkBlue'}*/}
        {/*    size={{ base: 'sm', sm: 'md' }}*/}
        {/*  >*/}
        {/*    <Icon as={GoPlus} w="16px" h="16px" color={isLightTheme ? 'white' : mainTextColor} />*/}
        {/*  </Button>*/}
        {/*</Tooltip>*/}
        {activeTradeItemsList?.length !== 0 && (
          <Tooltip label={t(LN_CLOSE_ALL_TRADES_BTN)} aria-label="close all trades">
            <Button
              rightIcon={<BsTrash />}
              onClick={onCloseAll}
              aria-label="close-all-trades"
              variant="link"
              size="xs"
              minWidth={5}
              _hover={{
                color: 'red.500',
              }}
            />
          </Tooltip>
        )}
      </Flex>

      {activeTradeItemsList?.map((el) => (
        <Box position="relative" key={el?.id}>
          <ActiveTradeButton
            active={el?.id === chosenActiveTradeItem?.id}
            data={el}
            onActive={onActive}
          />
        </Box>
      ))}
    </>
  )
}

export default memo(ActiveTradeInnerElements)
