// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { GoSignOut } from 'react-icons/go'

// Components
import { TextSm, UserLogoBtn } from 'components'
import SubscriptionPlan from 'components/SubscriptionPlan'
import BalanceCollapse from 'pages/Dashboard/components/Navbar/Balance/BalanceCollapse'
import { SidebarItem } from 'pages/User/components/Sidebar'
import { sidebarList } from 'pages/User/components/Sidebar/constants'
import useUserSidebar from 'pages/User/components/Sidebar/useUserSidebar'

// UI
import { Box, Flex } from '@chakra-ui/react'

const UserInfo = () => {
  const { t } = useTranslation()

  const { userInfo, onChangeSidebarActiveItem, onLogout, sidebarActiveItem } = useUserSidebar()

  return (
    <Box>
      <Flex alignItems="center" flexDirection="column" gap={0.5}>
        <Box position="relative">
          <Box position="absolute" top="-20px" right="-20px" zIndex="1">
            <SubscriptionPlan width="36px" textFontSize="9px" />
          </Box>
          <UserLogoBtn
            w="17px"
            h="45px"
            mb={1}
            _hover={{
              cursor: 'default',
            }}
          />
        </Box>

        <TextSm fontSize={{ base: 'xs', sm: 'sm' }}>#{userInfo?.id}</TextSm>
        <TextSm fontWeight="600" color="navy.300" fontSize={{ base: 'xs', sm: 'sm' }}>
          {userInfo?.email}
        </TextSm>
      </Flex>
      <Flex flexDirection="column" gap={2} mt={2}>
        <Box mb={2} display={{ base: 'inline-block', '2xs': 'none' }}>
          <BalanceCollapse />
        </Box>

        {sidebarList?.map((el) => (
          <>
            <SidebarItem
              key={el?.id}
              onClickItem={onChangeSidebarActiveItem}
              id={el?.id}
              sidebarActiveItem={sidebarActiveItem}
              icon={el?.icon}
              title={t(el?.title)}
              isMobile
            />
          </>
        ))}
        <SidebarItem
          key="logout"
          onClickItem={onLogout}
          id="logout"
          sidebarActiveItem={sidebarActiveItem}
          icon={GoSignOut}
          title="Logout"
          isMobile
        />
      </Flex>
    </Box>
  )
}

export default memo(UserInfo)
