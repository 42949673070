// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { PiBookmarkSimpleDuotone } from 'react-icons/pi'

// Languages
import { LN_MARKET_SIDEBAR_ACCORDION_ADD_FAVOURITE } from 'locales/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Button, Icon, Tooltip } from '@chakra-ui/react'

const BookmarkBtn = ({ onClick, iconSize = '16px', isActive, ...args }) => {
  const { t } = useTranslation()
  const { isLightThemeSelector, highLightColor } = useCurrentColorTheme()

  return (
    <Tooltip label={t(LN_MARKET_SIDEBAR_ACCORDION_ADD_FAVOURITE)} aria-label="add to favourites">
      <Button variant="text" onClick={onClick}>
        <Icon
          as={PiBookmarkSimpleDuotone}
          color={
            isActive
              ? isLightThemeSelector
                ? 'yellow.400'
                : 'yellow.200'
              : isLightThemeSelector
              ? 'gray.400'
              : 'gray.200'
          }
          w={iconSize}
          h={iconSize}
          transition="0.8s"
          _hover={{
            cursor: 'pointer',
            color: 'yellow.100',
            transition: '0.8s',
          }}
          {...args}
        />
      </Button>
    </Tooltip>
  )
}

export default memo(BookmarkBtn)
