export const GET_USER_DEPOSIT_FIELDS = 'GET_USER_DEPOSIT_FIELDS'
export const SET_USER_DEPOSIT_FIELDS = 'SET_USER_DEPOSIT_FIELDS'
export const SET_LOADING_USER_DEPOSIT_FIELDS = 'SET_LOADING_USER_DEPOSIT_FIELDS'

export const GET_DEPOSIT_USER_HISTORY = 'GET_DEPOSIT_USER_HISTORY'
export const SET_DEPOSIT_USER_HISTORY = 'SET_DEPOSIT_USER_HISTORY'
export const SET_LOADING_DEPOSIT_USER_HISTORY = 'SET_LOADING_DEPOSIT_USER_HISTORY'

export const SET_IS_DEPOSIT_MODAL_INFO_OPEN = 'SET_IS_DEPOSIT_MODAL_INFO_OPEN'
export const SET_DEPOSIT_MODAL_ITEM_INFO = 'SET_DEPOSIT_MODAL_ITEM_INFO'
