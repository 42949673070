// Action Types
import {
  SET_IS_BOTTOM_NAV_OPEN,
  SET_IS_LEFT_NAV_OPEN,
  SET_IS_RIGHT_NAV_OPEN,
  SET_MAIN_GRAPH_BLOCK_HEIGHT,
  SET_MAIN_GRAPH_BLOCK_WIDTH,
} from 'store/actions/MainGraphBlockWidth/types'

export const setMainGraphBlockWidth = (payload) => ({
  type: SET_MAIN_GRAPH_BLOCK_WIDTH,
  payload: payload,
})

export const setMainGraphBlockHeight = (payload) => ({
  type: SET_MAIN_GRAPH_BLOCK_HEIGHT,
  payload: payload,
})

export const setIsLeftNavOpen = (payload) => ({
  type: SET_IS_LEFT_NAV_OPEN,
  payload: payload,
})

export const setIsRightNavOpen = (payload) => ({
  type: SET_IS_RIGHT_NAV_OPEN,
  payload: payload,
})

export const setIsBottomNavOpen = (payload) => ({
  type: SET_IS_BOTTOM_NAV_OPEN,
  payload: payload,
})
