// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { RxCross2 } from 'react-icons/rx'
import { useSelector } from 'react-redux'

// Languages
import {
  LN_HISTORY_CLOSE_ALL,
  LN_HISTORY_CLOSE_ALL_ALERT_MESSAGE,
  LN_USER_RESTRICTIONS,
} from 'locales/constants'

// Components
import { AlertWarning } from 'components'

// Hooks
import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'
import useOrderBottomInfo from 'hooks/useOrderBottomInfo'

// Store
import { mainTabsValueSelector } from 'store/actions/OrderBar/selectors'

// UI
import { Button, Icon, Tooltip } from '@chakra-ui/react'

const CloseAllBtn = (props) => {
  const { isDisabled, ...args } = props

  const { t } = useTranslation()

  const mainTabsValue = useSelector(mainTabsValueSelector)
  const { isOpenCloseAll, onOpenCloseAll, onCloseAlertCloseAll, mainTabsValues, onCloseAllItems } =
    useOrderBottomInfo()

  const { isBlockedCloseTrade, isTradeAllowed } = useCheckUserRestrictions()

  const onCloseAll = useCallback(() => {
    onCloseAllItems()
  }, [onCloseAllItems])

  const tooltipTitle = isBlockedCloseTrade || !isTradeAllowed ? t(LN_USER_RESTRICTIONS) : null

  return (
    <>
      {(mainTabsValue === mainTabsValues?.positions ||
        mainTabsValue === mainTabsValues?.pendingOrders) && (
        <Tooltip label={tooltipTitle} aria-label={tooltipTitle}>
          <Button
            isDisabled={isDisabled || isBlockedCloseTrade || !isTradeAllowed}
            rightIcon={<Icon as={RxCross2} />}
            onClick={onOpenCloseAll}
            aria-label="toggle-orders"
            variant="link"
            size="xs"
            {...args}
          >
            {t(LN_HISTORY_CLOSE_ALL)}
          </Button>
        </Tooltip>
      )}
      <AlertWarning
        isOpen={isOpenCloseAll}
        onClose={onCloseAlertCloseAll}
        content={t(LN_HISTORY_CLOSE_ALL_ALERT_MESSAGE) + '?'}
        actionTitleBtn={t(LN_HISTORY_CLOSE_ALL)}
        onActionBtn={onCloseAll}
      />
    </>
  )
}

export default memo(CloseAllBtn)
