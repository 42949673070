// Modules
import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Components
import LeftNavbar from 'pages/Dashboard/components/LeftNavbar/LeftNavbar'
import LeftNavbarMobile from 'pages/Dashboard/components/LeftNavbar/LeftNavbarMobile'
import { NavListConstants } from 'pages/Dashboard/components/LeftNavbar/constants'
import { mainTabsList } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'
import { sidebarList } from 'pages/User/components/Sidebar/constants'

// Constants
import { routers } from 'navigation/constants'

// Store
import { logout } from 'store/actions/Auth/actions'
import { setIsFullSizeModal, setMainTabsValue } from 'store/actions/OrderBar/actions'
import { setUserSidebarActiveItem } from 'store/actions/User/actions'
import { isMobileSelector } from 'store/actions/User/selectors'

const LeftNavbarContainer = (props) => {
  const { isActiveNav, setIsActiveNav, onLeftNavOpen } = props
  const isMobile = useSelector(isMobileSelector)

  const history = useHistory()
  const dispatch = useDispatch()

  const onChooseItem = useCallback(
    (id) => {
      switch (id) {
        case NavListConstants.trading:
          setIsActiveNav(true)
          onLeftNavOpen()
          break
        case NavListConstants.deposit:
          dispatch(setUserSidebarActiveItem(sidebarList?.[1]?.id))
          history.push('/' + routers.user)
          break
        case NavListConstants.history:
          dispatch(setMainTabsValue(mainTabsList?.[2]?.value))
          dispatch(setIsFullSizeModal(true))
          break
        case NavListConstants.logOut:
          dispatch(logout())
          break
      }
    },
    [setIsActiveNav, onLeftNavOpen, dispatch, history],
  )

  return isMobile ? (
    <LeftNavbarMobile onChooseItem={onChooseItem} isActiveNav={isActiveNav} />
  ) : (
    <LeftNavbar onChooseItem={onChooseItem} isActiveNav={isActiveNav} />
  )
}

export default memo(LeftNavbarContainer)
