// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_TRANSACTION_CALC_ASSET_PRICE,
  LN_TRANSACTION_CALC_PENDING,
  LN_TRANSACTION_CALC_PENDING_MODAL_MESSAGE,
  LN_TRANSACTION_CALC_REVERT_MARKET_PRICE_BTN,
} from 'locales/constants'

// Components
import { TextXs } from 'components'
import InputMarket from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/InputMarket'
import InputMarketMobileUI from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/InputMarket/InputMarketMobileUI'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Button, Collapse, useDisclosure } from '@chakra-ui/react'

const PendingPopoverMobileUI = (props) => {
  const {
    popoverLabelVal,
    onChangeAssetPriceInput,
    assetPriceValue,
    onIncrease,
    onDecrease,
    onResetValue,
    onDefaultValue,
    unchangeableCurrencyPriceValue,
  } = props

  const { t } = useTranslation()
  const { mainTextColor, isLightTheme, mainBorderColor } = useCurrentColorTheme()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onToggleOpen = useCallback(() => {
    if (isOpen) {
      onClose()
    } else {
      onOpen()
    }
  }, [isOpen, onClose, onOpen])

  return (
    <Box>
      <Button
        w="full"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap={1}
        onClick={onToggleOpen}
      >
        <TextXs fontWeight="400" fontSize="10px">
          {t(LN_TRANSACTION_CALC_PENDING)}
        </TextXs>
        <TextXs color={mainTextColor} fontSize="10px" pl="3px">
          {popoverLabelVal}
        </TextXs>
      </Button>
      <Box marginTop="-4px">
        <Collapse in={isOpen} animateOpacity>
          <Box
            bg={isLightTheme ? 'gray.50' : 'whiteAlpha.50'}
            p={2}
            borderBottomRightRadius={4}
            borderBottomLeftRadius={4}
            border="1px solid"
            borderColor={mainBorderColor}
            borderTop={0}
          >
            <Box mt={2}>
              <TextXs fontWeight="600">{t(LN_TRANSACTION_CALC_PENDING)}</TextXs>
            </Box>
            <Box>
              <InputMarketMobileUI
                label={t(LN_TRANSACTION_CALC_ASSET_PRICE)}
                onChange={onChangeAssetPriceInput}
                value={assetPriceValue}
                onIncreaseValue={onIncrease}
                onDecreaseValue={onDecrease}
                onResetValue={onResetValue}
              />
              <Button
                size="sm"
                w="full"
                onClick={onDefaultValue}
                isDisabled={assetPriceValue === unchangeableCurrencyPriceValue}
              >
                {t(LN_TRANSACTION_CALC_REVERT_MARKET_PRICE_BTN)}
              </Button>
              <TextXs mt={2} color={mainTextColor}>
                {t(LN_TRANSACTION_CALC_PENDING_MODAL_MESSAGE)}
              </TextXs>
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}

export default memo(PendingPopoverMobileUI)
