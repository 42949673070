// Modules
import { memo } from 'react'

// Components
import { CloseSidebar, SliderNavbarWrapperUI } from 'components'
import SliderNavbarFooter from 'components/MobileUI/SliderNavbar/SliderNavbarFooter'
import SubscriptionPlan from 'components/SubscriptionPlan'
import BalanceCollapse from 'pages/Dashboard/components/Navbar/Balance/BalanceCollapse'
import MarginInfo from 'pages/Dashboard/components/Navbar/MarginInfo'

// Utils
import { getMobileScrollbarCss } from 'utils/scrollbarHelper'

// UI
import { Box, Flex } from '@chakra-ui/react'

const SliderNavbar = ({ isOpen, onClose }) => {
  return (
    <SliderNavbarWrapperUI isOpen={isOpen} onClose={onClose}>
      <Box
        maxHeight="496px"
        overflowY="auto"
        pb="70px"
        pr={2}
        h="100%"
        css={getMobileScrollbarCss()}
      >
        <Flex w="100%" justifyContent="flex-end" mb={{ base: 0, '2xs': 2 }}>
          <CloseSidebar onClose={onClose} />
          <SubscriptionPlan width="36px" textFontSize="9px" />
        </Flex>
        <Box mb={2} display={{ base: 'inline-block', '2xs': 'none' }} w="100%" mt="-17px">
          <BalanceCollapse />
        </Box>
        <Box>
          <MarginInfo />
        </Box>
      </Box>
      <SliderNavbarFooter pb="39px" />
    </SliderNavbarWrapperUI>
  )
}

export default memo(SliderNavbar)
