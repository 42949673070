// Action Types
import {
  ADD_FAVOURITE_CURRENCY,
  SET_FAVOURITE_CURRENCY_LIST,
} from 'store/actions/FavouritesCurrency/types'

export const addFavouriteItem = (payload) => ({
  type: ADD_FAVOURITE_CURRENCY,
  payload: payload,
})

export const setFavouriteList = (payload) => ({
  type: SET_FAVOURITE_CURRENCY_LIST,
  payload: payload,
})
