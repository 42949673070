// Modules
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Constants
import { mainTabsValues } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'

import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Store
import {
  getClosedTransactionsList,
  getOpenTransactionsList,
  getPendingTransactionsList,
} from 'store/actions/OrderBar/actions'
import {
  closedPositionsListPaginationSelector,
  mainTabsValueSelector,
  pendingOrdersListPaginationSelector,
  positionsListPaginationSelector,
} from 'store/actions/OrderBar/selectors'

export const paginationDefault = { total: 0, page: 1 }
export const paginationPerPage = 20

function useOrderBottomInfoPagination() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { userTradingAccountId } = useUserTradingAccountId()

  const [canPreviousPage, setCanPreviousPage] = useState(false)
  const [canNextPage, setCanNextPage] = useState(false)

  const mainTabsValue = useSelector(mainTabsValueSelector) // order tab
  const pendingOrdersListPagination = useSelector(pendingOrdersListPaginationSelector)
  const closedPositionsListPagination = useSelector(closedPositionsListPaginationSelector)
  const positionsListPagination = useSelector(positionsListPaginationSelector)
  // console.log('positionsListPagination', positionsListPagination)
  // console.log('closedPositionsListPagination', closedPositionsListPagination)
  // console.log('pendingOrdersListPagination', pendingOrdersListPagination)

  // const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' })
  // const [deleteItemAlert, setDeleteItemAlert] = useState({})

  const paginationInfo = useMemo(() => {
    switch (mainTabsValue) {
      case mainTabsValues?.positions:
        return positionsListPagination
      case mainTabsValues?.pendingOrders:
        return pendingOrdersListPagination
      case mainTabsValues?.closedPositions:
        return closedPositionsListPagination
      default:
        return paginationDefault
    }
  }, [
    mainTabsValue,
    pendingOrdersListPagination,
    closedPositionsListPagination,
    positionsListPagination,
  ])

  const sentRequest = useCallback(
    (pageValue) => {
      if (!userTradingAccountId) return null

      switch (mainTabsValue) {
        case mainTabsValues?.positions:
          dispatch(
            getOpenTransactionsList({
              tradingAccountId: userTradingAccountId,
              page: pageValue,
            }),
          )

          break
        case mainTabsValues?.pendingOrders:
          dispatch(
            getPendingTransactionsList({
              tradingAccountId: userTradingAccountId,
              page: pageValue,
            }),
          )
          break
        case mainTabsValues?.closedPositions:
          dispatch(
            getClosedTransactionsList({
              tradingAccountId: userTradingAccountId,
              page: pageValue,
            }),
          )
          break
      }
    },
    [mainTabsValue, dispatch, userTradingAccountId],
  )

  const onNextPage = useCallback(() => {
    sentRequest(Number(paginationInfo.page) + 1)
  }, [sentRequest, paginationInfo])

  const onPreviousPage = useCallback(() => {
    sentRequest(Number(paginationInfo.page) - 1)
  }, [sentRequest, paginationInfo])

  // console.log('paginationInfo', paginationInfo)

  return {
    paginationInfo,
    onPreviousPage,
    onNextPage,
    onPage: sentRequest,
  }
}

export default useOrderBottomInfoPagination
