// Action Types
import {
  ADD_EXPANDABLE_ACCORDION_INDS,
  DELETE_EXPANDABLE_ACCORDION_INDS,
  SET_ACCORDION_SEARCH_VALUE,
  SET_ACTIVE_MARKET_TOP_TAB,
  SET_EXPANDABLE_ACCORDION_INDS,
  WS_SET_SOCKET_CURRENCY_PARAMETERS,
} from 'store/actions/marketSidebar/types'

export const setExpandableAccordionInds = (payload) => ({
  type: SET_EXPANDABLE_ACCORDION_INDS,
  payload: payload,
})

export const addExpandableAccordionInd = (payload) => ({
  type: ADD_EXPANDABLE_ACCORDION_INDS,
  payload: payload,
})

export const deleteExpandableAccordionInd = (payload) => ({
  type: DELETE_EXPANDABLE_ACCORDION_INDS,
  payload: payload,
})

export const setActiveMarketTopTab = (payload) => ({
  type: SET_ACTIVE_MARKET_TOP_TAB,
  payload: payload,
})

export const wsSetSocketCurrencyParameters = (payload) => ({
  type: WS_SET_SOCKET_CURRENCY_PARAMETERS,
  payload: payload,
})

export const setAccordionSearchValue = (payload) => ({
  type: SET_ACCORDION_SEARCH_VALUE,
  payload: payload,
})
