// Modules
import _ from 'lodash'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import MarketWatchMobileUI from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketWatchMobileUI'
import MarketWatchUI from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketWatchUI'
import { tabsList } from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs/constants'

// Hooks
import useMarketWatchAccordionList from 'hooks/useMarketWatchAccordionList'
import useTradeInfoModal from 'hooks/useTradeInfoModal'

// Utils
import { searchByName } from 'utils/findElemInListCurrency'

// Store
import {
  currencyListLoadingSelector,
  currencyListSelector,
} from 'store/actions/CurrencySocket/selectors'
import { isBottomNavOpenSelector } from 'store/actions/MainGraphBlockWidth/selectors'
import { isMobileSelector } from 'store/actions/User/selectors'
import {
  setAccordionSearchValue,
  setActiveMarketTopTab,
  setExpandableAccordionInds,
  wsSetSocketCurrencyParameters,
} from 'store/actions/marketSidebar/actions'
import {
  accordionSearchValueSelector,
  marketTopTabValueSelector,
} from 'store/actions/marketSidebar/selectors'

const MarketWatchContainer = (props) => {
  const { onLeftNavClose } = props
  const { titlesAccordion, updatedFavourites } = useMarketWatchAccordionList()
  const { onOpenTradeInfoModal } = useTradeInfoModal()
  const isMobile = useSelector(isMobileSelector)

  const dispatch = useDispatch()
  const list = useSelector(currencyListSelector)
  const loadingList = useSelector(currencyListLoadingSelector)
  const topTabValue = useSelector(marketTopTabValueSelector)
  const searchValue = useSelector(accordionSearchValueSelector)

  const isBottomNavOpen = useSelector(isBottomNavOpenSelector)

  // const [searchValue, setSearchValue] = useState('')
  // const [topTabValue, setTopTabValue] = useState(tabsList[0].id)

  useEffect(() => {
    if (searchValue === '') {
      const firstListId = list?.[0]?.id
      if (!firstListId) return

      dispatch(setExpandableAccordionInds([firstListId]))
    }
  }, [dispatch, list, searchValue])

  const filtered = useMemo(() => {
    const inputValueTrimmed = searchValue?.trim()?.toLowerCase()

    // if tab === all
    if (topTabValue === tabsList[0].id) {
      if (inputValueTrimmed !== '' && inputValueTrimmed?.length >= 3) {
        let objResult = {}
        titlesAccordion?.map((groupId) => {
          const listArr = searchByName(inputValueTrimmed, list?.[groupId])
          if (listArr?.length === 0) return
          objResult = { ...objResult, [groupId]: listArr }
        })

        return objResult
      } else {
        // if searched value is empty
        return list
      }
    }
  }, [searchValue, titlesAccordion, list, topTabValue])

  const favourites = useMemo(() => {
    const inputValueTrimmed = searchValue?.trim()?.toLowerCase()

    // If tab === favourite
    if (topTabValue !== tabsList[0].id) {
      if (inputValueTrimmed !== '' && inputValueTrimmed?.length >= 3) {
        return searchByName(inputValueTrimmed, updatedFavourites)
      } else {
        return updatedFavourites
      }
    }
  }, [searchValue, updatedFavourites, topTabValue])

  const onSearch = useCallback(
    (inputValue) => {
      console.log('onSearch', inputValue)
      dispatch(setAccordionSearchValue(inputValue))
      // setSearchValue(inputValue)
      const inputValueTrimmed = inputValue?.trim()?.toLowerCase()

      if (inputValueTrimmed === '') {
        // setSearchValue('')
        dispatch(setAccordionSearchValue(''))
      }

      // If tab === all
      if (topTabValue === tabsList[0].id) {
        if (inputValueTrimmed === '' || inputValueTrimmed?.length < 3) {
          dispatch(setExpandableAccordionInds([titlesAccordion?.[0]]))
          dispatch(wsSetSocketCurrencyParameters({ groups: [], channels: [] }))
        } else {
          let objResult = {}
          titlesAccordion?.map((groupId) => {
            const listArr = searchByName(inputValueTrimmed, list?.[groupId])
            if (listArr?.length === 0) return
            objResult = { ...objResult, [groupId]: listArr }
          })

          // Get ids from filtered list where list length > 9 to open all accordion tabs
          console.log('objResult search', objResult)
          const labels = Object.values(objResult).flatMap((items) => _.map(items, 'label'))
          console.log('objResult labels', labels)
          const openedAccordionIds = objResult ? _.keys(objResult) : []
          dispatch(setExpandableAccordionInds(openedAccordionIds))
          dispatch(wsSetSocketCurrencyParameters({ groups: [], channels: labels }))
        }
      }
    },
    [list, dispatch, titlesAccordion, topTabValue],
  )

  const onChooseTab = useCallback(
    (value) => {
      dispatch(setActiveMarketTopTab(value))
      // setTopTabValue(value)
      // setSearchValue('')
      dispatch(setAccordionSearchValue(''))
      if (value === tabsList[0]?.id) {
        dispatch(setExpandableAccordionInds([titlesAccordion?.[0]]))
      }
      if (value === tabsList[1].id) {
        dispatch(setExpandableAccordionInds([]))
      }
    },
    [dispatch, titlesAccordion, dispatch],
  )

  return isMobile ? (
    <MarketWatchMobileUI
      topTabValue={topTabValue}
      onChooseTab={onChooseTab}
      searchValue={searchValue}
      onSearch={onSearch}
      loadingList={loadingList}
      filtered={filtered}
      favourites={favourites}
      onOpenTradeInfoModal={onOpenTradeInfoModal}
    />
  ) : (
    <MarketWatchUI
      onLeftNavClose={onLeftNavClose}
      topTabValue={topTabValue}
      onChooseTab={onChooseTab}
      searchValue={searchValue}
      onSearch={onSearch}
      loadingList={loadingList}
      isBottomNavOpen={isBottomNavOpen}
      filtered={filtered}
      favourites={favourites}
      onOpenTradeInfoModal={onOpenTradeInfoModal}
    />
  )
}

export default memo(MarketWatchContainer)
