// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_BTN_CLICK_TO_COPY } from 'locales/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Button, Text, Tooltip } from '@chakra-ui/react'

const CopyBtnLikeInput = ({ value, tooltipTitle = LN_BTN_CLICK_TO_COPY, ...args }) => {
  const { isLightTheme } = useCurrentColorTheme()
  const { t } = useTranslation()

  return (
    <Tooltip label={t(tooltipTitle)} aria-label={tooltipTitle?.toLowerCase()}>
      <Button
        bg={isLightTheme ? 'gray.100' : 'whiteAlpha.50'}
        onClick={() => {
          navigator.clipboard.writeText(value)
        }}
        _hover={{
          bg: isLightTheme ? 'gray.200' : 'whiteAlpha.100',
        }}
        w="100%"
        justifyContent="start"
        {...args}
      >
        <Text fontSize="md" fontWeight={400} overflow="hidden">
          {value}
        </Text>
      </Button>
    </Tooltip>
  )
}

export default memo(CopyBtnLikeInput)
