// Modules
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { LiaEuroSignSolid } from 'react-icons/lia'
import {
  PiBankLight,
  PiBookmarkSimpleDuotone,
  PiChartPieSliceFill,
  PiColumnsFill,
  PiFireFill,
} from 'react-icons/pi'
import { TbCloudFilled } from 'react-icons/tb'
import { useSelector } from 'react-redux'

// Constants
import { CurrencyGroups, FavouriteGroup } from 'constants/currency'

// Utils
import { findElemInListCurrency } from 'utils/findElemInListCurrency'

// Store
import { currencyListSelector } from 'store/actions/CurrencySocket/selectors'
import { favouritesCurrencyListSelector } from 'store/actions/FavouritesCurrency/selectors'

function useMarketWatchAccordionList() {
  const list = useSelector(currencyListSelector)
  const favouritesTrade = useSelector(favouritesCurrencyListSelector)

  const titlesAccordion = useMemo(() => (list ? _.keys(list) : []), [list])

  const getIconAccordion = useCallback((groupId) => {
    switch (groupId) {
      case FavouriteGroup:
        return PiBookmarkSimpleDuotone
      case CurrencyGroups.forex:
        return LiaEuroSignSolid
      case CurrencyGroups.stocks:
        return PiBankLight
      case CurrencyGroups.indices:
        return PiChartPieSliceFill
      case CurrencyGroups.crypto:
        return TbCloudFilled
      case CurrencyGroups.commodities:
        return PiFireFill
      case CurrencyGroups.metals:
        return PiColumnsFill
      default:
        return PiBankLight
    }
  }, [])

  const updatedFavourites = useMemo(() => {
    const updatedFavouritesList = favouritesTrade?.map((el) => {
      const updatedItem = findElemInListCurrency(el?.name, list)
      if (updatedItem) {
        return { ...el, price: updatedItem?.price, spread: updatedItem?.spread }
      } else {
        return el
      }
    })
    return updatedFavouritesList
  }, [favouritesTrade, list])

  const getCurrencyCategoryByName = useCallback(
    (currencyName) => getCurrencyCategoryByNameHelper(currencyName, list, titlesAccordion),
    [list, titlesAccordion],
  )

  return { titlesAccordion, getIconAccordion, updatedFavourites, getCurrencyCategoryByName }
}

export default useMarketWatchAccordionList

export const getCurrencyCategoryByNameHelper = (currencyName, list, titlesAccordion) => {
  for (let i = 0; i < titlesAccordion.length; i++) {
    const category = titlesAccordion[i]
    const currency = _.find(list[category], { name: currencyName })
    if (currency) {
      return category
    }
  }
  return null
}
