// Types
import {
  GET_DEPOSIT_USER_HISTORY,
  GET_USER_DEPOSIT_FIELDS,
  SET_DEPOSIT_MODAL_ITEM_INFO,
  SET_DEPOSIT_USER_HISTORY,
  SET_IS_DEPOSIT_MODAL_INFO_OPEN,
  SET_LOADING_DEPOSIT_USER_HISTORY,
  SET_LOADING_USER_DEPOSIT_FIELDS,
  SET_USER_DEPOSIT_FIELDS,
} from 'store/actions/UserDeposit/types'

const initialState = {
  depositFields: null,
  loadingDepositFields: false,

  depositHistory: null,
  loadingDepositHistory: false,

  isDepositModalInfoOpen: false,
  modalDepositItemInfo: null,
}

const userDepositReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DEPOSIT_FIELDS:
      return {
        ...state,
        loadingDepositFields: true,
      }
    case SET_USER_DEPOSIT_FIELDS:
      return {
        ...state,
        depositFields: action.payload,
        loadingDepositFields: false,
      }
    case SET_LOADING_USER_DEPOSIT_FIELDS:
      return {
        ...state,
        loadingDepositFields: action.payload,
      }
    case GET_DEPOSIT_USER_HISTORY:
      return {
        ...state,
        loadingDepositHistory: true,
      }
    case SET_DEPOSIT_USER_HISTORY:
      return {
        ...state,
        depositHistory: action.payload,
        loadingDepositHistory: false,
      }
    case SET_LOADING_DEPOSIT_USER_HISTORY:
      return {
        ...state,
        loadingDepositHistory: action.payload,
      }

    case SET_DEPOSIT_MODAL_ITEM_INFO:
      return {
        ...state,
        modalDepositItemInfo: action.payload,
      }
    case SET_IS_DEPOSIT_MODAL_INFO_OPEN:
      return {
        ...state,
        isDepositModalInfoOpen: action.payload,
      }
    default:
      return state
  }
}
export default userDepositReducer
