// Hooks
import { themeColorMode } from 'hooks/useCurrentColorTheme'

export const popoverStyles = {
  Popover: {
    parts: ['content', 'header', 'body', 'footer', 'popper', 'arrow', 'closeButton'],
    baseStyle: ({ colorMode }) => ({
      content: {
        backgroundColor: colorMode === themeColorMode.light ? 'white' : 'brand.mainColor4',
        border: colorMode === themeColorMode.light ? '2px solid' : '1px solid',
        borderColor:
          colorMode === themeColorMode.light ? 'brand.borderColorLight' : 'brand.mainColor2',
      },
      arrow: {
        background:
          colorMode === themeColorMode.light
            ? 'brand.modalBgLight!important'
            : 'brand.mainColor4!important',
      },
    }),
    variants: {},
  },
}
