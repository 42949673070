// Modules
import { memo } from 'react'

function CloseTradeIcon(props) {
  const { fill = '#000000', w = 32, h = 32 } = props

  return (
    <svg version="1.1" width={w} height={h} x="0" y="0" viewBox="0 0 512 512">
      <path
        d="M498.526 444.632c7.434 0 13.474-6.026 13.474-13.474s-6.04-13.474-13.474-13.474H336.842c-7.447 0-13.474 6.026-13.474 13.474s6.026 13.474 13.474 13.474z"
        fill={fill}
        opacity="1"
        data-original={fill}
        className=""
      />
      <path
        d="M67.368 444.632h215.579c7.434 0 13.474-6.026 13.474-13.474s-6.04-13.474-13.474-13.474H67.368c-22.289 0-40.421-18.132-40.421-40.421v-148.21h458.105v148.211c0 7.447 6.026 13.474 13.474 13.474 7.434 0 13.474-6.026 13.474-13.474V134.737c0-37.145-30.237-67.368-67.368-67.368H67.368C30.21 67.368 0 97.592 0 134.737v242.526c0 37.145 30.21 67.369 67.368 67.369zm0-350.316h377.263c22.289 0 40.421 18.132 40.421 40.421v67.368H26.947v-67.368c0-22.29 18.132-40.421 40.421-40.421z"
        fill={fill}
        opacity="1"
        data-original={fill}
        className=""
      />
      <path
        d="M121.263 309.895c-7.447 0-13.474 6.026-13.474 13.474s6.026 13.474 13.474 13.474h53.895c7.434 0 13.474-6.026 13.474-13.474s-6.04-13.474-13.474-13.474z"
        fill={fill}
        opacity="1"
        data-original={fill}
        className=""
      />
    </svg>
  )
}

export default memo(CloseTradeIcon)
