// UI
import { Heading, useBreakpointValue } from '@chakra-ui/react'

const HeadingBase = (props) => {
  const { children, size, ...args } = props
  return (
    <Heading size={size} color="brand.textColor1" {...args}>
      {children}
    </Heading>
  )
}

export const HeadingXs = (props) => <HeadingBase size="xs" {...props} />

export const HeadingSm = (props) => <HeadingBase size="sm" {...props} />

export const HeadingLg = (props) => <HeadingBase size="md" {...props} />

export const HeadingXl = (props) => {
  const isMobile = useBreakpointValue(
    {
      md: false,
      base: true,
    },
    { ssr: false },
  )

  return <HeadingBase fontSize={isMobile ? '24px' : '30px'} {...props} />
}
