// Modules
import { memo } from 'react'
import { PiBookmarkSimpleDuotone } from 'react-icons/pi'

// Components
import { TextXs } from 'components'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Flex, Icon } from '@chakra-ui/react'

const FavouritesCurrencyHint = ({ ...args }) => {
  const { mainTextColor } = useCurrentColorTheme()

  return (
    <Flex bg="whiteAlpha.50" p={4} pt={2} pb={2} gap={1} borderRadius="md" {...args}>
      <TextXs color={mainTextColor}>To add currency to favourites use icon </TextXs>
      <Icon as={PiBookmarkSimpleDuotone} color="gray.200" w="16px" h="16px" transition="0.8s" />
    </Flex>
  )
}

export default memo(FavouritesCurrencyHint)
