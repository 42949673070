// Modules
import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import KYCField from './components/KYCField'
import { sidebarList } from 'pages/User/components/Sidebar/constants'

// Store
import { deleteKYCField, getKYCFieldsList, sendKYCField } from 'store/actions/KYC/actions'
import { kycFieldListSelector } from 'store/actions/KYC/selectors'

// UI
import { Box, Card, CardBody } from '@chakra-ui/react'

// Components
import { MainTitle } from '../Withdrawal'

const Verification = () => {
  const [fields, setFields] = useState({})

  const dispatch = useDispatch()
  const kycFieldList = useSelector(kycFieldListSelector)

  useEffect(() => {
    dispatch(getKYCFieldsList())
  }, [dispatch])

  useEffect(() => {
    setFields(kycFieldList)
  }, [kycFieldList])

  const sendImageHandler = useCallback(
    (fieldName) => {
      dispatch(sendKYCField({ name: fieldName, file: fields[fieldName].file }))
    },
    [dispatch, fields],
  )

  const setFileToImage = useCallback(
    (field) => {
      const [file] = field.files
      const url = URL.createObjectURL(file)
      if (file) {
        setFields({ ...fields, [field.name]: { ...fields[field.name], url, file: file } })
      }
    },
    [setFields, fields],
  )
  const deleteFieldHandler = useCallback(
    (fieldName) => {
      dispatch(deleteKYCField({ name: fieldName }))
    },
    [dispatch],
  )

  return (
    <Box w="100%" p={3}>
      <MainTitle title={sidebarList[3]?.title} />
      <Card bg="whiteAlpha.50" borderRadius="md" border="1px solid" borderColor="whiteAlpha.100">
        <CardBody>
          {fields &&
            Object.keys(fields).map((field) => (
              <KYCField
                deleteFieldHandler={deleteFieldHandler}
                key={field.filename}
                {...fields[field]}
                changeHandler={setFileToImage}
                sendImageHandler={sendImageHandler}
              />
            ))}
        </CardBody>
      </Card>
    </Box>
  )
}

export default memo(Verification)
