// Modules
import { memo } from 'react'

// Components
import ActiveTradeInnerElements from 'pages/Dashboard/components/Main/ActiveTradeBlock/ActiveTradeInnerElements'

// Utils
import { getHorizontalScrollbarCss } from 'utils/scrollbarHelper'

// UI
import { Box, Flex } from '@chakra-ui/react'

const ActiveTradeBlockMobile = (props) => {
  const { activeTradeItemsList, chosenActiveTradeItem, onAddNew, onActive, onCloseAll, width } =
    props

  return (
    <>
      <Box width={width + 'px'}>
        <Flex
          gap={{ base: 2, '2sm': 4 }}
          overflowX="auto"
          pb={2}
          css={getHorizontalScrollbarCss()}
          mb="10px"
          position="relative"
        >
          <ActiveTradeInnerElements
            activeTradeItemsList={activeTradeItemsList}
            chosenActiveTradeItem={chosenActiveTradeItem}
            onAddNew={onAddNew}
            onActive={onActive}
            onCloseAll={onCloseAll}
          />
        </Flex>
      </Box>
    </>
  )
}

export default memo(ActiveTradeBlockMobile)
