// Modules
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

// Components
import CalcDescription from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/ProfitCalculator/ProfitCalculatorModal/CalcDescription'
import CalcForm, {
  fieldsName,
} from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/ProfitCalculator/ProfitCalculatorModal/CalcForm'
import { ButtonValues } from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/ProfitCalculator/ProfitCalculatorModal/constants'
import {
  RADIO_BY_PROFIT,
  RADIO_BY_RATE,
} from 'pages/Dashboard/components/TransactionCreationSidebar/constants'

import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'

// Utils
import {
  getCurrentCurrencyAssertValue,
  getLossFromSL,
  getProfitFromTP,
  getROE,
  getRequiredMargin,
  typeTransaction,
} from 'utils/transactionFormulas'

// Context
import { MarketCalcContext } from 'context/MarketCalcContext'

// Store
import { chosenActiveTradeItemSelector } from 'store/actions/ActiveTradeItems/selectors'
import { assertListSelector } from 'store/actions/TransactionCreation/selectors'

// UI
import { Box, Flex } from '@chakra-ui/react'

const CalcModalContent = () => {
  const chosenActiveTradeItem = useSelector(chosenActiveTradeItemSelector)
  const assertList = useSelector(assertListSelector)

  const {
    currentCurrencyLeverageValue,
    currentCurrencyContractSizeValue,
    currentCurrencySymbolPointValue,
    currentCurrencySpreadValue,

    unchangeableSocketPrice,
    currencyRate,
  } = useContext(MarketCalcContext)

  const { defaultVolumeOnPlatform } = useCheckUserRestrictions()

  const [descValues, setDescValues] = useState({
    requiredMargin: 0,
    profitFromTP: 0,
    lossFromSL: 0,
    roe: 0,
  })

  const currencyContractSize = useMemo(() => {
    const val = getCurrentCurrencyAssertValue(assertList, chosenActiveTradeItem)
    return val?.symbol_contract_size ?? 0
  }, [assertList, chosenActiveTradeItem])

  // const rateValue = useMemo(() => {
  //   return rates?.USD
  // }, [rates])

  const onCalcValues = useCallback(
    (
      volume,
      valueTypeTransaction,
      takeProfitRadio,
      takeProfit,
      entryPrice,
      stopLossRadio,
      stopLoss,
    ) => {
      const requiredMargin = getRequiredMargin(
        volume,
        unchangeableSocketPrice,
        currentCurrencyContractSizeValue,
        currentCurrencyLeverageValue?.id,
        currencyRate,
      )

      const transactionTypeVal =
        valueTypeTransaction === ButtonValues.buy ? typeTransaction?.buy : typeTransaction?.sell

      const profitFromTP =
        takeProfitRadio === RADIO_BY_PROFIT
          ? takeProfit
          : getProfitFromTP(
              takeProfit,
              volume,
              entryPrice,
              currentCurrencySpreadValue,
              transactionTypeVal,
              currencyRate,
              currencyContractSize,
            )

      const lossFromSL =
        stopLossRadio === RADIO_BY_PROFIT
          ? stopLoss
          : getLossFromSL(
              stopLoss,
              volume,
              entryPrice,
              currentCurrencySpreadValue,
              transactionTypeVal,
              currencyRate,
              currencyContractSize,
            )

      const roeValue = getROE(profitFromTP, requiredMargin)

      return {
        requiredMargin: requiredMargin,
        profitFromTP: profitFromTP,
        lossFromSL: lossFromSL,
        roe: roeValue,
      }
    },
    [
      unchangeableSocketPrice,
      currentCurrencySpreadValue,
      currentCurrencyContractSizeValue,
      currentCurrencyLeverageValue?.id,
      currencyContractSize,
      currencyRate,
    ],
  )

  const onGetDefaultValues = useCallback(() => {
    const typeTransactionDefault = ButtonValues.buy

    const calc = onCalcValues(
      defaultVolumeOnPlatform,
      typeTransactionDefault,
      RADIO_BY_RATE,
      unchangeableSocketPrice,
      unchangeableSocketPrice,
      RADIO_BY_RATE,
      unchangeableSocketPrice,
    )

    setDescValues(calc)
  }, [onCalcValues, unchangeableSocketPrice, defaultVolumeOnPlatform])

  useEffect(() => {
    onGetDefaultValues()
  }, [onGetDefaultValues])

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      const calc = onCalcValues(
        values?.[fieldsName?.volume],
        values?.[fieldsName.typeTransaction],
        values?.[fieldsName?.takeProfitRadio],
        values?.[fieldsName?.takeProfit],
        values?.[fieldsName?.entryPrice],
        values?.[fieldsName?.stopLossRadio],
        values?.[fieldsName?.stopLoss],
      )

      setDescValues(calc)
      setSubmitting(false)
    },
    [onCalcValues],
  )

  const onResetToDefaultForm = useCallback(() => {
    onGetDefaultValues()
  }, [onGetDefaultValues])

  return (
    <Flex gap={3}>
      <Box flex="1">
        <CalcForm handleSubmit={handleSubmit} onResetToDefaultForm={onResetToDefaultForm} />
      </Box>
      <CalcDescription
        activeCurrencyName={chosenActiveTradeItem?.name}
        leverage={currentCurrencyLeverageValue?.name}
        pip={currentCurrencySymbolPointValue}
        descValues={descValues}
      />
    </Flex>
  )
}

export default memo(CalcModalContent)
