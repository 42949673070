// Modules
import _ from 'lodash'

// Constants
import {
  mainTabsList,
  secondaryTabsList,
} from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'
import {
  ordersBarPositionsLengthDefaultValue,
  ordersBarPositionsLengthKey,
} from 'pages/Dashboard/components/OrdersBottomBar/constants'

import { paginationDefault } from 'hooks/useOrderBottomPagination'

// Types
import {
  GET_CLOSED_TRANSACTIONS_LIST,
  GET_OPEN_TRANSACTIONS_LIST,
  GET_PENDING_TRANSACTIONS_LIST,
  GET_USER_TRANSACTIONS_LIST,
  SET_CLOSED_TRANSACTIONS_LIST,
  SET_CLOSED_TRANSACTIONS_LIST_LOADING,
  SET_CLOSED_TRANSACTIONS_LIST_PAGINATION,
  SET_IS_FULL_SIZE_MODAL,
  SET_MAIN_TABS_VALUE,
  SET_OPEN_TRANSACTIONS_LIST,
  SET_OPEN_TRANSACTIONS_LIST_LOADING,
  SET_OPEN_TRANSACTIONS_LIST_PAGINATION,
  SET_PENDING_ORDERS_LIST,
  SET_PENDING_ORDERS_LIST_PAGINATION,
  SET_PENDING_TRANSACTIONS_LIST_LOADING,
  SET_POSITIONS_LENGTH,
  SET_SEARCH_VALUE,
  SET_SECONDARY_TABS_VALUE,
  SET_SELECT_TIME_VALUE,
  SET_USER_TRANSACTIONS_LIST,
  SET_USER_TRANSACTIONS_LIST_LOADING,
  TRANSACTION_CONTENT_BAR_LOADING,
  WS_FOLLOW_PROFITS_CHANGE_VALUE,
} from 'store/actions/OrderBar/types'

const initialState = {
  positionsList: null,
  positionsListPagination: paginationDefault,

  closedPositionsList: null,
  closedPositionsListPagination: paginationDefault,

  pendingOrdersList: null,
  pendingOrdersListPagination: paginationDefault,

  mainTabsValue: mainTabsList?.[0]?.value,
  secondaryTabsValue: secondaryTabsList?.[0]?.value,
  searchValue: '',
  selectTimeValue: '',
  isFullSizeModal: false,

  userTransactionsList: null,
  userTransactionsListLoading: false,
  positionsLength:
    JSON.parse(localStorage.getItem(ordersBarPositionsLengthKey)) ||
    ordersBarPositionsLengthDefaultValue,

  openListLoading: false,
  closedListLoading: false,
  pendingListLoading: false,

  loadingBar: true,
}

const orderBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAIN_TABS_VALUE:
      return {
        ...state,
        mainTabsValue: action.payload,
      }
    case SET_SECONDARY_TABS_VALUE:
      return {
        ...state,
        secondaryTabsValue: action.payload,
      }
    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      }
    case SET_SELECT_TIME_VALUE:
      return {
        ...state,
        selectTimeValue: action.payload,
      }
    case SET_IS_FULL_SIZE_MODAL:
      return {
        ...state,
        isFullSizeModal: action.payload,
      }

    case SET_USER_TRANSACTIONS_LIST:
      return {
        ...state,
        userTransactionsList: action.payload,
      }
    case GET_USER_TRANSACTIONS_LIST:
      return {
        ...state,
        userTransactionsListLoading: true,
      }
    case SET_USER_TRANSACTIONS_LIST_LOADING:
      return {
        ...state,
        userTransactionsListLoading: action.payload,
      }
    case SET_POSITIONS_LENGTH:
      return {
        ...state,
        positionsLength: action.payload,
      }

    // open
    case SET_OPEN_TRANSACTIONS_LIST:
      return {
        ...state,
        positionsList: action.payload,
      }
    case SET_OPEN_TRANSACTIONS_LIST_PAGINATION:
      return {
        ...state,
        positionsListPagination: { ...state.positionsListPagination, ...action.payload },
      }
    case GET_OPEN_TRANSACTIONS_LIST:
      return {
        ...state,
        openListLoading: true,
      }
    case SET_OPEN_TRANSACTIONS_LIST_LOADING:
      return {
        ...state,
        openListLoading: action.payload,
      }
    // pending
    case SET_PENDING_ORDERS_LIST:
      return {
        ...state,
        pendingOrdersList: action.payload,
      }
    case SET_PENDING_ORDERS_LIST_PAGINATION:
      return {
        ...state,
        pendingOrdersListPagination: { ...state.pendingOrdersListPagination, ...action.payload },
      }
    case GET_PENDING_TRANSACTIONS_LIST:
      return {
        ...state,
        pendingListLoading: true,
      }
    case SET_PENDING_TRANSACTIONS_LIST_LOADING:
      return {
        ...state,
        pendingListLoading: action.payload,
      }
    // closed
    case SET_CLOSED_TRANSACTIONS_LIST:
      return {
        ...state,
        closedPositionsList: action.payload,
      }
    case SET_CLOSED_TRANSACTIONS_LIST_PAGINATION:
      return {
        ...state,
        closedPositionsListPagination: {
          ...state.closedPositionsListPagination,
          ...action.payload,
        },
      }
    case GET_CLOSED_TRANSACTIONS_LIST:
      return {
        ...state,
        closedListLoading: true,
      }
    case SET_CLOSED_TRANSACTIONS_LIST_LOADING:
      return {
        ...state,
        closedListLoading: action.payload,
      }
    case TRANSACTION_CONTENT_BAR_LOADING:
      return {
        ...state,
        loadingBar: action.payload,
      }
    // profit socket
    case WS_FOLLOW_PROFITS_CHANGE_VALUE:
      const tradeId = action.payload?.tradeId

      let transformOpen = _.cloneDeep(state.positionsList)
      let transformPending = _.cloneDeep(state.pendingOrdersList)

      const itemOpenIndexToChange = _.findIndex(state.positionsList, { id: tradeId })
      const itemPendingIndexToChange = _.findIndex(state.pendingOrdersList, { id: tradeId })

      if (itemOpenIndexToChange !== -1) {
        transformOpen[itemOpenIndexToChange] = {
          ...transformOpen[itemOpenIndexToChange],
          current_rate: action.payload?.currentRate,
          profit: action.payload?.profit,
        }
      }
      if (itemPendingIndexToChange !== -1) {
        transformPending[itemPendingIndexToChange] = {
          ...transformPending[itemPendingIndexToChange],
          current_rate: action.payload?.currentRate,
          profit: action.payload?.profit,
        }
      }

      return {
        ...state,
        positionsList: transformOpen,
        pendingOrdersList: transformPending,
      }

    default:
      return state
  }
}
export default orderBarReducer
