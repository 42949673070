// Modules
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

// Hooks
import useMarketWatchAccordionList from 'hooks/useMarketWatchAccordionList'

// Store
import { addActiveTradeItem, setActiveTradeItem } from 'store/actions/ActiveTradeItems/actions'

function useCurrencyActiveItemItem(element) {
  const dispatch = useDispatch()
  const { getCurrencyCategoryByName } = useMarketWatchAccordionList()

  const onAddItem = useCallback(() => {
    const category = getCurrencyCategoryByName(element?.name)
    console.log('element', element)

    dispatch(setActiveTradeItem({ ...element, category }))
    dispatch(addActiveTradeItem({ ...element, category }))
  }, [dispatch, element, getCurrencyCategoryByName])

  return { onAddItem }
}

export default useCurrencyActiveItemItem
