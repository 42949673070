// Types
import {
  SET_INFO_MODAL_TRADE_ITEM,
  SET_IS_OPEN_MODAL_INFO,
} from 'store/actions/InfoModalTradeItem/types'

const initialState = {
  chosenInfoModalTradeItem: null,
  isOpenInfoModal: false,
}

const infoModalTradeItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO_MODAL_TRADE_ITEM:
      return {
        ...state,
        chosenInfoModalTradeItem: action.payload,
      }
    case SET_IS_OPEN_MODAL_INFO:
      return {
        ...state,
        isOpenInfoModal: action.payload,
      }
    default:
      return state
  }
}
export default infoModalTradeItemReducer
