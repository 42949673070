// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Components
import { secondaryTabsList } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'
import TabBtn from 'pages/Dashboard/components/OrdersBottomBar/TabBtn'

import useOrderBottomInfo from 'hooks/useOrderBottomInfo'

// Store
import { secondaryTabsValueSelector } from 'store/actions/OrderBar/selectors'

const SecondaryTabs = (props) => {
  const { ...args } = props

  const { t } = useTranslation()

  const secondaryTabsValue = useSelector(secondaryTabsValueSelector)
  const { onHandleSecondaryTabs } = useOrderBottomInfo()

  return (
    <>
      {secondaryTabsList?.map((el) => {
        return (
          <TabBtn
            active={el?.value === secondaryTabsValue}
            key={el?.value}
            title={t(el?.title)}
            onClick={() => onHandleSecondaryTabs(el?.value)}
            pl={3}
            pr={3}
            {...args}
          />
        )
      })}
    </>
  )
}

export default memo(SecondaryTabs)
