// Modules
import { memo, useMemo } from 'react'

// Components
import { TextXs } from 'components'

// Utils
import { roundDigitsAfterDots } from 'utils/formatDigitsWithSpaces'
import { formatPrice } from 'utils/formatPrice'

export const typePriceConst = {
  sell: 'sell',
  buy: 'buy',
}

const PriceText = ({ price, typePrice, symbolPoint }) => {
  const textColor = useMemo(() => {
    if (typePrice === typePriceConst.buy) {
      return 'green.200'
    } else if (typePrice === typePriceConst.sell) {
      return 'red.100'
    } else {
      return 'white'
    }
  }, [typePrice])

  return (
    <TextXs fontWeight={500} color={textColor}>
      {/*TODO: not right round*/}
      {formatPrice(roundDigitsAfterDots(price, symbolPoint))}
    </TextXs>
  )
}
export default memo(PriceText)
