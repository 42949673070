// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const chosenInfoModalTradeItemSelector = createSelector(
  selfSelector,
  (self) => self.chosenInfoModalTradeItem,
)

export const isOpenInfoModalSelector = createSelector(selfSelector, (self) => self.isOpenInfoModal)
