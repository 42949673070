// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_AUTH_CONTINUE_BTN } from 'locales/constants'

// UI
import { Button } from '@chakra-ui/react'

const SubmitBtn = (props) => {
  const { t } = useTranslation()

  const { disabled, loading, title = t(LN_AUTH_CONTINUE_BTN) } = props

  return (
    <Button
      disabled={disabled}
      mb={4}
      w="100%"
      colorScheme="blue"
      color="white"
      type="submit"
      isLoading={loading}
    >
      {title}
    </Button>
  )
}

export default memo(SubmitBtn)
