// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const positionsListSelector = createSelector(selfSelector, (self) => self.positionsList)
export const closedPositionsListSelector = createSelector(
  selfSelector,
  (self) => self.closedPositionsList,
)
export const pendingOrdersListSelector = createSelector(
  selfSelector,
  (self) => self.pendingOrdersList,
)

export const mainTabsValueSelector = createSelector(selfSelector, (self) => self.mainTabsValue)
export const secondaryTabsValueSelector = createSelector(
  selfSelector,
  (self) => self.secondaryTabsValue,
)

export const searchValueSelector = createSelector(selfSelector, (self) => self.searchValue)
export const selectTimeValueSelector = createSelector(selfSelector, (self) => self.selectTimeValue)

export const isFullSizeModalSelector = createSelector(selfSelector, (self) => self.isFullSizeModal)

export const userTransactionsListSelector = createSelector(
  selfSelector,
  (self) => self.userTransactionsList,
)

export const userTransactionsListLoadingSelector = createSelector(
  selfSelector,
  (self) => self.userTransactionsListLoading,
)

export const positionsLengthSelector = createSelector(selfSelector, (self) => self.positionsLength)

export const openListLoadingSelector = createSelector(selfSelector, (self) => self.openListLoading)
export const closedListLoadingSelector = createSelector(
  selfSelector,
  (self) => self.closedListLoading,
)

export const pendingListLoadingSelector = createSelector(
  selfSelector,
  (self) => self.pendingListLoading,
)

export const loadingBarSelector = createSelector(selfSelector, (self) => self.loadingBar)

export const pendingOrdersListPaginationSelector = createSelector(
  selfSelector,
  (self) => self.pendingOrdersListPagination,
)

export const closedPositionsListPaginationSelector = createSelector(
  selfSelector,
  (self) => self.closedPositionsListPagination,
)

export const positionsListPaginationSelector = createSelector(
  selfSelector,
  (self) => self.positionsListPagination,
)
