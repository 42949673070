// Modules
import { memo } from 'react'

// Components
import { TextXs } from 'components'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

const NameText = ({ name }) => {
  const { isLightThemeSelector } = useCurrentColorTheme()

  return (
    <TextXs fontWeight={500} color={isLightThemeSelector ? 'brand.textColor2Light' : 'white'}>
      {name}
    </TextXs>
  )
}

export default memo(NameText)
