// Modules
import { memo } from 'react'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Text, useBreakpointValue } from '@chakra-ui/react'

// xs: "14px",
// sm: "16px",
// md: "20px",
// lg: "24px",
// xl: "30px",

const TextBase = memo((props) => {
  const { children, size, ...args } = props
  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Text
      fontSize={size}
      color={!isLightTheme ? 'brand.textColor2' : 'brand.textColor2Light'}
      {...args}
    >
      {children}
    </Text>
  )
})

export const TextXs = memo((props) => <TextBase size="xs" {...props} />)

export const TextSm = memo((props) => <TextBase size="sm" {...props} />)

export const TextMd = memo((props) => {
  const isMobile = useBreakpointValue({
    md: false,
    base: true,
  })

  return <TextBase fontSize={isMobile ? '14px' : '16px'} {...props} />
})

export const TextLg = memo((props) => <TextBase size={{ base: 'md', '2sm': 'lg' }} {...props} />)

export const TextXl = memo((props) => {
  // const fontSize = useBreakpointValue({
  //   md: '20px',
  //   base: '18px',
  // })

  return <TextBase fontSize="20px" {...props} />
})

TextXl.displayName = 'TextXl'
TextLg.displayName = 'TextLg'
TextMd.displayName = 'TextMd'
TextSm.displayName = 'TextSm'
TextXs.displayName = 'TextXs'
TextBase.displayName = 'TextBase'
