export const SET_CURRENCY_MARKET_LIST = 'SET_CURRENCY_MARKET_LIST'
export const GET_CURRENCY_MARKET_LIST = 'GET_CURRENCY_MARKET_LIST'
export const SET_CURRENCY_MARKET_LIST_LOADING = 'SET_CURRENCY_MARKET_LIST_LOADING'

export const SET_INTERVAL_GRAPH_VALUE = 'SET_INTERVAL_GRAPH_VALUE'

// WEB SOCKET
export const WS_CONNECT_CURRENCY_MARKET = 'WS_CONNECT_CURRENCY_MARKET'
export const WS_DISCONNECT_CURRENCY_MARKET = 'WS_DISCONNECT_CURRENCY_MARKET'
export const WS_CURRENCY_MARKET_MESSAGE_RECEIVED = 'WS_CURRENCY_MARKET_MESSAGE_RECEIVED'
export const WS_ERROR_CURRENCY_MARKET = 'WS_ERROR_CURRENCY_MARKET'
export const WS_CURRENCY_MARKET_CHANGE_CURRENCY_VALUE = 'WS_CURRENCY_MARKET_CHANGE_CURRENCY_VALUE'
