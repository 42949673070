// Modules
import AppInner from 'AppInner'
import { Provider } from 'react-redux'

// Components
import customTheme from 'theme/customTheme'

// Store
import store from 'store'

// UI
import { ChakraProvider } from '@chakra-ui/react'

const App = () => {
  return (
    // <I18nextProvider i18n={customI18n}>
    <Provider store={store}>
      <ChakraProvider theme={customTheme}>
        <AppInner />
      </ChakraProvider>
    </Provider>
    // </I18nextProvider>
  )
}

export default App

// import { TVChartContainer } from 'components/TVChartContainer'
//
// function App() {
//   return (
//     <>
//       <p>
//         Edit <code>src/App.js</code> and save to reload.
//       </p>
//
//       <TVChartContainer />
//     </>
//   )
// }
//
// export default App
