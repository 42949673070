// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const withdrawalHistoryListSelector = createSelector(
  selfSelector,
  (self) => self.withdrawalHistoryList,
)

export const loadingWithdrawalHistoryListSelector = createSelector(
  selfSelector,
  (self) => self.loadingWithdrawalHistoryList,
)

export const modalWithdrawalItemInfoSelector = createSelector(
  selfSelector,
  (self) => self.modalWithdrawalItemInfo,
)

export const isWithdrawalModalInfoOpenSelector = createSelector(
  selfSelector,
  (self) => self.isWithdrawalModalInfoOpen,
)
