// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_ADD_NEW_TRADE_MODAL_TABLE_ASSET,
  LN_ADD_NEW_TRADE_MODAL_TABLE_BUY,
  LN_ADD_NEW_TRADE_MODAL_TABLE_NO_DATA,
  LN_ADD_NEW_TRADE_MODAL_TABLE_SELL,
} from 'locales/constants'

// Components
import { FavouritesCurrencyHint, TextMd } from 'components'
import { favouritesKey } from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal/MenuTradeModalBody'
import TableRow from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal/TableData/TableRow'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useTradeInfoModal from 'hooks/useTradeInfoModal'

// UI
import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'

const TableData = (props) => {
  const { data, activeTab = '' } = props

  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()
  const { onOpenTradeInfoModal } = useTradeInfoModal()

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{t(LN_ADD_NEW_TRADE_MODAL_TABLE_ASSET)}</Th>
            <Th textAlign="center">{t(LN_ADD_NEW_TRADE_MODAL_TABLE_BUY)}</Th>
            {/*<Th textAlign="center">Open Price</Th>*/}
            <Th textAlign="center">{t(LN_ADD_NEW_TRADE_MODAL_TABLE_SELL)}</Th>
            {/*<Th textAlign="center">Volume</Th>*/}
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {!data || data?.length === 0 ? (
            activeTab !== favouritesKey ? (
              <TextMd mt={3} color={mainTextColor}>
                {t(LN_ADD_NEW_TRADE_MODAL_TABLE_NO_DATA)}
              </TextMd>
            ) : (
              <FavouritesCurrencyHint mt={3} />
            )
          ) : (
            data?.map((el) => (
              <TableRow key={el?.id} el={el} onOpenInfoModal={onOpenTradeInfoModal} />
            ))
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default memo(TableData)
