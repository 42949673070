export const subscriptionPlansConst = {
  bronze: {
    id: 1,
    title: 'Bronze',
  },
  silver: {
    id: 2,
    title: 'Silver',
  },
  gold: {
    id: 3,
    title: 'Gold',
  },
  platinum: {
    id: 4,
    title: 'Platinum',
  },
}

// 1 => "Bronze",
//   2 => "Silver",
//   3 => "Gold",
//   4 => "Platinum",
