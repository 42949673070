import _ from 'lodash'

export const formatPrice = (number) => {
  return formatDigitsWithDots(number, 8)
}

export const formatDigitsWithDots = (number, numOfSymbols) => {
  if (number.toString().replace('.', '').length > numOfSymbols) {
    return _.truncate(number.toString(), { length: numOfSymbols - 1 }) + '...'
  } else {
    return number
  }
}
