// Modules
import { AnimatePresence } from 'framer-motion'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import { mainTabsValues } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'
import OrdersBottomBarMobileUI from 'pages/Dashboard/components/OrdersBottomBar/OrdersBottomBarMobileUI'
import OrdersBottomBarUI from 'pages/Dashboard/components/OrdersBottomBar/OrdersBottomBarUI'

// Hooks
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Store
import { isBottomNavOpenSelector } from 'store/actions/MainGraphBlockWidth/selectors'
import {
  getClosedTransactionsList,
  getOpenTransactionsList,
  getPendingTransactionsList,
  getUserTransactionsList,
} from 'store/actions/OrderBar/actions'
import {
  closedPositionsListSelector,
  mainTabsValueSelector,
  pendingOrdersListSelector,
  positionsListSelector,
  secondaryTabsValueSelector,
} from 'store/actions/OrderBar/selectors'
import { isMobileSelector } from 'store/actions/User/selectors'

const OrdersBottomBarContainer = (props) => {
  const { onBottomBlockOpen, onBottomBlockClose } = props

  const dispatch = useDispatch()
  const { userTradingAccountId } = useUserTradingAccountId()
  const isMobile = useSelector(isMobileSelector)

  useEffect(() => {
    if (userTradingAccountId) {
      dispatch(getUserTransactionsList({ tradingAccountId: userTradingAccountId }))
    }
  }, [dispatch, userTradingAccountId])

  const isBottomNavOpen = useSelector(isBottomNavOpenSelector)
  const mainTabsValue = useSelector(mainTabsValueSelector)

  const positionsList = useSelector(positionsListSelector)
  const closedPositionsList = useSelector(closedPositionsListSelector)
  const pendingOrdersList = useSelector(pendingOrdersListSelector)

  const secondaryTabsValue = useSelector(secondaryTabsValueSelector)

  const filteringDataByType = useCallback((list, orderType) => {
    return list
    // if (orderType === secondaryTabsValues.all) return list
    // return list?.filter((el) => el?.command_id === Number(orderType))
  }, [])

  const orderList = useMemo(() => {
    let list = []
    switch (mainTabsValue) {
      case mainTabsValues?.positions:
        list = filteringDataByType(positionsList, secondaryTabsValue)
        break
      case mainTabsValues?.pendingOrders:
        list = filteringDataByType(pendingOrdersList, secondaryTabsValue)
        break
      case mainTabsValues?.closedPositions:
        list = filteringDataByType(closedPositionsList, secondaryTabsValue)
        // if (selectTimeValue) {
        //   list = filterDataByTime(list, selectTimeValue)
        // }
        break
    }

    // const searchValueTrimmed = searchValue?.trim()?.toLowerCase()
    // if (searchValueTrimmed) {
    //   return list?.filter((el) => el?.symbol?.toLowerCase().includes(searchValueTrimmed))
    // } else {
    return list
    // }
  }, [
    mainTabsValue,
    positionsList,
    pendingOrdersList,
    closedPositionsList,
    secondaryTabsValue,
    // searchValue,
    filteringDataByType,
    // selectTimeValue,
  ])

  useEffect(() => {
    if (userTradingAccountId) {
      dispatch(getOpenTransactionsList({ tradingAccountId: userTradingAccountId }))
    }
  }, [dispatch, userTradingAccountId])

  return isMobile ? (
    <OrdersBottomBarMobileUI
      onBottomBlockOpen={onBottomBlockOpen}
      onBottomBlockClose={onBottomBlockClose}
      isBottomNavOpen={isBottomNavOpen}
      orderList={orderList}
    />
  ) : (
    <AnimatePresence>
      <OrdersBottomBarUI
        onBottomBlockOpen={onBottomBlockOpen}
        onBottomBlockClose={onBottomBlockClose}
        isBottomNavOpen={isBottomNavOpen}
        orderList={orderList}
      />
    </AnimatePresence>
  )
}

export default memo(OrdersBottomBarContainer)
