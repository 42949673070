// Modules
import { memo } from 'react'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Text } from '@chakra-ui/react'

const ErrorMessage = (props) => {
  const { error, ...args } = props

  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Text color={isLightTheme ? 'red.200' : 'red.100'} mt={1} {...args}>
      {error}
    </Text>
  )
}

export default memo(ErrorMessage)
