// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { GoPlus } from 'react-icons/go'

// Languages
import { LN_MARKET_SIDEBAR_ACCORDION_ADD_TRADE } from 'locales/constants'

// Store
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Icon, IconButton, Tooltip } from '@chakra-ui/react'

const PlusBtn = ({ onClick }) => {
  const { t } = useTranslation()
  const { isLightThemeSelector } = useCurrentColorTheme()

  return (
    <Tooltip label={t(LN_MARKET_SIDEBAR_ACCORDION_ADD_TRADE)} aria-label="add trade">
      <IconButton
        icon={<Icon as={GoPlus} w="16px" h="16px" />}
        onClick={onClick}
        aria-label="add to trade"
        isRound
        size="xs"
        bg={isLightThemeSelector ? '#e2e8f0' : 'rgba(255, 255, 255, 0.08)'}
        color={isLightThemeSelector ? '#1A202C' : 'rgba(255, 255, 255, 0.92)'}
        _hover={{
          backgroundColor: isLightThemeSelector ? '#d1d9e4' : 'rgba(255, 255, 255, 0.16)',
        }}
      />
    </Tooltip>
  )
}
export default memo(PlusBtn)
