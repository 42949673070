// Modules
import { memo } from 'react'

// Components
import MarketWatchContainer from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch'

const MenuTradeModalBodyMobileUI = () => {
  return <MarketWatchContainer />
}

export default memo(MenuTradeModalBodyMobileUI)
