// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Language
import {
  LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_PROFIT,
  LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_RATE,
} from 'locales/constants'

// Components
import { TextXs } from 'components'
import {
  RADIO_BY_PROFIT,
  RADIO_BY_RATE,
} from 'pages/Dashboard/components/TransactionCreationSidebar/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Radio, RadioGroup, Stack } from '@chakra-ui/react'

const RadioProfitGroup = (props) => {
  const { name, ...args } = props

  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()

  return (
    <Box>
      <RadioGroup {...args}>
        <Stack direction="row">
          <Radio value={RADIO_BY_RATE} size="sm">
            <TextXs color={mainTextColor}>
              {t(LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_RATE)}
            </TextXs>
          </Radio>
          <Radio value={RADIO_BY_PROFIT} size="sm">
            <TextXs color={mainTextColor}>
              {t(LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_PROFIT)}
            </TextXs>
          </Radio>
        </Stack>
      </RadioGroup>
    </Box>
  )
}

export default memo(RadioProfitGroup)
