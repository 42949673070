// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_ADD_NEW_TRADE_MODAL_TITLE } from 'locales/constants'

// Components
import MenuTradeModalBody from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal/MenuTradeModalBody'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'

const MenuTradeModalContainer = (props) => {
  const { isOpen, onClose } = props

  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay background="transparent" />
      <ModalContent maxW="60%">
        <ModalHeader color={mainTextColor}>{t(LN_ADD_NEW_TRADE_MODAL_TITLE)}</ModalHeader>
        <ModalCloseButton />
        <MenuTradeModalBody />
      </ModalContent>
    </Modal>
  )
}

export default memo(MenuTradeModalContainer)
