// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// UI
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react'

const RestrictionBox = (props) => {
  const { list, title, keysTitles } = props
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader pb={2}>
        <Heading size="sm">{t(title)}</Heading>
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing="4">
          {list?.map((el) => (
            <Flex key={el} gap={3} alignItems="center">
              {keysTitles?.[el]?.icon}
              <Box>
                <Text fontSize="sm">{t(keysTitles?.[el]?.title)}</Text>
              </Box>
            </Flex>
          ))}
        </Stack>
      </CardBody>
    </Card>
  )
}

export default memo(RestrictionBox)
