// Languages
import {
  LN_AUTH_VALIDATION_EMAIL_REQUIRED,
  LN_AUTH_VALIDATION_INVALID_EMAIL,
  LN_AUTH_VALIDATION_PASSWORD_MUST_MATCH,
  LN_AUTH_VALIDATION_PASSWORD_NUM_CHARACTERS,
  LN_AUTH_VALIDATION_PASSWORD_REQUIRED,
  LN_AUTH_VALIDATION_REPEAT_PASSWORD_REQUIRED,
  LN_VALIDATION_INPUT_VALUE_IS_REQUIRED,
  LN_VALIDATION_INPUT_VALUE_NOT_POSITIVE,
  LN_VALIDATION_INPUT_VALUE_NOT_ZERO,
} from 'locales/constants'

export const validationText = {
  emailInvalid: LN_AUTH_VALIDATION_INVALID_EMAIL,
  emailRequired: LN_AUTH_VALIDATION_EMAIL_REQUIRED,
  passwordLength: LN_AUTH_VALIDATION_PASSWORD_NUM_CHARACTERS,
  passwordRequired: LN_AUTH_VALIDATION_PASSWORD_REQUIRED,
  repeatPasswordRequired: LN_AUTH_VALIDATION_REPEAT_PASSWORD_REQUIRED,
  repeatPasswordMatch: LN_AUTH_VALIDATION_PASSWORD_MUST_MATCH,

  inputValueIsRequired: LN_VALIDATION_INPUT_VALUE_IS_REQUIRED,
  inputValueNotPositive: LN_VALIDATION_INPUT_VALUE_NOT_POSITIVE,
  inputValueNotZero: LN_VALIDATION_INPUT_VALUE_NOT_ZERO,
}
