// Modules
import { GoHistory, GoHomeFill, GoSignOut } from 'react-icons/go'
import { GrDownload } from 'react-icons/gr'

// Languages
import {
  LN_SIDEBAR_LIST_LOG_OUT,
  LN_SIDEBAR_LIST_TRADING,
  LN_SIDEBAR_LIST_TRADING_DEPOSIT,
  LN_SIDEBAR_LIST_TRADING_HISTORY,
} from 'locales/constants'

export const NavListConstants = {
  trading: 'trading',
  deposit: 'deposit',
  history: 'history',
  logOut: 'logOut',
}

export const NavList = [
  {
    icon: GoHomeFill,
    title: LN_SIDEBAR_LIST_TRADING,
    id: NavListConstants.trading,
  },
  // {
  //   icon: GoProjectSymlink,
  //   title: 'Market Watch',
  //   // title: 'nav_list_trading',
  // },
  {
    icon: GrDownload,
    title: LN_SIDEBAR_LIST_TRADING_DEPOSIT,
    id: NavListConstants.deposit,
  },
  {
    icon: GoHistory,
    title: LN_SIDEBAR_LIST_TRADING_HISTORY,
    id: NavListConstants.history,
  },
  {
    icon: GoSignOut,
    title: LN_SIDEBAR_LIST_LOG_OUT,
    id: NavListConstants.logOut,
  },
]
