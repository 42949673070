// Modules
import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import HeaderBarMobileUI from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/HeaderBarMobileUI'
import HeaderBarUI from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/HeaderBarUI'

// Hooks
import useOrderBottomInfo from 'hooks/useOrderBottomInfo'
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Store
import { isBottomNavOpenSelector } from 'store/actions/MainGraphBlockWidth/selectors'
import { setIsFullSizeModal } from 'store/actions/OrderBar/actions'
import {
  loadingBarSelector,
  mainTabsValueSelector,
  pendingOrdersListSelector,
  positionsListSelector,
  searchValueSelector,
} from 'store/actions/OrderBar/selectors'
import { isMobileSelector } from 'store/actions/User/selectors'

const HeaderBarContainer = (props) => {
  const { onBottomBlockOpen, onBottomBlockClose } = props

  const loadingBar = useSelector(loadingBarSelector)
  const isMobile = useSelector(isMobileSelector)

  const dispatch = useDispatch()
  const isBottomNavOpen = useSelector(isBottomNavOpenSelector)
  const mainTabsValue = useSelector(mainTabsValueSelector)
  const searchValue = useSelector(searchValueSelector)

  const { onSearch, totalProfit, totalVolume } = useOrderBottomInfo()
  const { userTradingAccountSymbol } = useUserTradingAccountId()

  const onOpenFullSize = useCallback(() => {
    dispatch(setIsFullSizeModal(true))
  }, [dispatch])

  const positionsList = useSelector(positionsListSelector)
  const pendingOrdersList = useSelector(pendingOrdersListSelector)

  return isMobile ? (
    <HeaderBarMobileUI
      onBottomBlockOpen={onBottomBlockOpen}
      onBottomBlockClose={onBottomBlockClose}
      isBottomNavOpen={isBottomNavOpen}
      mainTabsValue={mainTabsValue}
      loadingBar={loadingBar}
      onOpenFullSize={onOpenFullSize}
      searchValue={searchValue}
      onSearch={onSearch}
      positionsList={positionsList}
      pendingOrdersList={pendingOrdersList}
      totalVolume={totalVolume}
      totalProfit={totalProfit}
      userTradingAccountSymbol={userTradingAccountSymbol}
    />
  ) : (
    <HeaderBarUI
      onBottomBlockOpen={onBottomBlockOpen}
      onBottomBlockClose={onBottomBlockClose}
      isBottomNavOpen={isBottomNavOpen}
      mainTabsValue={mainTabsValue}
      loadingBar={loadingBar}
      onOpenFullSize={onOpenFullSize}
      searchValue={searchValue}
      onSearch={onSearch}
      positionsList={positionsList}
      pendingOrdersList={pendingOrdersList}
      totalVolume={totalVolume}
      totalProfit={totalProfit}
      userTradingAccountSymbol={userTradingAccountSymbol}
    />
  )
}

export default memo(HeaderBarContainer)
