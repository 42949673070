import _ from 'lodash'

// export const formatDigitsWithSpaces = (digits) => {
//   const convertDigits = Number(digits)
//
//   // Use Lodash to add spaces every 3 digits from the end
//   return _.chain(digits)
//     .thru((value) => String(value).replace(/[^0-9.]/g, '')) // Remove non-numeric characters
//     .thru((value) => _.split(value, '.')) // Split into integer and decimal parts
//     .map((part) => part.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')) // Add spaces to integer part
//     .join('.') // Join integer and decimal parts
//     .value()
// }
export const formatDigitsWithSpaces = (digits) => {
  const convertDigits = Number(digits)

  // Use Lodash to add spaces every 3 digits from the end
  return _.chain(digits)
    .thru((value) => String(value).replace(/[^0-9.-]/g, '')) // Keep minus sign and remove other non-numeric characters
    .thru((value) => {
      // Handle negative numbers
      const isNegative = value.startsWith('-')
      const numberPart = isNegative ? value.slice(1) : value // Remove minus sign for now

      const [integerPart, decimalPart] = _.split(numberPart, '.') // Split into integer and decimal parts

      const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // Add spaces to integer part

      const result = decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger // Rejoin if decimal part exists
      return isNegative ? `-${result}` : result // Add back minus sign if necessary
    })
    .value()
}

export const roundThreeDigitsAfterDots = (digits) => {
  if (!digits) return digits
  const convertDigits = Number(digits)

  return roundDigitsAfterDots(convertDigits, 3)
}

export const roundTwoDigitsAfterDots = (digits) => {
  return roundDigitsAfterDots(digits, 2)
}

export const roundDigitsAfterDots = (digits, length) => {
  if (!digits) return digits

  return _.round(digits, length)
}
