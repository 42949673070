export const toastStyles = {
  Toast: {
    baseStyle: {
      container: {
        borderRadius: 'md',
        p: 4,
        color: 'white',
        zIndex: 100002,
      },
    },
    variants: {
      success: {
        container: {
          bg: 'green.400',
          color: 'white',
        },
      },
      error: {
        container: {
          bg: 'red.400',
          color: 'white',
        },
      },
      warning: {
        container: {
          bg: 'orange.400',
          color: 'white',
        },
      },
      info: {
        container: {
          bg: 'blue.400',
          color: 'white',
        },
      },
    },
  },
}
