// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import DepositHistory from 'pages/User/components/Content/Deposit/DepositHistory'
import DepositTabs from 'pages/User/components/Content/Deposit/DepositTabs'
import { MainTitle } from 'pages/User/components/Content/Withdrawal'
import { sidebarList } from 'pages/User/components/Sidebar/constants'

// UI
import { Box } from '@chakra-ui/react'

const Deposit = () => {
  const { t } = useTranslation()

  return (
    <Box w="100%" p={3}>
      <Box justifyContent="space-between" alignItems="center" pb={5}>
        <MainTitle title={t(sidebarList[1]?.title)} />
        <DepositTabs />
        {/*<DepositHistory />*/}
      </Box>
    </Box>
  )
}

export default memo(Deposit)
