// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_SEARCH_ACCORDION_INPUT_TITLE } from 'locales/constants'

// Components
import { SearchInput, TextSm } from 'components'
import LoadingBlock from 'components/LoadingBlock'
import MarketAccordion from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion'
import ListPanel from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/ListPanel'
import TopTabs from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs'
import { tabsList } from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs/constants'

// Constants
import { BottomBarHeight } from 'constants/index'

// Utils
import { getScrollbarCss } from 'utils/scrollbarHelper'

// UI
import { Box, CloseButton, Flex } from '@chakra-ui/react'

const MarketWatchUI = (props) => {
  const {
    onLeftNavClose,
    topTabValue,
    onChooseTab,
    searchValue,
    onSearch,
    loadingList,
    isBottomNavOpen,
    filtered,
    favourites,
    onOpenTradeInfoModal,
  } = props

  const { t } = useTranslation()

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" p={3} pb={4}>
        <TopTabs active={topTabValue} onChooseTab={onChooseTab} />
        <CloseButton
          onClick={onLeftNavClose}
          _hover={{
            backgroundColor: 'transparent',
            color: 'gray.400',
          }}
        />
      </Flex>
      <Box p={3} pt={0}>
        <Box w="100%">
          <SearchInput
            searchedValue={searchValue}
            setSearchedValue={onSearch}
            mb={3}
            disabled={loadingList}
            placeholder={t(LN_SEARCH_ACCORDION_INPUT_TITLE) + '...'}
            fontSize="sm"
          />
          <Box
            overflowY="auto"
            height={isBottomNavOpen ? `calc(66.3vh - ${BottomBarHeight})` : '66.3vh'}
            pr={2}
            pb={2}
            css={getScrollbarCss()}
          >
            {topTabValue === tabsList[0].id ? (
              (!filtered || filtered?.length === 0) && !loadingList ? (
                <TextSm>No items</TextSm>
              ) : loadingList ? (
                <LoadingBlock />
              ) : (
                <MarketAccordion list={filtered} onOpenInfo={onOpenTradeInfoModal} />
              )
            ) : (
              <ListPanel
                topTabValue={topTabValue}
                list={favourites}
                onOpenInfo={onOpenTradeInfoModal}
                favourite
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default memo(MarketWatchUI)
