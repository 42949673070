// Languages
import {
  LN_HISTORY_ALL,
  LN_HISTORY_BUY,
  LN_HISTORY_PENDING_CLOSED_POSITIONS,
  LN_HISTORY_PENDING_ORDERS,
  LN_HISTORY_POSITIONS,
  LN_HISTORY_SELECT_TIME_ALL_TIME,
  LN_HISTORY_SELECT_TIME_CURRENT_MONTH,
  LN_HISTORY_SELECT_TIME_CURRENT_WEEK,
  LN_HISTORY_SELECT_TIME_LAST_MONTH,
  LN_HISTORY_SELECT_TIME_LAST_WEEK,
  LN_HISTORY_SELECT_TIME_TODAY,
  LN_HISTORY_SELECT_TIME_YESTERDAY,
  LN_HISTORY_SELL,
} from 'locales/constants'

import { typeTransaction } from 'utils/transactionFormulas'

export const mainTabsValues = {
  positions: 'positions',
  pendingOrders: 'pending-orders',
  closedPositions: 'closed-positions',
}

export const mainTabsList = [
  {
    title: LN_HISTORY_POSITIONS,
    value: mainTabsValues.positions,
  },
  {
    title: LN_HISTORY_PENDING_ORDERS,
    value: mainTabsValues.pendingOrders,
  },
  {
    title: LN_HISTORY_PENDING_CLOSED_POSITIONS,
    value: mainTabsValues.closedPositions,
  },
]

export const secondaryTabsValues = {
  all: 'all',
  sell: typeTransaction.sell,
  buy: typeTransaction.buy,
}

export const secondaryTabsList = [
  {
    title: LN_HISTORY_ALL,
    value: secondaryTabsValues.all,
  },
  {
    title: LN_HISTORY_SELL,
    value: secondaryTabsValues.sell,
  },
  {
    title: LN_HISTORY_BUY,
    value: secondaryTabsValues.buy,
  },
]

export const selectTimeValues = {
  all: 'all',
  today: 'today',
  yesterday: 'yesterday',
  currentWeek: 'currentWeek',
  currentMonth: 'currentMonth',
  lastWeek: 'lastWeek',
  lastMonth: 'lastMonth',
}

export const selectTimeList = [
  {
    title: LN_HISTORY_SELECT_TIME_ALL_TIME,
    value: selectTimeValues.all,
  },
  {
    title: LN_HISTORY_SELECT_TIME_TODAY,
    value: selectTimeValues.today,
  },
  {
    title: LN_HISTORY_SELECT_TIME_YESTERDAY,
    value: selectTimeValues.yesterday,
  },
  {
    title: LN_HISTORY_SELECT_TIME_CURRENT_WEEK,
    value: selectTimeValues.currentWeek,
  },
  {
    title: LN_HISTORY_SELECT_TIME_CURRENT_MONTH,
    value: selectTimeValues.currentMonth,
  },
  {
    title: LN_HISTORY_SELECT_TIME_LAST_WEEK,
    value: selectTimeValues.lastWeek,
  },
  {
    title: LN_HISTORY_SELECT_TIME_LAST_MONTH,
    value: selectTimeValues.lastMonth,
  },
]
