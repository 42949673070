// Modules
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { GrPrevious } from 'react-icons/gr'
import { GrNext } from 'react-icons/gr'

// Languages
import { LN_PAGINATION_OF, LN_PAGINATION_PAGE } from 'locales/constants'

// Components
import { TextXs } from 'components'

// UI
import { Button, HStack, Icon, IconButton } from '@chakra-ui/react'

const sizeSm = 'xs'

const Pagination = (props) => {
  const {
    gotoPage,
    nextPage,
    previousPage,
    total,
    pageIndex,
    pageSize,
    btnSize = sizeSm,
    blockStyles = {},
  } = props

  const { t } = useTranslation()

  const pageCount = useMemo(() => Math.ceil(total / pageSize), [total, pageSize])

  const canPreviousPage = useMemo(() => pageIndex > 1, [pageIndex])

  const canNextPage = useMemo(() => pageIndex < pageCount, [pageIndex, pageCount])

  const onFirst = useCallback(() => gotoPage(1), [gotoPage])

  const onLast = useCallback(() => gotoPage(pageCount), [gotoPage, pageCount])

  const onPrev = useCallback(() => {
    if (canPreviousPage) {
      previousPage()
    }
  }, [previousPage, canPreviousPage])

  const onNext = useCallback(() => {
    if (canNextPage) {
      nextPage()
    }
  }, [nextPage, canNextPage])

  return (
    <HStack spacing={4} mt={5} justifyContent="flex-end" {...blockStyles}>
      <HStack spacing={2}>
        <BtnPage title="1" onClick={onFirst} disabled={!canPreviousPage} btnSize={btnSize} />
        <IconBtnPage
          icon={GrPrevious}
          onClick={onPrev}
          label="previous"
          disabled={!canPreviousPage}
          btnSize={btnSize}
        />

        <TextXs pl={1} pr={1}>
          {t(LN_PAGINATION_PAGE)}{' '}
          <strong>
            {pageIndex} {t(LN_PAGINATION_OF)} {pageCount}
          </strong>
        </TextXs>
        <IconBtnPage
          icon={GrNext}
          onClick={onNext}
          label="next"
          disabled={!canNextPage}
          btnSize={btnSize}
        />
        {pageCount !== 1 && (
          <BtnPage title={pageCount} onClick={onLast} disabled={!canNextPage} btnSize={btnSize} />
        )}
      </HStack>
    </HStack>
  )
}

export default memo(Pagination)

const IconBtnPage = (props) => {
  const { icon, onClick, label, disabled, btnSize } = props
  return (
    <IconButton
      icon={<Icon as={icon} />}
      onClick={onClick}
      aria-label={label}
      colorScheme="gray"
      size={btnSize}
      isDisabled={disabled}
    />
  )
}

const BtnPage = (props) => {
  const { title, onClick, disabled, btnSize } = props
  return (
    <Button colorScheme="gray" size={btnSize} onClick={onClick} isDisabled={disabled}>
      {title}
    </Button>
  )
}
