// Modules
import { memo, useCallback } from 'react'

// Components
import { TextXs } from 'components'

const PercentageText = ({ percentage }) => {
  const checkTextColor = useCallback((text) => {
    if (text.startsWith('+')) {
      return 'green.200'
    } else if (text.startsWith('-')) {
      return 'red.100'
    } else {
      return 'white'
    }
    // if closed === brand.textColor2
  }, [])

  return <TextXs color={checkTextColor(percentage?.toString())}>{percentage}%</TextXs>
}
export default memo(PercentageText)
