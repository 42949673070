// Modules
import { call, put, select, takeEvery } from 'redux-saga/effects'

import { LN_TRANSACTION_CLOSE_NOTIFICATION_TITLE } from 'locales/constants'
import i18n from 'locales/i18n'

// Constants
import { toastConstructor, toastType } from 'components/ToastApp/constants'
import { secondaryTabsList } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'
import {
  ordersBarPositionsLengthKey,
  ordersBarPositionsLengthValues,
} from 'pages/Dashboard/components/OrdersBottomBar/constants'

// Utils
import { transformPositionOrdersUsage } from 'utils/calculatePositionOrdersUsage'
import { addedProfitRows } from 'utils/transactionFormulas'

// Store
import {
  getClosedTransactionsList,
  getOpenTransactionsList,
  getPendingTransactionsList,
  getUserTransactionsList,
  onTransactionContentBarLoading,
  setClosedTransactionsList,
  setClosedTransactionsListLoading,
  setClosedTransactionsListPagination,
  setOpenTransactionsList,
  setOpenTransactionsListLoading,
  setOpenTransactionsListPagination,
  setPendingOrdersList,
  setPendingOrdersListPagination,
  setPendingTransactionsListLoading,
  setPositionsLength,
  setUserTransactionsList,
  setUserTransactionsListLoading,
} from 'store/actions/OrderBar/actions'
import {
  GET_CLOSED_TRANSACTIONS_LIST,
  GET_OPEN_TRANSACTIONS_LIST,
  GET_PENDING_TRANSACTIONS_LIST,
  GET_USER_TRANSACTIONS_LIST,
  ON_CLOSE_ALL_TRANSACTIONS,
  ON_CLOSE_TRANSACTION,
  WS_FOLLOW_TRANSACTIONS_CHANGE_VALUE,
} from 'store/actions/OrderBar/types'
import { showToast } from 'store/actions/ToastsApp/actions'
import { onGetUserBalance } from 'store/actions/User/actions'
import {
  closeAllTransactionsByPositionId,
  closeTransaction,
  getTransactions,
} from 'store/api/market'
import { onUserNotAuth } from 'store/sagas/utils'

function* getUserTransactions(action) {
  try {
    const token = yield select((state) => state.auth.token)

    if (token) {
      const response = yield call(getTransactions, { ...action.payload })
      yield put(setUserTransactionsList(response?.data || []))
      yield put(setUserTransactionsListLoading(false))
      if (response) {
        const calcLength = transformPositionOrdersUsage(response?.header?.[0])
        yield put(setPositionsLength(calcLength))
        localStorage.setItem(ordersBarPositionsLengthKey, JSON.stringify(calcLength))
      }
    }
  } catch (error) {
    yield put(setUserTransactionsListLoading(false))
    yield put(showToast(toastConstructor(toastType.error, error?.response?.data?.error)))
    yield call(onUserNotAuth, error)
  }
}

function* getOpenTransactionsListFunc(action) {
  yield put(onTransactionContentBarLoading(true))
  try {
    const token = yield select((state) => state.auth.token)
    const searchValue = yield select((state) => state.orderBar.searchValue)
    const secondaryTabsValue = yield select((state) => state.orderBar.secondaryTabsValue)

    const command =
      secondaryTabsValue && secondaryTabsValue !== secondaryTabsList?.[0]?.value
        ? { commandId: secondaryTabsValue }
        : {}

    const args = {
      ...command,
      search: searchValue ? searchValue : '',
      ...action.payload,
      positionId: ordersBarPositionsLengthValues.open,
    }

    if (token) {
      const response = yield call(getTransactions, args)

      // let pagination = null
      // let addedProfitList = []
      // if (response?.data) {
      // addedProfitList = addedProfitRows(response?.data)
      // pagination = response?.pagination
      // }

      yield put(setOpenTransactionsList(response?.data || []))
      yield put(setOpenTransactionsListPagination(response?.pagination || null))
      yield put(setOpenTransactionsListLoading(false))
      yield put(onTransactionContentBarLoading(false))
    }
  } catch (error) {
    yield put(onTransactionContentBarLoading(false))
    yield put(setOpenTransactionsListLoading(false))
    yield put(showToast(toastConstructor(toastType.error, error?.response?.data?.error)))
    yield call(onUserNotAuth, error)
  }
}

function* getUserClosedTransactionsList(action) {
  yield put(onTransactionContentBarLoading(true))
  try {
    const token = yield select((state) => state.auth.token)
    const selectTimeValue = yield select((state) => state.orderBar.selectTimeValue)
    const searchValue = yield select((state) => state.orderBar.searchValue)
    const secondaryTabsValue = yield select((state) => state.orderBar.secondaryTabsValue)

    const command =
      secondaryTabsValue && secondaryTabsValue !== secondaryTabsList?.[0]?.value
        ? { commandId: secondaryTabsValue }
        : {}
    const time = selectTimeValue ? { period: selectTimeValue } : {}

    const args = {
      ...command,
      ...time,
      search: searchValue ? searchValue : '',
      ...action.payload,
      positionId: ordersBarPositionsLengthValues.close,
    }

    if (token) {
      const response = yield call(getTransactions, args)

      // let addedProfitList = []
      // let pagination = null
      // if (response?.data) {
      //   addedProfitList = addedProfitRows(response?.data)
      //   pagination = response?.pagination
      // }

      yield put(setClosedTransactionsList(response?.data || []))
      yield put(setClosedTransactionsListPagination(response?.pagination || null))
      yield put(setClosedTransactionsListLoading(false))
      yield put(onTransactionContentBarLoading(false))
    }
  } catch (error) {
    yield put(setClosedTransactionsListLoading(false))
    yield put(onTransactionContentBarLoading(false))
    yield put(showToast(toastConstructor(toastType.error, error?.response?.data?.error)))
    yield call(onUserNotAuth, error)
  }
}

function* getUserPendingTransactionsList(action) {
  yield put(onTransactionContentBarLoading(true))
  try {
    const token = yield select((state) => state.auth.token)
    const searchValue = yield select((state) => state.orderBar.searchValue)
    const secondaryTabsValue = yield select((state) => state.orderBar.secondaryTabsValue)

    const command =
      secondaryTabsValue && secondaryTabsValue !== secondaryTabsList?.[0]?.value
        ? { commandId: secondaryTabsValue }
        : {}

    const args = {
      ...command,
      search: searchValue ? searchValue : '',
      ...action.payload,
      positionId: ordersBarPositionsLengthValues.pending,
    }

    if (token) {
      const response = yield call(getTransactions, args)

      // let addedProfitList = []
      // let pagination = null
      // if (response?.data) {
      //   addedProfitList = addedProfitRows(response?.data)
      //   pagination = response?.pagination
      // }

      yield put(setPendingOrdersList(response?.data || []))
      yield put(setPendingOrdersListPagination(response?.pagination || null))

      yield put(setPendingTransactionsListLoading(false))
      yield put(onTransactionContentBarLoading(false))
    }
  } catch (error) {
    yield put(onTransactionContentBarLoading(false))
    yield put(setPendingTransactionsListLoading(false))
    yield put(showToast(toastConstructor(toastType.error, error?.response?.data?.error)))
    yield call(onUserNotAuth, error)
  }
}

function* onCloseTransactionHandler(action) {
  const t = i18n.t.bind(i18n)
  try {
    yield put(onTransactionContentBarLoading(true))
    const token = yield select((state) => state.auth.token)

    const currentTradingAccount = yield select((state) => state.user.currentTradingAccount)
    const userTradingAccountId = currentTradingAccount?.trading_account_id

    if (token) {
      const response = yield call(closeTransaction, { orderId: action.payload })

      yield put(getOpenTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getPendingTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getUserTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(onGetUserBalance(userTradingAccountId))

      yield put(onTransactionContentBarLoading(false))
      yield put(
        showToast(
          toastConstructor(
            toastType.success,
            response.message,
            t(LN_TRANSACTION_CLOSE_NOTIFICATION_TITLE),
          ),
        ),
      )
    }
  } catch (error) {
    yield put(onTransactionContentBarLoading(false))
    yield put(
      showToast(
        toastConstructor(
          toastType.error,
          error?.response?.data?.error_message,
          t(LN_TRANSACTION_CLOSE_NOTIFICATION_TITLE),
        ),
      ),
    )
    yield call(onUserNotAuth, error)
  }
}

function* onCloseAllTransactionsHandler(action) {
  const t = i18n.t.bind(i18n)
  try {
    yield put(onTransactionContentBarLoading(true))
    const token = yield select((state) => state.auth.token)

    const currentTradingAccount = yield select((state) => state.user.currentTradingAccount)
    const userTradingAccountId = currentTradingAccount?.trading_account_id

    if (token) {
      const response = yield call(closeAllTransactionsByPositionId, { positionId: action.payload })

      yield put(getOpenTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getPendingTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getUserTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(onGetUserBalance(userTradingAccountId))

      yield put(onTransactionContentBarLoading(false))
      yield put(
        showToast(
          toastConstructor(
            toastType.success,
            response.message,
            t(LN_TRANSACTION_CLOSE_NOTIFICATION_TITLE),
          ),
        ),
      )
    }
  } catch (error) {
    yield put(onTransactionContentBarLoading(false))
    yield put(
      showToast(
        toastConstructor(
          toastType.error,
          error?.response?.data?.error_message,
          t(LN_TRANSACTION_CLOSE_NOTIFICATION_TITLE),
        ),
      ),
    )
    yield call(onUserNotAuth, error)
  }
}

function* getAllLists() {
  try {
    yield put(onTransactionContentBarLoading(true))
    const token = yield select((state) => state.auth.token)
    const currentTradingAccount = yield select((state) => state.user.currentTradingAccount)
    const userTradingAccountId = currentTradingAccount?.trading_account_id

    if (token && userTradingAccountId) {
      yield put(getOpenTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getUserTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getClosedTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getPendingTransactionsList({ tradingAccountId: userTradingAccountId }))
    }
  } catch (error) {
    yield put(onTransactionContentBarLoading(false))
    yield put(showToast(toastConstructor(toastType.error, error?.response?.data?.error_message)))
    yield call(onUserNotAuth, error)
  }
}

export function* watchOrderBarCreation() {
  yield takeEvery(GET_USER_TRANSACTIONS_LIST, getUserTransactions)
  yield takeEvery(GET_OPEN_TRANSACTIONS_LIST, getOpenTransactionsListFunc)
  yield takeEvery(GET_CLOSED_TRANSACTIONS_LIST, getUserClosedTransactionsList)
  yield takeEvery(GET_PENDING_TRANSACTIONS_LIST, getUserPendingTransactionsList)
  yield takeEvery(ON_CLOSE_TRANSACTION, onCloseTransactionHandler)
  yield takeEvery(ON_CLOSE_ALL_TRANSACTIONS, onCloseAllTransactionsHandler)
  yield takeEvery(WS_FOLLOW_TRANSACTIONS_CHANGE_VALUE, getAllLists)
}
