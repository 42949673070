// Modules
import { memo } from 'react'
import { useSelector } from 'react-redux'

// Components
import ActiveTradeInnerElements from 'pages/Dashboard/components/Main/ActiveTradeBlock/ActiveTradeInnerElements'

// Utils
import { getHorizontalScrollbarCss } from 'utils/scrollbarHelper'

// Store
import { mainGraphBlockWidthSelector } from 'store/actions/MainGraphBlockWidth/selectors'

// UI
import { Flex } from '@chakra-ui/react'

const ActiveTradeBlock = (props) => {
  const { activeTradeItemsList, chosenActiveTradeItem, onAddNew, onActive, onCloseAll } = props

  const mainGraphBlockWidth = useSelector(mainGraphBlockWidthSelector)

  return (
    <>
      {/*// <Box w="70%" overflow="hidden">*/}
      <Flex
        maxW={mainGraphBlockWidth - 15 + 'px'}
        gap={4}
        overflowX="auto"
        pb={2}
        css={getHorizontalScrollbarCss()}
        mb="10px"
        position="relative"
      >
        <ActiveTradeInnerElements
          activeTradeItemsList={activeTradeItemsList}
          chosenActiveTradeItem={chosenActiveTradeItem}
          onAddNew={onAddNew}
          onActive={onActive}
          onCloseAll={onCloseAll}
        />
      </Flex>
      {/*// </Box>*/}
    </>
  )
}

export default memo(ActiveTradeBlock)
