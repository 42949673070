// Modules
import { memo } from 'react'

// Components
import { InputBase } from 'components'

// UI
import { Box, FormControl, FormLabel, InputGroup, InputRightAddon } from '@chakra-ui/react'

const DisabledInputInfo = (props) => {
  const { label, value, isInputRightAddon = false, inputRightAddonVal, ...args } = props

  return (
    <Box {...args}>
      <FormControl>
        <FormLabel fontSize={{ base: 'sm', sm: 'md' }}>{label}</FormLabel>
        <InputGroup>
          <InputBase
            value={value}
            disabled
            sx={{
              ':disabled': {
                opacity: 0.9,
              },
            }}
            size={{ base: 'sm', sm: 'md' }}
          />
          {isInputRightAddon && <InputRightAddon>{inputRightAddonVal}</InputRightAddon>}
        </InputGroup>
      </FormControl>
    </Box>
  )
}

export default memo(DisabledInputInfo)
