// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa6'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { SlWallet } from 'react-icons/sl'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_NAVBAR_BALANCE } from 'locales/constants'

// Components
import { TextMd, TextSm, TextXs } from 'components'
import WrapperInfoItem from 'components/InfoItem/WrapperInfoItem'
import { TextLg } from 'components/Typography/TextBase'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { formatDigitsWithSpaces } from 'utils/formatDigitsWithSpaces'
import { formatDigitsWithDots } from 'utils/formatPrice'
import {
  currencyConvert,
  getUserBalanceMobileNavbar,
  getUserBalanceNavbar,
} from 'utils/userBalanceTransform'

// Store
import { onSetUserBalance, onSetUserCurrentTradingAccount } from 'store/actions/User/actions'
import {
  currentTradingAccountSelector,
  tradingAccountsSelector,
  userBalanceLoadingSelector,
  userBalanceSelector,
} from 'store/actions/User/selectors'

// UI
import {
  Box,
  Collapse,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'

const BalanceCollapse = () => {
  const { isLightTheme } = useCurrentColorTheme()

  const dispatch = useDispatch()

  const balanceValue = useSelector(userBalanceSelector)
  const userBalanceLoading = useSelector(userBalanceLoadingSelector)

  const tradingAccounts = useSelector(tradingAccountsSelector)
  const currentTradingAccount = useSelector(currentTradingAccountSelector)

  const onChooseTradingAccount = useCallback(
    (currency) => {
      dispatch(onSetUserCurrentTradingAccount(currency))
      dispatch(onSetUserBalance(currency?.balance ?? 0))
    },
    [dispatch],
  )
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onToggleOpen = useCallback(() => {
    if (isOpen) {
      onClose()
    } else {
      onOpen()
    }
  }, [isOpen, onClose, onOpen])

  return (
    <Box>
      <TextXs pt={2} pb={1}>
        Trading Accounts:
      </TextXs>
      <Box disabled={userBalanceLoading} height="100%" onClick={onToggleOpen}>
        <BalanceBtnMobile
          balanceValue={balanceValue}
          userBalanceLoading={userBalanceLoading}
          currencyName={currentTradingAccount?.currency?.name}
          isOpen={isOpen}
        />
      </Box>
      <Box mt="-1px">
        <Collapse in={isOpen} animateOpacity>
          <Box
            // bg={isLightTheme ? 'gray.50' : 'gray.700'}
            borderColor={isLightTheme ? 'gray.100' : 'whiteAlpha.100'}
          >
            <Box pt={1} borderBottomRightRadius={2} borderBottomLeftRadius={2}>
              {tradingAccounts?.map((el) => (
                <BalanceMenuItem
                  key={el?.currency?.id}
                  isChosen={currentTradingAccount?.trading_account_id === el?.trading_account_id}
                  balance={el?.balance ? formatDigitsWithSpaces(el?.balance) : '0'}
                  onChooseTradingAccount={onChooseTradingAccount}
                  currencyType={el?.currency?.name}
                  obj={el}
                  isMobile
                />
              ))}
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}

export default memo(BalanceCollapse)

const BalanceMenuItem = memo((props) => {
  const { isChosen, balance, onChooseTradingAccount, currencyType, obj, isMobile } = props
  const { isLightTheme } = useCurrentColorTheme()

  const onChoose = useCallback(() => {
    onChooseTradingAccount(obj)
  }, [obj, onChooseTradingAccount])

  return (
    <Box
      p={2}
      pt={1}
      pb={1}
      cursor="pointer"
      borderBottom="1px"
      borderColor={isLightTheme ? 'gray.100' : 'whiteAlpha.100'}
      onClick={onChoose}
      _hover={{
        bg: isLightTheme ? 'brand.mainAccent1Light' : 'brand.lightAccent',
      }}
      role="group"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap={2}>
          {isMobile ? (
            <TextXs _groupHover={{ color: 'white' }}>{currencyConvert(currencyType)}</TextXs>
          ) : (
            <TextMd _groupHover={{ color: 'white' }}>{currencyConvert(currencyType)}</TextMd>
          )}
          {isMobile ? (
            <TextXs fontWeight={500} _groupHover={{ color: 'white' }}>
              {formatDigitsWithDots(balance, 20)}
            </TextXs>
          ) : (
            <TextMd fontWeight={500} _groupHover={{ color: 'white' }}>
              {formatDigitsWithDots(balance, 20)}
            </TextMd>
          )}
        </Flex>
        {isChosen && (
          <Icon as={FaCheck} w={{ base: '12px', sm: '18px' }} _groupHover={{ color: 'white' }} />
        )}
      </Flex>
    </Box>
  )
})

const BalanceBtnMobile = memo((props) => {
  const { balanceValue, userBalanceLoading, currencyName, isOpen } = props

  const { isLightTheme } = useCurrentColorTheme()

  return (
    <WrapperInfoItem
      flexDirection="row"
      alignItems="center"
      height="100%"
      gap={{ base: 2, sm: 1, '2sm': 2 }}
      pl={{ base: 1, sm: 3 }}
      pr={{ base: 1, sm: 3 }}
    >
      <Box
        color={isLightTheme ? '#4D6CEF' : 'navy.300'}
        display={{ base: 'inline-block', sm: 'none', '2sm': 'inline-block' }}
      >
        <SlWallet />
      </Box>
      <Flex alignItems="center" gap={{ base: 1, sm: 4 }} justifyContent="space-between" w="100%">
        <Box>
          {userBalanceLoading ? (
            <Spinner size="sm" color={isLightTheme ? '#4D6CEF' : 'navy.300'} />
          ) : (
            <TextSm
              fontSize={{ base: 'xs', sm: 'sm' }}
              fontWeight="600"
              color={isLightTheme ? '#4D6CEF' : 'navy.300'}
            >
              {getUserBalanceMobileNavbar(balanceValue, currencyName)}
            </TextSm>
          )}
        </Box>
        <Icon as={isOpen ? IoIosArrowUp : IoIosArrowDown} w={{ base: '13px', sm: '18px' }} />
      </Flex>
    </WrapperInfoItem>
  )
})

BalanceMenuItem.displayName = 'BalanceMenuItem'
BalanceBtnMobile.displayName = 'BalanceBtnMobile'
