// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Components
import TabBtn from 'pages/Dashboard/components/OrdersBottomBar/TabBtn'

// Hooks
import useOrderBottomInfo from 'hooks/useOrderBottomInfo'

// Store
import { mainTabsValueSelector } from 'store/actions/OrderBar/selectors'

const MainTabs = (props) => {
  const { ...args } = props

  const { t } = useTranslation()

  const mainTabsValue = useSelector(mainTabsValueSelector)
  const { mainTabs, onHandleMainTabs } = useOrderBottomInfo()

  return (
    <>
      {mainTabs?.map((el) => {
        return (
          <TabBtn
            active={el?.value === mainTabsValue}
            key={el?.value}
            title={`${t(el?.title)} (${el?.count})`}
            onClick={() => onHandleMainTabs(el?.value)}
            {...args}
          />
        )
      })}
    </>
  )
}

export default memo(MainTabs)
