// Types
import {
  GET_WITHDRAWAL_HISTORY,
  SET_IS_WITHDRAWAL_MODAL_INFO_OPEN,
  SET_WITHDRAWAL_HISTORY,
  SET_WITHDRAWAL_MODAL_ITEM_INFO,
} from 'store/actions/Withdrawal/types'

export const getWithdrawalHistory = () => ({
  type: GET_WITHDRAWAL_HISTORY,
})

export const setWithdrawalHistory = (payload) => ({
  type: SET_WITHDRAWAL_HISTORY,
  payload: payload,
})

export const setIsWithdrawalModalInfoOpen = (payload) => ({
  type: SET_IS_WITHDRAWAL_MODAL_INFO_OPEN,
  payload: payload,
})

export const setWithdrawalModalInfoItem = (payload) => ({
  type: SET_WITHDRAWAL_MODAL_ITEM_INFO,
  payload: payload,
})
