// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { GoDash, GoPlus } from 'react-icons/go'
import { LuDelete } from 'react-icons/lu'

// Languages
import { LN_TRANSACTION_CALC_RESET } from 'locales/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Icon, IconButton, Stack, Tooltip } from '@chakra-ui/react'

const IncreaseDecreaseBtns = ({ onIncreaseValue, onDecreaseValue, onResetValue }) => {
  const { t } = useTranslation()
  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Stack gap={2} w="40px">
      <IconButton
        icon={<Icon as={GoPlus} w="14px" h="14px" />}
        aria-label="plus"
        onClick={onIncreaseValue}
      />
      <IconButton
        icon={<Icon as={GoDash} w="14px" h="14px" />}
        aria-label="minus"
        onClick={onDecreaseValue}
      />
      <Tooltip label={t(LN_TRANSACTION_CALC_RESET)} aria-label="add trade">
        <IconButton
          icon={<Icon as={LuDelete} w="14px" h="14px" />}
          aria-label="reset"
          onClick={onResetValue}
        />
      </Tooltip>
    </Stack>
  )
}

export default memo(IncreaseDecreaseBtns)
