// Modules
import { combineReducers } from 'redux'

// Reducers
import activeTradeItemsReducer from 'store/reducers/activeTradeItemsReducer'
import authReducer from 'store/reducers/authReducer'
import balanceReducer from 'store/reducers/balanceReducer'
import currencySocketReducer from 'store/reducers/currencySockets/currencySocketReducer'
import marketSidebarReducer from 'store/reducers/currencySockets/marketSidebarReducer'
import favouritesCurrencyListReducer from 'store/reducers/favouritesCurrencyListReducer'
import infoModalTradeItemReducer from 'store/reducers/infoModalTradeItemReducer'
import mainGraphBlockWidthReducer from 'store/reducers/mainGraphBlockWidthReducer'
import orderBarReducer from 'store/reducers/orderBarReducer'
import socketGraphReducer from 'store/reducers/socketGraphReducer'
import toastsReducer from 'store/reducers/toastsReducer'
import transactionCreationReducer from 'store/reducers/transactionCreationReducer'
import userDepositReducer from 'store/reducers/userDepositReducer'
import userReducer from 'store/reducers/userReducer'
import withdrawalReducer from 'store/reducers/withdrawalReducer'

import kycReducer from './kycReducer'

const rootReducer = combineReducers({
  activeTradeItems: activeTradeItemsReducer,
  infoModalTradeItem: infoModalTradeItemReducer,
  mainGraphBlockWidth: mainGraphBlockWidthReducer,
  marketSidebar: marketSidebarReducer,
  userBalance: balanceReducer,
  orderBar: orderBarReducer,
  user: userReducer,
  withdrawal: withdrawalReducer,
  auth: authReducer,
  toastsApp: toastsReducer,
  socketGraph: socketGraphReducer,
  favouritesCurrencyList: favouritesCurrencyListReducer,
  currencySocket: currencySocketReducer,
  transactionCreation: transactionCreationReducer,
  kyc: kycReducer,
  userDeposit: userDepositReducer,
})

export default rootReducer
