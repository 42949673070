// Modules
import { memo, useCallback, useState } from 'react'

// Components
import { InputBase } from 'components'

// UI
import { FormControl, FormLabel } from '@chakra-ui/react'

const IbanInputBase = (props) => {
  const [iban, setIBAN] = useState('')

  const handleIBANChange = useCallback(
    (e) => {
      // Remove non-alphanumeric characters
      const input = e.target.value.replace(/[^a-zA-Z0-9]/g, '')

      // Format IBAN with spaces every four characters
      const formattedIBAN = input.replace(/(.{4})/g, '$1 ').trim()

      setIBAN(formattedIBAN)
    },
    [setIBAN],
  )

  return (
    <FormControl isRequired>
      <FormLabel>IBAN</FormLabel>
      <InputBase onChange={handleIBANChange} value={iban} />
    </FormControl>
  )
}

export default memo(IbanInputBase)
