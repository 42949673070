// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_AUTH_EMAIL } from 'locales/constants'

// Components
import { ErrorMessage, InputBase } from 'components'
import { formConstants } from 'pages/Auth/Login/constants'

// UI
import { FormControl, FormLabel, InputGroup } from '@chakra-ui/react'

const EmailField = (props) => {
  const { formik } = props

  const { t } = useTranslation()

  const { email } = formConstants

  return (
    <FormControl isRequired mb={3}>
      <FormLabel>{t(LN_AUTH_EMAIL)}</FormLabel>
      <InputGroup>
        <InputBase type="email" {...formik.getFieldProps(email)} />
      </InputGroup>
      {formik.touched[email] && formik.errors[email] && (
        <ErrorMessage error={t(formik.errors[email])} />
      )}
    </FormControl>
  )
}

export default memo(EmailField)
