import {
  LN_HISTORY_TABLE_CLOSED_TIME,
  LN_HISTORY_TABLE_CLOSE_PRICE,
  LN_HISTORY_TABLE_CLOSE_PROFIT,
  LN_HISTORY_TABLE_CREATED_TIME,
  LN_HISTORY_TABLE_CURRENT_RATE,
  LN_HISTORY_TABLE_FINAL_PROFIT,
  LN_HISTORY_TABLE_INSTRUMENT,
  LN_HISTORY_TABLE_OPEN_PRICE,
  LN_HISTORY_TABLE_ORDER_ID,
  LN_HISTORY_TABLE_PROFIT,
  LN_HISTORY_TABLE_START_RATE,
  LN_HISTORY_TABLE_STOP_LOSS,
  LN_HISTORY_TABLE_SWAP,
  LN_HISTORY_TABLE_TAKE_PROFIT,
  LN_HISTORY_TABLE_TYPE,
  LN_HISTORY_TABLE_VOLUME,
} from 'locales/constants'

export const sortListValue = {
  desc: 'desc',
  asc: 'asc',
}

export const sortByListValue = {
  orderId: 'id',
  createdTime: 'created_at',
  instrument: 'label',
  type: 'command_id',
  volume: 'volume_lots',
  openPrice: 'open_price',
  currentRate: 'current_rate',
  startRate: 'start_at_price',
  takeProfit: 'close_at_profit',
  stopLoss: 'close_at_loss',
  swap: 'swap',
  profit: 'profit',
  closeProfit: 'close_at_profit',
  closedTime: 'close_time',
  closePrice: 'close_price',
  finalProfit: 'profit',
  deleteItem: 'deleteItem',
}

export const headerPositions = [
  { title: LN_HISTORY_TABLE_ORDER_ID, name: sortByListValue?.orderId },
  { title: LN_HISTORY_TABLE_CREATED_TIME, name: sortByListValue?.createdTime },
  { title: LN_HISTORY_TABLE_INSTRUMENT, name: sortByListValue?.instrument },
  { title: LN_HISTORY_TABLE_TYPE, name: sortByListValue?.type },
  { title: LN_HISTORY_TABLE_VOLUME, name: sortByListValue?.volume },
  { title: LN_HISTORY_TABLE_OPEN_PRICE, name: sortByListValue?.openPrice },
  { title: LN_HISTORY_TABLE_CURRENT_RATE, name: sortByListValue?.currentRate },
  { title: LN_HISTORY_TABLE_TAKE_PROFIT, name: sortByListValue?.takeProfit },
  { title: LN_HISTORY_TABLE_STOP_LOSS, name: sortByListValue?.stopLoss },
  { title: LN_HISTORY_TABLE_SWAP, name: sortByListValue?.swap },
  { title: LN_HISTORY_TABLE_PROFIT, name: sortByListValue?.profit },
  { title: '', name: sortByListValue?.deleteItem },
]

export const headerPending = [
  { title: LN_HISTORY_TABLE_ORDER_ID, name: sortByListValue?.orderId },
  { title: LN_HISTORY_TABLE_CREATED_TIME, name: sortByListValue?.createdTime },
  { title: LN_HISTORY_TABLE_INSTRUMENT, name: sortByListValue?.instrument },
  { title: LN_HISTORY_TABLE_TYPE, name: sortByListValue?.type },
  { title: LN_HISTORY_TABLE_VOLUME, name: sortByListValue?.volume },

  { title: LN_HISTORY_TABLE_CURRENT_RATE, name: sortByListValue?.currentRate },
  { title: LN_HISTORY_TABLE_START_RATE, name: sortByListValue?.startRate },

  { title: LN_HISTORY_TABLE_CLOSE_PROFIT, name: sortByListValue?.closeProfit },
  { title: LN_HISTORY_TABLE_STOP_LOSS, name: sortByListValue?.stopLoss },
  { title: '', name: sortByListValue?.deleteItem },
]

export const headerClosed = [
  { title: LN_HISTORY_TABLE_ORDER_ID, name: sortByListValue?.orderId },
  { title: LN_HISTORY_TABLE_CREATED_TIME, name: sortByListValue?.createdTime },
  { title: LN_HISTORY_TABLE_CLOSED_TIME, name: sortByListValue?.closedTime },
  { title: LN_HISTORY_TABLE_INSTRUMENT, name: sortByListValue?.instrument },
  { title: LN_HISTORY_TABLE_TYPE, name: sortByListValue?.type },
  { title: LN_HISTORY_TABLE_VOLUME, name: sortByListValue?.volume },
  { title: LN_HISTORY_TABLE_OPEN_PRICE, name: sortByListValue?.openPrice },
  { title: LN_HISTORY_TABLE_CLOSE_PRICE, name: sortByListValue?.closePrice },
  { title: LN_HISTORY_TABLE_TAKE_PROFIT, name: sortByListValue?.takeProfit },
  { title: LN_HISTORY_TABLE_STOP_LOSS, name: sortByListValue?.stopLoss },
  { title: LN_HISTORY_TABLE_SWAP, name: sortByListValue?.swap },
  { title: LN_HISTORY_TABLE_FINAL_PROFIT, name: sortByListValue?.finalProfit },
]

export const dataMockPositions = [
  {
    orderId: '3434',
    createdTime: '25.11.2023',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    openPrice: '1.70417',
    currentRate: '1.70430',
    takeProfit: '5',
    stopLoss: null,
    swap: null,
    profit: '-4.77',
  },
  {
    orderId: '1233',
    createdTime: '24.11.2023',
    instrument: 'GBP/CAD',
    type: 'sell',
    volume: '1',
    openPrice: '1.80417',
    currentRate: '1.80430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    profit: '-7.77',
  },
  {
    orderId: '8769',
    createdTime: '23.11.2023',
    instrument: 'GBP/JPY',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    profit: '-2.77',
  },
  {
    orderId: '8767',
    createdTime: '23.11.2022',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    profit: '-2.77',
  },
  {
    orderId: '1767',
    createdTime: '23.11.2022',
    instrument: 'GBP/JPY',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    profit: '-2.77',
  },
]

export const dataMockClosed = [
  {
    orderId: '3434',
    createdTime: '25.11.2023',
    closedTime: '25.11.2023',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    openPrice: '1.70417',
    closePrice: '1.70417',
    currentRate: '1.70530',
    takeProfit: '5',
    stopLoss: null,
    swap: null,
    finalProfit: '4.77',
  },
  {
    orderId: '1233',
    createdTime: '24.11.2023',
    closedTime: '25.11.2023',
    instrument: 'GBP/CAD',
    type: 'sell',
    volume: '1',
    openPrice: '1.80417',
    closePrice: '1.70417',
    currentRate: '1.80430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    finalProfit: '7.77',
  },
  {
    orderId: '8769',
    createdTime: '23.11.2023',
    closedTime: '25.11.2023',
    instrument: 'GBP/JPY',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    closePrice: '1.70417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    finalProfit: '2.77',
  },
  {
    orderId: '8767',
    createdTime: '23.11.2022',
    closedTime: '25.11.2023',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    closePrice: '1.70417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    finalProfit: '2.77',
  },
  {
    orderId: '1767',
    createdTime: '23.11.2022',
    closedTime: '25.11.2023',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    closePrice: '1.70417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    finalProfit: '2.77',
  },
  {
    orderId: '1762',
    createdTime: '23.11.2022',
    closedTime: '14.01.2024',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    closePrice: '1.70417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    finalProfit: '2.77',
  },
  {
    orderId: '1763',
    createdTime: '23.11.2022',
    closedTime: '13.01.2024',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    closePrice: '1.70417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    finalProfit: '2.77',
  },
  {
    orderId: '1764',
    createdTime: '23.11.2022',
    closedTime: '13.12.2023',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    closePrice: '1.70417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    finalProfit: '2.77',
  },
  {
    orderId: '1765',
    createdTime: '23.11.2022',
    closedTime: '04.01.2024',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    openPrice: '1.90417',
    closePrice: '1.70417',
    currentRate: '1.90430',
    takeProfit: null,
    stopLoss: null,
    swap: null,
    finalProfit: '2.77',
  },
]

export const dataMockPending = [
  {
    orderId: '3434',
    createdTime: '25.11.2023',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    currentRate: '1.70530',
    startRate: '1.70530',
    stopLoss: null,
    closeProfit: '4.77',
  },
  {
    orderId: '1233',
    createdTime: '24.11.2023',
    instrument: 'GBP/JPY',
    type: 'sell',
    volume: '1',
    currentRate: '1.80430',
    startRate: '1.70530',
    stopLoss: null,
    closeProfit: '7.77',
  },
  {
    orderId: '8769',
    createdTime: '23.11.2023',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    currentRate: '1.90430',
    startRate: '1.70530',
    stopLoss: null,
    closeProfit: '2.77',
  },
  {
    orderId: '8767',
    createdTime: '23.11.2022',
    instrument: 'GBP/CAD',
    type: 'buy',
    volume: '1',
    currentRate: '1.90430',
    startRate: '1.70530',
    stopLoss: null,
    closeProfit: '2.77',
  },
  {
    orderId: '1767',
    createdTime: '23.11.2022',
    instrument: 'GBP/JPY',
    type: 'buy',
    volume: '1',
    currentRate: '1.90430',
    startRate: '1.70530',
    stopLoss: null,
    closeProfit: '2.77',
  },
]
