// Constants
import { ScreenWidthWithoutNavbar, isOpenBottomBarKey } from 'constants/index'

// Types
import {
  SET_IS_BOTTOM_NAV_OPEN,
  SET_IS_LEFT_NAV_OPEN,
  SET_IS_RIGHT_NAV_OPEN,
  SET_MAIN_GRAPH_BLOCK_HEIGHT,
  SET_MAIN_GRAPH_BLOCK_WIDTH,
} from 'store/actions/MainGraphBlockWidth/types'

const initialState = {
  mainGraphBlockWidth: null,
  mainGraphBlockHeight: ScreenWidthWithoutNavbar,
  isLeftNavOpen: true,
  isRightNavOpen: true,
  isBottomNavOpen: localStorage.getItem(isOpenBottomBarKey) !== 'false',
}

const mainGraphBlockWidthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAIN_GRAPH_BLOCK_WIDTH:
      return {
        ...state,
        mainGraphBlockWidth: action.payload,
      }
    case SET_MAIN_GRAPH_BLOCK_HEIGHT:
      return {
        ...state,
        mainGraphBlockHeight: action.payload,
      }
    case SET_IS_LEFT_NAV_OPEN:
      return {
        ...state,
        isLeftNavOpen: action.payload,
      }
    case SET_IS_RIGHT_NAV_OPEN:
      return {
        ...state,
        isRightNavOpen: action.payload,
      }
    case SET_IS_BOTTOM_NAV_OPEN:
      return {
        ...state,
        isBottomNavOpen: action.payload,
      }
    default:
      return state
  }
}
export default mainGraphBlockWidthReducer
