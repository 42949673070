// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_TRANSACTION_CALC_VOLUME,
  LN_TRANSACTION_MARKET_CLOSED_WARNING,
  LN_TRANSACTION_MARKET_CLOSED_WARNING_OPEN_HOURS,
} from 'locales/constants'

// Components
import { TextXs } from 'components'
import InputMarket from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/InputMarket'
import MarketInfo from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/MarketInfo'
import SubmitBtns from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/SubmitBtns'
import TakeProfitBlock from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/TakeProfitBlock'

// UI
import { Box } from '@chakra-ui/react'

const FormMarketUI = (props) => {
  const {
    volumeValue,
    onChangeVolumeInput,
    onIncreaseValue,
    onDecreaseValue,
    onResetValue,
    currentCurrencyPriceValue,
    onCreateTransaction,
    currentCurrencySymbolPointValue,
    scheduleToday,
    isMarketOpen,
    scheduleLoading,
    isBlockedOpeningTrade,
    allowOpenTradeInNonTradingTime,
    isDisabled,
  } = props

  const { t } = useTranslation()

  return (
    <Box w="100%">
      <Box>
        <InputMarket
          label={t(LN_TRANSACTION_CALC_VOLUME)}
          value={volumeValue}
          onChange={onChangeVolumeInput}
          onIncreaseValue={onIncreaseValue}
          onDecreaseValue={onDecreaseValue}
          onResetValue={onResetValue}
        />
        <MarketInfo />
        <TakeProfitBlock />
        <Box mt={!isMarketOpen ? 0 : 3}>
          <SubmitBtns
            sellValue={currentCurrencyPriceValue}
            buyValue={currentCurrencyPriceValue}
            disabled={isDisabled}
            onCreateTransaction={onCreateTransaction}
            currencySymbolPointValue={currentCurrencySymbolPointValue}
            isBlockedOpeningTrade={isBlockedOpeningTrade}
          />
        </Box>
        {allowOpenTradeInNonTradingTime ? null : (
          <>
            {!scheduleLoading && !isMarketOpen && scheduleToday?.open && (
              <TextXs fontSize="11px" color="red.500">
                {t(LN_TRANSACTION_MARKET_CLOSED_WARNING)},{' '}
                {t(LN_TRANSACTION_MARKET_CLOSED_WARNING_OPEN_HOURS)}: {scheduleToday?.open} -{' '}
                {scheduleToday?.close}
              </TextXs>
            )}
            {!scheduleLoading && !isMarketOpen && !scheduleToday?.open && (
              <TextXs fontSize="11px" color="red.500">
                {t(LN_TRANSACTION_MARKET_CLOSED_WARNING)}
              </TextXs>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default memo(FormMarketUI)
