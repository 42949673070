// Modules
import _ from 'lodash'
import { memo, useCallback, useEffect, useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

// Components
import { TextSm, TextXs } from 'components'
import LoadingBlock from 'components/LoadingBlock'
import ListPanel from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/ListPanel'
import customTheme from 'theme/customTheme'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useMarketWatchAccordionList from 'hooks/useMarketWatchAccordionList'

// Store
import { currencyListLoadingSelector } from 'store/actions/CurrencySocket/selectors'
import { assertListSelector } from 'store/actions/TransactionCreation/selectors'
import { setExpandableAccordionInds } from 'store/actions/marketSidebar/actions'
import { expandableAccordionIndsSelector } from 'store/actions/marketSidebar/selectors'

// UI
import { Box, Icon, useTheme } from '@chakra-ui/react'
import { ChakraProvider } from '@chakra-ui/react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { ThemeProvider } from '@mui/material/styles'

const MarketAccordion = (props) => {
  const { list, onOpenInfo } = props

  const theme = useTheme()
  const { isLightTheme, mainTextColor } = useCurrentColorTheme()
  const { titlesAccordion, getIconAccordion } = useMarketWatchAccordionList()
  const dispatch = useDispatch()

  const expandableAccordionInds = useSelector(expandableAccordionIndsSelector)
  const loadingList = useSelector(currencyListLoadingSelector)

  const assertList = useSelector(assertListSelector)

  const [isChooseFirstTimeAccordionId, setIsChooseFirstTimeAccordionId] = useState(false)

  useEffect(() => {
    if (!isChooseFirstTimeAccordionId) {
      setIsChooseFirstTimeAccordionId(true)
      dispatch(setExpandableAccordionInds([titlesAccordion?.[0]]))
    }
  }, [dispatch, titlesAccordion, isChooseFirstTimeAccordionId])

  const handleChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      dispatch(setExpandableAccordionInds([panel]))
    } else {
      dispatch(setExpandableAccordionInds([]))
    }
  }

  const groupAssertListByAssetLabel = useCallback(() => {
    return _.groupBy(assertList, 'asset_label')
  }, [assertList])

  const filterGroupedAssertListByAssetLabel = useCallback((groupId, groupedList) => {
    return Object.keys(groupedList)
      .filter((key) => key.toLowerCase().includes(groupId.toLowerCase()))
      .reduce((acc, key) => {
        acc[key] = groupedList[key]
        return acc
      }, {})
  }, [])

  const isExpanded = useCallback(
    (id) => {
      if (!id) return false
      return !!expandableAccordionInds?.find((elId) => elId === id)
    },
    [expandableAccordionInds],
  )

  if (!list && !loadingList) {
    return <TextSm>No items</TextSm>
  }

  if (loadingList) {
    return <LoadingBlock />
  }

  return (
    <ThemeProvider theme={theme}>
      {titlesAccordion?.map((groupId) => {
        const isExpandedAccordion = isExpanded(groupId)

        const groupedAssertListByAssetLabel = groupAssertListByAssetLabel()
        const filteredGroupedAssertListByAssetLabel = filterGroupedAssertListByAssetLabel(
          groupId,
          groupedAssertListByAssetLabel,
        )
        const foundKeys = _.keys(filteredGroupedAssertListByAssetLabel)
        const isMoreThanOneTrue = foundKeys?.length > 1

        return !list?.[groupId] || list?.[groupId]?.length === 0 ? null : (
          <Accordion
            key={groupId}
            expanded={isExpandedAccordion}
            onChange={handleChange(groupId)}
            sx={{
              marginBottom: '0.25rem!important',
              marginTop: '0!important',
              background: theme.colors.whiteAlpha['5'],
              borderRadius: '0.375rem',
              color: theme.colors.whiteAlpha['900'],
              backgroundColor: isLightTheme
                ? theme.colors.gray['100']
                : theme.colors.whiteAlpha['50'],
            }}
          >
            <AccordionSummary
              aria-controls={`panel${groupId}-content`}
              id={`panel${groupId}-header`}
              sx={{
                padding: '0.5rem 1rem',
                borderRadius: '0.375rem',
                background: isLightTheme
                  ? theme.colors.brand.mainAccent1Light
                  : theme.colors.navy['500'],
                minHeight: 'auto!important',
                '& .MuiAccordionSummary-content': {
                  margin: '0!important',
                  alignItems: 'center',
                  color: isLightTheme ? 'white' : theme.colors.whiteAlpha['900'],
                },
              }}
            >
              <Icon as={getIconAccordion(groupId)} />
              <Box
                as="span"
                flex="1"
                textAlign="left"
                ml={1}
                fontSize="0.875rem"
                textTransform="capitalize"
              >
                {groupId}
              </Box>
              <Box
                as="span"
                fontSize="xs"
                p="2px 4px"
                bg="navy.300"
                borderRadius="xl"
                color={isLightTheme ? 'white' : 'gray.200'}
                mr={2}
                minW="22px"
                textAlign="center"
              >
                {list?.[groupId]?.length}
              </Box>
              <Icon as={isExpandedAccordion ? IoIosArrowUp : IoIosArrowDown} w="14px" h="14px" />
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: '0.5rem',
                paddingBottom: '1rem',
              }}
            >
              <ChakraProvider theme={customTheme}>
                {!isMoreThanOneTrue ? (
                  <ListPanel list={list?.[groupId]} onOpenInfo={onOpenInfo} />
                ) : (
                  foundKeys?.map((item, idx) => {
                    const listGroup = list?.[groupId]
                    const assertGroupedItemList = filteredGroupedAssertListByAssetLabel?.[item]
                    const filteredList = _.filter(listGroup, (item) =>
                      _.some(assertGroupedItemList, { symbol_description: item?.name }),
                    )

                    return filteredList && filteredList?.length > 0 ? (
                      <Box key={idx + item} pt={2}>
                        <TextXs pl={1} pb={1} color={mainTextColor}>
                          {item}
                        </TextXs>

                        <ListPanel list={filteredList} onOpenInfo={onOpenInfo} />
                      </Box>
                    ) : null
                  })
                )}
              </ChakraProvider>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </ThemeProvider>
  )
}

export default memo(MarketAccordion)
