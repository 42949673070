// Modules
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Languages
import { LN_ADD_NEW_TRADE_MODAL_TABLE_NO_ITEMS } from 'locales/constants'

// Components
import { ControlledSearchInput, TextSm } from 'components'
import LoadingBlock from 'components/LoadingBlock'
import TableData from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal/TableData'
import VerticalTabs from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal/VerticalTabs'
import { getAllListValues } from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal/helper'

// Constants
import { FavouriteGroup } from 'constants/currency'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useMarketWatchAccordionList from 'hooks/useMarketWatchAccordionList'

// Utils
import { getScrollbarCss } from 'utils/scrollbarHelper'

// Store
import {
  currencyListLoadingSelector,
  currencyListSelector,
} from 'store/actions/CurrencySocket/selectors'

// UI
import { Box, Flex, ModalBody } from '@chakra-ui/react'

export const favouritesKey = 'favourites'

const MenuTradeModalBodyUI = () => {
  const { titlesAccordion, updatedFavourites } = useMarketWatchAccordionList()

  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()
  const loadingList = useSelector(currencyListLoadingSelector)
  const list = useSelector(currencyListSelector)

  const [customTitles, setCustomTitles] = useState([])
  const [searchedValue, setSearchedValue] = useState('')
  const [activeTab, setActiveTab] = useState({})

  const FavouriteItemTab = useMemo(() => {
    return {
      [favouritesKey]: updatedFavourites,
    }
  }, [updatedFavourites])

  useEffect(() => {
    setCustomTitles([FavouriteGroup, ...titlesAccordion])
  }, [titlesAccordion])

  useEffect(() => {
    setActiveTab(favouritesKey)
  }, [setActiveTab])

  const onSearch = useCallback(
    (inputValue) => {
      const inputValueTrimmed = inputValue?.trim()?.toLowerCase()
      setSearchedValue(inputValueTrimmed)

      if (inputValueTrimmed === '') {
        setSearchedValue('')
      }
    },
    [setSearchedValue],
  )

  const filtered = useMemo(() => {
    const inputValueTrimmed = searchedValue?.trim()?.toLowerCase()

    if (inputValueTrimmed !== '') {
      const allListValues = getAllListValues(list)
      const filteredValue = allListValues?.filter((el) => {
        return el?.label?.toLowerCase().includes(inputValueTrimmed)
      })

      return filteredValue
    } else {
      // if searched value is empty
      return { ...FavouriteItemTab, ...list }
    }
  }, [searchedValue, FavouriteItemTab, list])

  const onChooseTab = useCallback((value) => setActiveTab(value), [setActiveTab])

  const activeTabItems = useMemo(() => {
    if (!list) return []
    const transformedList = { ...FavouriteItemTab, ...list }
    return transformedList?.[activeTab] || []
  }, [list, FavouriteItemTab, activeTab])

  return (
    <ModalBody pb={5}>
      <ControlledSearchInput onSearch={onSearch} mb={3} disabled={loadingList} />
      {!filtered && !loadingList ? (
        <TextSm color={mainTextColor}>{t(LN_ADD_NEW_TRADE_MODAL_TABLE_NO_ITEMS)}</TextSm>
      ) : loadingList ? (
        <LoadingBlock />
      ) : (
        <Flex gap={3} overflowY="auto" height="50vh" pr={2} css={getScrollbarCss()}>
          {searchedValue === '' && (
            <VerticalTabs
              onChooseTab={onChooseTab}
              active={activeTab}
              accordionData={filtered}
              tabTitles={customTitles}
            />
          )}
          <Box flex={1}>
            <TableData
              data={searchedValue === '' ? activeTabItems : filtered}
              activeTab={activeTab}
            />
          </Box>
        </Flex>
      )}
    </ModalBody>
  )
}

export default memo(MenuTradeModalBodyUI)
