// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_ADD_NEW_TRADE_MODAL_FAVOURITES_TAB } from 'locales/constants'

// Constants
import { favouritesKey } from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal/MenuTradeModalBody'

// Hooks
import useMarketWatchAccordionList from 'hooks/useMarketWatchAccordionList'

// UI
import { Box, Button, ButtonGroup, Icon, Stack } from '@chakra-ui/react'

const VerticalTabs = (props) => {
  const { onChooseTab, active, accordionData, tabTitles } = props

  const { getIconAccordion } = useMarketWatchAccordionList()

  return (
    <Stack direction="column">
      <ButtonGroup gap="0.5" orientation="vertical">
        {tabTitles?.map((groupId) => (
          <TabBtn
            key={groupId}
            onClick={() => onChooseTab(groupId)}
            title={groupId}
            icon={getIconAccordion(groupId)}
            active={active === groupId}
            counter={accordionData?.[groupId]?.length}
          />
        ))}
      </ButtonGroup>
    </Stack>
  )
}

export default memo(VerticalTabs)

const TabBtn = (props) => {
  const { title, icon, onClick, active, counter } = props

  const { t } = useTranslation()

  return (
    <Button
      borderRadius="50px"
      colorScheme={active ? 'blue' : 'gray'}
      fontSize="sm"
      size="md"
      onClick={onClick}
      gap={1}
    >
      <Icon as={icon} />
      <Box as="span" flex="1" textAlign="left" ml={1} textTransform="capitalize">
        {title === favouritesKey ? t(LN_ADD_NEW_TRADE_MODAL_FAVOURITES_TAB) : title}
      </Box>
      <Box
        as="span"
        fontSize="xs"
        p="2px 4px"
        bg="navy.300"
        borderRadius="xl"
        color="gray.200"
        mr={2}
        minW="22px"
      >
        {counter}
      </Box>
    </Button>
  )
}
