// Modules
import _ from 'lodash'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'

// Components
import MarginInfo from 'pages/Dashboard/components/Navbar/MarginInfo/MarginInfo'
import MarginInfoMobile from 'pages/Dashboard/components/Navbar/MarginInfo/MarginInfoMobile'

// Hooks
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Utils
import { formatDigitsWithSpaces, roundTwoDigitsAfterDots } from 'utils/formatDigitsWithSpaces'
import {
  getAvailableValue,
  getEquityValue,
  getLevelValue,
  getTotalPlValue,
  getUsage,
} from 'utils/transactionFormulas'
import { currencyConvert } from 'utils/userBalanceTransform'

// Store
import { positionsListSelector } from 'store/actions/OrderBar/selectors'
import { assertListSelector } from 'store/actions/TransactionCreation/selectors'
import { isMobileSelector, ratesSelector, userBalanceSelector } from 'store/actions/User/selectors'

export const prettifyValue = (value) => formatDigitsWithSpaces(roundTwoDigitsAfterDots(value))

const MarginInfoContainer = () => {
  const isMobile = useSelector(isMobileSelector)

  const positionsList = useSelector(positionsListSelector)
  const balanceValue = useSelector(userBalanceSelector)
  const assertList = useSelector(assertListSelector)
  const ratesList = useSelector(ratesSelector)

  const { userTradingAccountName } = useUserTradingAccountId()

  const plValue = useMemo(() => {
    if (!positionsList) return 0
    return getTotalPlValue(positionsList)
  }, [positionsList])

  // margin
  // const requiredMarginValue = useMemo(() => {
  //   return getRightRequiredMargin(positionsList, assertList)
  // }, [positionsList, assertList])

  const usageValue = useMemo(() => {
    return getUsage(positionsList, assertList, ratesList)
  }, [positionsList, assertList, ratesList])

  const equityValue = useMemo(() => {
    return getEquityValue(balanceValue, plValue)
  }, [balanceValue, plValue])

  const levelValue = useMemo(() => {
    return _.round(getLevelValue(equityValue, usageValue))
  }, [usageValue, equityValue])

  const availableValue = useMemo(() => {
    return getAvailableValue(balanceValue, usageValue, plValue)
  }, [balanceValue, usageValue, plValue])

  // TODO: uncomment when it will be ready
  // const creditValue = useMemo(() => {
  //   return 0
  // }, [])

  const getCurrencySymbol = useMemo(() => {
    return currencyConvert(userTradingAccountName)
  }, [userTradingAccountName])

  return isMobile ? (
    <MarginInfoMobile
      levelValue={levelValue}
      usageValue={usageValue}
      availableValue={availableValue}
      equityValue={equityValue}
      plValue={plValue}
      getCurrencySymbol={getCurrencySymbol}
    />
  ) : (
    <MarginInfo
      levelValue={levelValue}
      usageValue={usageValue}
      availableValue={availableValue}
      equityValue={equityValue}
      plValue={plValue}
      getCurrencySymbol={getCurrencySymbol}
    />
  )
}

export default memo(MarginInfoContainer)
