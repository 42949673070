// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const expandableAccordionIndsSelector = createSelector(
  selfSelector,
  (self) => self.expandableAccordionInds,
)

export const marketTopTabValueSelector = createSelector(
  selfSelector,
  (self) => self.marketTopTabValue,
)

// for socket parameters groups['crypto'], channels['BTC]
export const socketMarketParametersSelector = createSelector(
  selfSelector,
  (self) => self.socketMarketParameters,
)

export const accordionSearchValueSelector = createSelector(
  selfSelector,
  (self) => self.accordionSearchValue,
)
