// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const mainGraphBlockWidthSelector = createSelector(
  selfSelector,
  (self) => self.mainGraphBlockWidth,
)

export const mainGraphBlockHeightSelector = createSelector(
  selfSelector,
  (self) => self.mainGraphBlockHeight,
)

export const isLeftNavOpenSelector = createSelector(selfSelector, (self) => self.isLeftNavOpen)

export const isRightNavOpenSelector = createSelector(selfSelector, (self) => self.isRightNavOpen)

export const isBottomNavOpenSelector = createSelector(selfSelector, (self) => self.isBottomNavOpen)
