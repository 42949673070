// Constants
import { ordersBarPositionsLengthValues } from 'pages/Dashboard/components/OrdersBottomBar/constants'

export const transformPositionOrdersUsage = (data) => {
  if (!data)
    return {
      [ordersBarPositionsLengthValues.open]: 0,
      [ordersBarPositionsLengthValues.close]: 0,
      [ordersBarPositionsLengthValues.pending]: 0,
    }

  return {
    [ordersBarPositionsLengthValues.open]: data?.open || 0,
    [ordersBarPositionsLengthValues.close]: data?.close || 0,
    [ordersBarPositionsLengthValues.pending]: data?.pending || 0,
  }
}
