// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Languages
import {
  LN_HISTORY_SEARCH_BY_INSTRUMENT_TITLE,
  LN_HISTORY_TOTAL_PROFIT,
  LN_HISTORY_TOTAL_VOLUME,
} from 'locales/constants'

// Components
import { SearchInput, TextXs } from 'components'
import Pagination from 'components/Table/Pagination'
import ContentTableList from 'pages/Dashboard/components/OrdersBottomBar/FullSizeModal/ContentTableList'
import CloseAllBtn from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/CloseAllBtn'
import MainTabs from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/MainTabs'
import SecondaryTabs from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/SecondaryTabs'
import SelectFilterTime from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/SelectFilterTime'
import { mainTabsValues } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useOrderBottomInfo from 'hooks/useOrderBottomInfo'
import useOrderBottomInfoPagination, { paginationPerPage } from 'hooks/useOrderBottomPagination'
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

import { formatDigitsWithSpaces, roundTwoDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// Store
import {
  loadingBarSelector,
  mainTabsValueSelector,
  searchValueSelector,
} from 'store/actions/OrderBar/selectors'

// UI
import { Box, Flex, Stack } from '@chakra-ui/react'

const Content = (props) => {
  const { orderList } = props

  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()

  const searchValue = useSelector(searchValueSelector)
  const mainTabsValue = useSelector(mainTabsValueSelector)

  const loadingBar = useSelector(loadingBarSelector)

  const { onSearch, totalProfit, totalVolume } = useOrderBottomInfo()
  const { paginationInfo, onPreviousPage, onNextPage, onPage } = useOrderBottomInfoPagination()
  const { userTradingAccountSymbol } = useUserTradingAccountId()

  return (
    <>
      <Stack gap={2}>
        <Flex gap={2} alignItems="center">
          <MainTabs fontSize="14px" pt={1} pl={2} pr={2} pb={1} height="auto" />
        </Flex>
        <SearchInput
          searchedValue={searchValue}
          setSearchedValue={onSearch}
          h="30px"
          fontSize="13px"
          isCleanText={false}
          placeholder={t(LN_HISTORY_SEARCH_BY_INSTRUMENT_TITLE)}
          _placeholder={{ color: mainTextColor }}
          disabled={loadingBar}
        />
        <Flex justifyContent="space-between" mt={3}>
          <Flex gap={2} alignItems="center">
            <SecondaryTabs isDisabled={loadingBar} />
          </Flex>
          <Box>
            <CloseAllBtn isDisabled={loadingBar} />
            {mainTabsValue === mainTabsValues?.closedPositions && (
              <SelectFilterTime isDisabled={loadingBar} />
            )}
          </Box>
        </Flex>
        <ContentTableList orderList={orderList} loading={loadingBar} />
      </Stack>

      <Flex
        gap={4}
        justifyContent="flex-end"
        position="absolute"
        bottom="10px"
        right="21px"
        flexDirection="row"
        alignItems="flex-end"
      >
        {mainTabsValue === mainTabsValues?.closedPositions && (
          <Flex gap={2}>
            <TextXs color={mainTextColor} fontWeight="500" opacity={loadingBar ? 0.4 : 1}>
              <strong>{t(LN_HISTORY_TOTAL_VOLUME)}</strong>: {totalVolume}
            </TextXs>
            <TextXs color={mainTextColor} fontWeight="500" opacity={loadingBar ? 0.4 : 1}>
              <strong>{t(LN_HISTORY_TOTAL_PROFIT)}</strong>:{' '}
              {formatDigitsWithSpaces(roundTwoDigitsAfterDots(totalProfit))}{' '}
              {userTradingAccountSymbol}
            </TextXs>
          </Flex>
        )}
        {paginationInfo?.total > paginationInfo?.per_page && !loadingBar && (
          <Box>
            <Pagination
              gotoPage={onPage}
              nextPage={onNextPage}
              previousPage={onPreviousPage}
              total={paginationInfo?.total || 0}
              pageIndex={Number(paginationInfo?.page) || 1}
              pageSize={paginationInfo?.per_page || paginationPerPage}
              blockStyles={{ marginTop: 0 }}
            />
          </Box>
        )}
      </Flex>
    </>
  )
}

export default memo(Content)
