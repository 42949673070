// Modules
import axios from 'axios'
import Cookies from 'js-cookie'

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
// export const API_BASE_URL = 'https://vm4905774.62ssd.had.wf/api'
export const cookieAuthToken = 'authToken'
export const unauthorizedMessage = 'Unauthorized action.'
export const URL_SOCKET_PATH = process.env.REACT_APP_URL_SOCKET_PATH
export const URL_REST_PATH = process.env.REACT_APP_URL_REST_PATH
// export const URL_SOCKET_PATH = 'vm4905774.62ssd.had.wf'
export const WS_PORT = process.env.REACT_APP_WS_PORT
// export const WS_PORT = 4002
export const WS_TRANSACTIONS_PORT = process.env.REACT_APP_WS_TRANSACTIONS_PORT
// export const WS_TRANSACTIONS_PORT = 4003
export const WS_PROFIT_PORT = process.env.REACT_APP_WS_PROFIT_PORT
// export const WS_PROFIT_PORT = 4004
export const WS_API_KEY = process.env.REACT_APP_WS_API_KEY
// export const WS_API_KEY = '1|uz9jKuwgPhdqcwKvvkDZT2fq3Qdpwdnf'

export const GetAuthToken = (token) => {
  return {
    Authorization: `Bearer ${token}`,
  }
}

// Create axios instance
export const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
})

// Add a request interceptor to update the token before each request
axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get(cookieAuthToken)
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
