// Modules
import { memo } from 'react'

// Components
import Content from 'pages/User/components/Content'
import Navbar from 'pages/User/components/Navbar'
import Sidebar, { sidebarWidth } from 'pages/User/components/Sidebar'

// UI
import { Box, Flex } from '@chakra-ui/react'

const UserUI = () => {
  return (
    <Box>
      <Navbar />
      <main>
        <Flex justifyContent="space-between" mt="80px">
          <Sidebar />
          <Flex justifyContent="flex-end" w="100%">
            <Box ml={sidebarWidth} minH="90vh" w="100%">
              <Content />
            </Box>
          </Flex>
        </Flex>
      </main>
    </Box>
  )
}

export default memo(UserUI)
