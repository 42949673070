// Modules
import { memo } from 'react'
import { MdKeyboardDoubleArrowRight } from 'react-icons/md'

// UI
import { IconButton } from '@chakra-ui/react'

const CloseSidebar = ({ onClose }) => (
  <IconButton
    aria-label="Close sidebar"
    icon={<MdKeyboardDoubleArrowRight />}
    onClick={onClose}
    colorScheme="blue"
    mb={4}
    size="sm"
    borderRadius={0}
    borderBottomRightRadius={4}
    position="absolute"
    top="0"
    left="0"
  />
)

export default memo(CloseSidebar)
