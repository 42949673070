// Languages
import {
  LN_USER_WITHDRAWAL_TABLE_AMOUNT,
  LN_USER_WITHDRAWAL_TABLE_CURRENCY,
  LN_USER_WITHDRAWAL_TABLE_DATE,
  LN_USER_WITHDRAWAL_TABLE_STATUS,
  LN_USER_WITHDRAWAL_TABLE_STATUS_FAILED,
  LN_USER_WITHDRAWAL_TABLE_STATUS_SUCCESS,
  LN_USER_WITHDRAWAL_TABLE_STATUS_WAITING,
  LN_USER_WITHDRAWAL_TABLE_TYPE,
} from 'locales/constants'

export const statusConstants = {
  waiting: 'waiting',
  failed: 'failed',
  success: 'success',
}

export const statusLanguageConstants = {
  waiting: LN_USER_WITHDRAWAL_TABLE_STATUS_WAITING,
  failed: LN_USER_WITHDRAWAL_TABLE_STATUS_FAILED,
  success: LN_USER_WITHDRAWAL_TABLE_STATUS_SUCCESS,
}

export const headerConstants = {
  date: 'date',
  amount: 'amount',
  currency: 'currency',
  typeWithdrawal: 'typeWithdrawal',
  status: 'status',
  infoBtn: 'infoBtn',
}

export const columns = [
  {
    Header: LN_USER_WITHDRAWAL_TABLE_DATE,
    accessor: headerConstants?.date,
  },
  {
    Header: LN_USER_WITHDRAWAL_TABLE_AMOUNT,
    accessor: headerConstants?.amount,
  },
  {
    Header: LN_USER_WITHDRAWAL_TABLE_CURRENCY,
    accessor: headerConstants?.currency,
  },
  {
    Header: LN_USER_WITHDRAWAL_TABLE_TYPE,
    accessor: headerConstants?.typeWithdrawal,
  },
  {
    Header: LN_USER_WITHDRAWAL_TABLE_STATUS,
    accessor: headerConstants?.status,
  },
  {
    Header: '',
    accessor: headerConstants?.infoBtn,
  },
]
