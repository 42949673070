// Modules
import { lazy, memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Lazy Components
import TableRow from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/TableRow'
// Components
import { statusConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/constants'

// Store
import { setWithdrawalHistory } from 'store/actions/Withdrawal/actions'
import { withdrawalHistoryListSelector } from 'store/actions/Withdrawal/selectors'

// UI
import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'

export const infoColumnWidth = '30px'

const TableHistory = (props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    onCancelWithdrawalStatus,
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const list = useSelector(withdrawalHistoryListSelector)

  // const onCancelWithdrawalStatus = useCallback(
  //   (el) => {
  //     const transformed = list?.map((c) => {
  //       if (c?.id === el?.id) {
  //         return {
  //           ...c,
  //           status: statusConstants?.failed,
  //         }
  //       }
  //       return c
  //     })
  //     dispatch(setWithdrawalHistory(transformed))
  //   },
  //   [list, dispatch],
  // )

  return (
    <TableContainer>
      <Table variant="simple" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr key={headerGroup?.accessor} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <Th
                  key={column?.accessor}
                  {...column.getHeaderProps()}
                  style={
                    columnIndex === headerGroup.headers.length - 1
                      ? { width: infoColumnWidth }
                      : null
                  }
                >
                  {t(column.render('Header'))}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <TableRow
                key={row?.original?.id}
                row={row}
                onCancelWithdrawalStatus={onCancelWithdrawalStatus}
              />
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default memo(TableHistory)
