// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { LN_USER_WITHDRAWAL_TABLE_INFO_BTN } from 'locales/constants'

// Components
import { CrossBtn, InfoBtn, TextMd } from 'components'
import {
  StatusItem,
  getStatusItem,
} from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/StatusItem'
import {
  headerConstants,
  statusConstants,
} from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/constants'

// Utils
import { formatDateToFullDateTime } from 'utils/dateTransform'
import { formatDigitsWithSpaces } from 'utils/formatDigitsWithSpaces'

import {
  setIsWithdrawalModalInfoOpen,
  setWithdrawalModalInfoItem,
} from 'store/actions/Withdrawal/actions'

// UI
import { Flex } from '@chakra-ui/react'

const TableCell = (props) => {
  const { row, cell, onCancelWithdrawalStatus } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onCancelWithdrawal = useCallback(
    () => onCancelWithdrawalStatus(row),
    [row, onCancelWithdrawalStatus],
  )

  const statusItem = useCallback(
    (status) => {
      switch (status) {
        case statusConstants?.failed:
          return <TextMd color="red.200">{status}</TextMd>
        case statusConstants?.waiting:
          return (
            <Flex gap={1} alignItems="center">
              <TextMd>{status}</TextMd>
              <CrossBtn tooltipTitle="Cancel withdrawal" onClick={onCancelWithdrawal} />
            </Flex>
          )
        case statusConstants?.success:
          return <TextMd color="green.200">{status}</TextMd>
      }
    },
    [onCancelWithdrawal],
  )

  const onInfoBtn = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(setWithdrawalModalInfoItem(row))
      dispatch(setIsWithdrawalModalInfoOpen(true))
    },
    [row, dispatch],
  )

  if (cell.column.id === headerConstants.date) {
    return cell?.value ? formatDateToFullDateTime(cell?.value) : '-'
  }

  if (cell.column.id === headerConstants.amount) {
    return cell?.value ? formatDigitsWithSpaces(cell?.value) : '0'
  }

  if (cell.column.id === headerConstants.currency) {
    return cell?.value
  }

  if (cell.column.id === headerConstants.typeWithdrawal) {
    return cell?.value?.toUpperCase()
  }

  if (cell.column.id === headerConstants.status) {
    return <StatusItem status={cell?.value} onCancelWithdrawal={onCancelWithdrawal} />
  }

  if (cell.column.id === headerConstants.infoBtn) {
    return <InfoBtn onClick={onInfoBtn} tooltipTitle={t(LN_USER_WITHDRAWAL_TABLE_INFO_BTN)} />
  }

  return cell.render('Cell')
}

export default memo(TableCell)
