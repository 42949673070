// Modules
import { lazy, memo } from 'react'

// Lazy Components
import TableCell from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/TableCell'
// Components
import { infoColumnWidth } from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/TableHistory'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Td, Tr } from '@chakra-ui/react'

const TableRow = (props) => {
  const { onCancelWithdrawalStatus, row } = props

  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Tr
      {...row.getRowProps()}
      _hover={{
        transition: '0.8s',
        background: isLightTheme ? 'gray.100' : 'gray.700',
      }}
    >
      {row.cells.map((cell, cellIndex) => (
        <Td
          key={cell?.column?.id + cell?.row?.id}
          {...cell.getCellProps()}
          style={cellIndex === row.cells.length - 1 ? { width: infoColumnWidth } : null}
        >
          <TableCell
            row={row?.original}
            cell={cell}
            onCancelWithdrawalStatus={onCancelWithdrawalStatus}
          />
        </Td>
      ))}
    </Tr>
  )
}

export default memo(TableRow)
