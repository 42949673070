// Modules
import { Field } from 'formik'
import { memo } from 'react'

// Components
import RadioProfitGroup from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/RadioProfitGroup/index'

const RadioProfitGroupField = (props) => {
  const { name } = props

  return (
    <Field name={name}>
      {({ field, form }) => (
        <RadioProfitGroup
          onChange={(val) => form.setFieldValue(name, Number(val))}
          value={field.value}
        />
      )}
    </Field>
  )
}

export default memo(RadioProfitGroupField)
