// Action Types
import {
  ADD_ACTIVE_TRADE_ITEM,
  DELETE_ALL_TRADES,
  DELETE_TRADE_ITEM,
  SET_ACTIVE_TRADE_ITEM,
} from 'store/actions/ActiveTradeItems/types'

export const addActiveTradeItem = (payload) => ({
  type: ADD_ACTIVE_TRADE_ITEM,
  payload: payload,
})

export const deleteTradeItem = (payload) => ({
  type: DELETE_TRADE_ITEM,
  payload: payload,
})

export const setActiveTradeItem = (payload) => ({
  type: SET_ACTIVE_TRADE_ITEM,
  payload: payload,
})

export const setDeleteAllTradeItem = () => ({
  type: DELETE_ALL_TRADES,
})
