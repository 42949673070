// Types
import {
  GET_WITHDRAWAL_HISTORY,
  SET_IS_WITHDRAWAL_MODAL_INFO_OPEN,
  SET_WITHDRAWAL_HISTORY,
  SET_WITHDRAWAL_MODAL_ITEM_INFO,
} from 'store/actions/Withdrawal/types'

const initialState = {
  withdrawalHistoryList: null,
  loadingWithdrawalHistoryList: false,
  modalWithdrawalItemInfo: null,
  isWithdrawalModalInfoOpen: false,
}

const withdrawalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WITHDRAWAL_HISTORY:
      return {
        ...state,
        withdrawalHistoryList: action.payload,
        loadingWithdrawalHistoryList: false,
      }
    case GET_WITHDRAWAL_HISTORY:
      return {
        ...state,
        withdrawalHistoryList: null,
        loadingWithdrawalHistoryList: true,
      }
    case SET_IS_WITHDRAWAL_MODAL_INFO_OPEN:
      return {
        ...state,
        isWithdrawalModalInfoOpen: action.payload,
      }
    case SET_WITHDRAWAL_MODAL_ITEM_INFO:
      return {
        ...state,
        modalWithdrawalItemInfo: action.payload,
      }
    default:
      return state
  }
}
export default withdrawalReducer
