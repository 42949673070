// Modules
import _ from 'lodash'

export const getAllListValues = (list) => {
  // Flatten the array of lists into a single array of items
  const allValues = _.flatMap(list, (obj) => _.flatMap(obj, (value) => value))
  return allValues
  // const allItems = _.flatMap(AccordionList, 'list')
  // return _.map(allItems, (item, i) => {
  //   return { ...item, id: i }
  // })
}
