// Constants
import translation_DE from 'locales/translation_DE.json'
import translation_EN from 'locales/translation_EN.json'

export const languages = {
  en: 'en',
  de: 'de',
}

export const resources = {
  [languages.en]: {
    translation: translation_EN,
  },
  [languages.de]: {
    translation: translation_DE,
  },
}
