// Modules
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Store
import { onGetCountriesList } from 'store/actions/User/actions'
import {
  countriesListLoadingSelector,
  countriesListSelector,
  userInfoSelector,
} from 'store/actions/User/selectors'

function useGetUserCountyCode() {
  const dispatch = useDispatch()

  const userInfo = useSelector(userInfoSelector)
  const countriesList = useSelector(countriesListSelector)
  const countriesListLoading = useSelector(countriesListLoadingSelector)
  const countryId = userInfo?.country_id

  useEffect(() => {
    dispatch(onGetCountriesList())
  }, [dispatch])

  const countryName = useMemo(() => {
    if (!countryId) return ''
    const elem = countriesList?.find((el) => el?.id === countryId)
    return elem?.name
  }, [countryId, countriesList])

  return {
    countryName,
    countriesListLoading,
  }
}

export default useGetUserCountyCode
