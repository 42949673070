// Types
import {
  DELETE_KYC_FIELD,
  GET_KYC_FIELD_LIST,
  SEND_KYC_FIELD,
  SET_KYC_FIELD_LIST,
} from 'store/actions/KYC/types'

export const getKYCFieldsList = () => ({
  type: GET_KYC_FIELD_LIST,
})

export const setKYCFieldsList = (payload) => {
  return {
    type: SET_KYC_FIELD_LIST,
    payload,
  }
}

export const sendKYCField = (payload) => {
  return {
    type: SEND_KYC_FIELD,
    payload,
  }
}

export const deleteKYCField = (payload) => {
  return {
    type: DELETE_KYC_FIELD,
    payload,
  }
}
