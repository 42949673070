// Modules
import { memo } from 'react'

// UI
import { FormControl, FormLabel, Textarea } from '@chakra-ui/react'

const TextareaBase = (props) => {
  const { label, value, disabled = false, ...args } = props

  return (
    <FormControl>
      {label && <FormLabel fontSize={{ base: 'sm', sm: 'md' }}>{label}</FormLabel>}
      <Textarea
        value={value}
        isDisabled={disabled}
        sx={{
          ':disabled': {
            opacity: 0.9,
          },
        }}
        size={{ base: 'sm', sm: 'md' }}
        {...args}
      />
    </FormControl>
  )
}

export default memo(TextareaBase)
