// Modules
import { memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import {
  LN_USER_WITHDRAWAL_ACCOUNT_HOLDER_NAME,
  LN_USER_WITHDRAWAL_ACCOUNT_NUMBER,
  LN_USER_WITHDRAWAL_CARD_HOLDER_NAME,
  LN_USER_WITHDRAWAL_CARD_NUMBER,
  LN_USER_WITHDRAWAL_INFO_MODAL_ADDRESS,
  LN_USER_WITHDRAWAL_INFO_MODAL_COPY_ADDRESS,
  LN_USER_WITHDRAWAL_INFO_MODAL_TITLE,
  LN_USER_WITHDRAWAL_TABLE_AMOUNT,
  LN_USER_WITHDRAWAL_TABLE_DATE,
  LN_USER_WITHDRAWAL_TABLE_STATUS,
  LN_USER_WITHDRAWAL_TABLE_TYPE,
  LN_USER_WITHDRAWAL_WALLET_ADDRESS,
} from 'locales/constants'

// Components
import { CopyBtnLikeInput } from 'components'
import InputBase from 'components/Form/InputBase'
import DisabledInputInfo from 'pages/User/components/Content/PersonalInfo/DisabledInputInfo'
import { withdrawalDetailsConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalForm/constants'
import { StatusItem } from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/StatusItem'
import { statusConstants } from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/constants'

// Utils
import { formatDateToFullDateTime } from 'utils/dateTransform'
import { formatDigitsWithSpaces } from 'utils/formatDigitsWithSpaces'

// Store
import {
  setIsWithdrawalModalInfoOpen,
  setWithdrawalModalInfoItem,
} from 'store/actions/Withdrawal/actions'
import {
  isWithdrawalModalInfoOpenSelector,
  modalWithdrawalItemInfoSelector,
} from 'store/actions/Withdrawal/selectors'

// UI
import {
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

const InfoModal = (props) => {
  const { onCancelWithdrawalStatus } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const info = useSelector(modalWithdrawalItemInfoSelector)
  const isOpen = useSelector(isWithdrawalModalInfoOpenSelector)

  const onClose = useCallback(() => {
    dispatch(setIsWithdrawalModalInfoOpen(false))
    dispatch(setWithdrawalModalInfoItem(null))
  }, [dispatch])

  const onCancelWithdrawal = useCallback(() => {
    onCancelWithdrawalStatus(info)
    dispatch(setWithdrawalModalInfoItem({ ...info, status: statusConstants?.failed }))
  }, [info, onCancelWithdrawalStatus, dispatch])

  const switcherInfoDependsOnType = useMemo(() => {
    switch (info?.typeWithdrawal?.toLowerCase()) {
      case withdrawalDetailsConstants.bank:
        return (
          <>
            {info?.description?.cardHolderName && (
              <>
                <FormLabel>{t(LN_USER_WITHDRAWAL_ACCOUNT_HOLDER_NAME)}</FormLabel>
                <CopyBtnLikeInput value={info?.description?.cardHolderName} pb={2} />
              </>
            )}
            {info?.description?.cardHolderName && (
              <>
                <FormLabel>Bank SWIFT code</FormLabel>
                <CopyBtnLikeInput value={info?.description?.swiftCode} pb={2} />
              </>
            )}
            {info?.description?.cardHolderName && (
              <>
                <FormLabel>{t(LN_USER_WITHDRAWAL_ACCOUNT_NUMBER)}</FormLabel>
                <CopyBtnLikeInput value={info?.description?.accountNumber} pb={2} />
              </>
            )}
          </>
        )
      case withdrawalDetailsConstants.card:
        return (
          <>
            {info?.description?.cardHolderName && (
              <>
                <FormLabel>{t(LN_USER_WITHDRAWAL_CARD_HOLDER_NAME)}</FormLabel>
                <CopyBtnLikeInput value={info?.description?.cardHolderName} pb={2} />
              </>
            )}
            {info?.address && (
              <>
                <FormLabel>{t(LN_USER_WITHDRAWAL_CARD_NUMBER)}</FormLabel>
                <CopyBtnLikeInput value={info?.address} pb={2} />
              </>
            )}
          </>
        )
      case withdrawalDetailsConstants.bitcoin:
      case withdrawalDetailsConstants.usdt:
      case withdrawalDetailsConstants.ethereum:
        return (
          <>
            {info?.address && (
              <>
                <FormLabel>{t(LN_USER_WITHDRAWAL_WALLET_ADDRESS)}</FormLabel>
                <CopyBtnLikeInput value={info?.address} />
              </>
            )}
          </>
        )
    }
  }, [info, t])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(LN_USER_WITHDRAWAL_INFO_MODAL_TITLE)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={5}>
          <Flex pb={2} gap={2} alignItems="center">
            <FormLabel m={0}>{t(LN_USER_WITHDRAWAL_TABLE_STATUS)}: </FormLabel>
            <StatusItem status={info?.status} onCancelWithdrawal={onCancelWithdrawal} />
          </Flex>
          <DisabledInputInfo
            label={t(LN_USER_WITHDRAWAL_TABLE_DATE)}
            value={info?.date ? formatDateToFullDateTime(info?.date) : '-'}
            pb={2}
          />
          <DisabledInputInfo
            label={t(LN_USER_WITHDRAWAL_TABLE_AMOUNT)}
            value={info?.amount ? formatDigitsWithSpaces(info?.amount) : '0'}
            isInputRightAddon
            inputRightAddonVal={info?.currency}
            pb={2}
          />
          <DisabledInputInfo
            label={t(LN_USER_WITHDRAWAL_TABLE_TYPE)}
            value={info?.typeWithdrawal?.toUpperCase()}
            pb={2}
          />
          <FormLabel>{t(LN_USER_WITHDRAWAL_INFO_MODAL_ADDRESS)}</FormLabel>
          <CopyBtnLikeInput
            value={info?.address}
            tooltipTitle={t(LN_USER_WITHDRAWAL_INFO_MODAL_COPY_ADDRESS)}
          />
          {switcherInfoDependsOnType}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default memo(InfoModal)
