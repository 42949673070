// Modules
import { memo } from 'react'

// Components
import { CloseSidebar, SliderNavbarWrapperUI } from 'components'
import SliderNavbarFooter from 'components/MobileUI/SliderNavbar/SliderNavbarFooter'
import UserInfo from 'pages/User/components/Navbar/MobileUI/UserInfo'

// Utils
import { getMobileScrollbarCss } from 'utils/scrollbarHelper'

// UI
import { Box, Flex } from '@chakra-ui/react'

const SliderMobileNavbar = ({ isOpen, onClose }) => {
  return (
    <SliderNavbarWrapperUI isOpen={isOpen} onClose={onClose}>
      <Box
        maxHeight="496px"
        overflowY="auto"
        pb="60px"
        pr={2}
        h="100%"
        css={getMobileScrollbarCss()}
      >
        <Flex w="100%" justifyContent="flex-end" mb={2}>
          <CloseSidebar onClose={onClose} />
        </Flex>
        <Box mt={4}>
          <UserInfo />
        </Box>
      </Box>

      <SliderNavbarFooter />
    </SliderNavbarWrapperUI>
  )
}

export default memo(SliderMobileNavbar)
