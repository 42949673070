// Modules
import { lazy, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import {
  LN_USER_WITHDRAWAL_TABLE_WITHDRAWAL_REQUESTS_TITLE,
  LN_USER_WITHDRAWAL_TAB_NO_DATA,
} from 'locales/constants'

// Components
import { TextMd } from 'components'
// Lazy Components
import WithdrawalHistory from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/WithdrawalHistory'
import { mockList } from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/mock'
import { MainTitle } from 'pages/User/components/Content/Withdrawal/index'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Store
import { getWithdrawalHistory, setWithdrawalHistory } from 'store/actions/Withdrawal/actions'
import {
  loadingWithdrawalHistoryListSelector,
  withdrawalHistoryListSelector,
} from 'store/actions/Withdrawal/selectors'

// UI
import { Box, Flex, Spinner } from '@chakra-ui/react'

const WithdrawalHistoryWrapper = () => {
  const { t } = useTranslation()
  const { highLightColor } = useCurrentColorTheme()

  const dispatch = useDispatch()
  const loading = useSelector(loadingWithdrawalHistoryListSelector)
  const list = useSelector(withdrawalHistoryListSelector)

  useEffect(() => {
    dispatch(getWithdrawalHistory())
    dispatch(setWithdrawalHistory(mockList))
  }, [dispatch])

  return (
    <Box>
      <MainTitle title={t(LN_USER_WITHDRAWAL_TABLE_WITHDRAWAL_REQUESTS_TITLE)} pt={6} />
      {loading ? (
        <Flex alignItems="center" justifyContent="center">
          <Spinner color={highLightColor} />
        </Flex>
      ) : !list || list?.length === 0 ? (
        <TextMd fontWeight={500}>{t(LN_USER_WITHDRAWAL_TAB_NO_DATA)}</TextMd>
      ) : (
        <WithdrawalHistory list={list} />
      )}
    </Box>
  )
}

export default memo(WithdrawalHistoryWrapper)
