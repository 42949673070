// Modules
import { memo } from 'react'
import { FaUserAlt } from 'react-icons/fa'

// UI
import { Icon, IconButton } from '@chakra-ui/react'

const UserLogoBtn = (props) => {
  const { onUserBtn, ...args } = props

  return (
    <IconButton
      icon={<Icon as={FaUserAlt} />}
      onClick={onUserBtn}
      aria-label="user"
      isRound
      colorScheme="gray"
      variant="solid"
      size="lg"
      {...args}
    />
  )
}

export default memo(UserLogoBtn)
