// Modules
import { Field, Form, Formik } from 'formik'
import { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

// Languages
import {
  LN_TRANSACTION_CALC_CALCULATE_BTN,
  LN_TRANSACTION_CALC_DEFAULT_BTN,
  LN_TRANSACTION_CALC_MODAL_BUY_TAB,
  LN_TRANSACTION_CALC_MODAL_ENTRY_PRICE,
  LN_TRANSACTION_CALC_MODAL_SELL_TAB,
  LN_TRANSACTION_CALC_MODAL_STOP_LOSS,
  LN_TRANSACTION_CALC_MODAL_TAKE_PROFIT,
  LN_TRANSACTION_CALC_MODAL_VOLUME,
} from 'locales/constants'

// Components
import { TextXs } from 'components'
import { ButtonValues } from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/ProfitCalculator/ProfitCalculatorModal/constants'
import RadioProfitGroupField from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/RadioProfitGroup/RadioProfitGroupField'
import { RADIO_BY_RATE } from 'pages/Dashboard/components/TransactionCreationSidebar/constants'

// Utils
import { validationText } from 'utils/validation/constants'

import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'
// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import {
  checkIfProfitRadioInputIsRequiredValidation,
  validationInputCalcTransaction,
} from 'utils/validation'

// Context
import { MarketCalcContext } from 'context/MarketCalcContext'

// UI
import { Box, Button, Flex, FormControl, FormErrorMessage, Input, Stack } from '@chakra-ui/react'

export const fieldsName = {
  typeTransaction: 'typeTransaction',
  volume: 'volume',
  entryPrice: 'entryPrice',
  takeProfit: 'takeProfit',
  takeProfitRadio: 'takeProfitRadio',
  stopLoss: 'stopLoss',
  stopLossRadio: 'stopLossRadio',
}

const CalcForm = (props) => {
  const { handleSubmit, onResetToDefaultForm } = props

  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()
  const { defaultVolumeOnPlatform } = useCheckUserRestrictions()

  const { unchangeableSocketPrice } = useContext(MarketCalcContext)

  const validationSchema = Yup.object({
    [fieldsName.volume]: validationInputCalcTransaction(
      Yup.number().required(validationText.inputValueIsRequired),
    ),
    [fieldsName.entryPrice]: validationInputCalcTransaction(
      Yup.number().required(validationText.inputValueIsRequired),
    ),
    [fieldsName.takeProfit]: checkIfProfitRadioInputIsRequiredValidation(
      fieldsName.takeProfitRadio,
    ),
    [fieldsName.stopLoss]: checkIfProfitRadioInputIsRequiredValidation(fieldsName.stopLossRadio),
  })

  return (
    <Formik
      initialValues={{
        [fieldsName.typeTransaction]: ButtonValues.buy,
        [fieldsName.volume]: defaultVolumeOnPlatform,
        [fieldsName.entryPrice]: unchangeableSocketPrice,
        [fieldsName.takeProfitRadio]: RADIO_BY_RATE,
        [fieldsName.takeProfit]: unchangeableSocketPrice,
        [fieldsName.stopLossRadio]: RADIO_BY_RATE,
        [fieldsName.stopLoss]: unchangeableSocketPrice,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, isValid, resetForm }) => (
        <Form>
          <Box>
            <Field name={fieldsName.typeTransaction}>
              {({ form }) => (
                <Flex alignItems="center" justifyContent="center">
                  <Button
                    color={
                      values?.[fieldsName.typeTransaction] !== ButtonValues.buy
                        ? mainTextColor
                        : 'white'
                    }
                    borderEndRadius="0"
                    w="full"
                    size="sm"
                    colorScheme={
                      values?.[fieldsName.typeTransaction] !== ButtonValues.buy ? 'gray' : 'blue'
                    }
                    textTransform="uppercase"
                    onClick={() => form.setFieldValue(fieldsName.typeTransaction, ButtonValues.buy)}
                  >
                    {t(LN_TRANSACTION_CALC_MODAL_BUY_TAB)}
                  </Button>
                  <Button
                    color={
                      values?.[fieldsName.typeTransaction] !== ButtonValues.sell
                        ? mainTextColor
                        : 'white'
                    }
                    borderStartRadius="0"
                    w="full"
                    size="sm"
                    colorScheme={
                      values?.[fieldsName.typeTransaction] !== ButtonValues.sell ? 'gray' : 'red'
                    }
                    textTransform="uppercase"
                    onClick={() =>
                      form.setFieldValue(fieldsName.typeTransaction, ButtonValues.sell)
                    }
                  >
                    {t(LN_TRANSACTION_CALC_MODAL_SELL_TAB)}
                  </Button>
                </Flex>
              )}
            </Field>
            <Stack gap={3} mt={2}>
              <CustomInputField
                name={fieldsName.volume}
                label={t(LN_TRANSACTION_CALC_MODAL_VOLUME)}
                type="number"
              />
              <CustomInputField
                name={fieldsName.entryPrice}
                label={t(LN_TRANSACTION_CALC_MODAL_ENTRY_PRICE)}
                type="number"
              />
              <RadioProfitGroupField name={fieldsName.takeProfitRadio} />
              <CustomInputField
                name={fieldsName.takeProfit}
                label={t(LN_TRANSACTION_CALC_MODAL_TAKE_PROFIT)}
                type="number"
              />
              <RadioProfitGroupField name={fieldsName.stopLossRadio} />
              <CustomInputField
                name={fieldsName.stopLoss}
                label={t(LN_TRANSACTION_CALC_MODAL_STOP_LOSS)}
                type="number"
              />
              <Flex gap={2} mt={4}>
                <Button
                  w="50%"
                  colorScheme="gray"
                  type="button"
                  isDisabled={isSubmitting}
                  fontWeight="normal"
                  onClick={() => {
                    resetForm()
                    onResetToDefaultForm()
                  }}
                >
                  {t(LN_TRANSACTION_CALC_DEFAULT_BTN)}
                </Button>
                <Button
                  w="50%"
                  colorScheme="blue"
                  type="submit"
                  isDisabled={isSubmitting || !isValid}
                >
                  {t(LN_TRANSACTION_CALC_CALCULATE_BTN)}
                </Button>
              </Flex>
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default memo(CalcForm)

const InputBlock = (props) => {
  const { label, value, ...args } = props

  return (
    <Box>
      <TextXs pb={1}>{label}</TextXs>
      <Input borderRadius="md" size="sm" type="number" value={value} {...args} />
    </Box>
  )
}

export const CustomInputField = ({ label, name, ...props }) => {
  const { t } = useTranslation()

  return (
    <Field name={name}>
      {({ field, form }) => (
        <FormControl isInvalid={form.errors[name] && form.touched[name]}>
          <TextXs pb={1}>{label}</TextXs>
          <Input borderRadius="md" size="sm" {...field} {...props} />
          <FormErrorMessage>{t(form.errors[name])}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}
