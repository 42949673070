// Modules
import { memo, useCallback } from 'react'

// Components
import { TextXs } from 'components'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Flex, Icon } from '@chakra-ui/react'

const NavButton = (props) => {
  const { id, icon, title, onChoose, isActive, iconW = 5, iconH = 5 } = props

  const { isLightTheme } = useCurrentColorTheme()

  const onChooseItem = useCallback(() => onChoose(id), [onChoose, id])

  return (
    <Flex
      w="100%"
      flexDirection="column"
      gap={1}
      alignItems="center"
      onClick={onChooseItem}
      _hover={{
        cursor: 'pointer',
        backgroundColor: isLightTheme ? 'blue.600' : 'navy.400',
        transition: '0.8s',
      }}
      transition="0.8s"
      pb={4}
      pt={4}
      bg={isActive ? (isLightTheme ? 'brand.mainAccent1Light' : 'navy.500') : 'transparent'}
      role="group"
    >
      <Icon
        as={icon}
        _groupHover={{ color: 'white' }}
        color={isActive ? (isLightTheme ? 'white' : '') : isLightTheme ? '' : 'white'}
        w={iconW}
        h={iconH}
      />
      {title && (
        <TextXs
          color={isActive ? 'white' : isLightTheme ? 'brand.textColor1Light' : 'brand.textColor2'}
          _groupHover={{ color: 'white' }}
          whiteSpace="pre-wrap"
          overflowWrap="break-word"
          textAlign="center"
          fontWeight={500}
        >
          {title}
          {/*{t(title)}*/}
        </TextXs>
      )}
    </Flex>
  )
}

export default memo(NavButton)
