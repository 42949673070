// Modules
import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

// Constants
import { toastType } from 'components/ToastApp/constants'

// Store
import {
  idSelector,
  showToastSelector,
  toastMessageSelector,
  toastTitleSelector,
  toastTypeSelector,
} from 'store/actions/ToastsApp/selectors'

// UI
import { useToast } from '@chakra-ui/react'

const ToastApp = () => {
  const toast = useToast()

  const show = useSelector(showToastSelector)
  const typeToast = useSelector(toastTypeSelector)
  const message = useSelector(toastMessageSelector)
  const title = useSelector(toastTitleSelector)
  const id = useSelector(idSelector)

  const [recentMessages, setRecentMessages] = useState(new Set())

  useEffect(() => {
    if (show && message) {
      if (!recentMessages.has(message)) {
        if (typeToast === toastType.error && message === 'Unauthorized action.') {
          toast({
            position: 'bottom-left',
            title: title,
            description: message,
            status: typeToast,
            duration: 3000,
            isClosable: true,
            id: id,
          })
          // Add message to recentMessages to prevent duplicate
          setRecentMessages((prevMessages) => {
            return new Set(prevMessages).add(message)
          })

          // Remove message after a timeout to allow re-showing later if needed
          const timer = setTimeout(() => {
            setRecentMessages((prevMessages) => {
              const updatedMessages = new Set(prevMessages)
              updatedMessages.delete(message)
              return updatedMessages
            })
          }, 4000) // Match the toast duration here

          // Clear timeout when component unmounts
          return () => clearTimeout(timer)
        } else {
          toast({
            position: 'bottom-left',
            title: title,
            description: message,
            status: typeToast,
            duration: 3000,
            isClosable: true,
            id: id,
          })
        }
      }
    }
  }, [id, show, message, toast, typeToast, title, recentMessages, setRecentMessages])

  return null
}

export default memo(ToastApp)
