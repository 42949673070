export const ordersBarPositionsLengthKey = 'ordersBarPositionsLengthKey'

export const ordersBarPositionsLengthValues = {
  close: 1,
  open: 2,
  pending: 3,
}

export const ordersBarPositionsLengthDefaultValue = {
  [ordersBarPositionsLengthValues.close]: 0,
  [ordersBarPositionsLengthValues.open]: 0,
  [ordersBarPositionsLengthValues.pending]: 0,
}
