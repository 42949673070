// Hooks
import { themeColorMode } from 'hooks/useCurrentColorTheme'

export const tooltipStyles = {
  Tooltip: {
    baseStyle: ({ colorMode }) => ({
      borderRadius: 'md',
      fontSize: 'xs',
      color: colorMode === themeColorMode.light ? 'brand.textColor2Light' : 'gray.200',
      backgroundColor: colorMode === themeColorMode.light ? 'gray.100' : 'gray.600',
      border: '1px solid',
      borderColor: colorMode === themeColorMode.light ? 'gray.300' : 'brand.mainColor2',
      paddingY: '2px',
    }),
  },
}
