// Types
import {
  CREATE_TRANSACTION_LOADING,
  CREATE_TRANSACTION_RESET_FORM,
  GET_ASSERT_LIST,
  SET_ASSERT_LIST,
  SET_ASSERT_LIST_LOADING,
  SET_SHOW_MOBILE_CREATE_TRANSACTION_MODAL,
} from 'store/actions/TransactionCreation/types'

const initialState = {
  assertList: null,
  assertListLoading: true,
  transactionCreationLoading: false,
  transactionCreationResetFormAction: null,
  isMobileCreateTransactionModalOpen: false,
}

const transactionCreationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ASSERT_LIST:
      return {
        ...state,
        assertList: action.payload,
      }
    case GET_ASSERT_LIST:
      return {
        ...state,
        assertListLoading: true,
      }
    case SET_ASSERT_LIST_LOADING:
      return {
        ...state,
        assertListLoading: action.payload,
      }
    case CREATE_TRANSACTION_LOADING:
      return {
        ...state,
        transactionCreationLoading: action.payload,
      }
    case CREATE_TRANSACTION_RESET_FORM:
      return {
        ...state,
        transactionCreationResetFormAction: action.payload,
      }
    case SET_SHOW_MOBILE_CREATE_TRANSACTION_MODAL:
      return {
        ...state,
        isMobileCreateTransactionModalOpen: action.payload,
      }
    default:
      return state
  }
}
export default transactionCreationReducer
