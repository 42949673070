import moment from 'moment'

// "2021-02-18 12:39:38" to "2021.02.18, 12:39:38"
export const formatDateToFullDateTime = (dateTimeString) => {
  const formattedDateTime = moment(dateTimeString).format('YYYY.MM.DD, HH:mm:ss')
  return formattedDateTime
}

// "2021-02-18 12:39:38" to "2021.02.18, 12:39:38"
export const formatDateToPrettierFullDateTime = (dateTimeString) => {
  if (!dateTimeString) return null
  const formattedDateTime = moment(dateTimeString).format('DD.MM.YYYY, HH:mm:ss')
  return formattedDateTime
}
