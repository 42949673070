// Modules
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { FaMoneyBillTransfer } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'

import { TVChartContainer } from 'components/TVChartContainer'
// Components
import InfoModal from 'pages/Dashboard/components/InfoModal'
import LeftNavbar from 'pages/Dashboard/components/LeftNavbar'
import MarketWatch from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch'
import { NavList } from 'pages/Dashboard/components/LeftNavbar/constants'
import Main from 'pages/Dashboard/components/Main'
import ActiveTradeBlock from 'pages/Dashboard/components/Main/ActiveTradeBlock'
// import ActiveTradeBlock from 'src/pages/Dashboard/components/Main/ActiveTradeBlock'
// import TransactionCreationSidebar from 'src/pages/Dashboard/components/TransactionCreationSidebar'
import Navbar from 'pages/Dashboard/components/Navbar'
import Balance from 'pages/Dashboard/components/Navbar/Balance'
import BordersBottomBar from 'pages/Dashboard/components/OrdersBottomBar'
import TransactionCreationModalMobile from 'pages/Dashboard/components/TransactionCreationSidebar/TransactionCreationModalMobile'
import WrapperForDrawerMobileTemperaryView from 'pages/Dashboard/components/WrapperForDrawer/MobileTemperaryView'

// Constants
import {
  BottomBarHeight,
  LeftNavBarIconsMobileWidth,
  LeftNavBarIconsWidth,
  LeftTabsWidth,
  NavBarMobileHeight,
  RightMarketSidebar,
  ScreenWidthWithoutNavbar,
  isOpenBottomBarKey,
} from 'constants/index'

import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Store
import {
  setIsBottomNavOpen,
  setIsLeftNavOpen,
  setIsRightNavOpen,
  setMainGraphBlockWidth,
} from 'store/actions/MainGraphBlockWidth/actions'
import {
  isBottomNavOpenSelector,
  isLeftNavOpenSelector,
  isRightNavOpenSelector,
} from 'store/actions/MainGraphBlockWidth/selectors'

// Ui
import { Box, Flex, Icon, IconButton } from '@chakra-ui/react'

const DashboardMobile = () => {
  const dispatch = useDispatch()

  const [isActiveLeftNav, setIsActiveLeftNav] = useState(NavList[0].title)
  const [windowSizeState, setWindowSizeState] = useState()
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const divRef = useRef(null)
  const windowWidth = windowSize?.current?.[0]

  const isLeftNavOpen = useSelector(isLeftNavOpenSelector)
  const isRightNavOpen = useSelector(isRightNavOpenSelector)
  const { mainBorderColor } = useCurrentColorTheme()

  const mainWidthCalculation = useCallback(
    (currentWindowWidth) => {
      const leftTab = isLeftNavOpen ? LeftTabsWidth : 0
      const rightSidebar = isRightNavOpen ? RightMarketSidebar : 0
      return currentWindowWidth - LeftNavBarIconsWidth - leftTab - rightSidebar
    },
    [isLeftNavOpen, isRightNavOpen],
  )

  // window size
  useEffect(() => {
    dispatch(setMainGraphBlockWidth(mainWidthCalculation(windowWidth)))
    setWindowSizeState(windowWidth)
  }, [windowWidth, dispatch, mainWidthCalculation])

  useEffect(() => {
    dispatch(setMainGraphBlockWidth(mainWidthCalculation(windowSizeState)))
  }, [windowSizeState, isRightNavOpen, isLeftNavOpen, dispatch, mainWidthCalculation])

  // const mainBlockWidth = useMemo(() => mainWidthCalculation(windowWidth), [windowWidth])

  // console.log('windowSize', windowSize)
  // console.log('mainBlockWidth', mainBlockWidth)

  useEffect(() => {
    // Function to get the size of the div element
    const getSize = () => {
      const divElement = divRef.current
      if (divElement) {
        // Introduce a delay of 0.3 seconds before getting the bounding client rect
        // setTimeout(() => {
        const rect = divElement?.getBoundingClientRect()
        // console.log('Width:', rect.width)
        // console.log('Height:', rect.height)
        if (windowWidth !== rect.width) {
          setWindowSizeState(rect.width)
          // console.log('mainWidthCalculation(rect.width)', mainWidthCalculation(rect.width))
          dispatch(setMainGraphBlockWidth(mainWidthCalculation(rect.width)))
        }

        // setWindowSize(rect.width)
        // }, 400)
      }
    }

    // Call the getSize function initially
    getSize()

    // Attach an event listener for resizing, if needed
    window.addEventListener('resize', getSize)

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', getSize)
    }
  }, [isLeftNavOpen, isRightNavOpen, windowWidth, dispatch])

  const onLeftNavOpen = useCallback(() => dispatch(setIsLeftNavOpen(true)), [dispatch])

  const onLeftNavClose = useCallback(() => dispatch(setIsLeftNavOpen(false)), [dispatch])

  const onBottomBlockOpen = useCallback(() => {
    dispatch(setIsBottomNavOpen(true))
    localStorage.setItem(isOpenBottomBarKey, 'true')
  }, [dispatch])

  const onBottomBlockClose = useCallback(() => {
    dispatch(setIsBottomNavOpen(false))
    localStorage.setItem(isOpenBottomBarKey, 'false')
  }, [dispatch])

  ////////////////////

  const elementRef = useRef(null)
  const [width, setWidth] = useState(0)

  // Function to update the width
  const updateWidth = () => {
    if (elementRef.current) {
      setWidth(elementRef.current.offsetWidth)
    }
  }

  // Update width on component mount and window resize
  useEffect(() => {
    // Update width initially
    updateWidth()

    // Add resize event listener
    window.addEventListener('resize', updateWidth)

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [])

  // console.log('width', width)

  return (
    <>
      <Box>
        <Navbar />
        <Box mt={NavBarMobileHeight + 'px'}>
          <LeftNavbar
            isActiveNav={isActiveLeftNav}
            setIsActiveNav={setIsActiveLeftNav}
            onLeftNavOpen={onLeftNavOpen}
            onLeftNavClose={onLeftNavClose}
          />
          <Flex
            ml={LeftNavBarIconsMobileWidth + 'px'}
            pl={2}
            pt={5}
            pr={2}
            mb={6}
            position="relative"
            zIndex={2}
          >
            <Flex w="100%" ref={elementRef}>
              <Box>
                <Box>
                  <ActiveTradeBlock width={width} />
                  <Box height="60vh" w="100%" mt={3}>
                    <TVChartContainer />
                  </Box>
                </Box>
                <Box borderTop="1px solid" borderColor={mainBorderColor} mt={6} pt={3}>
                  <MarketWatch />
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Box>
        <BordersBottomBar
          onBottomBlockOpen={onBottomBlockOpen}
          onBottomBlockClose={onBottomBlockClose}
        />
        <InfoModal />
      </Box>
      <TransactionCreationModalMobile />
    </>
  )
}

export default memo(DashboardMobile)
