// Modules
import { memo } from 'react'

// Components
import Navbar from 'pages/Auth/components/Navbar'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Card, CardBody, Flex } from '@chakra-ui/react'

const PageWrapper = ({ children, ...args }) => {
  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Box>
      <Navbar />
      <Box height="100vh">
        <Flex width="100%" alignItems="center" justifyContent="center" paddingTop="70px">
          <Card
            bg={isLightTheme ? 'white' : 'whiteAlpha.50'}
            borderRadius="md"
            border="1px solid"
            borderColor="whiteAlpha.100"
            minW="500px"
            maxW="500px"
            {...args}
          >
            <CardBody>
              <Box mt={3}>{children}</Box>
            </CardBody>
          </Card>
        </Flex>
      </Box>
    </Box>
  )
}
export default memo(PageWrapper)
