// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const activeTradeItemsListSelector = createSelector(
  selfSelector,
  (self) => self.activeTradeItemsList,
)

export const chosenActiveTradeItemSelector = createSelector(
  selfSelector,
  (self) => self.chosenActiveTradeItem,
)
