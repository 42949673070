// Modules
import { memo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

// import { useDispatch, useSelector } from 'react-redux'
// Components
import { TVChartContainer } from 'components/TVChartContainer'
import ActiveTradeBlock from 'pages/Dashboard/components/Main/ActiveTradeBlock'

import { BottomBarHeight } from 'constants'

import {
  isBottomNavOpenSelector,
  mainGraphBlockWidthSelector,
} from 'store/actions/MainGraphBlockWidth/selectors'

// import { exampleAction } from 'src/store/actions/exampleActions'
import { Box, Stack } from '@chakra-ui/react'

// UI

const Main = () => {
  const isBottomNavOpen = useSelector(isBottomNavOpenSelector)

  return (
    <Box w="100%" h="100%">
      <ActiveTradeBlock />
      <Box height={isBottomNavOpen ? `86%` : '89%'}>
        <TVChartContainer />
      </Box>
    </Box>
  )
}

export default memo(Main)
