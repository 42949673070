// Modules
import * as Yup from 'yup'

import { formConstants } from 'pages/Auth/Login/constants'
import { fieldsName } from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/ProfitCalculator/ProfitCalculatorModal/CalcForm'
import { RADIO_BY_PROFIT } from 'pages/Dashboard/components/TransactionCreationSidebar/constants'

// Utils
import { validationText } from 'utils/validation/constants'

export const validationEmail = {
  [formConstants.email]: Yup.string()
    .email(validationText.emailInvalid)
    .required(validationText.emailRequired),
}

export const validationPassword = {
  [formConstants.password]: Yup.string()
    .min(6, validationText.passwordLength)
    .required(validationText.passwordRequired),
}

export const validationRepeatPassword = {
  [formConstants.repeatPassword]: Yup.string()
    .oneOf([Yup.ref(formConstants.password), null], validationText.repeatPasswordMatch)
    .required(validationText.repeatPasswordRequired),
}

export const validationInputCalcTransaction = (additionalValidation) =>
  Yup.number()
    .positive(validationText.inputValueNotPositive)
    .notOneOf([0], validationText.inputValueNotZero)
    .concat(additionalValidation)

export const checkIfProfitRadioInputIsRequiredValidation = (fieldInput) =>
  Yup.number().when(fieldInput, {
    is: Number(RADIO_BY_PROFIT),
    then: (schema) =>
      schema
        .required(validationText.inputValueIsRequired)
        .positive(validationText.inputValueNotPositive)
        .notOneOf([0], validationText.inputValueNotZero),
    otherwise: (schema) =>
      schema
        .positive(validationText.inputValueNotPositive)
        .notOneOf([0], validationText.inputValueNotZero),
  })
