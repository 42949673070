// Modules
import { lazy, memo, useCallback } from 'react'
import { FaCheck } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'

// Components
import { TextMd } from 'components'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { formatDigitsWithSpaces } from 'utils/formatDigitsWithSpaces'
import { formatDigitsWithDots } from 'utils/formatPrice'
import { currencyConvert } from 'utils/userBalanceTransform'

// Store
import { onSetUserBalance, onSetUserCurrentTradingAccount } from 'store/actions/User/actions'
import {
  currentTradingAccountSelector,
  tradingAccountsSelector,
  userBalanceLoadingSelector,
  userBalanceSelector,
} from 'store/actions/User/selectors'

// UI
import { Box, Flex, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react'

// Lazy Components
const BalanceBtnLaptop = lazy(() =>
  import('pages/Dashboard/components/Navbar/Balance/BalanceBtnLaptop'),
)
const BalanceBtnMobile = lazy(() =>
  import('pages/Dashboard/components/Navbar/Balance/BalanceBtnMobile'),
)

const Balance = ({ isMobileView }) => {
  const { isLightTheme } = useCurrentColorTheme()

  const dispatch = useDispatch()

  const balanceValue = useSelector(userBalanceSelector)
  const userBalanceLoading = useSelector(userBalanceLoadingSelector)

  const tradingAccounts = useSelector(tradingAccountsSelector)
  const currentTradingAccount = useSelector(currentTradingAccountSelector)

  const onChooseTradingAccount = useCallback(
    (currency) => {
      dispatch(onSetUserCurrentTradingAccount(currency))
      dispatch(onSetUserBalance(currency?.balance ?? 0))
    },
    [dispatch],
  )

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton disabled={userBalanceLoading} height="100%">
            {isMobileView ? (
              <BalanceBtnMobile
                balanceValue={balanceValue}
                userBalanceLoading={userBalanceLoading}
                currencyName={currentTradingAccount?.currency?.name}
                isOpen={isOpen}
              />
            ) : (
              <BalanceBtnLaptop
                balanceValue={balanceValue}
                userBalanceLoading={userBalanceLoading}
                currencyName={currentTradingAccount?.currency?.name}
                isOpen={isOpen}
              />
            )}
          </MenuButton>
          <MenuList
            bg={isLightTheme ? 'gray.50' : 'gray.700'}
            borderColor={isLightTheme ? 'gray.100' : 'whiteAlpha.100'}
          >
            <Box pt={1}>
              <TextMd
                p={2}
                pt={0}
                borderBottom="1px"
                borderColor={isLightTheme ? 'gray.100' : 'whiteAlpha.100'}
              >
                Trading Accounts
              </TextMd>
              {tradingAccounts?.map((el) => (
                <BalanceMenuItem
                  key={el?.currency?.id}
                  isChosen={currentTradingAccount?.trading_account_id === el?.trading_account_id}
                  balance={el?.balance ? formatDigitsWithSpaces(el?.balance) : '0'}
                  onChooseTradingAccount={onChooseTradingAccount}
                  currencyType={el?.currency?.name}
                  obj={el}
                />
              ))}
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default memo(Balance)

const BalanceMenuItem = memo((props) => {
  const { isChosen, balance, onChooseTradingAccount, currencyType, obj } = props
  const { isLightTheme } = useCurrentColorTheme()

  const onChoose = useCallback(() => {
    onChooseTradingAccount(obj)
  }, [obj, onChooseTradingAccount])

  return (
    <Box
      p={2}
      pt={1}
      pb={1}
      cursor="pointer"
      borderBottom="1px"
      borderColor={isLightTheme ? 'gray.100' : 'whiteAlpha.100'}
      onClick={onChoose}
      _hover={{
        bg: isLightTheme ? 'brand.mainAccent1Light' : 'brand.lightAccent',
      }}
      role="group"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex gap={2}>
          <TextMd fontWeight={500} _groupHover={{ color: 'white' }}>
            {currencyConvert(currencyType)}
          </TextMd>
          <TextMd fontWeight={500} _groupHover={{ color: 'white' }}>
            {formatDigitsWithDots(balance, 20)}
          </TextMd>
        </Flex>
        {isChosen && <Icon as={FaCheck} w="18px" _groupHover={{ color: 'white' }} />}
      </Flex>
    </Box>
  )
})

BalanceMenuItem.displayName = 'BalanceMenuItem'
