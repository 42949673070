// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { TextSm } from 'components'

// UI
import { Th } from '@chakra-ui/react'

const TableHeaderTh = (props) => {
  const { title, ...args } = props

  const { t } = useTranslation()

  return (
    <Th w="auto">
      <TextSm {...args}>{t(title)}</TextSm>
    </Th>
  )
}

export default memo(TableHeaderTh)
