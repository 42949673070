// Modules
import { memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import {
  LN_HISTORY_NO_DATA,
  LN_HISTORY_TABLE_DELETE_ALERT_BTN,
  LN_HISTORY_TABLE_DELETE_ALERT_MESSAGE,
} from 'locales/constants'

// Components
import { AlertWarning, TextSm } from 'components'
import LoadingBlock from 'components/LoadingBlock'
import Pagination from 'components/Table/Pagination'
import TableHeaderTh from 'pages/Dashboard/components/OrdersBottomBar/ContentBar/TableHeaderTh'

// Hooks
import useOrderBottomInfoContent from 'hooks/useOrderBottomInfoContent'
import useOrderBottomInfoPagination, { paginationPerPage } from 'hooks/useOrderBottomPagination'
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Utils
import { getScrollbarCss } from 'utils/scrollbarHelper'

// Store
import {
  getClosedTransactionsList,
  getOpenTransactionsList,
  getPendingTransactionsList,
} from 'store/actions/OrderBar/actions'
import { loadingBarSelector } from 'store/actions/OrderBar/selectors'

// UI
import { Box, Table, TableContainer, Tbody, Thead, Tr } from '@chakra-ui/react'

const ContentBar = (props) => {
  const { ordersList, isMobile } = props

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const loadingBar = useSelector(loadingBarSelector)

  const { userTradingAccountId } = useUserTradingAccountId()

  useEffect(() => {
    if (userTradingAccountId) {
      // dispatch(getOpenTransactionsList({ tradingAccountId: userTradingAccountId }))
      dispatch(getClosedTransactionsList({ tradingAccountId: userTradingAccountId }))
      dispatch(getPendingTransactionsList({ tradingAccountId: userTradingAccountId }))
    }
  }, [dispatch, userTradingAccountId])

  const {
    sortedData,
    sortConfig,
    deleteItemAlert,
    isOpenDeleteItem,
    handleSort,
    onCloseDeleteItem,
    headersList,
    getRow,
    onHandleDeleteItemBtn,
  } = useOrderBottomInfoContent(ordersList, 'xs')

  const { paginationInfo, onPreviousPage, onNextPage, onPage } = useOrderBottomInfoPagination()

  return (
    <Box
      h="100%"
      w="100%"
      position="relative"
      borderTop="1px solid"
      borderTopColor="navy.20"
      mt={0.5}
      maxHeight={isMobile ? '45vh' : '150px'}
      overflowY="auto"
      mr={2}
      css={getScrollbarCss()}
      p="2px 15px"
      pb="25px"
    >
      <Box>
        {loadingBar ? (
          <LoadingBlock h={100} />
        ) : (
          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  {headersList?.map((header) => (
                    <TableHeaderTh
                      key={header.name}
                      sortBy={sortConfig.key}
                      sortOrder={sortConfig.direction}
                      name={header.name}
                      handleSort={handleSort}
                      title={header.title}
                    />
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {!sortedData || sortedData?.length === 0 ? (
                  <TextSm mt={2}>{t(LN_HISTORY_NO_DATA)}</TextSm>
                ) : (
                  sortedData?.map((el) => getRow(el))
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Box>
      {paginationInfo?.total > paginationInfo?.per_page && !loadingBar && (
        <Pagination
          gotoPage={onPage}
          nextPage={onNextPage}
          previousPage={onPreviousPage}
          total={paginationInfo?.total || 0}
          pageIndex={Number(paginationInfo?.page) || 1}
          pageSize={paginationInfo?.per_page || paginationPerPage}
        />
      )}

      <AlertWarning
        isOpen={isOpenDeleteItem}
        onClose={onCloseDeleteItem}
        content={`${t(LN_HISTORY_TABLE_DELETE_ALERT_MESSAGE)}: ${deleteItemAlert?.id}?`}
        actionTitleBtn={t(LN_HISTORY_TABLE_DELETE_ALERT_BTN)}
        onActionBtn={onHandleDeleteItemBtn}
        // onActionBtn={onActionBtn}
      />
    </Box>
  )
}

export default memo(ContentBar)
