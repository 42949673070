// Modules
import Cookies from 'js-cookie'

// Types
import {
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_RESULT,
  LOGOUT,
  NOT_AUTH,
  ON_LOGIN,
  ON_LOGIN_RESULT,
  RESET_PASSWORD_ACTION,
  RESET_PASSWORD_RESULT,
  SET_TOKEN,
} from 'store/actions/Auth/types'
import { cookieAuthToken } from 'store/api'

const initialState = {
  token: Cookies.get(cookieAuthToken),
  isAuthenticated: !!Cookies.get(cookieAuthToken),
  loading: false,
  error: '',
  forgotPassword: { loading: false, error: '' },
  resetPassword: { loading: false, error: '' },
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
        isAuthenticated: true,
      }
    case ON_LOGIN_RESULT:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case ON_LOGIN:
      return {
        ...state,
        loading: true,
      }
    case LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
      }
    case NOT_AUTH:
      return {
        ...state,
        isAuthenticated: false,
      }
    case FORGOT_PASSWORD_ACTION:
      return {
        ...state,
        forgotPassword: { loading: true },
      }
    case FORGOT_PASSWORD_RESULT:
      return {
        ...state,
        forgotPassword: { loading: false, ...action.payload },
      }
    case RESET_PASSWORD_ACTION:
      return {
        ...state,
        resetPassword: { loading: true },
      }
    case RESET_PASSWORD_RESULT:
      return {
        ...state,
        resetPassword: { loading: false, ...action.payload },
      }
    default:
      return state
  }
}
export default userReducer
