// Constants
import { localStorageActiveTradeItemsKey } from 'constants/index'

import { getCurrencyCategoryByNameHelper } from 'hooks/useMarketWatchAccordionList'

// Types
import {
  ADD_ACTIVE_TRADE_ITEM,
  DELETE_ALL_TRADES,
  DELETE_TRADE_ITEM,
  SET_ACTIVE_TRADE_ITEM,
} from 'store/actions/ActiveTradeItems/types'

const initialState = {
  activeTradeItemsList: JSON.parse(localStorage.getItem(localStorageActiveTradeItemsKey)) || [],
  chosenActiveTradeItem:
    JSON.parse(localStorage.getItem(localStorageActiveTradeItemsKey))?.[0] || null,
}

const activeTradeItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACTIVE_TRADE_ITEM:
      const itemIdToAdd = action.payload
      const itemIndexToAdd = state.activeTradeItemsList.findIndex(
        (item) => item?.id === itemIdToAdd?.id && item?.name === itemIdToAdd?.name,
      )

      let newActiveAdd = [...state.activeTradeItemsList]
      if (itemIndexToAdd === -1) {
        newActiveAdd = [action.payload, ...state.activeTradeItemsList]
      }

      localStorage.setItem(localStorageActiveTradeItemsKey, JSON.stringify(newActiveAdd))
      return {
        ...state,
        activeTradeItemsList: newActiveAdd,
      }
    case SET_ACTIVE_TRADE_ITEM:
      return {
        ...state,
        chosenActiveTradeItem: action.payload,
      }
    case DELETE_ALL_TRADES:
      localStorage.setItem(localStorageActiveTradeItemsKey, JSON.stringify([]))
      return {
        ...state,
        activeTradeItemsList: [],
        chosenActiveTradeItem: null,
      }
    case DELETE_TRADE_ITEM:
      localStorage.setItem(localStorageActiveTradeItemsKey, JSON.stringify(action.payload))
      return {
        ...state,
        activeTradeItemsList: action.payload,
      }
    default:
      return state
  }
}
export default activeTradeItemsReducer
