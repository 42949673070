// Modules
import { memo } from 'react'
import { IoIosMenu } from 'react-icons/io'

// Assets
import TradeIcon from 'assets/trade-icon'

// Components
import Balance from 'pages/Dashboard/components/Navbar/Balance'
import SliderMobileNavbar from 'pages/User/components/Navbar/MobileUI/SliderMobileNavbar'
import useUserSidebar from 'pages/User/components/Sidebar/useUserSidebar'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Flex, IconButton, useDisclosure } from '@chakra-ui/react'

const NavbarMobileUI = () => {
  const { mainBorderColor, mainBgColor, isLightTheme } = useCurrentColorTheme()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { onStartTrading } = useUserSidebar()

  return (
    <>
      <header style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1 }}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          p="10px"
          borderBottom="2px solid"
          borderColor={mainBorderColor}
          bg={mainBgColor}
        >
          <Box w="54px" mr={7} alignItems="center" justifyContent="center" display="flex">
            Logo
          </Box>
          <Flex ml={7} gap={3} alignItems="center">
            <Box display={{ base: 'none', '2xs': 'inline-block' }}>
              <Balance isMobileView />
            </Box>

            <IconButton
              aria-label="Start trading"
              icon={<TradeIcon color={isLightTheme ? '#000000' : 'white'} />}
              onClick={onStartTrading}
              colorScheme="blue"
              size="sm"
              isRound
            />
            <IconButton
              aria-label="Open sidebar"
              icon={<IoIosMenu />}
              onClick={onOpen}
              colorScheme="blue"
              size="sm"
            />
          </Flex>
        </Flex>
      </header>
      <SliderMobileNavbar onClose={onClose} isOpen={isOpen} />
    </>
  )
}

export default memo(NavbarMobileUI)
