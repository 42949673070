import _ from 'lodash'

export const findElemInListCurrency = (name, list) => {
  const keysList = _.keys(list)

  let findElem = null
  keysList?.map((groupId) => {
    const elem = list[groupId]?.find((el) => el?.name === name)
    if (elem) {
      findElem = elem
    }
  })

  return findElem
}

export const searchByName = (inputValueTrimmed, list) => {
  return list?.filter((el) => el?.label?.toLowerCase().includes(inputValueTrimmed))
}
