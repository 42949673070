// Constants
import { Currency } from 'constants/currency'

export const defaultProps = {
  symbol: Currency.BTCUSD,
  interval: '1D',
  datafeedUrl: 'http://127.0.0.1:3000/api/polygon',
  // datafeedUrl: 'https://demo_feed.tradingview.com',
  libraryPath: '/charting_library/',
  chartsStorageUrl: 'https://saveload.tradingview.com',
  chartsStorageApiVersion: '1.1',
  clientId: 'tradingview.com',
  userId: 'public_user_id',
  fullscreen: false,
  autosize: true,
  studiesOverrides: {},
  overrides: {
    'mainSeriesProperties.candleStyle.upColor': '#0bab64',
    'mainSeriesProperties.candleStyle.downColor': '#BF320D',
  },
  disabledFeatures: [
    'use_localstorage_for_settings',
    'currency_options',
    'symbol_search_hot_key',
    'header_symbol_search',
    'compare_symbol',
    'header_compare',
    'symbol_search_control',
    'header_saveload',
    'header_indicators',
    'auto_scale',
    'control_bar',
    'trading_notifications',
    'charting_library_debug_mode',
    'symbol_info',
    'context_menus',
    'scales_context_menu',
    'show_object_tree',
    // 'timezone_menu',
    // 'timeframes_toolbar', // chart footer
  ],
}
