// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.TVChartContainer {*/
/*	height: calc(82vh - 72px);*/
/*}*/

.TVChartContainer {
	position: relative;
	overflow: hidden;
	padding-top: 31.25%; /* 16:9 aspect ratio (9 / 16 * 100%) */
	height: 100%
}

.TVChartContainer iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/TVChartContainer/index.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB,8BAA8B;AAC9B,IAAI;;AAEJ;CACC,kBAAkB;CAClB,gBAAgB;CAChB,mBAAmB,EAAE,sCAAsC;CAC3D;AACD;;AAEA;CACC,kBAAkB;CAClB,MAAM;CACN,OAAO;CACP,WAAW;CACX,YAAY;AACb","sourcesContent":["/*.TVChartContainer {*/\n/*\theight: calc(82vh - 72px);*/\n/*}*/\n\n.TVChartContainer {\n\tposition: relative;\n\toverflow: hidden;\n\tpadding-top: 31.25%; /* 16:9 aspect ratio (9 / 16 * 100%) */\n\theight: 100%\n}\n\n.TVChartContainer iframe {\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
