// Action Types
import {
  CREATE_TRANSACTION,
  CREATE_TRANSACTION_LOADING,
  CREATE_TRANSACTION_RESET_FORM,
  GET_ASSERT_LIST,
  SET_ASSERT_LIST,
  SET_ASSERT_LIST_LOADING,
  SET_SHOW_MOBILE_CREATE_TRANSACTION_MODAL,
} from 'store/actions/TransactionCreation/types'

export const getAssertList = () => ({
  type: GET_ASSERT_LIST,
})

export const setAssertListLoading = (payload) => ({
  type: SET_ASSERT_LIST_LOADING,
  payload: payload,
})

export const setAssertList = (payload) => ({
  type: SET_ASSERT_LIST,
  payload: payload,
})

export const createTransaction = (payload) => ({
  type: CREATE_TRANSACTION,
  payload: payload,
})

export const createTransactionLoading = (payload) => ({
  type: CREATE_TRANSACTION_LOADING,
  payload: payload,
})

export const createTransactionResetForm = (payload) => ({
  type: CREATE_TRANSACTION_RESET_FORM,
  payload: payload,
})

export const setShowMobileCreateTransactionModal = (payload) => ({
  type: SET_SHOW_MOBILE_CREATE_TRANSACTION_MODAL,
  payload: payload,
})
