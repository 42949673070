// Modules
import { memo } from 'react'

// Components
import { ErrorMessage } from 'components'

const ErrorForm = ({ error }) => {
  return error ? <ErrorMessage error={error} fontSize="xs" /> : <span />
}

export default memo(ErrorForm)
