// Modules
import { memo } from 'react'
import { useSelector } from 'react-redux'

// Components
import MenuTradeModalBodyMobileUI from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal/MenuTradeModalBodyMobileUI'
import MenuTradeModalBodyUI from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal/MenuTradeModalBodyUI'

// Store
import { isMobileSelector } from 'store/actions/User/selectors'

export const favouritesKey = 'favourites'

const MenuTradeModalBody = () => {
  const isMobile = useSelector(isMobileSelector)

  return isMobile ? <MenuTradeModalBodyMobileUI /> : <MenuTradeModalBodyUI />
}

export default memo(MenuTradeModalBody)
