// Modules
import { memo, useContext } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_TRANSACTION_CALC_CONTRACT_SIZE,
  LN_TRANSACTION_CALC_LEVERAGE,
  LN_TRANSACTION_CALC_MODAL_REQUIRED_MARGIN,
  LN_TRANSACTION_CALC_SPREAD,
  LN_TRANSACTION_CALC_SWAP,
  LN_TRANSACTION_CALC_SYMBOL_POINT,
} from 'locales/constants'

// Components
import { TextXs } from 'components'
import { TabBase } from 'pages/User/components/Content/Deposit'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { formatDigitsWithSpaces, roundTwoDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// Context
import { MarketCalcContext } from 'context/MarketCalcContext'

// UI
import { Flex } from '@chakra-ui/react'

const MarketInfo = () => {
  const { t } = useTranslation()

  const {
    currentCurrencySwapValue,
    currentCurrencyLeverageValue,
    currentCurrencyContractSizeValue,
    currentCurrencySymbolPointValue,
    currentCurrencySpreadValue,
    requiredMargin,
  } = useContext(MarketCalcContext)

  return (
    <>
      <InfoText
        title={t(LN_TRANSACTION_CALC_CONTRACT_SIZE)}
        value={currentCurrencyContractSizeValue}
      />
      <InfoText
        title={t(LN_TRANSACTION_CALC_MODAL_REQUIRED_MARGIN)}
        value={formatDigitsWithSpaces(roundTwoDigitsAfterDots(requiredMargin))}
      />
      <InfoText
        title={t(LN_TRANSACTION_CALC_LEVERAGE)}
        value={currentCurrencyLeverageValue?.name}
      />
      <InfoText title={t(LN_TRANSACTION_CALC_SPREAD)} value={currentCurrencySpreadValue} />
      <InfoText title={t(LN_TRANSACTION_CALC_SWAP)} value={currentCurrencySwapValue} />
      <InfoText
        title={t(LN_TRANSACTION_CALC_SYMBOL_POINT)}
        value={currentCurrencySymbolPointValue}
      />
    </>
  )
}

export default memo(MarketInfo)

const InfoText = memo((props) => {
  const { title, value, ...args } = props
  const { mainTextColor } = useCurrentColorTheme()

  return (
    <Flex justifyContent="space-between">
      <TextXs color={mainTextColor} fontSize="11px" fontWeight={500}>
        {title}:
      </TextXs>
      <TextXs color="green.200" fontSize="11px" fontWeight={500} {...args}>
        {value}
      </TextXs>
    </Flex>
  )
})

InfoText.displayName = 'InfoText'
