// Modules
import { memo, useCallback } from 'react'
import { GrTransaction } from 'react-icons/gr'
import { IoIosMenu } from 'react-icons/io'
import { useDispatch } from 'react-redux'

// Components
import { UserLogoBtn } from 'components'
import Balance from 'pages/Dashboard/components/Navbar/Balance'
import SliderNavbar from 'pages/Dashboard/components/Navbar/NavbarMobile/SliderNavbar'
import { zIndex } from 'theme/globalStyles'

// Constants
import { NavBarMobileHeight } from 'constants/index'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Store
import { setShowMobileCreateTransactionModal } from 'store/actions/TransactionCreation/actions'

// UI
import { Box, Flex, IconButton } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'

const NavbarMobile = ({ onUserBtn }) => {
  const dispatch = useDispatch()
  const { mainBgColor, mainBorderColor } = useCurrentColorTheme()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenCreationMobilModal = useCallback(() => {
    dispatch(setShowMobileCreateTransactionModal(true))
  }, [dispatch])

  return (
    <>
      <Box
        bg={mainBgColor}
        position="fixed"
        w="100%"
        zIndex={zIndex}
        // zIndex="1000"
        top={0}
      >
        <Flex
          h={NavBarMobileHeight + 'px'}
          alignItems="center"
          justifyContent="space-between"
          p={{ base: '11px 9px', sm: '15px' }}
          borderBottom="2px solid"
          borderColor={mainBorderColor}
        >
          <Flex w="100%" justifyContent="space-between">
            <Box minW="85px" mr={7} alignItems="center" justifyContent="center" display="flex">
              Logo
            </Box>
            <Flex
              w="100%"
              gap={{ base: 3, '2xs': 1, sm: 3 }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Box display={{ base: 'none', '2xs': 'inline-block' }}>
                <Balance isMobileView />
              </Box>

              <Box>
                <IconButton
                  aria-label="Create Transaction"
                  icon={<GrTransaction />}
                  onClick={onOpenCreationMobilModal}
                  colorScheme="gray"
                  size="sm"
                  isRound
                />
              </Box>
              <Box>
                <UserLogoBtn onUserBtn={onUserBtn} size="sm" />
              </Box>

              <IconButton
                aria-label="Open sidebar"
                icon={<IoIosMenu />}
                onClick={onOpen}
                colorScheme="blue"
                size="sm"
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>
      <SliderNavbar onClose={onClose} isOpen={isOpen} />
    </>
  )
}

export default memo(NavbarMobile)
