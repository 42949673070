// Modules
import { useRef } from 'react'

// UI
import { Button, CloseButton, FormControl, Text } from '@chakra-ui/react'

const Statuses = {
  NEW: 1,
  IN_REVIEW: 2,
  FAILED: 3,
  SUCCESSFUL: 4,
  NOT_UPLOADED: 5,
}

const StatuseLabels = {
  New: 1,
  'In rewiew': 2,
  Failed: 3,
  Successful: 4,
  'Not uploaded': 5,
}

const KYCField = ({
  label,
  filename,
  changeHandler,
  url,
  status,
  sendImageHandler,
  deleteFieldHandler,
  file,
}) => {
  const inputRef = useRef([])
  const getKeyByValue = (object, value) => Object.keys(object).find((key) => object[key] === value)

  const statusName = status.name
  statusName.replace('_', ' ')
  return (
    <FormControl style={{ marginBottom: 45 }}>
      <Button style={{ position: 'relative' }}>
        {label}
        <input
          disabled={status.id === Statuses.SUCCESSFUL}
          name={filename}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            opacity: 0,
          }}
          ref={inputRef}
          onChange={() => changeHandler(inputRef.current)}
          type="file"
        />
        {url && (
          <img
            style={{
              position: 'absolute',
              left: 215,
              bottom: -60,
              width: 100,
              height: 100,
              borderRadius: 8,
            }}
            src={url}
            alt="asd"
          />
        )}
      </Button>
      <Text fontSize="sm">Status: {getKeyByValue(StatuseLabels, status.id)}</Text>
      {url && file && (
        <Button
          colorScheme="teal"
          disabled={status.id === Statuses.SUCCESSFUL}
          onClick={() => sendImageHandler(filename)}
        >
          Upload
        </Button>
      )}
      {(url || (status.id !== Statuses.NOT_UPLOADED && status.id !== Statuses.SUCCESSFUL)) && (
        <CloseButton
          color={'gray'}
          style={{
            position: 'absolute',
            top: 0,
            left: 285,
            zIndex: 2,
          }}
          onClick={() => deleteFieldHandler(filename)}
        />
      )}
    </FormControl>
  )
}

export default KYCField
