// Modules
import { AnimatePresence, motion } from 'framer-motion'
import { memo } from 'react'

// Components
import MarketWatch from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch'
import MarketSideBar from 'pages/Dashboard/components/TransactionCreationSidebar'
import { mainBgColors } from 'theme/globalStyles'

// Constants
import {
  BottomBarHeight,
  LeftTabsWidth,
  RightMarketSidebar,
  ScreenWidthWithoutNavbar,
} from 'constants/index'

import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box } from '@chakra-ui/react'

export const sideVariants = {
  closed: {
    opacity: 0,
    transition: {
      staggerDirection: -1,
      duration: 0.1,
    },
  },
  open: {
    opacity: 1,
    transition: {
      staggerDirection: 1,
      duration: 0.3,
    },
  },
}

const WrapperForDrawerMobileTemperaryView = (props) => {
  const {
    children,
    openLeftNav,
    onLeftNavClose,
    openRightBlock,
    onRightBlockClose,
    onBottomBlockOpen,
    onBottomBlockClose,
    openBottomNav,
  } = props

  const { mainBgColor, isLightTheme } = useCurrentColorTheme()

  const asideLeftContent = () => {
    return <MarketWatch onLeftNavClose={onLeftNavClose} />
  }

  return (
    <Box
      display="flex"
      flex="1"
      w="100%"
      justifyContent="space-between"
      height={
        openBottomNav
          ? `calc(${ScreenWidthWithoutNavbar} - ${BottomBarHeight})`
          : ScreenWidthWithoutNavbar
      }
    >
      <AnimatePresence>
        {openLeftNav && (
          <motion.aside
            // initial={{ width: 0 }}
            animate={{
              width: 340,
              // minWidth: 340,
            }}
            exit={{
              width: 0,
              transition: { delay: 0.2, duration: 0.1 },
            }}
            style={{
              borderRight: '1px solid',
              borderRightColor: isLightTheme
                ? mainBgColors.borderColorLight
                : 'var(--chakra-colors-gray-600)',
              display: 'flex',
            }}
          >
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={sideVariants}
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              <Box minW={LeftTabsWidth + 'px'} position="relative" bg={mainBgColor}>
                {/*<Flex justifyContent="space-between" alignItems="center" p={3}>*/}
                {/*  <TextMd color="white" fontWeight="500">*/}
                {/*    {leftNavTitle}*/}
                {/*  </TextMd>*/}
                {/*  <CloseButton*/}
                {/*    onClick={onLeftNavClose}*/}
                {/*    _hover={{*/}
                {/*      backgroundColor: 'transparent',*/}
                {/*      color: 'gray.400',*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</Flex>*/}
                {/*<Box p={3} pt={0}>*/}
                {asideLeftContent()}
                {/*</Box>*/}
              </Box>
            </motion.div>
          </motion.aside>
        )}
      </AnimatePresence>
      {children}
    </Box>
  )
}

export default memo(WrapperForDrawerMobileTemperaryView)
