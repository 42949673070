// Constants
import { localStorageFavouritesKey } from 'constants/index'

// Types
import {
  ADD_FAVOURITE_CURRENCY,
  SET_FAVOURITE_CURRENCY_LIST,
} from 'store/actions/FavouritesCurrency/types'

const initialState = {
  favouritesCurrencyList: JSON.parse(localStorage.getItem(localStorageFavouritesKey)) || [],
}

const favouritesCurrencyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FAVOURITE_CURRENCY_LIST:
      return {
        ...state,
        favouritesCurrency: action.payload,
      }
    case ADD_FAVOURITE_CURRENCY:
      const itemIdToAdd = action.payload
      const itemIndexToAdd = state.favouritesCurrencyList.findIndex(
        (item) => item?.id === itemIdToAdd?.id && item?.name === itemIdToAdd?.name,
      )

      let newFavoritesAdd = [...state.favouritesCurrencyList, action.payload]
      if (itemIndexToAdd !== -1) {
        newFavoritesAdd = [
          ...state.favouritesCurrencyList.slice(0, itemIndexToAdd),
          ...state.favouritesCurrencyList.slice(itemIndexToAdd + 1),
        ]
      }
      localStorage.setItem(localStorageFavouritesKey, JSON.stringify(newFavoritesAdd))
      return {
        ...state,
        favouritesCurrencyList: newFavoritesAdd,
      }

    // case DELETE_FAVOURITE_CURRENCY:
    //   const newFavoritesRemove = state?.favouritesCurrencyList.filter(
    //     (item) => item.id !== action.payload?.id,
    //   )
    //   localStorage.setItem(localStorageFavouritesKey, JSON.stringify(newFavoritesRemove))
    //   return {
    //     ...state,
    //     favouritesCurrencyList: newFavoritesRemove,
    //   }
    default:
      return state
  }
}
export default favouritesCurrencyListReducer
