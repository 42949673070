// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const currencyListSelector = createSelector(selfSelector, (self) => self.currencyList)

export const currencyListLoadingSelector = createSelector(
  selfSelector,
  (self) => self.currencyListLoading,
)

export const intervalGraphValueSelector = createSelector(
  selfSelector,
  (self) => self.intervalGraphValue,
)
