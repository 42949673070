// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowDown } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_HISTORY_SELECT_TIME_TITLE } from 'locales/constants'

// Constants
import { selectTimeList } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'

import useOrderBottomInfo from 'hooks/useOrderBottomInfo'

// Store
import { setSelectTimeValue } from 'store/actions/OrderBar/actions'
import { selectTimeValueSelector } from 'store/actions/OrderBar/selectors'

// UI
import { Icon, Select } from '@chakra-ui/react'

const SelectFilterTime = ({ ...args }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const selectTimeValue = useSelector(selectTimeValueSelector)

  const { sentRequest } = useOrderBottomInfo()

  const onSelect = useCallback(
    (val) => {
      sentRequest({ period: val?.target?.value })
      dispatch(setSelectTimeValue(val?.target?.value))
    },
    [dispatch, sentRequest],
  )

  return (
    <Select
      placeholder={t(LN_HISTORY_SELECT_TIME_TITLE)}
      size="xs"
      fontSize="12px"
      h="21px"
      outlineOffset="1px"
      outline="1px solid transparent"
      boxShadow="none"
      borderRadius="md"
      variant="filled"
      iconSize="13px"
      icon={<Icon as={IoIosArrowDown} />}
      onChange={onSelect}
      value={selectTimeValue}
      {...args}
    >
      {selectTimeList?.map((el) => (
        <option key={el?.value} value={el?.value}>
          {t(el?.title)}
        </option>
      ))}
    </Select>
  )
}

export default memo(SelectFilterTime)
