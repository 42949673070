// Modules
import { call, put, select, takeEvery } from 'redux-saga/effects'

// Languages
import {
  LN_NOTIFICATIONS_GET_ASSERT_LIST,
  LN_TRANSACTION_CREATE_NOTIFICATION_ERROR_MESSAGE,
  LN_TRANSACTION_CREATE_NOTIFICATION_SUCCESS_MESSAGE,
  LN_TRANSACTION_CREATE_NOTIFICATION_TITLE,
} from 'locales/constants'
import i18n from 'locales/i18n'

// Constants
import { toastConstructor, toastType } from 'components/ToastApp/constants'

import {
  getClosedTransactionsList,
  getOpenTransactionsList,
  getPendingTransactionsList,
  getUserTransactionsList,
} from 'store/actions/OrderBar/actions'
// Store
import { showToast } from 'store/actions/ToastsApp/actions'
import {
  createTransactionLoading,
  createTransactionResetForm,
  setAssertList,
  setAssertListLoading,
} from 'store/actions/TransactionCreation/actions'
import { CREATE_TRANSACTION, GET_ASSERT_LIST } from 'store/actions/TransactionCreation/types'
import { createTransaction, getAssertListAPI } from 'store/api/market'
import { onUserNotAuth } from 'store/sagas/utils'

function* getAssertList() {
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)

    if (token) {
      const listResponse = yield call(getAssertListAPI)

      yield put(setAssertList(listResponse || []))
      yield put(setAssertListLoading(false))
    }
  } catch (error) {
    yield put(setAssertListLoading(false))
    yield put(
      showToast(
        toastConstructor(
          toastType.error,
          error?.response?.data?.error,
          t(LN_NOTIFICATIONS_GET_ASSERT_LIST),
        ),
      ),
    )
    yield call(onUserNotAuth, error)
  }
}

function* userCreateTransaction(action) {
  const t = i18n.t.bind(i18n)
  try {
    const token = yield select((state) => state.auth.token)

    if (token) {
      const currentTradingAccount = yield select((state) => state.user.currentTradingAccount)
      const userTradingAccountId = currentTradingAccount?.trading_account_id

      const response = yield call(createTransaction, { ...action.payload })

      yield put(getOpenTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getPendingTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getClosedTransactionsList({ tradingAccountId: userTradingAccountId }))
      yield put(getUserTransactionsList({ tradingAccountId: userTradingAccountId }))

      yield put(createTransactionLoading(false))
      yield put(createTransactionResetForm(Math.random()))
      yield put(
        showToast(
          toastConstructor(
            toastType.success,
            t(LN_TRANSACTION_CREATE_NOTIFICATION_SUCCESS_MESSAGE),
            response.message || t(LN_TRANSACTION_CREATE_NOTIFICATION_TITLE),
          ),
        ),
      )
    }
  } catch (error) {
    yield put(createTransactionLoading(false))
    yield put(
      showToast(
        toastConstructor(
          toastType.error,
          error?.response?.data?.message,
          t(LN_TRANSACTION_CREATE_NOTIFICATION_TITLE),
        ),
      ),
    )
    yield call(onUserNotAuth, error)
  }
}

export function* watchTransactionCreation() {
  yield takeEvery(GET_ASSERT_LIST, getAssertList)
  yield takeEvery(CREATE_TRANSACTION, userCreateTransaction)
}
