// export const SET_POSITIONS_LIST = 'SET_POSITIONS_LIST'
export const SET_PENDING_ORDERS_LIST = 'SET_PENDING_ORDERS_LIST'
export const SET_PENDING_ORDERS_LIST_PAGINATION = 'SET_PENDING_ORDERS_LIST_PAGINATION'
// export const SET_CLOSED_POSITIONS_LIST = 'SET_CLOSED_POSITIONS_LIST'
export const SET_MAIN_TABS_VALUE = 'SET_MAIN_TABS_VALUE'
export const SET_SECONDARY_TABS_VALUE = 'SET_SECONDARY_TABS_VALUE'
export const SET_SEARCH_VALUE = 'SET_SEARCH_VALUE'
export const SET_SELECT_TIME_VALUE = 'SET_SELECT_TIME_VALUE'
export const SET_IS_FULL_SIZE_MODAL = 'SET_IS_FULL_SIZE_MODAL'

export const SET_USER_TRANSACTIONS_LIST = 'SET_USER_TRANSACTIONS_LIST'
export const GET_USER_TRANSACTIONS_LIST = 'GET_USER_TRANSACTIONS_LIST'
export const SET_USER_TRANSACTIONS_LIST_LOADING = 'SET_USER_TRANSACTIONS_LIST_LOADING'
export const SET_POSITIONS_LENGTH = 'SET_POSITIONS_LENGTH'

// open
export const SET_OPEN_TRANSACTIONS_LIST = 'SET_OPEN_TRANSACTIONS_LIST'
export const SET_OPEN_TRANSACTIONS_LIST_PAGINATION = 'SET_OPEN_TRANSACTIONS_LIST_PAGINATION'
export const GET_OPEN_TRANSACTIONS_LIST = 'GET_OPEN_TRANSACTIONS_LIST'
export const SET_OPEN_TRANSACTIONS_LIST_LOADING = 'SET_OPEN_TRANSACTIONS_LIST_LOADING'

// pending
// export const SET_PENDING_TRANSACTIONS_LIST = 'SET_PENDING_TRANSACTIONS_LIST'
export const GET_PENDING_TRANSACTIONS_LIST = 'GET_PENDING_TRANSACTIONS_LIST'
export const SET_PENDING_TRANSACTIONS_LIST_LOADING = 'SET_PENDING_TRANSACTIONS_LIST_LOADING'

// closed
export const SET_CLOSED_TRANSACTIONS_LIST = 'SET_CLOSED_TRANSACTIONS_LIST'
export const SET_CLOSED_TRANSACTIONS_LIST_PAGINATION = 'SET_CLOSED_TRANSACTIONS_LIST_PAGINATION'
export const GET_CLOSED_TRANSACTIONS_LIST = 'GET_CLOSED_TRANSACTIONS_LIST'
export const SET_CLOSED_TRANSACTIONS_LIST_LOADING = 'SET_CLOSED_TRANSACTIONS_LIST_LOADING'

export const ON_CLOSE_TRANSACTION = 'ON_CLOSE_TRANSACTION'
export const ON_CLOSE_ALL_TRANSACTIONS = 'ON_CLOSE_ALL_TRANSACTIONS'

export const TRANSACTION_CONTENT_BAR_LOADING = 'TRANSACTION_CONTENT_BAR_LOADING'

// WEB SOCKET
// TRANSACTIONS
export const WS_CONNECT_FOLLOW_TRANSACTIONS = 'WS_CONNECT_FOLLOW_TRANSACTIONS'
export const WS_DISCONNECT_FOLLOW_TRANSACTIONS = 'WS_DISCONNECT_FOLLOW_TRANSACTIONS'
export const WS_FOLLOW_TRANSACTIONS_MESSAGE_RECEIVED = 'WS_FOLLOW_TRANSACTIONS_MESSAGE_RECEIVED'
export const WS_ERROR_FOLLOW_TRANSACTIONS = 'WS_ERROR_FOLLOW_TRANSACTIONS'
export const WS_FOLLOW_TRANSACTIONS_CHANGE_VALUE = 'WS_FOLLOW_TRANSACTIONS_CHANGE_VALUE'

// PROFIT
export const WS_CONNECT_FOLLOW_PROFITS = 'WS_CONNECT_FOLLOW_PROFITS'
export const WS_DISCONNECT_FOLLOW_PROFITS = 'WS_DISCONNECT_FOLLOW_PROFITS'
export const WS_FOLLOW_PROFITS_MESSAGE_RECEIVED = 'WS_FOLLOW_PROFITS_MESSAGE_RECEIVED'
export const WS_ERROR_FOLLOW_PROFITS = 'WS_ERROR_FOLLOW_PROFITS'
export const WS_FOLLOW_PROFITS_CHANGE_VALUE = 'WS_FOLLOW_PROFITS_CHANGE_VALUE'
