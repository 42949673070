// Modules
import _ from 'lodash'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Constants
import { RADIO_BY_RATE } from 'pages/Dashboard/components/TransactionCreationSidebar/constants'

// Hooks
import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'

// Utils
import { getCurrentCurrencyAssertValue, getRequiredMargin } from 'utils/transactionFormulas'

// Store
import { chosenActiveTradeItemSelector } from 'store/actions/ActiveTradeItems/selectors'
import { tokenSelector } from 'store/actions/Auth/selectors'
import { currencyListSelector } from 'store/actions/CurrencySocket/selectors'
import {
  assertListSelector,
  transactionCreationResetFormActionSelector,
} from 'store/actions/TransactionCreation/selectors'
import { ratesSelector } from 'store/actions/User/selectors'
import { getCurrencyListAPI } from 'store/api/market'

export const MarketCalcContext = createContext({})

const MarketCalcProvider = ({ children }) => {
  const chosenActiveTradeItem = useSelector(chosenActiveTradeItemSelector)
  const currencyList = useSelector(currencyListSelector)
  const assertList = useSelector(assertListSelector)
  const token = useSelector(tokenSelector)
  const transactionCreationResetFormAction = useSelector(transactionCreationResetFormActionSelector)
  const ratesList = useSelector(ratesSelector)
  const { defaultVolumeOnPlatform } = useCheckUserRestrictions()

  const [unchangeableCurrencyList, setUnchangeableCurrencyList] = useState({})
  const [volumeValue, setVolumeValue] = useState(0)

  useEffect(() => {
    if (defaultVolumeOnPlatform) {
      setVolumeValue(defaultVolumeOnPlatform)
    }
  }, [defaultVolumeOnPlatform])

  // take profit & stop loss popover
  const [takeProfitRadioValue, setTakeProfitRadioValue] = useState(RADIO_BY_RATE)
  const [takeProfitValue, setTakeProfitValue] = useState(0)
  const [stopLossRadioValue, setStopLossRadioValue] = useState(RADIO_BY_RATE)
  const [stopLossValue, setStopLossValue] = useState(0)
  const [stopLossTouched, setStopLossTouched] = useState(false)
  const [takeProfitTouched, setTakeProfitTouched] = useState(false)

  // pending popover
  const [assetPriceValue, setAssetPriceValue] = useState(0)
  const [assetPriceTouched, setAssetPriceTouched] = useState(false)

  // save socket price for calc modal
  const [unchangeableSocketPrice, setUnchangeableSocketPrice] = useState(0)

  useEffect(() => {
    setStopLossTouched(false)
    setTakeProfitTouched(false)
    setAssetPriceTouched(false)
  }, [chosenActiveTradeItem])

  const getUnchangeableList = useCallback(async () => {
    const result = await getCurrencyListAPI()
    setUnchangeableCurrencyList(result?.currencies)
  }, [])

  useEffect(() => {
    getUnchangeableList()?.then()
  }, [token, getUnchangeableList])

  const unchangeableCurrencyPriceValue = useMemo(() => {
    if (unchangeableCurrencyList && chosenActiveTradeItem) {
      const allPairs = Object.values(unchangeableCurrencyList).flatMap((pairArr) => pairArr)
      const pair = _.find(allPairs, { name: chosenActiveTradeItem?.name })
      return pair?.price ?? 0
    } else {
      return 0
    }
  }, [chosenActiveTradeItem, unchangeableCurrencyList])

  useEffect(() => {
    setStopLossValue(unchangeableCurrencyPriceValue)
    setTakeProfitValue(unchangeableCurrencyPriceValue)
    setAssetPriceValue(unchangeableCurrencyPriceValue)
  }, [unchangeableCurrencyPriceValue])

  const currentCurrencyPriceValue = useMemo(() => {
    if (currencyList && chosenActiveTradeItem) {
      const allPairs = Object.values(currencyList).flatMap((pairArr) => pairArr)
      const pair = _.find(allPairs, { name: chosenActiveTradeItem?.name })
      return pair?.price ?? 0
    } else {
      return 0
    }
  }, [chosenActiveTradeItem, currencyList])

  const onSetUnchangeableSocketPriceModal = useCallback(async () => {
    setUnchangeableSocketPrice(currentCurrencyPriceValue)
  }, [setUnchangeableSocketPrice, currentCurrencyPriceValue])

  const currentCurrencyAssertValue = useMemo(() => {
    return getCurrentCurrencyAssertValue(assertList, chosenActiveTradeItem)
  }, [chosenActiveTradeItem, assertList])

  // console.log('currentCurrencyAssertValue', currentCurrencyAssertValue)
  // console.log('currencyList', currencyList)

  const currencyRate = useMemo(() => {
    // can be usd, eur ...
    const currencyValue = currentCurrencyAssertValue?.currency
    const rateValue = currencyValue ? ratesList?.[currencyValue] : 1
    return rateValue
  }, [currentCurrencyAssertValue, ratesList])

  const currentCurrencySwapValue = useMemo(
    () => currentCurrencyAssertValue?.swap ?? 0,
    [currentCurrencyAssertValue?.swap],
  )

  const currentCurrencyLeverageValue = useMemo(
    () => currentCurrencyAssertValue?.leverage ?? 0,
    [currentCurrencyAssertValue?.leverage],
  )

  const currentCurrencySymbolPointValue = useMemo(
    () => Number(currentCurrencyAssertValue?.symbol_point ?? 0) ?? 0,
    [currentCurrencyAssertValue?.symbol_point],
  )

  const currentCurrencySpreadValue = useMemo(
    () => currentCurrencyAssertValue?.spread ?? 0,
    [currentCurrencyAssertValue?.spread],
  )

  const currentCurrencyContractSizeValue = useMemo(
    () => currentCurrencyAssertValue?.symbol_contract_size ?? 0,
    [currentCurrencyAssertValue?.symbol_contract_size],
  )

  const resetToDefaultValues = useCallback(() => {
    setVolumeValue(defaultVolumeOnPlatform)

    setTakeProfitRadioValue(RADIO_BY_RATE)
    setTakeProfitValue(unchangeableCurrencyPriceValue)
    setStopLossRadioValue(RADIO_BY_RATE)
    setStopLossValue(unchangeableCurrencyPriceValue)

    setStopLossTouched(false)
    setTakeProfitTouched(false)
    setAssetPriceTouched(false)

    setAssetPriceValue(unchangeableCurrencyPriceValue)
  }, [
    setStopLossTouched,
    setTakeProfitTouched,
    setAssetPriceTouched,
    setVolumeValue,
    setTakeProfitRadioValue,
    setTakeProfitValue,
    setStopLossRadioValue,
    setStopLossValue,
    unchangeableCurrencyPriceValue,
    setAssetPriceValue,
    defaultVolumeOnPlatform,
  ])

  const requiredMargin = useMemo(
    () =>
      getRequiredMargin(
        volumeValue,
        currentCurrencyPriceValue,
        currentCurrencyContractSizeValue,
        currentCurrencyLeverageValue?.id,
        currencyRate,
      ),
    [
      currentCurrencyPriceValue,
      currentCurrencyLeverageValue?.id,
      currentCurrencyContractSizeValue,
      currencyRate,
      volumeValue,
    ],
  )

  useEffect(() => {
    resetToDefaultValues()
    // Important: not delete this argument dependencies
  }, [transactionCreationResetFormAction])

  const contextValue = {
    currentCurrencySwapValue,
    currentCurrencyLeverageValue,
    unchangeableCurrencyPriceValue,
    currentCurrencyPriceValue,
    currentCurrencyContractSizeValue,
    currentCurrencySymbolPointValue,
    currentCurrencySpreadValue,

    takeProfitRadioValue,
    setTakeProfitRadioValue,
    takeProfitValue,
    setTakeProfitValue,
    stopLossRadioValue,
    setStopLossRadioValue,
    stopLossValue,
    setStopLossValue,
    assetPriceValue,
    setAssetPriceValue,
    assetPriceTouched,
    setAssetPriceTouched,
    stopLossTouched,
    setStopLossTouched,
    takeProfitTouched,
    setTakeProfitTouched,
    requiredMargin,

    unchangeableSocketPrice,
    onSetUnchangeableSocketPriceModal,

    volumeValue,
    setVolumeValue,

    currencyRate,

    resetToDefaultValues,
    currentCurrencyAssertValue,
  }

  return <MarketCalcContext.Provider value={contextValue}>{children}</MarketCalcContext.Provider>
}

export default MarketCalcProvider
