// Modules
import { memo } from 'react'

// Components
import FormMarket from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket'

// Context
import MarketCalcProvider from 'context/MarketCalcContext'

// UI
import { Box, CloseButton, Flex } from '@chakra-ui/react'

const TransactionCreationSidebar = (props) => {
  const { onSidebarClose } = props

  // const onChooseItem = useCallback((title: string) => {
  //   setIsActiveNav(title)
  //   onSidebarClose()
  // }, [])

  return (
    <MarketCalcProvider>
      <Flex justifyContent="space-between" alignItems="center" p={3} pt={4} pb={4}>
        <Box position="absolute" top="-2px" right="0px">
          <CloseButton
            onClick={onSidebarClose}
            _hover={{
              backgroundColor: 'transparent',
              color: 'gray.400',
            }}
          />
        </Box>
        <FormMarket />
      </Flex>
    </MarketCalcProvider>
  )
}

export default memo(TransactionCreationSidebar)
