// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { GrLanguage } from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux'

// Constants
import { appLanguagesList, appLanguagesLocalStoreKey } from 'components/LanguageDropdown/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Store
import { setSelectedAppLanguage } from 'store/actions/User/actions'
import { selectedLanguageSelector } from 'store/actions/User/selectors'

// UI
import { Select } from '@chakra-ui/react'

const LanguageDropdown = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const { mainBorderColor } = useCurrentColorTheme()

  const selectedLanguage = useSelector(selectedLanguageSelector)

  const handleLanguageChange = useCallback(
    (e) => {
      const language = e?.target?.value
      dispatch(setSelectedAppLanguage(language))
      localStorage.setItem(appLanguagesLocalStoreKey, language)
      i18n.changeLanguage(language)
    },
    [dispatch, i18n],
  )

  return (
    <>
      {/*<Menu>*/}
      {/*  <MenuButton as={Button} variant="outline" size="sm">*/}
      {/*    <Text display="flex" alignItems="center" gap={1} justifyContent="center">*/}
      {/*      <Icon as={GrLanguage} />*/}
      {/*      <Text textTransform="uppercase">{selectedLanguage}</Text>*/}
      {/*    </Text>*/}
      {/*  </MenuButton>*/}
      {/*  <MenuList>*/}
      {/*    {appLanguagesList?.map((el) => (*/}
      {/*      <MenuItem key={el?.value} onClick={() => handleLanguageChange(el?.value)}>*/}
      {/*        {el?.title}*/}
      {/*      </MenuItem>*/}
      {/*    ))}*/}
      {/*  </MenuList>*/}
      {/*</Menu>*/}

      {/*<Button variant="ghost" colorScheme="teal" size="sm" leftIcon={<Icon as={GrLanguage} />} />*/}
      <Select
        size="sm"
        onChange={handleLanguageChange}
        value={selectedLanguage}
        border={0}
        borderBottom="1px solid"
        borderColor={mainBorderColor}
        outline={0}
      >
        {appLanguagesList?.map((el) => (
          <option key={el?.value} value={el?.value}>
            {el?.title}
          </option>
        ))}
      </Select>
    </>
  )
}

export default memo(LanguageDropdown)
