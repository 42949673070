// Modules
import { memo, useCallback, useState } from 'react'

// Components
import { SearchInput } from 'components'

const ControlledSearchInput = (props) => {
  const { onSearch, ...args } = props

  const [searchedValue, setSearchedValue] = useState('')

  const onSearchValue = useCallback(
    (inputValue) => {
      setSearchedValue(inputValue)
      onSearch(inputValue)
    },
    [onSearch],
  )

  return <SearchInput searchedValue={searchedValue} setSearchedValue={onSearchValue} {...args} />
}

export default memo(ControlledSearchInput)
