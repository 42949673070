// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import { TextLg } from 'components'
import WithdrawalForm from 'pages/User/components/Content/Withdrawal/WithdrawalForm'
import WithdrawalHistory from 'pages/User/components/Content/Withdrawal/WithdrawalHistory'
import { sidebarList } from 'pages/User/components/Sidebar/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box } from '@chakra-ui/react'

const Withdrawal = () => {
  const { t } = useTranslation()

  return (
    <Box w="100%" p={3}>
      <MainTitle title={t(sidebarList[2]?.title)} />
      <WithdrawalForm />
      <WithdrawalHistory />
    </Box>
  )
}

export default memo(Withdrawal)

export const MainTitle = memo(({ title, pt = 0, pb = 5 }) => {
  const { mainTextColor } = useCurrentColorTheme()

  return (
    <Box pt={pt} pb={pb}>
      <TextLg fontWeight={500} color={mainTextColor}>
        {title}
      </TextLg>
    </Box>
  )
})

MainTitle.displayName = 'MainTitle'
