export const mainBgColors = {
  dark: '#1A202C',
  light: '#f6f7f9',
  borderColorLight: '#7290eb40',
  // borderColorLight: '#edeef0',
}
// #99b5ed
// #657dd5 - hover

export const blueColors = {
  light: {
    50: '#b7c5fd',
    100: '#5b78f0',
    200: '#4D6CEF',
    300: '#2e51eb',
    400: '#1638d2',
    500: '#99b5ed',
    // 500: '#195fdd',
    600: '#657dd5',
    // 600: '#3652ba',
    700: '#314bac',
    800: '#00061d',
  },
  dark: {
    50: '#b7c5fd',
    100: '#5b78f0',
    200: '#4D6CEF',
    300: '#2e51eb',
    400: '#1638d2',
    500: '#0f2ba4',
    600: '#091f76',
    700: '#021349',
    800: '#00061d',
  },
}

export const globalStyles = {
  colors: {
    brand: {
      100: '#E9E3FF',
      200: '#422AFB',
      300: '#422AFB',
      400: '#7551FF',
      500: '#422AFB',
      600: '#3311DB',
      700: '#02044A',
      800: '#190793',
      900: '#11047A',

      mainColor1: '#181f2d',
      mainColor2: '#363f52',
      mainColor3: '#FFFFFF',
      mainColor4: '#232934',

      mainAccent1: '#4D6CEF',
      mainAccent1Light: '#99b5ed',
      mainAccent2Light: '#657dd5',
      // mainAccent1Light: '#195fdd',
      mainAccent2: '#0bab64',
      mainAccent3: '#b02e0c',
      mainAccent4: '#9D5FDC',
      mainAccent5: '#5151F9',
      mainAccent6: '#223895',
      mainAccent7: '#ffd300',

      lightAccent: '#1a5fdd4d',
      lightAccent2: '#f7fbff',
      lightAccent3: '#195fddeb',

      textColor1: '#ffffff',
      textColor2: '#8c8f96',

      textColor1Light: '#666666',
      textColor2Light: '#3a3a3a',

      modalBg: '#181f2d',
      modalBgLight: mainBgColors.light,
      borderColor: '#353c4e',
      borderColorDark: '#4a5568',
      borderColorLight: mainBgColors.borderColorLight,
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.10)',
      bgColorDark: mainBgColors.dark,
      bgColorLight: mainBgColors.light,
    },
    yellow: {
      100: '#ffd3008a',
      200: '#ffd300',
    },

    navy: {
      20: '#728fea33',
      50: '#d0dcfb',
      100: '#aac0fe',
      200: '#a3b9f8',
      300: '#728fea',
      400: '#3652ba',
      500: '#1b3bbb',
      600: '#24388a',
      700: '#1B254B',
      800: '#111c44',
      900: '#0b1437',
    },
    red: {
      50: '#EF3E10',
      100: '#BF320D',
      200: '#b02e0c',
      300: '#8F250A',
      400: '#5F1907',
      500: '#b02e0c',
    },
    // blue: {
    //   50: '#b7c5fd',
    //   100: '#5b78f0',
    //   200: '#4D6CEF',
    //   300: '#2e51eb',
    //   400: '#1638d2',
    //   500: '#0f2ba4',
    //   600: '#091f76',
    //   700: '#021349',
    //   800: '#00061d',
    // },
    green: {
      50: '#66F5B5',
      100: '#3BF2A1',
      200: '#0bab64',
      300: '#0CC070',
      400: '#099054',
      500: '#066038',
      600: '#03301C',
    },
    purple: {
      50: '#AD79E2',
      100: '#9653D9',
      200: '#9D5FDC',
      300: '#7F2ED1',
      400: '#6525A7',
      500: '#4C1B7E',
      600: '#331254',
      700: '#19092A',
    },
    gray: {
      50: '#EDF2F7',
      100: '#E2E8F0',
      200: '#d1d9e4',
      // 200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: 'hidden',
        letterSpacing: '-0.5px',
        // bg: savedColorModeValue === 'dark' ? mainBgColors.dark : mainBgColors.light,
        // bg: props.colorMode === 'dark' ? mainBgColors.dark : mainBgColors.light,
      },
      html: {},
    }),
  },
}

export const zIndex = '1000'
// modal 1400
