// Modules
import { memo } from 'react'

// Components
import { TextXs } from 'components'
import IncreaseDecreaseBtns from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/InputMarket/IncreaseDecreaseBtns'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Flex, Input } from '@chakra-ui/react'

const InputMarket = (props) => {
  const { label, onIncreaseValue, onDecreaseValue, onResetValue, boxProps, ...args } = props

  const { mainTextColor } = useCurrentColorTheme()

  return (
    <Flex gap={2} mb={2} mt={3} h="58px" {...boxProps}>
      <Box flex="1" position="relative">
        <TextXs
          color={mainTextColor}
          fontSize="11px"
          position="absolute"
          top="3px"
          left="7px"
          zIndex={1}
        >
          {label}
        </TextXs>
        <Input pl="6px" pt="5px" type="number" h="100%" variant="filled" {...args} />
      </Box>
      <IncreaseDecreaseBtns
        onIncreaseValue={onIncreaseValue}
        onDecreaseValue={onDecreaseValue}
        onResetValue={onResetValue}
      />
    </Flex>
  )
}

export default memo(InputMarket)
