// Modules
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Languages
import { languages, resources } from 'locales'

// Constants
import { appLanguagesLocalStoreKey } from 'components/LanguageDropdown/constants'

// Get the language from local storage or set default to 'en'
const savedLanguage = localStorage.getItem(appLanguagesLocalStoreKey) || languages.en

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: savedLanguage, // Default language
    fallbackLng: languages.en, // Fallback language if the current language does not have a translation
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  })

export default i18n
