// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_TRANSACTION_CALC_MODAL_PROFIT_CALCULATOR_TITLE } from 'locales/constants'

// Components
import CalcModalContent from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/ProfitCalculator/ProfitCalculatorModal/CalcModalContent'

//Utils
import { getScrollbarCss } from 'utils/scrollbarHelper'

// UI
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

const ProfitCalculatorModal = (props) => {
  const { onClose, isOpen } = props

  const { t } = useTranslation()

  return (
    <Modal size="xl" onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb={0}>{t(LN_TRANSACTION_CALC_MODAL_PROFIT_CALCULATOR_TITLE)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto" height="500px" pr={2} css={getScrollbarCss()} mb={4}>
          <CalcModalContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default memo(ProfitCalculatorModal)
