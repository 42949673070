// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const favouritesCurrencyListSelector = createSelector(
  selfSelector,
  (self) => self.favouritesCurrencyList,
)
