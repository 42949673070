// Modules
import { memo } from 'react'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Flex } from '@chakra-ui/react'

const WrapperInfoItem = (props) => {
  const { children, ...rest } = props

  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Flex
      flexDirection="column"
      pl={3}
      pr={3}
      pt={1}
      pb={1}
      bg={isLightTheme ? 'gray.50' : 'whiteAlpha.50'}
      borderRadius="md"
      border="1px solid"
      borderColor={isLightTheme ? 'gray.100' : 'whiteAlpha.100'}
      {...rest}
    >
      {children}
    </Flex>
  )
}

export default memo(WrapperInfoItem)
