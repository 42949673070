// Modules
import { lazy, memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HiShieldCheck } from 'react-icons/hi2'
import { TbShieldCancel } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// Languages
import {
  LN_USER_PERSONAL_INFORMATION_CONFIRMED_BTN,
  LN_USER_PERSONAL_INFORMATION_CONFIRM_BTN,
  LN_USER_PERSONAL_INFORMATION_COUNTRY,
  LN_USER_PERSONAL_INFORMATION_EMAIL,
  LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_MESSAGE,
  LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_TITLE,
  LN_USER_PERSONAL_INFORMATION_FIRST_NAME,
  LN_USER_PERSONAL_INFORMATION_LAST_NAME,
  LN_USER_PERSONAL_INFORMATION_NOT_VERIFIED,
  LN_USER_PERSONAL_INFORMATION_PHONE_NUMBER,
  LN_USER_PERSONAL_INFORMATION_STATUS,
  LN_USER_PERSONAL_INFORMATION_VERIFIED,
} from 'locales/constants'

// Components
import { LoadingBlock, TextMd, TextSm } from 'components'
import { isUserEmailVerifiedKey } from 'components/LanguageDropdown/constants'
import { toastConstructor, toastType } from 'components/ToastApp/constants'
// Lazy Components
import DisabledInputInfo from 'pages/User/components/Content/PersonalInfo/DisabledInputInfo'
import RestrictionPlatform from 'pages/User/components/Content/PersonalInfo/Restrictions/RestrictionPlatform'
import RestrictionUserProfile from 'pages/User/components/Content/PersonalInfo/Restrictions/RestrictionUserProfile'
import { MainTitle } from 'pages/User/components/Content/Withdrawal'
import { sidebarList } from 'pages/User/components/Sidebar/constants'

// Hooks
import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useGetUserCountyCode from 'hooks/useGetUserCountyCode'

// Utils
import { handlingEmptyValuesForShowing } from 'utils/handlingEmptyValuesForShowing'

// Store
import { showToast } from 'store/actions/ToastsApp/actions'
import { onConfirmUserEmail } from 'store/actions/User/actions'
import {
  loadingConfirmEmailSelector,
  loadingUserInfoSelector,
  userInfoSelector,
} from 'store/actions/User/selectors'

// UI
import { Box, Button, Flex, Icon, Stack } from '@chakra-ui/react'

const PersonalInfo = () => {
  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()

  const location = useLocation()
  // TODO: for email verification
  const searchParams = new URLSearchParams(location.search)
  const signature = searchParams.get('signature')

  const dispatch = useDispatch()
  const userInfo = useSelector(userInfoSelector)
  const loadingConfirmEmail = useSelector(loadingConfirmEmailSelector)
  const loadingUserInfo = useSelector(loadingUserInfoSelector)

  const { countriesListLoading, countryName } = useGetUserCountyCode()
  const { isRestricted, isTradePlatformSettings } = useCheckUserRestrictions()

  const isEmailVerified = userInfo?.email_verified_at

  useEffect(() => {
    const hasAlreadyVerified = localStorage.getItem(isUserEmailVerifiedKey)
    if (signature && hasAlreadyVerified !== 'true') {
      localStorage.setItem(isUserEmailVerifiedKey, 'true')
      dispatch(
        showToast(
          toastConstructor(
            toastType.success,
            t(LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_MESSAGE),
            t(LN_USER_PERSONAL_INFORMATION_EMAIL_NOTIFICATION_TITLE),
          ),
        ),
      )
    }
  }, [isEmailVerified, signature, dispatch, t])

  const onConfirmEmail = useCallback(() => dispatch(onConfirmUserEmail()), [dispatch])

  const loading = loadingUserInfo || countriesListLoading

  return (
    <Box w="100%" p={3}>
      <Flex
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', sm: 'center' }}
        pb={5}
        flexDirection={{ base: 'column', sm: 'row' }}
        gap={{ base: 4, sm: 0 }}
      >
        <MainTitle title={t(sidebarList[0]?.title)} pb={0} />
        {!loading && (
          <Flex gap={2} alignItems="center">
            <TextSm color={mainTextColor} fontWeight="500">
              {t(LN_USER_PERSONAL_INFORMATION_STATUS)}:
            </TextSm>
            {userInfo?.email_verified_at ? (
              <>
                <TextMd color="green.200" fontWeight="600">
                  {t(LN_USER_PERSONAL_INFORMATION_VERIFIED)}
                </TextMd>
                <Icon as={HiShieldCheck} color="green.200" />
              </>
            ) : (
              <>
                <TextMd color="red.200" fontWeight="600">
                  {t(LN_USER_PERSONAL_INFORMATION_NOT_VERIFIED)}
                </TextMd>
                <Icon as={TbShieldCancel} color="red.200" />
              </>
            )}
          </Flex>
        )}
      </Flex>

      {loading ? (
        <LoadingBlock />
      ) : (
        <>
          <Flex gap={5} w="100%" flexDirection={{ base: 'column', '2sm': 'row' }}>
            <Flex gap={3} flexDirection="column" w={{ base: '100%', '2sm': '50%' }}>
              <DisabledInputInfo
                label={t(LN_USER_PERSONAL_INFORMATION_FIRST_NAME)}
                value={handlingEmptyValuesForShowing(userInfo?.first_name)}
              />
              <DisabledInputInfo
                label={t(LN_USER_PERSONAL_INFORMATION_LAST_NAME)}
                value={handlingEmptyValuesForShowing(userInfo?.last_name)}
              />
              <Flex alignItems="end" w="100%" gap={2}>
                <DisabledInputInfo
                  label={t(LN_USER_PERSONAL_INFORMATION_EMAIL)}
                  value={handlingEmptyValuesForShowing(userInfo?.email)}
                  w="100%"
                />
                <Button
                  colorScheme="green"
                  w="35%"
                  isDisabled={userInfo?.email_verified_at}
                  onClick={onConfirmEmail}
                  isLoading={loadingConfirmEmail}
                  size={{ base: 'sm', sm: 'md' }}
                >
                  {userInfo?.email_verified_at
                    ? t(LN_USER_PERSONAL_INFORMATION_CONFIRMED_BTN)
                    : t(LN_USER_PERSONAL_INFORMATION_CONFIRM_BTN)}
                </Button>
              </Flex>
            </Flex>
            <Flex gap={3} flexDirection="column" w={{ base: '100%', '2sm': '50%' }}>
              <DisabledInputInfo
                label={t(LN_USER_PERSONAL_INFORMATION_PHONE_NUMBER)}
                value={handlingEmptyValuesForShowing(userInfo?.phone)}
              />
              <DisabledInputInfo
                label={t(LN_USER_PERSONAL_INFORMATION_COUNTRY)}
                value={handlingEmptyValuesForShowing(countryName)}
              />
            </Flex>
          </Flex>
          <Stack gap={5} mt={10} pb={5}>
            {isRestricted && <RestrictionUserProfile />}
            {isTradePlatformSettings && <RestrictionPlatform />}
          </Stack>
        </>
      )}
    </Box>
  )
}

export default memo(PersonalInfo)
