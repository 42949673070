// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_AUTH_PASSWORD } from 'locales/constants'

// Components
import { ErrorMessage, InputBase } from 'components'
import { formConstants } from 'pages/Auth/Login/constants'

// UI
import { FormControl, FormLabel, InputGroup } from '@chakra-ui/react'

const PasswordField = (props) => {
  const { formik } = props

  const { t } = useTranslation()

  const { password } = formConstants

  return (
    <FormControl isRequired mb={3}>
      <FormLabel>{t(LN_AUTH_PASSWORD)}</FormLabel>
      <InputGroup>
        <InputBase type="password" {...formik.getFieldProps(password)} />
      </InputGroup>
      {formik.touched[password] && formik.errors[password] && (
        <ErrorMessage error={t(formik.errors[password])} />
      )}
    </FormControl>
  )
}

export default memo(PasswordField)
