// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Constants
import { tabsList } from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs/constants'

// UI
import { Button, ButtonGroup, Stack } from '@chakra-ui/react'

const TopTabs = (props) => {
  const { onChooseTab, active } = props

  const { t } = useTranslation()

  return (
    <Stack direction="column">
      <ButtonGroup gap="1">
        {tabsList.map((el) => (
          <TabBtn
            key={el.id}
            onClick={() => onChooseTab(el.id)}
            title={t(el.title)}
            // title={el.title}
            active={active === el.id}
          />
        ))}
      </ButtonGroup>
    </Stack>
  )
}

export default memo(TopTabs)

const TabBtn = (props) => {
  const { title, onClick, active } = props

  return (
    <Button
      borderRadius="50px"
      pt={1}
      pl={3}
      pr={3}
      pb={1}
      colorScheme={active ? 'blue' : 'gray'}
      fontSize="sm"
      size="sm"
      onClick={onClick}
    >
      {title}
    </Button>
  )
}
