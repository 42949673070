// Modules
import { motion } from 'framer-motion'
import { memo } from 'react'

// Components
import ContentBar from 'pages/Dashboard/components/OrdersBottomBar/ContentBar'
import FullSizeModal from 'pages/Dashboard/components/OrdersBottomBar/FullSizeModal'
import TopTabs from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar'
import { sideVariants } from 'pages/Dashboard/components/WrapperForDrawer'
import { zIndex } from 'theme/globalStyles'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box } from '@chakra-ui/react'

const OrdersBottomBarMobileUI = (props) => {
  const { onBottomBlockOpen, onBottomBlockClose, isBottomNavOpen, orderList } = props

  const { mainBorderColor, mainBgColor, isLightTheme } = useCurrentColorTheme()

  return (
    <>
      <Box
        borderTop={isLightTheme ? '2px solid' : '1px solid'}
        borderColor={mainBorderColor}
        position="fixed"
        width="100%"
        bottom="0"
        zIndex={zIndex}
        // zIndex="100001"
        background={mainBgColor}
      >
        <TopTabs onBottomBlockOpen={onBottomBlockOpen} onBottomBlockClose={onBottomBlockClose} />
        {isBottomNavOpen && (
          <motion.div
            // initial={{ width: 0 }}
            animate={{
              height: '50vh',
            }}
            exit={{
              height: 0,
              transition: { delay: 0.2, duration: 0.1 },
            }}
            style={{
              width: '100%',
            }}
          >
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={sideVariants}
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              <ContentBar ordersList={orderList} isMobile />
            </motion.div>
          </motion.div>
        )}
      </Box>
      <FullSizeModal orderList={orderList} />
    </>
  )
}

export default memo(OrdersBottomBarMobileUI)
