// Modules
import { memo, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// Languages
import {
  LN_USER_WITHDRAWAL_ACCOUNT_HOLDER_NAME,
  LN_USER_WITHDRAWAL_ACCOUNT_NUMBER,
  LN_USER_WITHDRAWAL_AMOUNT,
  LN_USER_WITHDRAWAL_CARD_HOLDER_NAME,
  LN_USER_WITHDRAWAL_CARD_NUMBER,
  LN_USER_WITHDRAWAL_REQUEST_NEW_WITHDRAWAL,
  LN_USER_WITHDRAWAL_REQUEST_WITHDRAWAL_SUBMIT_BTN,
  LN_USER_WITHDRAWAL_WALLET_ADDRESS,
  LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS,
  LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS_TITLE_FORM,
} from 'locales/constants'

// Components
import { TextMd } from 'components'
import InputBase from 'components/Form/InputBase'
import IbanInputBase from 'pages/User/components/Content/Withdrawal/WithdrawalForm/IbanInputBase'
import {
  withdrawalDetailsConstants,
  withdrawalDetailsList,
} from 'pages/User/components/Content/Withdrawal/WithdrawalForm/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  Select,
} from '@chakra-ui/react'

const WithdrawalForm = () => {
  const { t } = useTranslation()
  const { mainTextColor, isLightTheme } = useCurrentColorTheme()

  const dispatch = useDispatch()

  const [detailsActiveItem, setDetailsActiveItem] = useState(withdrawalDetailsConstants.bank)

  const onRequestWithdrawal = useCallback(() => {}, [])

  const onChangeWithdrawalDetails = useCallback(
    (e) => {
      setDetailsActiveItem(e?.target?.value)
    },
    [setDetailsActiveItem],
  )

  const inputSwitcher = useMemo(() => {
    switch (detailsActiveItem) {
      case withdrawalDetailsConstants.bank:
        return (
          <>
            <FormControl isRequired>
              <FormLabel>{t(LN_USER_WITHDRAWAL_ACCOUNT_NUMBER)}</FormLabel>
              <InputBase type="number" />
            </FormControl>
            <IbanInputBase />
          </>
        )
      case withdrawalDetailsConstants.bitcoin:
      case withdrawalDetailsConstants.usdt:
      case withdrawalDetailsConstants.ethereum:
        return (
          <FormControl isRequired>
            <FormLabel>{t(LN_USER_WITHDRAWAL_WALLET_ADDRESS)}</FormLabel>
            <InputBase />
          </FormControl>
        )
      case withdrawalDetailsConstants.card:
        return (
          <FormControl isRequired>
            <FormLabel>{t(LN_USER_WITHDRAWAL_CARD_NUMBER)}</FormLabel>
            <InputBase />
          </FormControl>
        )
    }
  }, [detailsActiveItem, t])

  return (
    <Card
      bg={isLightTheme ? 'white' : 'whiteAlpha.50'}
      borderRadius="md"
      border="1px solid"
      borderColor="whiteAlpha.100"
    >
      <CardBody>
        <Box pb={5}>
          <TextMd fontWeight={500} color={mainTextColor}>
            {t(LN_USER_WITHDRAWAL_REQUEST_NEW_WITHDRAWAL)}
          </TextMd>
        </Box>

        <Flex gap={5} w="100%">
          <Flex gap={3} flexDirection="column" w="50%">
            <FormControl isRequired>
              <FormLabel>{t(LN_USER_WITHDRAWAL_AMOUNT)}</FormLabel>
              <InputGroup>
                <InputBase type="number" placeholder="0.00" />
                <InputRightAddon>€</InputRightAddon>
              </InputGroup>
            </FormControl>
          </Flex>
          <Flex gap={3} flexDirection="column" w="50%">
            <FormControl isRequired>
              <FormLabel>{t(LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS)}</FormLabel>
              <Select onChange={onChangeWithdrawalDetails} variant="filled">
                {withdrawalDetailsList?.map((el) => (
                  <option key={el?.id} value={el?.id}>
                    {el?.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
        </Flex>

        <Box pt={5} pb={5}>
          <TextMd color={mainTextColor} fontWeight={500}>
            {t(LN_USER_WITHDRAWAL_WITHDRAWAL_DETAILS_TITLE_FORM)}
          </TextMd>
        </Box>
        <Flex gap={5} w="100%">
          <Flex gap={3} flexDirection="column" w="50%">
            {inputSwitcher}
          </Flex>
          <Flex gap={3} flexDirection="column" w="50%">
            {detailsActiveItem === withdrawalDetailsConstants.bank && (
              <>
                <FormControl isRequired>
                  <FormLabel>{t(LN_USER_WITHDRAWAL_ACCOUNT_HOLDER_NAME)}</FormLabel>
                  <InputBase />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Bank SWIFT code</FormLabel>
                  <InputBase placeholder="AAAA-BB-CC-123" />
                </FormControl>
              </>
            )}
            {detailsActiveItem === withdrawalDetailsConstants.card && (
              <FormControl isRequired>
                <FormLabel>{t(LN_USER_WITHDRAWAL_CARD_HOLDER_NAME)}</FormLabel>
                <InputBase />
              </FormControl>
            )}
          </Flex>
        </Flex>
        <Flex pt={6}>
          <Button colorScheme="blue" color="white" onClick={onRequestWithdrawal}>
            {t(LN_USER_WITHDRAWAL_REQUEST_WITHDRAWAL_SUBMIT_BTN)}
          </Button>
        </Flex>
      </CardBody>
    </Card>
  )
}

export default memo(WithdrawalForm)
