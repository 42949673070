// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_SEARCH_INPUT_TITLE } from 'locales/constants'

// Components
import { InputBase } from 'components'

// UI
import { CloseButton, InputGroup, InputRightElement } from '@chakra-ui/react'

const SearchInput = (props) => {
  const { searchedValue, setSearchedValue, isCleanText = true, ...args } = props

  const { t } = useTranslation()

  const onSearchValue = useCallback(
    (e) => {
      const inputValue = e?.target?.value
      setSearchedValue(inputValue)
    },
    [setSearchedValue],
  )

  const onDeleteValue = useCallback(() => {
    setSearchedValue('')
  }, [setSearchedValue])

  return (
    <InputGroup>
      <InputBase
        value={searchedValue}
        placeholder={t(LN_SEARCH_INPUT_TITLE) + '...'}
        onChange={onSearchValue}
        pr={searchedValue ? 8 : 4}
        {...args}
      />
      {searchedValue && isCleanText && (
        <InputRightElement>
          <CloseButton
            onClick={onDeleteValue}
            color="red.100"
            __css={{
              svg: {
                width: '0.8em',
              },
            }}
          />
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default memo(SearchInput)
