// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { GiResize } from 'react-icons/gi'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

// Languages
import {
  LN_HISTORY_SEARCH_BY_INSTRUMENT_TITLE,
  LN_HISTORY_TOTAL_PROFIT,
  LN_HISTORY_TOTAL_VOLUME,
} from 'locales/constants'

// Components
import { SearchInput, TextXs } from 'components'
import CloseAllBtn from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/CloseAllBtn'
import MainTabs from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/MainTabs'
import SecondaryTabs from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/SecondaryTabs'
import SelectFilterTime from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/SelectFilterTime'
import { mainTabsValues } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { formatDigitsWithSpaces, roundTwoDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// UI
import { Box, Flex, Icon, IconButton } from '@chakra-ui/react'

const HeaderBarMobileUI = (props) => {
  const {
    onBottomBlockOpen,
    onBottomBlockClose,
    isBottomNavOpen,
    mainTabsValue,
    loadingBar,
    onOpenFullSize,
    searchValue,
    onSearch,
    positionsList,
    pendingOrdersList,
    totalVolume,
    totalProfit,
    userTradingAccountSymbol,
  } = props

  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()

  return (
    <>
      <Flex
        p="2px 5px 2px 8px"
        h={isBottomNavOpen ? 'auto' : '28px'}
        alignItems="flex-start"
        justifyContent="flex-start"
        flexDirection="column"
        w="100%"
      >
        <Flex gap={2} alignItems="center" w="100%" justifyContent="space-between">
          <Flex gap={2} alignItems="center">
            <MainTabs />
          </Flex>
          <Box>
            {isBottomNavOpen && (
              <IconButton
                icon={<Icon as={GiResize} />}
                onClick={onOpenFullSize}
                aria-label="open-modal"
                variant="link"
                size="sm"
                isDisabled={loadingBar}
              />
            )}
            <IconButton
              icon={<Icon as={isBottomNavOpen ? IoIosArrowDown : IoIosArrowUp} />}
              onClick={isBottomNavOpen ? onBottomBlockClose : onBottomBlockOpen}
              aria-label="toggle-orders"
              variant="link"
              size="sm"
            />
          </Box>
        </Flex>
        {isBottomNavOpen && (
          <Box w="50%" mt={2} mb={2}>
            <SearchInput
              searchedValue={searchValue}
              setSearchedValue={onSearch}
              h="22px"
              w="97%"
              paddingLeft={2}
              fontSize="12px"
              isCleanText={false}
              placeholder={t(LN_HISTORY_SEARCH_BY_INSTRUMENT_TITLE)}
              _placeholder={{ color: mainTextColor }}
              disabled={loadingBar}
            />
          </Box>
        )}
        {isBottomNavOpen && (
          <Flex alignItems="center" w="100%" justifyContent="space-between">
            <Flex gap={1.5}>
              <SecondaryTabs isDisabled={loadingBar} />
            </Flex>
            <CloseAllBtn
              isDisabled={
                loadingBar ||
                (mainTabsValue === mainTabsValues?.positions
                  ? positionsList?.length === 0
                  : pendingOrdersList?.length === 0)
              }
            />
          </Flex>
        )}
        <Flex gap={1} alignItems="center" w="100%" justifyContent="space-between" mt={2}>
          {isBottomNavOpen && (
            <>
              {mainTabsValue === mainTabsValues?.closedPositions && (
                <Box>
                  <SelectFilterTime isDisabled={loadingBar} />
                </Box>
              )}
              {mainTabsValue === mainTabsValues?.closedPositions && isBottomNavOpen && (
                <Flex gap={2}>
                  <TextXs color={mainTextColor} fontWeight="500" opacity={loadingBar ? 0.4 : 1}>
                    {t(LN_HISTORY_TOTAL_VOLUME)}: {totalVolume}
                  </TextXs>
                  <TextXs color={mainTextColor} fontWeight="500" opacity={loadingBar ? 0.4 : 1}>
                    {t(LN_HISTORY_TOTAL_PROFIT)}:{' '}
                    {formatDigitsWithSpaces(roundTwoDigitsAfterDots(totalProfit))}{' '}
                    {userTradingAccountSymbol}
                  </TextXs>
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default memo(HeaderBarMobileUI)
