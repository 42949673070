// Modules
import { memo } from 'react'
import { TbFileDescription } from 'react-icons/tb'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Icon, IconButton, Tooltip } from '@chakra-ui/react'

const DescriptionBtn = ({ onClick, iconSize = '24px', tooltipTitle = null, ...args }) => {
  const { isLightThemeSelector } = useCurrentColorTheme()

  return (
    <Tooltip label={tooltipTitle} aria-label={tooltipTitle?.toLowerCase()}>
      <IconButton
        icon={<Icon as={TbFileDescription} w={iconSize} h={iconSize} />}
        onClick={onClick}
        aria-label="info"
        isRound
        size="xs"
        bg={'transparent'}
        _hover={{
          backgroundColor: isLightThemeSelector ? '#d1d9e4' : 'rgba(255, 255, 255, 0.16)',
        }}
        {...args}
      />
    </Tooltip>
  )
}

export default memo(DescriptionBtn)
