// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_CLOSE_ALL_TRADES_BTN,
  LN_TRANSACTION_CALC_BTN_BUY,
  LN_TRANSACTION_CALC_BTN_SELL,
  LN_USER_RESTRICTIONS,
} from 'locales/constants'

// Components
import { TextSm } from 'components'
import ProfitCalculator from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/ProfitCalculator'

// Utils
import { formatDigitsWithSpaces, roundDigitsAfterDots } from 'utils/formatDigitsWithSpaces'
import { typeTransaction } from 'utils/transactionFormulas'

// UI
import { Button, Stack, Tooltip } from '@chakra-ui/react'

const SubmitBtns = (props) => {
  const { onCreateTransaction, disabled, sellValue, buyValue, currencySymbolPointValue } = props

  const { t } = useTranslation()

  const tooltipTitle = disabled ? t(LN_USER_RESTRICTIONS) : null

  return (
    <Stack gap={2} mt={2} direction="row">
      <BtnBuy
        disabled={disabled}
        onCreateTransaction={onCreateTransaction}
        currencySymbolPointValue={currencySymbolPointValue}
        buyValue={buyValue}
        tooltipTitle={tooltipTitle}
      />
      <BtnSell
        disabled={disabled}
        onCreateTransaction={onCreateTransaction}
        currencySymbolPointValue={currencySymbolPointValue}
        sellValue={sellValue}
        tooltipTitle={tooltipTitle}
      />
      <ProfitCalculator />
    </Stack>
  )
}

export default memo(SubmitBtns)

const BtnSell = ({
  disabled,
  sellValue,
  onCreateTransaction,
  currencySymbolPointValue,
  tooltipTitle,
}) => {
  const { t } = useTranslation()

  const onSell = useCallback(() => {
    onCreateTransaction(typeTransaction?.sell)
  }, [onCreateTransaction])

  return (
    <Tooltip label={tooltipTitle} aria-label={tooltipTitle}>
      <Button
        isDisabled={disabled || sellValue === 0}
        w="full"
        display="flex"
        flexDirection="column"
        colorScheme="red"
        size="md"
        onClick={onSell}
      >
        <TextSm color="white">{t(LN_TRANSACTION_CALC_BTN_SELL)}</TextSm>
        <TextSm color="white" fontWeight="400">
          {formatDigitsWithSpaces(roundDigitsAfterDots(sellValue, currencySymbolPointValue))}
        </TextSm>
      </Button>
    </Tooltip>
  )
}

const BtnBuy = ({
  disabled,
  buyValue,
  onCreateTransaction,
  currencySymbolPointValue,
  tooltipTitle,
}) => {
  const { t } = useTranslation()

  const onBuy = useCallback(() => {
    onCreateTransaction(typeTransaction?.buy)
  }, [onCreateTransaction])

  return (
    <Tooltip label={tooltipTitle} aria-label={tooltipTitle}>
      <Button
        isDisabled={disabled || buyValue === 0}
        w="full"
        display="flex"
        flexDirection="column"
        // colorScheme="blue"
        variant="actionBlue"
        size="md"
        onClick={onBuy}
      >
        <TextSm color="white">{t(LN_TRANSACTION_CALC_BTN_BUY)}</TextSm>
        <TextSm color="white" fontWeight="400">
          {formatDigitsWithSpaces(roundDigitsAfterDots(buyValue, currencySymbolPointValue))}
        </TextSm>
      </Button>
    </Tooltip>
  )
}
