export const accordionStyles = {
  Accordion: {
    parts: ['button', 'panel', 'icon', 'container'],
    baseStyle: {},
    variants: {
      solid: () => {
        return {
          container: {
            border: '0',
          },
          panel: {
            border: '0',
            background: 'whiteAlpha.50',
            borderRadius: 'md',
            marginTop: '-2px',
          },
          button: {
            bg: 'navy.500',
            borderRadius: 'md',
            border: '0',
            _hover: {
              bg: 'navy.400',
            },
          },
          icon: {
            color: 'white',
          },
        }
      },
    },
  },
}
