// Modules
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'

// Components
import { TextLg } from 'components'
import Deposit from 'pages/User/components/Content/Deposit'
import PersonalInfo from 'pages/User/components/Content/PersonalInfo'
import Verification from 'pages/User/components/Content/Verification'
import Withdrawal from 'pages/User/components/Content/Withdrawal'
import { sidebarConstants } from 'pages/User/components/Sidebar/constants'

// Store
import { sidebarActiveItemSelector } from 'store/actions/User/selectors'

// UI
import { Box } from '@chakra-ui/react'

const Content = () => {
  const sidebarActiveItem = useSelector(sidebarActiveItemSelector)

  const showSidebarContent = useMemo(() => {
    switch (sidebarActiveItem) {
      case sidebarConstants.personalInfo:
        return <PersonalInfo />
      case sidebarConstants.deposit:
        return <Deposit />
      case sidebarConstants.withdrawal:
        return <Withdrawal />
      case sidebarConstants.verification:
        return <Verification />
    }
  }, [sidebarActiveItem])

  return (
    <Box w="100%" p={3}>
      <TextLg>{showSidebarContent}</TextLg>
    </Box>
  )
}

export default memo(Content)
