// Hooks
import { themeColorMode } from 'hooks/useCurrentColorTheme'

export const modalStyles = {
  Modal: {
    baseStyle: ({ colorMode }) => ({
      dialog: {
        backgroundColor:
          colorMode === themeColorMode.light ? 'brand.modalBgLight' : 'brand.modalBg',
        border: '2px solid',
        borderColor:
          colorMode === themeColorMode.light ? 'brand.borderColorLight' : 'brand.mainColor2',
      },
    }),
  },
}
