// Assets
import logo from 'assets/logo.svg'

export const LogoUrl = logo

export const ScreenWidthWithoutNavbar = '82vh'
export const LeftNavBarIconsWidth = '80'
export const LeftNavBarIconsMobileWidth = '53'
export const LeftNavBarIconsMobileXsWidth = '47'
export const NavBarMobileHeight = '64'
export const LeftTabsWidth = '339'
export const RightMarketSidebar = '306'
export const BottomBarHeight = '140px'

export const localStorageFavouritesKey = 'favouritesTrade'
export const localStorageActiveTradeItemsKey = 'activeTradeItems'
export const localStorageActiveUserTradeAccountKey = 'localStorageActiveUserTradeAccount'
export const isOpenBottomBarKey = 'isOpenBottomBarKey'
export const graphResolutionValueKey = 'graphResolutionValueKey'
