// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_DEPOSIT_TAB_HISTORY_INFO_BTN } from 'locales/constants'

// Components
import TextareaBase from 'components/Form/TextareaBase'

// Store
import {
  setDepositModalInfoItem,
  setIsDepositModalInfoOpen,
} from 'store/actions/UserDeposit/actions'
import {
  isDepositModalInfoOpenSelector,
  modalDepositItemInfoSelector,
} from 'store/actions/UserDeposit/selectors'

// UI
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

const InfoModal = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const info = useSelector(modalDepositItemInfoSelector)
  const isOpen = useSelector(isDepositModalInfoOpenSelector)

  const onClose = useCallback(() => {
    dispatch(setIsDepositModalInfoOpen(false))
    dispatch(setDepositModalInfoItem(null))
  }, [dispatch])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(LN_DEPOSIT_TAB_HISTORY_INFO_BTN)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={5}>
          <TextareaBase
            value={info?.description ? info?.description : '-'}
            pb={2}
            disabled
            resize="none"
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default memo(InfoModal)
