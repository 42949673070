// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { HiSortAscending } from 'react-icons/hi'
import { HiSortDescending } from 'react-icons/hi'

// Components
import {
  sortByListValue,
  sortListValue,
} from 'pages/Dashboard/components/OrdersBottomBar/ContentBar/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Button, Icon, Th, Tr } from '@chakra-ui/react'

const TableHeaderTh = (props) => {
  const { sortOrder, name, handleSort, title, sortBy, ...args } = props

  const { t } = useTranslation()
  const { highLightColor } = useCurrentColorTheme()

  const onHandleSort = useCallback(() => handleSort(name), [handleSort, name])

  return (
    <Th w={name === sortByListValue?.deleteItem ? '26px' : 'auto'}>
      <Button
        aria-label="sorting"
        variant="link"
        paddingInline={0}
        minW="auto"
        fontSize="11px"
        textTransform="uppercase"
        color="gray.400"
        onClick={onHandleSort}
        _hover={{
          textDecoration: 'none',
          color: highLightColor,
        }}
        {...args}
      >
        {t(title)}
        {sortBy === name && sortOrder === sortListValue.asc && <Icon as={HiSortAscending} />}
        {sortBy === name && sortOrder === sortListValue.desc && <Icon as={HiSortDescending} />}
      </Button>
    </Th>
  )
}

export default memo(TableHeaderTh)

export const TableTr = ({ children }) => {
  const { isLightTheme } = useCurrentColorTheme()

  return (
    <Tr
      _hover={{
        background: isLightTheme ? 'gray.100' : 'gray.700',
        transition: '0.8s',
      }}
    >
      {children}
    </Tr>
  )
}
