// Types
import {
  FORGOT_PASSWORD_ACTION,
  FORGOT_PASSWORD_RESULT,
  LOGOUT,
  NOT_AUTH,
  ON_LOGIN,
  ON_LOGIN_RESULT,
  RESET_PASSWORD_ACTION,
  RESET_PASSWORD_RESULT,
  SET_TOKEN,
} from 'store/actions/Auth/types'

export const logout = () => ({
  type: LOGOUT,
})

export const setToken = (payload) => ({
  type: SET_TOKEN,
  payload: payload,
})

export const onLoginUser = (payload) => ({
  type: ON_LOGIN,
  payload: payload,
})

export const onLoginResult = (payload) => ({
  type: ON_LOGIN_RESULT,
  payload: payload,
})

export const onForgotPassword = (payload) => ({
  type: FORGOT_PASSWORD_ACTION,
  payload: payload,
})

export const onForgotPasswordResult = (payload) => ({
  type: FORGOT_PASSWORD_RESULT,
  payload: payload,
})

export const onResetPassword = (payload) => ({
  type: RESET_PASSWORD_ACTION,
  payload: payload,
})

export const onResetPasswordResult = (payload) => ({
  type: RESET_PASSWORD_RESULT,
  payload: payload,
})

export const onNonAuth = () => ({
  type: NOT_AUTH,
})
