// Modules
import _ from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_USER_RESTRICTIONS } from 'locales/constants'

// Constants
import { toastConstructor, toastType } from 'components/ToastApp/constants'
import {
  mainTabsList,
  mainTabsValues,
  secondaryTabsValues,
} from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'
import { ordersBarPositionsLengthValues } from 'pages/Dashboard/components/OrdersBottomBar/constants'

// Hooks
import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Utils
import { getTotalPlValue } from 'utils/transactionFormulas'

// Store
import {
  getClosedTransactionsList,
  getOpenTransactionsList,
  getPendingTransactionsList,
  onCloseAllTransactions,
  setClosedTransactionsListPagination,
  setMainTabsValue,
  setOpenTransactionsListPagination,
  setPendingOrdersListPagination,
  setSearchValue,
  setSecondaryTabsValue,
  setSelectTimeValue,
} from 'store/actions/OrderBar/actions'
import {
  closedPositionsListSelector,
  mainTabsValueSelector,
  pendingOrdersListSelector,
  positionsLengthSelector,
  positionsListSelector,
  searchValueSelector,
} from 'store/actions/OrderBar/selectors'
import { showToast } from 'store/actions/ToastsApp/actions'

// UI
import { useDisclosure } from '@chakra-ui/react'

function useOrderBottomInfo() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { userTradingAccountId } = useUserTradingAccountId()
  const { isBlockedCloseTrade, isTradeAllowed } = useCheckUserRestrictions()

  const mainTabsValue = useSelector(mainTabsValueSelector)
  const searchValue = useSelector(searchValueSelector)

  const positionsLength = useSelector(positionsLengthSelector)
  const positionsList = useSelector(positionsListSelector)
  const closedPositionsList = useSelector(closedPositionsListSelector)
  const pendingOrdersList = useSelector(pendingOrdersListSelector)

  const {
    isOpen: isOpenCloseAll,
    onOpen: onOpenCloseAll,
    onClose: onCloseAlertCloseAll,
  } = useDisclosure()

  const sentRequestBase = useCallback(
    (mainTabs, options = {}) => {
      if (!userTradingAccountId) return null

      switch (mainTabs) {
        case mainTabsValues?.positions:
          dispatch(
            getOpenTransactionsList({
              tradingAccountId: userTradingAccountId,
              ...options,
            }),
          )

          break
        case mainTabsValues?.pendingOrders:
          dispatch(
            getPendingTransactionsList({
              tradingAccountId: userTradingAccountId,
              ...options,
            }),
          )
          break
        case mainTabsValues?.closedPositions:
          dispatch(
            getClosedTransactionsList({
              tradingAccountId: userTradingAccountId,
              ...options,
            }),
          )
          break
      }
    },
    [dispatch, userTradingAccountId],
  )

  const onHandleMainTabs = useCallback(
    (val) => {
      dispatch(setSecondaryTabsValue(secondaryTabsValues?.all))
      dispatch(setMainTabsValue(val))
      dispatch(setSearchValue(''))
      dispatch(setSelectTimeValue(''))
      dispatch(setPendingOrdersListPagination({ page: 1 }))
      dispatch(setOpenTransactionsListPagination({ page: 1 }))
      dispatch(setClosedTransactionsListPagination({ page: 1 }))

      sentRequestBase(val, { page: 1, search: '' })
    },
    [dispatch, sentRequestBase],
  )

  const sentRequest = useCallback(
    (options = {}) => {
      sentRequestBase(mainTabsValue, options)
    },
    [mainTabsValue, sentRequestBase],
  )

  const debouncedSearch = _.debounce((searchQuery) => {
    if (searchQuery.length > 2 || searchQuery.length === 0) {
      sentRequest({ search: searchValue })
    }
  }, 300) // Adjust debounce delay as needed

  useEffect(() => {
    debouncedSearch(searchValue)
    return () => {
      debouncedSearch.cancel() // Clean up the debounce on unmount
    }
  }, [searchValue])

  const onSearch = useCallback(
    (inputValue) => {
      // sentRequest({ search: inputValue })
      dispatch(setSearchValue(inputValue))
    },
    [dispatch],
  )

  const onHandleSecondaryTabs = useCallback(
    (val) => {
      dispatch(setSecondaryTabsValue(val))
      if (secondaryTabsValues.all === val) {
        sentRequest()
      } else {
        sentRequest({ commandId: val })
      }
    },
    [dispatch, sentRequest],
  )

  const mainTabs = useMemo(() => {
    return mainTabsList?.map((el) => {
      switch (el?.value) {
        case mainTabsValues?.positions:
          return { ...el, count: positionsLength?.[ordersBarPositionsLengthValues.open] || 0 }
        case mainTabsValues?.pendingOrders:
          return { ...el, count: positionsLength?.[ordersBarPositionsLengthValues.pending] || 0 }
        case mainTabsValues?.closedPositions:
          return { ...el, count: positionsLength?.[ordersBarPositionsLengthValues.close] || 0 }
      }
    })
  }, [positionsLength])

  const totalVolume = useMemo(() => {
    if (!closedPositionsList || closedPositionsList?.length == 0) return 0
    return _.round(
      _.reduce(
        closedPositionsList,
        (accumulator, order) => {
          // Convert the volume to a number (assuming it's in string format)
          const volume = parseFloat(order?.volume_lots)

          // Add the volume to the accumulator
          return accumulator + volume
        },
        0,
      ),
      3,
    )
  }, [closedPositionsList])

  const totalProfit = useMemo(() => {
    if (!closedPositionsList || closedPositionsList?.length == 0) return 0

    return getTotalPlValue(closedPositionsList) ?? 0
  }, [closedPositionsList])

  const onCloseAllItems = useCallback(() => {
    if (isBlockedCloseTrade || !isTradeAllowed) {
      dispatch(showToast(toastConstructor(toastType.error, t(LN_USER_RESTRICTIONS))))
    } else if (mainTabsValue === mainTabsValues?.positions) {
      dispatch(onCloseAllTransactions(ordersBarPositionsLengthValues.open))
      // positionsList?.map((el) => {
      //   dispatch(onCloseTransaction(el?.id))
      // })
      // dispatch(onCloseTransaction(deleteItemAlert?.id))
      onCloseAlertCloseAll()
    } else if (mainTabsValue === mainTabsValues?.pendingOrders) {
      dispatch(onCloseAllTransactions(ordersBarPositionsLengthValues.pending))
      // pendingOrdersList?.map((el) => {
      //   dispatch(onCloseTransaction(el?.id))
      // })
      onCloseAlertCloseAll()
    }
  }, [dispatch, onCloseAlertCloseAll, mainTabsValue, isBlockedCloseTrade, isTradeAllowed])

  return {
    onSearch,
    totalVolume,
    totalProfit,
    mainTabs,
    onHandleSecondaryTabs,
    onHandleMainTabs,
    isOpenCloseAll,
    onOpenCloseAll,
    onCloseAlertCloseAll,
    mainTabsValues,
    onCloseAllItems,
    sentRequest,
    isBlockedCloseTrade,
  }
}

export default useOrderBottomInfo
