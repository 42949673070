// Modules
import { memo } from 'react'

// Components
import { UserLogoBtn } from 'components'
import SubscriptionPlan from 'components/SubscriptionPlan'
import Balance from 'pages/Dashboard/components/Navbar/Balance'
import MarginInfo from 'pages/Dashboard/components/Navbar/MarginInfo'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Flex } from '@chakra-ui/react'

const Navbar = ({ onUserBtn }) => {
  const { mainBgColor, mainBorderColor } = useCurrentColorTheme()

  return (
    <Box bg={mainBgColor}>
      <Flex
        minH="80px"
        alignItems="center"
        justifyContent="space-between"
        p="15px"
        borderBottom="2px solid"
        borderColor={mainBorderColor}
      >
        <Flex w="100%">
          <Box minW="85px" mr={7} alignItems="center" justifyContent="center" display="flex">
            Logo
          </Box>
          <Flex w="100%" justifyContent="space-between">
            <MarginInfo />
            <Flex gap={3}>
              <Balance />
            </Flex>
          </Flex>
        </Flex>
        <Box ml={5} position="relative">
          <UserLogoBtn onUserBtn={onUserBtn} />
        </Box>

        <SubscriptionPlan ml={2} />
      </Flex>
    </Box>
  )
}

export default memo(Navbar)
