// Modules
import { memo } from 'react'

// Components
import { TextXs } from 'components'
import IncreaseDecreaseBtnsMobileUI from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/InputMarket/IncreaseDecreaseBtnsMobileUI'

// UI
import { Box, Flex, Input } from '@chakra-ui/react'

const InputMarketMobileUI = (props) => {
  const { label, onIncreaseValue, onDecreaseValue, onResetValue, boxProps, ...args } = props

  return (
    <Flex gap={0.5} mb={2} mt={3} flexDirection="column" {...boxProps}>
      <Box flex="1" position="relative">
        <TextXs fontSize="11px" mb={1}>
          {label}
        </TextXs>
        <Input pl="6px" pt="5px" type="number" h="100%" variant="filled" {...args} />
      </Box>
      <Box>
        <IncreaseDecreaseBtnsMobileUI
          onIncreaseValue={onIncreaseValue}
          onDecreaseValue={onDecreaseValue}
          onResetValue={onResetValue}
        />
      </Box>
    </Flex>
  )
}

export default memo(InputMarketMobileUI)
