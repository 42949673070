// Modules
import { call, put, select, takeEvery } from 'redux-saga/effects'

// Languages
import { LN_KYC_DELETE_FIELD, LN_KYC_GET_FIELD_LIST, LN_KYC_SEND_FIELD } from 'locales/constants'
import i18n from 'locales/i18n'

// Components
import { toastConstructor, toastType } from 'components/ToastApp/constants'

// Store
import { setKYCFieldsList } from 'store/actions/KYC/actions'
import { DELETE_KYC_FIELD, GET_KYC_FIELD_LIST, SEND_KYC_FIELD } from 'store/actions/KYC/types'
import { showToast } from 'store/actions/ToastsApp/actions'
import { deleteKYCFieldAPI, getKYCFieldsListAPI, sendKYCFieldAPI } from 'store/api/kyc'

function* getKYCFieldListSaga() {
  const t = i18n.t.bind(i18n)
  const token = yield select((state) => state.auth.token)
  try {
    const kycFieldList = yield call(getKYCFieldsListAPI, { token })
    yield put(setKYCFieldsList(kycFieldList))
  } catch (error) {
    yield put(
      showToast(
        toastConstructor(toastType.error, error?.response?.data?.error, t(LN_KYC_GET_FIELD_LIST)),
      ),
    )
  }
}

function* sendKYCFieldSaga(action) {
  const t = i18n.t.bind(i18n)
  const token = yield select((state) => state.auth.token)
  const { name, file } = action.payload
  try {
    yield call(sendKYCFieldAPI, { token, name, file })
    const kycFieldList = yield call(getKYCFieldsListAPI, { token })
    yield put(setKYCFieldsList(kycFieldList))
  } catch (error) {
    yield put(
      showToast(
        toastConstructor(toastType.error, error?.response?.data?.error, t(LN_KYC_SEND_FIELD)),
      ),
    )
  }
}

function* deleteKYCFieldSaga(action) {
  const t = i18n.t.bind(i18n)
  const token = yield select((state) => state.auth.token)
  const { name } = action.payload
  try {
    yield call(deleteKYCFieldAPI, { token, name })
    const kycFieldList = yield call(getKYCFieldsListAPI, { token, name })
    yield put(setKYCFieldsList(kycFieldList))
  } catch (error) {
    yield put(
      showToast(
        toastConstructor(toastType.error, error?.response?.data?.error, t(LN_KYC_DELETE_FIELD)),
      ),
    )
  }
}

export function* watchKYCInfo() {
  yield takeEvery(GET_KYC_FIELD_LIST, getKYCFieldListSaga)
  yield takeEvery(SEND_KYC_FIELD, sendKYCFieldSaga)
  yield takeEvery(DELETE_KYC_FIELD, deleteKYCFieldSaga)
}
