// Modules
import { memo } from 'react'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Flex } from '@chakra-ui/react'

const WrapperItem = ({ onItemClick, children, active }) => {
  const { isLightThemeSelector, highLightColor } = useCurrentColorTheme()

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      onClick={onItemClick}
      pb={1}
      borderBottom="1px solid"
      borderBottomColor={
        !active
          ? isLightThemeSelector
            ? 'brand.lightAccent'
            : 'navy.20'
          : isLightThemeSelector
          ? 'brand.lightAccent3'
          : 'navy.300'
      }
      transition="0.8s"
      _hover={{
        cursor: 'pointer',
        borderBottomColor: highLightColor,
        transition: '0.8s',
      }}
    >
      {children}
    </Flex>
  )
}
export default memo(WrapperItem)
