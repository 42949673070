// Modules
import { eventChannel } from 'redux-saga'
import { call, put, select, take, takeEvery } from 'redux-saga/effects'

// Store
import {
  wsFollowProfitsChangeValue,
  wsFollowProfitsMessageReceived,
} from 'store/actions/OrderBar/actions'
import {
  WS_CONNECT_FOLLOW_PROFITS,
  WS_DISCONNECT_FOLLOW_PROFITS,
} from 'store/actions/OrderBar/types'
import { URL_SOCKET_PATH, WS_PROFIT_PORT } from 'store/api'

const TYPE_CLOSE = 'CLOSE'
const TYPE_ERROR = 'ERROR'
const TYPE_MESSAGE = 'MESSAGE'
const TYPE_OPEN = 'OPEN'

function* handleMessages(action) {
  // console.log('handleMessages', action)
  yield put(wsFollowProfitsMessageReceived(action.payload))
  if (action?.type === TYPE_MESSAGE) {
    // console.log('TYPE_MESSAGE', action)
    yield put(wsFollowProfitsChangeValue(action.payload))
  }
}

// Saga
export function* watchProfitTransactionsWebSocket() {
  while (true) {
    yield take(WS_CONNECT_FOLLOW_PROFITS)
    const token = yield select((state) => state.auth.token)

    const currentTradingAccount = yield select((state) => state.user.currentTradingAccount)
    const userTradingAccountId = currentTradingAccount?.trading_account_id

    const socket = yield call(connectWebSocket, { token, userTradingAccountId })
    yield takeEvery(socket, handleMessages)
    yield take(WS_DISCONNECT_FOLLOW_PROFITS)
    socket.close()
  }
}

function connectWebSocket(props) {
  const { token, userTradingAccountId } = props

  return eventChannel((emitter) => {
    if (!token || !userTradingAccountId) {
      return () => {}
    }

    const socket = new WebSocket(
      `ws://${URL_SOCKET_PATH}:${WS_PROFIT_PORT}?trading_account=${userTradingAccountId}&token=${token}`,
    )

    socket.onopen = () => {
      // console.log('WebSocket connection opened')
      emitter({ type: TYPE_OPEN })
    }

    socket.onmessage = (event) => {
      // console.log('Received message:', event.data)
      emitter({ type: TYPE_MESSAGE, payload: JSON.parse(event.data) })
    }

    socket.onclose = () => {
      // console.log('WebSocket connection closed')
      emitter({ type: TYPE_CLOSE })

      // emitter.close() // Close the channel
    }

    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
      emitter({ type: TYPE_ERROR, payload: error })
    }

    // Return unsubscribe function
    return () => {
      // console.log('Socket off')
      socket.close()
    }
  })
}
