// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const tokenSelector = createSelector(selfSelector, (self) => self.token)

export const isAuthenticatedSelector = createSelector(selfSelector, (self) => self.isAuthenticated)

export const loadingSelector = createSelector(selfSelector, (self) => self.loading)
export const errorSelector = createSelector(selfSelector, (self) => self.error)

export const forgotPasswordSelector = createSelector(selfSelector, (self) => self.forgotPassword)
export const resetPasswordSelector = createSelector(selfSelector, (self) => self.resetPassword)
