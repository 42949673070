export const mockList = [
  {
    id: '0',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'card',
    address: '4111111111111111',
    description: {
      cardHolderName: 'William Smith',
    },
    amount: '23',
    status: 'waiting',
  },
  {
    id: '1',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'ethereum',
    address: '0x742d35Cc6634C0532925a3b844Bc454e4438f44e',
    amount: '25',
    status: 'failed',
  },
  {
    id: '2',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bitcoin',
    address: '1Lbcfr7sAHTD9CgdQo3HTMTkV8LK4ZnX71',
    amount: '29635.12',
    status: 'success',
  },
  {
    id: '21',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'usdt',
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    amount: '29',
    status: 'success',
  },
  {
    id: '3',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '4',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '5',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '6',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '7',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '8',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '9',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '10',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '11',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '12',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
  {
    id: '13',
    date: '2024-02-18 12:39:38',
    currency: 'EUR',
    typeWithdrawal: 'bank',
    description: {
      cardHolderName: 'John Smith',
      swiftCode: 'COBADEFF103',
      accountNumber: '37040044',
    },
    address: 'DE89370400440532013000',

    amount: '30',
    status: 'waiting',
  },
]
