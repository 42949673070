// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { GiResize } from 'react-icons/gi'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

// Languages
import {
  LN_HISTORY_SEARCH_BY_INSTRUMENT_TITLE,
  LN_HISTORY_TOTAL_PROFIT,
  LN_HISTORY_TOTAL_VOLUME,
} from 'locales/constants'

// Components
import { SearchInput, TextXs } from 'components'
import CloseAllBtn from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/CloseAllBtn'
import MainTabs from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/MainTabs'
import SecondaryTabs from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/SecondaryTabs'
import SelectFilterTime from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/SelectFilterTime'
import { mainTabsValues } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { formatDigitsWithSpaces, roundTwoDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// UI
import { Flex, Icon, IconButton } from '@chakra-ui/react'

const HeaderBarUI = (props) => {
  const {
    onBottomBlockOpen,
    onBottomBlockClose,
    isBottomNavOpen,
    mainTabsValue,
    loadingBar,
    onOpenFullSize,
    searchValue,
    onSearch,
    positionsList,
    pendingOrdersList,
    totalVolume,
    totalProfit,
    userTradingAccountSymbol,
  } = props

  const { t } = useTranslation()
  const { mainTextColor } = useCurrentColorTheme()

  return (
    <>
      <Flex p="2px 5px 2px 8px" h="28px" alignItems="center" justifyContent="space-between">
        <Flex gap={2} alignItems="center">
          <IconButton
            icon={<Icon as={isBottomNavOpen ? IoIosArrowDown : IoIosArrowUp} />}
            onClick={isBottomNavOpen ? onBottomBlockClose : onBottomBlockOpen}
            aria-label="toggle-orders"
            variant="link"
            size="sm"
          />
          <MainTabs />
        </Flex>
        <Flex gap={1} alignItems="center">
          {isBottomNavOpen && (
            <Flex gap={1} alignItems="center">
              <SecondaryTabs isDisabled={loadingBar} />
              {mainTabsValue === mainTabsValues?.closedPositions && (
                <SelectFilterTime isDisabled={loadingBar} />
              )}
              <SearchInput
                searchedValue={searchValue}
                setSearchedValue={onSearch}
                h="22px"
                w="97%"
                paddingLeft={2}
                fontSize="12px"
                isCleanText={false}
                placeholder={t(LN_HISTORY_SEARCH_BY_INSTRUMENT_TITLE)}
                _placeholder={{ color: mainTextColor }}
                disabled={loadingBar}
              />
            </Flex>
          )}
          {isBottomNavOpen && (
            <CloseAllBtn
              isDisabled={
                loadingBar ||
                (mainTabsValue === mainTabsValues?.positions
                  ? positionsList?.length === 0
                  : pendingOrdersList?.length === 0)
              }
            />
          )}

          {mainTabsValue === mainTabsValues?.closedPositions && isBottomNavOpen && (
            <>
              <TextXs color={mainTextColor} fontWeight="500" opacity={loadingBar ? 0.4 : 1}>
                {t(LN_HISTORY_TOTAL_VOLUME)}: {totalVolume}
              </TextXs>
              <TextXs color={mainTextColor} fontWeight="500" opacity={loadingBar ? 0.4 : 1}>
                {t(LN_HISTORY_TOTAL_PROFIT)}:{' '}
                {formatDigitsWithSpaces(roundTwoDigitsAfterDots(totalProfit))}{' '}
                {userTradingAccountSymbol}
              </TextXs>
            </>
          )}
          {isBottomNavOpen && (
            <IconButton
              icon={<Icon as={GiResize} />}
              onClick={onOpenFullSize}
              aria-label="open-modal"
              variant="link"
              size="sm"
              ml={2}
              isDisabled={loadingBar}
            />
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default memo(HeaderBarUI)
