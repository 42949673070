// Modules
import { memo } from 'react'

const TradeIcon = ({ w = 25, h = 25, color = '#000000' }) => {
  return (
    <>
      <svg width={w} height={h} x="0" y="0" viewBox="0 0 512 512" className="">
        <path
          fillRule="evenodd"
          d="M409.318 319.626a89.682 89.682 0 1 1-89.682 89.682 89.682 89.682 0 0 1 89.682-89.682zM84.383 198.935a18.174 18.174 0 1 1-18.174 18.173 18.174 18.174 0 0 1 18.174-18.173zm29.435 98.181H51.812v94.192h62.006zm352.37 27.891V137.295h-62.006v170.46a101.323 101.323 0 0 1 62.006 17.252zM378.1 234.1h-62.011v134.576a101.983 101.983 0 0 1 62.011-56.157zM290 170.276h-62v221.032h62zm-88.09 86.118H139.9v134.914h62.01zm105.907 158.914H19a6 6 0 0 1 0-12h288.817zM103.531 193.792l40.669-33.136a30.333 30.333 0 0 0 7.793 9.459q-20.424 16.4-40.884 32.981a30.326 30.326 0 0 0-7.577-9.3zm87-70.1L231.3 91.819a30.3 30.3 0 0 0 7.393 9.438q-20.454 16.069-40.67 32.114a30.312 30.312 0 0 0-7.5-9.682zm97.46-40.011 33.621 13.351a30.005 30.005 0 0 0-4.416 11.106l-33.62-13.352a29.989 29.989 0 0 0 4.415-11.105zm76.038 4.986 64.4-62.094-25.283 2.412a5.98 5.98 0 1 1-1.125-11.906l42.327-4.039a5.981 5.981 0 0 1 6.479 6.83l-5.5 41.546a5.98 5.98 0 1 1-11.859-1.547l3.27-24.678-64.405 62.086a30.369 30.369 0 0 0-8.309-8.613zm-17.135 6.661a18.174 18.174 0 1 0 18.169 18.172 18.174 18.174 0 0 0-18.174-18.174zm-88.6-35.184a18.174 18.174 0 1 1-18.173 18.173 18.174 18.174 0 0 1 18.173-18.173zm-87.381 68.3a18.174 18.174 0 1 1-18.174 18.174 18.174 18.174 0 0 1 18.174-18.174zM431.272 396.6c0-9.1-7.292-15.916-15.954-18v-7.43a6 6 0 0 0-12 0v7.421c-8.662 2.089-15.954 8.9-15.954 18 0 24.793 31.908 13.778 31.908 25.425a5.675 5.675 0 0 1-2.4 4.3c-6.313 5.03-17.509 1.546-17.509-4.3a6 6 0 0 0-12 0c0 9.1 7.292 15.916 15.954 18v7.42a6 6 0 1 0 12 0v-7.42c8.661-2.089 15.954-8.906 15.954-18 0-24.793-31.908-13.777-31.908-25.425a5.674 5.674 0 0 1 2.4-4.3c6.313-5.03 17.509-1.546 17.509 4.3a6 6 0 0 0 12 0z"
          fill={color}
          opacity="1"
          data-original="#000000"
          className=""
        />
      </svg>
      {/*<svg width={w} height={h} x="0" y="0" viewBox="0 0 512 512" className="">*/}
      {/*  <path*/}
      {/*    fillRule="evenodd"*/}
      {/*    d="M457.187 325.257V140.295h-50v170.337a95.207 95.207 0 0 1 50.005 14.625zM428.272 393.6a6 6 0 0 1-12 0c0-5.848-11.2-9.333-17.509-4.3a5.672 5.672 0 0 0-2.4 4.3c0 11.648 31.908.632 31.908 25.425 0 9.1-7.293 15.916-15.954 18v7.42a6 6 0 0 1-12 0v-7.42c-8.662-2.09-15.954-8.905-15.954-18a6 6 0 0 1 12 0c0 5.848 11.2 9.332 17.509 4.3a5.675 5.675 0 0 0 2.4-4.3c0-11.648-31.908-.632-31.908-25.425 0-9.1 7.292-15.916 15.954-18v-7.43a6 6 0 0 1 12 0v7.421c8.662 2.089 15.954 8.9 15.954 18zm-33.09-82.328V134.295a6 6 0 0 1 6-6h62.005a6 6 0 0 1 6 6v199.886a95.682 95.682 0 1 1-158.366 78.127H16a6 6 0 0 1 0-12h26.812V294.115a6 6 0 0 1 6-6h62.006a6 6 0 0 1 6 6v106.193H130.9V253.394a6 6 0 0 1 6-6h62.01a6 6 0 0 1 6 6v146.914H219V167.276a6 6 0 0 1 6-6h62a6 6 0 0 1 6 6v233.032h14.086V231.1a6 6 0 0 1 6-6H375.1a6 6 0 0 1 6 6v82.887a95.3 95.3 0 0 1 14.087-2.719zm-76.093 55.663a96.048 96.048 0 0 1 50.011-48.798V237.1h-50.011v129.831zm87.229-44.305a83.682 83.682 0 1 1-83.682 83.682 83.68 83.68 0 0 1 83.682-83.682zM81.383 189.935a24.065 24.065 0 0 1 14.38 4.741l50.2-40.9a24.177 24.177 0 0 1 36.714-29.295l50.467-39.444a24.177 24.177 0 1 1 46.118-6.626L324.324 96.3a24.18 24.18 0 0 1 32.276-6.368l68.822-66.362-25.283 2.412a5.98 5.98 0 1 1-1.125-11.906l42.327-4.039a5.981 5.981 0 0 1 6.479 6.83l-5.5 41.546a5.98 5.98 0 1 1-11.859-1.547l3.27-24.678-68.826 66.358c9.066 15.9-2.335 36.126-21.016 36.126a24.183 24.183 0 0 1-23.978-27.266l-45.058-17.893a24.181 24.181 0 0 1-34.339 4.935l-50.467 39.445a24.177 24.177 0 0 1-36.52 29.149l-50.2 40.905a24.177 24.177 0 1 1-21.94-14.012zm0 12a12.174 12.174 0 1 0 12.173 12.173 12.171 12.171 0 0 0-12.173-12.173zm262.506-103.61a12.174 12.174 0 1 0 12.174 12.175 12.171 12.171 0 0 0-12.174-12.173zm-88.6-35.184a12.174 12.174 0 1 0 12.174 12.173 12.17 12.17 0 0 0-12.174-12.173zm-87.381 68.3a12.174 12.174 0 1 0 12.174 12.174 12.171 12.171 0 0 0-12.174-12.174zM281 173.276h-50v227.032h50zm-88.09 86.118H142.9v140.914h50.01zm-88.092 40.722H54.812v100.192h50.006z"*/}
      {/*    fill={color}*/}
      {/*    opacity="1"*/}
      {/*    data-original="#000000"*/}
      {/*  />*/}
      {/*</svg>*/}
    </>
  )
}

export default memo(TradeIcon)
