// Modules
import Cookies from 'js-cookie'
import { put } from 'redux-saga/effects'

// Store
import { onNonAuth } from 'store/actions/Auth/actions'
import { cookieAuthToken } from 'store/api'

export function* onUserNotAuth(error) {
  if (error?.response?.status === 403 || error?.response?.status === 404) {
    // Clear token from cookies
    Cookies.remove(cookieAuthToken)

    yield put(onNonAuth())
  }
}
