// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Components
import LanguageDropdown from 'components/LanguageDropdown'
import ThemeSwitcher from 'components/ThemeSwitcher'
import NavButton from 'pages/Dashboard/components/LeftNavbar/NavButton'
import { NavList } from 'pages/Dashboard/components/LeftNavbar/constants'

// Constants
import { LeftNavBarIconsWidth } from 'constants/index'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Flex, Stack } from '@chakra-ui/react'

const LeftNavbar = (props) => {
  const { isActiveNav, onChooseItem } = props
  const { mainBorderColor, mainBgColor } = useCurrentColorTheme()
  const { t } = useTranslation()

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column"
      w={LeftNavBarIconsWidth + 'px'}
      minW={LeftNavBarIconsWidth + 'px'}
      maxW={LeftNavBarIconsWidth + 'px'}
      position="relative"
      borderRight="3px solid"
      borderRightColor={mainBorderColor}
      pb={5}
      bg={mainBgColor}
    >
      <Stack w="100%" direction="column" align="center" gap={0}>
        {NavList.map((el) => (
          <NavButton
            key={el.id}
            id={el.id}
            icon={el.icon}
            title={t(el.title)}
            onChoose={onChooseItem}
            isActive={isActiveNav === el.title}
          />
        ))}
      </Stack>

      <Box m={1}>
        {/*<LightModeIcon />*/}
        {/*<DarkModeIcon />*/}

        <Box mb={3}>
          <ThemeSwitcher />
        </Box>
        <LanguageDropdown />
      </Box>
    </Flex>
  )
}

export default memo(LeftNavbar)
