// Types
import {
  GET_DEPOSIT_USER_HISTORY,
  GET_USER_DEPOSIT_FIELDS,
  SET_DEPOSIT_MODAL_ITEM_INFO,
  SET_DEPOSIT_USER_HISTORY,
  SET_IS_DEPOSIT_MODAL_INFO_OPEN,
  SET_LOADING_DEPOSIT_USER_HISTORY,
  SET_LOADING_USER_DEPOSIT_FIELDS,
  SET_USER_DEPOSIT_FIELDS,
} from 'store/actions/UserDeposit/types'

export const getUserDepositFields = () => ({
  type: GET_USER_DEPOSIT_FIELDS,
})

export const setUserDepositFields = (payload) => ({
  type: SET_USER_DEPOSIT_FIELDS,
  payload: payload,
})
export const setLoadingUserDepositFields = (payload) => ({
  type: SET_LOADING_USER_DEPOSIT_FIELDS,
  payload: payload,
})

export const getDepositUserHistory = (payload) => ({
  type: GET_DEPOSIT_USER_HISTORY,
  payload: payload,
})

export const setDepositUserHistory = (payload) => ({
  type: SET_DEPOSIT_USER_HISTORY,
  payload: payload,
})

export const setLoadingDepositUserHistory = (payload) => ({
  type: SET_LOADING_DEPOSIT_USER_HISTORY,
  payload: payload,
})

export const setIsDepositModalInfoOpen = (payload) => ({
  type: SET_IS_DEPOSIT_MODAL_INFO_OPEN,
  payload: payload,
})

export const setDepositModalInfoItem = (payload) => ({
  type: SET_DEPOSIT_MODAL_ITEM_INFO,
  payload: payload,
})
