// Modules
import { AiOutlineDollar } from 'react-icons/ai'
import { BsPassport } from 'react-icons/bs'
import { GrDownload } from 'react-icons/gr'
import { PiUserCirclePlusFill } from 'react-icons/pi'

// Languages
import {
  LN_USER_DEPOSIT_TAB,
  LN_USER_PERSONAL_INFORMATION_TAB,
  LN_USER_VERIFICATION_TAB,
  LN_USER_WITHDRAWAL_TAB,
} from 'locales/constants'

export const sidebarConstants = {
  personalInfo: 'personalInfo',
  deposit: 'deposit',
  withdrawal: 'withdrawal',
  verification: 'verification',
}

export const sidebarList = [
  {
    id: sidebarConstants.personalInfo,
    title: LN_USER_PERSONAL_INFORMATION_TAB,
    icon: PiUserCirclePlusFill,
  },
  {
    id: sidebarConstants.deposit,
    title: LN_USER_DEPOSIT_TAB,
    icon: GrDownload,
  },
  {
    id: sidebarConstants.withdrawal,
    title: LN_USER_WITHDRAWAL_TAB,
    icon: AiOutlineDollar,
  },
  {
    id: sidebarConstants.verification,
    title: LN_USER_VERIFICATION_TAB,
    icon: BsPassport,
  },
]
