export const withdrawalDetailsConstants = {
  bank: 'bank',
  bitcoin: 'bitcoin',
  ethereum: 'ethereum',
  usdt: 'usdt',
  card: 'card',
}

export const withdrawalDetailsList = [
  {
    id: withdrawalDetailsConstants.bank,
    title: 'Bank',
  },
  {
    id: withdrawalDetailsConstants.bitcoin,
    title: 'Bitcoin',
  },
  {
    id: withdrawalDetailsConstants.ethereum,
    title: 'Ethereum',
  },
  {
    id: withdrawalDetailsConstants.usdt,
    title: 'USDT',
  },
  {
    id: withdrawalDetailsConstants.card,
    title: 'Card',
  },
]
