const trackColor = 'var(--chakra-colors-gray-400)'
const bgColor = '#728fea'

export const getScrollbarCss = () => ({
  '&::-webkit-scrollbar': {
    width: '7px',
  },
  '&::-webkit-scrollbar-track': {
    background: trackColor,
    width: '7px',
    borderRadius: '24px',
  },
  '&::-webkit-scrollbar-thumb': {
    height: '60px',
    background: bgColor,
    borderRadius: '24px',
  },
})
export const getMobileScrollbarCss = () => ({
  '&::-webkit-scrollbar': {
    width: '3px',
  },
  '&::-webkit-scrollbar-track': {
    background: trackColor,
    width: '3px',
    borderRadius: '24px',
  },
  '&::-webkit-scrollbar-thumb': {
    height: '60px',
    background: bgColor,
    borderRadius: '24px',
  },
})

export const getHorizontalScrollbarCss = () => ({
  '&::-webkit-scrollbar': {
    width: '5px',
    height: '5px',
  },
  '&::-webkit-scrollbar-track': {
    background: trackColor,
    width: '5px',
    height: '5px',
    borderRadius: '24px',
  },
  '&::-webkit-scrollbar-thumb': {
    height: '30px',
    background: bgColor,
    borderRadius: '24px',
  },
})
