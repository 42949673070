// Modules
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

// Languages
import { LN_DEPOSIT_TAB_HISTORY_INFO_BTN } from 'locales/constants'

// Components
import { TextMd } from 'components'
import DescriptionBtn from 'components/SmallList/DescriptionBtn'
import { statusConstants } from 'pages/User/components/Content/Deposit/DepositHistory/TableHistory/constants'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Utils
import { formatDateToPrettierFullDateTime } from 'utils/dateTransform'
import { formatDigitsWithSpaces, roundDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// Store
import {
  setDepositModalInfoItem,
  setIsDepositModalInfoOpen,
} from 'store/actions/UserDeposit/actions'

// UI
import { Flex, Icon, Td, Tr } from '@chakra-ui/react'

export const infoColumnWidth = '30px'

const TableRow = (props) => {
  const { row } = props

  const { isLightTheme } = useCurrentColorTheme()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onInfoBtn = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(setDepositModalInfoItem(row))
      dispatch(setIsDepositModalInfoOpen(true))
    },
    [row, dispatch],
  )

  const statusItem = useCallback((status) => {
    const title = t(status)
    switch (status) {
      case statusConstants?.[1]:
        return <TextMd color="red.200">{title}</TextMd>
      case statusConstants?.[2]:
        return <TextMd color="blue.200">{title}</TextMd>
      case statusConstants?.[3]:
        return <TextMd color="gray.200">{title}</TextMd>
      case statusConstants?.[4]:
        return <TextMd color="red.200">{title}</TextMd>
      case statusConstants?.[5]:
        return <TextMd color="green.200">{title}</TextMd>
      default:
        return <TextMd>-</TextMd>
    }
  }, [])

  return (
    <Tr
      _hover={{
        transition: '0.8s',
        background: isLightTheme ? 'gray.100' : 'gray.700',
      }}
    >
      <Td>{row?.date ? formatDateToPrettierFullDateTime(row?.date) : '-'}</Td>
      <Td>{row?.amount ? formatDigitsWithSpaces(roundDigitsAfterDots(row?.amount, 2)) : '0'}</Td>
      <Td>
        <Flex gap={2} alignItems="center">
          <Icon as={row?.typeFinanceIcon} />
          {t(row?.typeFinance)}
        </Flex>
      </Td>
      <Td>{row?.currency}</Td>
      <Td>{row?.depositType ? row?.depositType?.toUpperCase() : '-'}</Td>
      <Td>{statusItem(row?.status)}</Td>
      <Td style={{ width: infoColumnWidth }}>
        {!row?.description ? null : (
          <DescriptionBtn onClick={onInfoBtn} tooltipTitle={t(LN_DEPOSIT_TAB_HISTORY_INFO_BTN)} />
        )}
      </Td>
    </Tr>
  )
}

export default memo(TableRow)
