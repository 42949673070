// Modules
import { lazy, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import { HeadingLg } from 'components'

// Hooks
import useCheckIsActiveTradeItem from 'hooks/useCheckIsActiveTradeItem'
import useCheckIsFavouriteTradeItem from 'hooks/useCheckIsFavouriteTradeItem'
import useTradeInfoModal from 'hooks/useTradeInfoModal'

// Utils
import { getScrollbarCss } from 'utils/scrollbarHelper'

// Store
import { addActiveTradeItem, setActiveTradeItem } from 'store/actions/ActiveTradeItems/actions'
import { addFavouriteItem } from 'store/actions/FavouritesCurrency/actions'
import {
  chosenInfoModalTradeItemSelector,
  isOpenInfoModalSelector,
} from 'store/actions/InfoModalTradeItem/selectors'

// UI
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

// Lazy Components
const BookmarkBtn = lazy(() => import('components/SmallList/BookmarkBtn'))
const InfoModalContent = lazy(() => import('pages/Dashboard/components/InfoModal/InfoModalContent'))

const InfoModal = () => {
  const { checkIsFavouriteTradeItem } = useCheckIsFavouriteTradeItem()
  const { onCloseTradeInfoModal: onClose } = useTradeInfoModal()

  const dispatch = useDispatch()
  const isOpen = useSelector(isOpenInfoModalSelector)
  const item = useSelector(chosenInfoModalTradeItemSelector)

  const { isActiveTradeItem } = useCheckIsActiveTradeItem({ id: item?.id })

  const onBookmarkBtn = useCallback(() => {
    dispatch(addFavouriteItem(item))
  }, [dispatch, item])

  const onAddItem = useCallback(() => {
    dispatch(setActiveTradeItem(item))
    dispatch(addActiveTradeItem(item))
  }, [dispatch, item])

  return (
    <Modal onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" size="full">
      <ModalOverlay />
      <ModalContent h="100%">
        <ModalHeader display="flex" justifyContent="space-between" pt={9} pb={2}>
          <Flex gap={1} alignItems="center">
            <BookmarkBtn
              isActive={checkIsFavouriteTradeItem(item?.id, item?.name)}
              onClick={onBookmarkBtn}
              w="24px"
              h="24px"
            />
            <HeadingLg fontSize="25px">{item?.name}</HeadingLg>
          </Flex>
          <Box>
            <Button
              onClick={onAddItem}
              variant="outline"
              colorScheme={!isActiveTradeItem ? 'blue' : 'gray'}
            >
              {!isActiveTradeItem ? 'Trade Now' : 'Have already traded'}
            </Button>
          </Box>
        </ModalHeader>
        <ModalCloseButton top={1} right={1} />
        <ModalBody overflowY="auto" pr={2} mr={3} mb={2} css={getScrollbarCss()}>
          <InfoModalContent />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default memo(InfoModal)
