// Types
import {
  INIT_SOCKET,
  SOCKET_CLOSE,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
  SOCKET_MESSAGE_RECEIVED,
} from 'store/actions/SocketGraph/types'

const initialState = {
  socketChannel: null,
  isConnected: false,
  messages: [],
  error: null,
}

const socketGraphReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SOCKET:
      return {
        ...state,
        socketChannel: action.payload.socketChannel,
        isConnected: true,
        error: null,
      }
    case SOCKET_CLOSE:
      return {
        ...state,
        socketChannel: null,
        isConnected: false,
        error: null,
      }
    case SOCKET_MESSAGE_RECEIVED:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      }
    case SOCKET_CONNECTED:
      return {
        ...state,
        isConnected: true,
        error: null,
      }
    case SOCKET_DISCONNECTED:
      return {
        ...state,
        isConnected: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}
export default socketGraphReducer
