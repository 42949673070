// Modules
import { memo } from 'react'

// Components
import LanguageDropdown from 'components/LanguageDropdown'
import ThemeSwitcher from 'components/ThemeSwitcher'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Flex } from '@chakra-ui/react'

const SliderNavbarFooter = ({ ...args }) => {
  const { mainBgColor } = useCurrentColorTheme()

  return (
    <Flex
      position="absolute"
      bottom="0"
      w="96%"
      justifyContent="space-between"
      pr={4}
      pb="17px"
      bg={mainBgColor}
      {...args}
    >
      <ThemeSwitcher />
      <Box width="69px">
        <LanguageDropdown />
      </Box>
    </Flex>
  )
}
export default memo(SliderNavbarFooter)
