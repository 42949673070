// Modules
import { memo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_WARNING_ALERT_CANCEL_BTN } from 'locales/constants'

// UI
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react'

const AlertWarning = (props) => {
  const { isOpen, onClose, title = '', content, actionTitleBtn, onActionBtn } = props
  const cancelRef = useRef()

  const { t } = useTranslation()

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          <AlertDialogBody>{content}</AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onClose}>{t(LN_WARNING_ALERT_CANCEL_BTN)}</Button>
            <Button colorScheme="red" onClick={onActionBtn} ml={3}>
              {actionTitleBtn}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default memo(AlertWarning)
