// Modules
import { memo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Components
import Navbar from 'pages/Dashboard/components/Navbar/Navbar'
import NavbarMobile from 'pages/Dashboard/components/Navbar/NavbarMobile'

// Constants
import { routers } from 'navigation/constants'

// Store
import { isMobileSelector } from 'store/actions/User/selectors'

const NavbarContainer = () => {
  const history = useHistory()

  const isMobile = useSelector(isMobileSelector)

  const onUserBtn = useCallback(() => {
    history.push('/' + routers.user)
  }, [history])

  return isMobile ? <NavbarMobile onUserBtn={onUserBtn} /> : <Navbar onUserBtn={onUserBtn} />
}

export default memo(NavbarContainer)
