// Modules
import { memo, useRef } from 'react'

// Components
import { zIndex } from 'theme/globalStyles'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Slide, useOutsideClick } from '@chakra-ui/react'

const SliderNavbarWrapperUI = ({ isOpen, w, children, onClose }) => {
  const { mainBgColor } = useCurrentColorTheme()

  const ref = useRef()

  // Close sidebar if user clicks outside of the sidebar content
  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  })

  return (
    <>
      {/*{isOpen && (*/}
      {/*  <Box*/}
      {/*    pos="fixed"*/}
      {/*    top={0}*/}
      {/*    left={0}*/}
      {/*    right={0}*/}
      {/*    bottom={0}*/}
      {/*    bg="blackAlpha.600" // Semi-transparent black background*/}
      {/*    zIndex={999}*/}
      {/*    onClick={onClose} // Close sidebar when the overlay is clicked*/}
      {/*  />*/}
      {/*)}*/}
      <Slide in={isOpen} direction="right" zIndex="1000" style={{ zIndex: '1000' }}>
        <Box
          ref={ref}
          pos="fixed"
          top={0}
          right={0}
          bottom={0}
          width={w || { base: '100%', sm: '300px' }}
          bg={mainBgColor}
          boxShadow="md"
          p={4}
          zIndex={zIndex}
        >
          {children}
        </Box>
      </Slide>
    </>
  )
}

export default memo(SliderNavbarWrapperUI)
