// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_NAVBAR_MARGIN_INFO_AVAILABLE,
  LN_NAVBAR_MARGIN_INFO_CREDIT,
  LN_NAVBAR_MARGIN_INFO_EQUITY,
  LN_NAVBAR_MARGIN_INFO_LEVEL,
  LN_NAVBAR_MARGIN_INFO_PL,
  LN_NAVBAR_MARGIN_INFO_TITLE,
  LN_NAVBAR_MARGIN_INFO_USAGE,
} from 'locales/constants'

// Components
import { TextSm, TextXs } from 'components'
import WrapperInfoItem from 'components/InfoItem/WrapperInfoItem'
import { prettifyValue } from 'pages/Dashboard/components/Navbar/MarginInfo/index'
import { TabBase } from 'pages/User/components/Content/Deposit'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Flex } from '@chakra-ui/react'

const MarginInfoMobile = (props) => {
  const { levelValue, usageValue, availableValue, equityValue, plValue, getCurrencySymbol } = props
  const { t } = useTranslation()

  return (
    <Flex flexDirection="column">
      <TextXs fontSize="12px" fontWeight={600} pb={2}>
        {t(LN_NAVBAR_MARGIN_INFO_TITLE)}
      </TextXs>
      <Flex gap={2} flexDirection="column">
        <InfoItem title={t(LN_NAVBAR_MARGIN_INFO_LEVEL)} value={prettifyValue(levelValue) + '%'} />
        <InfoItem
          title={t(LN_NAVBAR_MARGIN_INFO_USAGE)}
          value={getCurrencySymbol + ' ' + prettifyValue(usageValue)}
        />
        <InfoItem
          title={t(LN_NAVBAR_MARGIN_INFO_AVAILABLE)}
          value={getCurrencySymbol + ' ' + prettifyValue(availableValue)}
        />
        <InfoItem
          title={t(LN_NAVBAR_MARGIN_INFO_EQUITY)}
          value={getCurrencySymbol + ' ' + prettifyValue(equityValue)}
        />
        <InfoItem
          title={t(LN_NAVBAR_MARGIN_INFO_PL)}
          value={getCurrencySymbol + ' ' + prettifyValue(plValue)}
        />
        {/*<InfoItem title={t(LN_NAVBAR_MARGIN_INFO_CREDIT)} value={'€ ' + creditValue} />*/}
      </Flex>
    </Flex>
  )
}

export default memo(MarginInfoMobile)

const InfoItem = memo((props) => {
  const { title, value, ...rest } = props

  const { isLightTheme } = useCurrentColorTheme()

  return (
    <WrapperInfoItem flexDirection="row" alignItems="center" gap={2} {...rest}>
      <TextXs fontWeight="500">{title}</TextXs>
      <TextXs fontWeight="600" color={isLightTheme ? 'brand.textColor2Light' : 'white'}>
        {value ? value : '-'}
      </TextXs>
    </WrapperInfoItem>
  )
})

InfoItem.displayName = 'InfoItem'
