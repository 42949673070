// Modules
import { memo } from 'react'

// UI
import { Flex, Spinner } from '@chakra-ui/react'

const LoadingBlock = (props) => {
  const { spinnerStyles, ...args } = props

  return (
    <Flex alignItems="center" justifyContent="center" {...args}>
      <Spinner size="md" {...spinnerStyles} />
    </Flex>
  )
}

export default memo(LoadingBlock)
