// Modules
import { memo, useMemo } from 'react'
import { CiTimer } from 'react-icons/ci'
import { LiaExchangeAltSolid } from 'react-icons/lia'
import { PiHandDeposit } from 'react-icons/pi'

// Languages
import {
  LN_PLATFORM_RESTRICTIONS_ALLOW_DEPOSIT_WITHOUT_VERIFICATION,
  LN_PLATFORM_RESTRICTIONS_ALLOW_OPEN_TRADE_IN_NON_TRADING_TIME,
  LN_PLATFORM_RESTRICTIONS_IS_TRADE_ALLOWED_FLAG,
  LN_PLATFORM_RESTRICTIONS_TITLE,
} from 'locales/constants'

// Components
import RestrictionBox from 'pages/User/components/Content/PersonalInfo/Restrictions/RestrictionBox'

// Hooks
import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'

// UI
import { Icon } from '@chakra-ui/react'

const isTradeAllowedFlag = 'isTradeAllowed'
const allowOpenTradeInNonTradingTimeFlag = 'allowOpenTradeInNonTradingTime'
const allowDepositWithoutVerificationFlag = 'allowDepositWithoutVerification'

const keysTitles = {
  [isTradeAllowedFlag]: {
    title: LN_PLATFORM_RESTRICTIONS_IS_TRADE_ALLOWED_FLAG,
    icon: <Icon as={LiaExchangeAltSolid} color="#000000" w="16px" h="16px" />,
  },
  [allowOpenTradeInNonTradingTimeFlag]: {
    title: LN_PLATFORM_RESTRICTIONS_ALLOW_OPEN_TRADE_IN_NON_TRADING_TIME,
    icon: <Icon as={CiTimer} color="#000000" w="16px" h="16px" />,
  },
  [allowDepositWithoutVerificationFlag]: {
    title: LN_PLATFORM_RESTRICTIONS_ALLOW_DEPOSIT_WITHOUT_VERIFICATION,
    icon: <Icon as={PiHandDeposit} color="#000000" w="16px" h="16px" />,
  },
}

const RestrictionPlatform = () => {
  const { isTradeAllowed, allowOpenTradeInNonTradingTime, allowDepositWithoutVerification } =
    useCheckUserRestrictions()

  const list = useMemo(() => {
    if (isTradeAllowed && !allowOpenTradeInNonTradingTime && !allowDepositWithoutVerification)
      return []

    let arr = []
    if (!isTradeAllowed) {
      arr.push(isTradeAllowedFlag)
    }
    if (allowOpenTradeInNonTradingTime) {
      arr.push(allowOpenTradeInNonTradingTimeFlag)
    }
    if (allowDepositWithoutVerification) {
      arr.push(allowDepositWithoutVerificationFlag)
    }
    return arr
  }, [isTradeAllowed, allowOpenTradeInNonTradingTime, allowDepositWithoutVerification])

  if (!list || list?.length === 0) {
    return null
  }

  return (
    <RestrictionBox list={list} title={LN_PLATFORM_RESTRICTIONS_TITLE} keysTitles={keysTitles} />
  )
}

export default memo(RestrictionPlatform)
