// Types
import { HIDE_TOAST, SHOW_TOAST } from 'store/actions/ToastsApp/types'

export const showToast = (payload) => ({
  type: 'SHOW_TOAST',
  payload: payload,
})

export const hideToast = () => ({
  type: 'HIDE_TOAST',
})
