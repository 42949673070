// Modules
import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RxCross2 } from 'react-icons/rx'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_TRADES_BAR_DELETE_TRADE, LN_TRADES_BAR_SET_ACTIVE_TRADE } from 'locales/constants'

// Components
import { TextXs } from 'components'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useMarketWatchAccordionList from 'hooks/useMarketWatchAccordionList'

// Store
import { deleteTradeItem, setActiveTradeItem } from 'store/actions/ActiveTradeItems/actions'
import { activeTradeItemsListSelector } from 'store/actions/ActiveTradeItems/selectors'

// UI
import { Box, Button, Flex, Icon, Tooltip, useBreakpointValue } from '@chakra-ui/react'

export const DEFAULT_CURRENCY_VALUE = 'BTC-USD'

const ActiveTradeButton = (props) => {
  const { data, active, onActive } = props

  const { isLightTheme } = useCurrentColorTheme()
  const isSmallScreen = useBreakpointValue({ base: true, sm: true, md: false })

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const activeTradeItemsList = useSelector(activeTradeItemsListSelector)
  const { getCurrencyCategoryByName } = useMarketWatchAccordionList()

  const [isHovered, setIsHovered] = useState(false)

  const onClick = useCallback(() => {
    onActive(data)
  }, [data, onActive])

  const onDelete = useCallback(
    (event) => {
      event.stopPropagation()
      const itemIdToDelete = data
      const itemIndexToDelete = activeTradeItemsList.findIndex(
        (item) => item?.id === itemIdToDelete?.id && item?.name === itemIdToDelete?.name,
      )
      let newList = [...activeTradeItemsList]
      if (itemIndexToDelete !== -1) {
        newList = [
          ...activeTradeItemsList.slice(0, itemIndexToDelete),
          ...activeTradeItemsList.slice(itemIndexToDelete + 1),
        ]
      }
      const newActiveTradeItemIndex = itemIndexToDelete - 1
      const newActiveTradeItem =
        itemIndexToDelete - 1 < 0
          ? newList?.length === 0
            ? {
                id: 8,
                label: DEFAULT_CURRENCY_VALUE,
                name: 'X:' + DEFAULT_CURRENCY_VALUE,
              }
            : newList[0]
          : newList[newActiveTradeItemIndex]

      dispatch(deleteTradeItem(newList))
      if (newActiveTradeItem) {
        const category = getCurrencyCategoryByName(newActiveTradeItem?.name)
        dispatch(setActiveTradeItem({ ...newActiveTradeItem, category }))
      }
    },
    [dispatch, data, activeTradeItemsList, getCurrencyCategoryByName],
  )

  return (
    <>
      {/*<Tooltip label={t(LN_TRADES_BAR_SET_ACTIVE_TRADE)} aria-label="set active trade">*/}
      <Button
        minW="80px"
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        gap={1}
        justifyContent="space-between"
        onClick={onClick}
        paddingX={{ base: 1, sm: 2 }}
        paddingY={1}
        borderBottom="2px solid"
        borderBottomColor={active ? 'brand.mainAccent1' : 'grey'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        variant={isLightTheme ? 'lightBlue' : 'darkBlue'}
      >
        <Flex gap={1} flexDirection="column" alignItems="flex-start">
          <TextXs fontWeight="600" color="white" fontSize={{ base: '9px', sm: '10px' }}>
            {data?.label}
          </TextXs>
          <TextXs
            fontWeight="500"
            fontSize={{ base: '9px', sm: '10px' }}
            textTransform="capitalize"
            color={isLightTheme ? '#f3f3f3' : 'brand.textColor2'}
          >
            {data?.category}
          </TextXs>
        </Flex>
        {(isHovered || isSmallScreen) && (
          // <Tooltip label={t(LN_TRADES_BAR_DELETE_TRADE)} aria-label="delete trade">
          <Box
            className="delete-icon"
            _hover={{
              color: 'white',
            }}
            onClick={onDelete}
            marginTop={{ base: '-2px', sm: '-2px', md: '0' }}
          >
            <Icon as={RxCross2} w="13px" />
          </Box>
          // </Tooltip>
        )}
      </Button>
      {/*</Tooltip>*/}
    </>
  )
}

export default memo(ActiveTradeButton)
