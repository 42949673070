// Modules
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route } from 'react-router-dom'

// Constants
import { routers } from 'navigation/constants'

// Store
import { isAuthenticatedSelector } from 'store/actions/Auth/selectors'

const IsUserLoginRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector)

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Redirect to={'/' + routers?.dashboard} /> : <Component {...props} />
      }
    />
  )
}

export default memo(IsUserLoginRoute)
