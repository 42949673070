// Modules
import { useFormik } from 'formik'
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

// Languages
import { LN_AUTH_FORGOT_PASSWORD_BTN, LN_AUTH_LOGIN_BTN } from 'locales/constants'

// Components
import { formConstants } from 'pages/Auth/Login/constants'
import EmailField from 'pages/Auth/components/EmailField'
import ErrorForm from 'pages/Auth/components/ErrorForm'
import InfoBtn from 'pages/Auth/components/InfoBtn'
import PageWrapper from 'pages/Auth/components/PageWrapper'
import PasswordField from 'pages/Auth/components/PasswordField'
import SubmitBtn from 'pages/Auth/components/SubmitBtn'

// Navigation
import { routers } from 'navigation/constants'

// Utils
import { validationEmail, validationPassword } from 'utils/validation'

// Store
import { onLoginUser } from 'store/actions/Auth/actions'
import { errorSelector, loadingSelector } from 'store/actions/Auth/selectors'

const validationSchema = Yup.object({
  ...validationEmail,
  ...validationPassword,
})

const LoginPage = () => {
  const history = useHistory()

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const loading = useSelector(loadingSelector)
  const error = useSelector(errorSelector)

  const formik = useFormik({
    initialValues: {
      [formConstants.email]: '',
      [formConstants.password]: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(onLoginUser(values))
    },
  })

  const onForgotPassword = useCallback(() => {
    history.push('/' + routers.forgotPassword)
  }, [history])

  return (
    <PageWrapper>
      <form onSubmit={formik.handleSubmit}>
        <EmailField formik={formik} />
        <PasswordField formik={formik} />
        <SubmitBtn disabled={!formik.isValid} loading={loading} title={t(LN_AUTH_LOGIN_BTN)} />
      </form>
      <InfoBtn onClick={onForgotPassword} title={t(LN_AUTH_FORGOT_PASSWORD_BTN)} />
      <ErrorForm error={error} />
    </PageWrapper>
  )
}

export default memo(LoginPage)
