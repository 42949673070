// Modules
import { memo } from 'react'
import { RxCross2 } from 'react-icons/rx'

// UI
import { Icon, IconButton, Tooltip } from '@chakra-ui/react'

const CrossBtn = ({ onClick, iconSize, tooltipTitle = 'Delete', ...args }) => (
  <Tooltip label={tooltipTitle} aria-label={tooltipTitle?.toLowerCase()}>
    <IconButton
      icon={<Icon as={RxCross2} w={iconSize} h={iconSize} color="red.200" />}
      onClick={onClick}
      aria-label="info"
      colorScheme="gray"
      size="xs"
      isRound
      background="transparent"
      {...args}
    />
  </Tooltip>
)

export default memo(CrossBtn)
