// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const assertListSelector = createSelector(selfSelector, (self) => self.assertList)

export const assertListLoadingSelector = createSelector(
  selfSelector,
  (self) => self.assertListLoading,
)

export const transactionCreationLoadingSelector = createSelector(
  selfSelector,
  (self) => self.transactionCreationLoading,
)

export const transactionCreationResetFormActionSelector = createSelector(
  selfSelector,
  (self) => self.transactionCreationResetFormAction,
)

export const isMobileCreateTransactionModalOpenSelector = createSelector(
  selfSelector,
  (self) => self.isMobileCreateTransactionModalOpen,
)
