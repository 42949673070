// Modules
import { memo, useMemo } from 'react'
import { PiHandWithdraw } from 'react-icons/pi'

// Assets
import CloseTradeIcon from 'assets/close-trade-icon'
import OpenTradeIcon from 'assets/open-trade-icon'

// Languages
import {
  LN_USER_RESTRICTIONS_CLOSE_TRADE,
  LN_USER_RESTRICTIONS_OPEN_TRADE,
  LN_USER_RESTRICTIONS_TITLE,
  LN_USER_RESTRICTIONS_WITHDRAWALS,
} from 'locales/constants'

// Components
import RestrictionBox from 'pages/User/components/Content/PersonalInfo/Restrictions/RestrictionBox'

// Hooks
import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'

// UI
import { Icon } from '@chakra-ui/react'

const isBlockedOpeningTradeFlag = 'isBlockedOpeningTrade'
const isBlockedCloseTradeFlag = 'isBlockedCloseTrade'
const isBlockedWithdrawalsFlag = 'isBlockedWithdrawals'

const keysTitles = {
  [isBlockedOpeningTradeFlag]: {
    title: LN_USER_RESTRICTIONS_OPEN_TRADE,
    icon: <OpenTradeIcon w="16px" h="16px" />,
  },
  [isBlockedCloseTradeFlag]: {
    title: LN_USER_RESTRICTIONS_CLOSE_TRADE,
    icon: <CloseTradeIcon w="16px" h="16px" />,
  },
  [isBlockedWithdrawalsFlag]: {
    title: LN_USER_RESTRICTIONS_WITHDRAWALS,
    icon: <Icon as={PiHandWithdraw} color="#000000" w="16px" h="16px" />,
  },
}

const RestrictionUserProfile = () => {
  const { isBlockedOpeningTrade, isBlockedCloseTrade, isBlockedWithdrawals } =
    useCheckUserRestrictions()

  const list = useMemo(() => {
    if (!isBlockedOpeningTrade && !isBlockedCloseTrade && !isBlockedWithdrawals) return []
    let arr = []
    if (isBlockedOpeningTrade) {
      arr.push(isBlockedOpeningTradeFlag)
    }
    if (isBlockedCloseTrade) {
      arr.push(isBlockedCloseTradeFlag)
    }
    if (isBlockedWithdrawals) {
      arr.push(isBlockedWithdrawalsFlag)
    }
    return arr
  }, [isBlockedOpeningTrade, isBlockedCloseTrade, isBlockedWithdrawals])

  if (!list || list?.length === 0) {
    return null
  }

  return <RestrictionBox list={list} title={LN_USER_RESTRICTIONS_TITLE} keysTitles={keysTitles} />
}

export default memo(RestrictionUserProfile)
