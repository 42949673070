// Modules
import Cookies from 'js-cookie'
import { call, put, takeEvery } from 'redux-saga/effects'

// Languages
import { LN_NOTIFICATIONS_FORGOT_PASSWORD } from 'locales/constants'
import i18n from 'locales/i18n'

// Constants
import { appLanguages, appLanguagesLocalStoreKey } from 'components/LanguageDropdown/constants'
import { toastConstructor, toastType } from 'components/ToastApp/constants'

// Store
import {
  onForgotPasswordResult,
  onLoginResult,
  onNonAuth,
  onResetPasswordResult,
  setToken,
} from 'store/actions/Auth/actions'
import {
  FORGOT_PASSWORD_ACTION,
  LOGOUT,
  ON_LOGIN,
  RESET_PASSWORD_ACTION,
} from 'store/actions/Auth/types'
import { showToast } from 'store/actions/ToastsApp/actions'
import { getUserInfo, onGetUserBalance } from 'store/actions/User/actions'
import { cookieAuthToken } from 'store/api'
import { forgotPasswordAPI, loginUserAPI, resetPasswordAPI } from 'store/api/auth'

function* loginUserSaga(action) {
  const expirationDate = new Date()
  expirationDate.setTime(expirationDate.getTime() + 14 * 24 * 60 * 60 * 1000) // Two weeks in milliseconds

  try {
    const { email, password } = action.payload
    const response = yield call(loginUserAPI, { email, password })

    const { token } = response
    yield put(setToken(token))

    // Save token in a cookie
    Cookies.set(cookieAuthToken, token, { expires: expirationDate })

    // Save default language in local storage
    localStorage.setItem(appLanguagesLocalStoreKey, appLanguages?.en)

    const tokenCookies = Cookies.get(cookieAuthToken)
    if (tokenCookies) {
      yield put(getUserInfo())
      // yield put(onGetUserBalance()) TODO: test it
    }

    yield put(onLoginResult(''))
  } catch (error) {
    yield put(onLoginResult(error?.response?.data?.error_message))
  }
}

function* forgotPasswordSaga(action) {
  const t = i18n.t.bind(i18n)
  try {
    const { email } = action.payload
    const response = yield call(forgotPasswordAPI, { email })

    // const { token } = response
    yield put(onForgotPasswordResult({ error: '' }))
    yield put(
      showToast(
        toastConstructor(toastType.success, response?.message, t(LN_NOTIFICATIONS_FORGOT_PASSWORD)),
      ),
    )
  } catch (error) {
    yield put(onForgotPasswordResult({ error: error?.response?.data?.error_message }))
  }
}

function* resetPasswordSaga(action) {
  try {
    const { email, token, password, repeatPassword } = action.payload
    const response = yield call(resetPasswordAPI, { email, token, password, repeatPassword })
    // const { token } = response
    yield put(onResetPasswordResult({ error: '' }))
  } catch (error) {
    yield put(onResetPasswordResult({ error: error?.response?.data?.error_message }))
  }
}

function* logoutSaga() {
  Cookies.remove(cookieAuthToken)

  yield put(onNonAuth())
}

export function* watchAuth() {
  yield takeEvery(ON_LOGIN, loginUserSaga)
  yield takeEvery(LOGOUT, logoutSaga)
  yield takeEvery(FORGOT_PASSWORD_ACTION, forgotPasswordSaga)
  yield takeEvery(RESET_PASSWORD_ACTION, resetPasswordSaga)
}
