// Modules
import { useTranslation } from 'react-i18next'

// Languages
import { LN_USER_WITHDRAWAL_TABLE_STATUS_CANCEL_BTN } from 'locales/constants'

// Components
import { CrossBtn, TextMd } from 'components'
import {
  statusConstants,
  statusLanguageConstants,
} from 'pages/User/components/Content/Withdrawal/WithdrawalHistory/constants'

// UI
import { Flex } from '@chakra-ui/react'

export const StatusItem = (props) => {
  const { status, onCancelWithdrawal } = props
  const { t } = useTranslation()

  switch (status) {
    case statusConstants?.failed:
      return <TextMd color="red.200">{t(statusLanguageConstants.failed)}</TextMd>
    case statusConstants?.waiting:
      return (
        <Flex gap={1} alignItems="center">
          <TextMd>{t(statusLanguageConstants.waiting)}</TextMd>
          <CrossBtn
            tooltipTitle={t(LN_USER_WITHDRAWAL_TABLE_STATUS_CANCEL_BTN)}
            onClick={onCancelWithdrawal}
          />
        </Flex>
      )
    case statusConstants?.success:
      return <TextMd color="green.200">{t(statusLanguageConstants.success)}</TextMd>
    default:
      return null
  }
}
