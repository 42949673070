// Modules
import { memo } from 'react'

// Components
import ThemeSwitcher from 'components/ThemeSwitcher'
import Balance from 'pages/Dashboard/components/Navbar/Balance'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Flex } from '@chakra-ui/react'

const Navbar = () => {
  const { mainBorderColor, mainBgColor } = useCurrentColorTheme()

  return (
    <header style={{ position: 'fixed', top: 0, width: '100%', zIndex: 1 }}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p="10px"
        borderBottom="2px solid"
        borderColor={mainBorderColor}
        bg={mainBgColor}
      >
        <Box minW="85px" mr={7} alignItems="center" justifyContent="center" display="flex">
          Logo
        </Box>
        <Flex ml={7} gap={5} alignItems="center">
          <ThemeSwitcher />
          {/*<Button colorScheme="blue" color="white" onClick={onStartTrading} mr={3}>*/}
          {/*  {t(LN_USER_NAVBAR_START_TRADING_BTN)}*/}
          {/*</Button>*/}
          <Balance />
          {/*<TextLg fontWeight="500">*/}
          {/*  {t(LN_NAVBAR_BALANCE)}: {getUserBalanceNavbar(balanceValue)}*/}
          {/*</TextLg>*/}
        </Flex>
      </Flex>
    </header>
  )
}

export default memo(Navbar)
