// Modules
import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import Content from 'pages/Dashboard/components/OrdersBottomBar/FullSizeModal/Content'

// Utils
import { getScrollbarCss } from 'utils/scrollbarHelper'

// Store
import { setIsFullSizeModal } from 'store/actions/OrderBar/actions'
import { isFullSizeModalSelector } from 'store/actions/OrderBar/selectors'

// UI
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

const FullSizeModal = (props) => {
  const { orderList } = props

  const dispatch = useDispatch()
  const isOpen = useSelector(isFullSizeModalSelector)

  const onClose = useCallback(() => {
    dispatch(setIsFullSizeModal(false))
  }, [dispatch])

  return (
    <Modal onClose={onClose} isOpen={isOpen} motionPreset="slideInBottom" size="full">
      <ModalOverlay />
      <ModalContent h="100%">
        <ModalHeader display="flex" justifyContent="space-between" pt={9} pb={0} />
        <ModalCloseButton top={1} right={1} />
        <ModalBody overflowY="auto" pr={2} mr={3} mb={12} css={getScrollbarCss()}>
          <Content orderList={orderList} />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default memo(FullSizeModal)
