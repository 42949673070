// Modules
import _ from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

// Store
import { activeTradeItemsListSelector } from 'store/actions/ActiveTradeItems/selectors'

function useCheckIsActiveTradeItem({ id }) {
  const activeTradeItemsList = useSelector(activeTradeItemsListSelector)

  const isActiveTradeItem = useMemo(
    () => _.find(activeTradeItemsList, { id }),
    [id, activeTradeItemsList],
  )

  return { isActiveTradeItem }
}

export default useCheckIsActiveTradeItem
