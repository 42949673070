// Components
import { accordionStyles } from 'theme/components/accordion'
import { buttonStyles } from 'theme/components/button'
import { modalStyles } from 'theme/components/modal'
import { popoverStyles } from 'theme/components/popover'
import { toastStyles } from 'theme/components/toast'
import { tooltipStyles } from 'theme/components/tooltip'
import { breakpoints } from 'theme/foundations/breakpoints'
import { blueColors, globalStyles } from 'theme/globalStyles'

// Hooks
import { themeColorMode, themeSwitcherKey } from 'hooks/useCurrentColorTheme'

// UI
import { extendTheme } from '@chakra-ui/react'

const savedColorMode = localStorage.getItem(themeSwitcherKey)

export const savedColorModeValue = savedColorMode || themeColorMode.dark

const config = {
  initialColorMode: savedColorModeValue,
  useSystemColorMode: true,
  // useSystemColorMode: false,
}

const fonts = {
  body: 'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  heading:
    'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
}

const overrides = {
  ...globalStyles,
  ...{
    colors: {
      ...globalStyles?.colors,
      blue: savedColorModeValue === 'dark' ? blueColors.dark : blueColors.light,
    },
  },
  breakpoints,
  fonts,
  config,
  components: {
    ...accordionStyles,
    ...modalStyles,
    ...buttonStyles,
    ...popoverStyles,
    ...tooltipStyles,
    ...toastStyles,
  },
}
console.log('overrides', overrides)
export default extendTheme(overrides)
