// Modules
import { memo } from 'react'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Flex } from '@chakra-ui/react'

const Navbar = () => {
  const { mainBorderColor, isLightTheme } = useCurrentColorTheme()

  return (
    <header>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        p="10px"
        borderBottom="2px solid"
        borderColor={mainBorderColor}
        bg={isLightTheme ? 'white' : 'transparent'}
      >
        <Box minW="85px" mr={7} alignItems="center" justifyContent="center" display="flex">
          Logo
        </Box>
      </Flex>
    </header>
  )
}

export default memo(Navbar)
