// Modules
import { memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'

// Components
import { InfoBtn } from 'components'
import BookmarkBtn from 'components/SmallList/BookmarkBtn'
import PriceText, { typePriceConst } from 'components/SmallList/PriceText'

// Hooks
import useCurrencyActiveItemItem from 'hooks/Currency/useCurrencyActiveItemItem'
import useCheckIsActiveTradeItem from 'hooks/useCheckIsActiveTradeItem'
import useCheckIsFavouriteTradeItem from 'hooks/useCheckIsFavouriteTradeItem'
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Store
import { addFavouriteItem } from 'store/actions/FavouritesCurrency/actions'

// UI
import { Flex, Td, Th, Tr } from '@chakra-ui/react'

const TableRow = (props) => {
  const { el, onOpenInfoModal } = props

  const { isLightTheme } = useCurrentColorTheme()

  const { checkIsFavouriteTradeItem } = useCheckIsFavouriteTradeItem()
  const { isActiveTradeItem } = useCheckIsActiveTradeItem({ id: el?.id })
  const { onAddItem } = useCurrencyActiveItemItem(el)

  const dispatch = useDispatch()

  const onBookmarkBtn = useCallback(
    (event) => {
      event.stopPropagation()
      dispatch(addFavouriteItem(el))
    },
    [dispatch, el],
  )

  const onInfoBtn = useCallback(
    (e) => {
      e.stopPropagation()
      onOpenInfoModal(el)
    },
    [onOpenInfoModal, el],
  )

  const borerBottomTdColor = !isActiveTradeItem
    ? isLightTheme
      ? 'brand.lightAccent'
      : 'navy.20'
    : isLightTheme
    ? 'brand.lightAccent3'
    : 'navy.300'

  return (
    <Tr
      onClick={onAddItem}
      key={el?.id}
      _hover={{
        cursor: 'pointer',
        background: isLightTheme ? 'gray.100' : 'blue.200',
        transition: '0.8s',
      }}
    >
      <Td borderBottomColor={borerBottomTdColor}>{el?.label}</Td>
      <Td textAlign="center" borderBottomColor={borerBottomTdColor}>
        <PriceText price={el?.price} typePrice={typePriceConst.buy} />
      </Td>
      <Td textAlign="center" borderBottomColor={borerBottomTdColor}>
        <PriceText price={el?.price - el?.spread} typePrice={typePriceConst.sell} />
      </Td>
      <Th borderBottomColor={borerBottomTdColor}>
        <Flex gap={2} justifyContent="flex-end">
          <BookmarkBtn
            isActive={checkIsFavouriteTradeItem(el?.id, el?.name)}
            iconSize={6}
            onClick={onBookmarkBtn}
          />
          {/*<InfoBtn onClick={onInfoBtn} />*/}
        </Flex>
      </Th>
    </Tr>
  )
}

export default memo(TableRow)
