// Modules
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// Navigation
import { routers } from 'navigation/constants'

// Store
import { logout } from 'store/actions/Auth/actions'
import { setUserSidebarActiveItem } from 'store/actions/User/actions'
import { sidebarActiveItemSelector, userInfoSelector } from 'store/actions/User/selectors'

const useUserSidebar = () => {
  const history = useHistory()

  const dispatch = useDispatch()
  const sidebarActiveItem = useSelector(sidebarActiveItemSelector)
  const userInfo = useSelector(userInfoSelector)

  const onChangeSidebarActiveItem = useCallback(
    (id) => {
      dispatch(setUserSidebarActiveItem(id))
    },
    [dispatch],
  )

  const onLogout = useCallback(() => {
    // Cookies.set(cookieAuthToken, null)
    dispatch(logout())
    // history.push('/' + routers.login)
  }, [dispatch])

  const onStartTrading = useCallback(() => {
    history.push('/' + routers.dashboard)
  }, [history])

  return {
    onLogout,
    onChangeSidebarActiveItem,
    onStartTrading,
    sidebarActiveItem,
    userInfo,
  }
}

export default useUserSidebar
