// Modules
import { eventChannel } from 'redux-saga'
import { call, put, select, take, takeEvery } from 'redux-saga/effects'

// Store
import {
  wsFollowTransactionsChangeValue,
  wsFollowTransactionsMessageReceived,
} from 'store/actions/OrderBar/actions'
import {
  WS_CONNECT_FOLLOW_TRANSACTIONS,
  WS_DISCONNECT_FOLLOW_TRANSACTIONS,
} from 'store/actions/OrderBar/types'
import { URL_SOCKET_PATH, WS_TRANSACTIONS_PORT } from 'store/api'

const TYPE_CLOSE = 'CLOSE'
const TYPE_ERROR = 'ERROR'
const TYPE_MESSAGE = 'MESSAGE'
const TYPE_OPEN = 'OPEN'

function* handleMessages(action) {
  // console.log('handleMessages', action)
  yield put(wsFollowTransactionsMessageReceived(action.payload))
  if (action?.type === TYPE_MESSAGE) {
    // console.log('TYPE_MESSAGE', action)
    yield put(wsFollowTransactionsChangeValue())
  }
}

// Saga
export function* watchFollowTransactionsWebSocket() {
  while (true) {
    yield take(WS_CONNECT_FOLLOW_TRANSACTIONS)
    const token = yield select((state) => state.auth.token)
    // console.log('token', token)
    const socket = yield call(connectWebSocket, { token })
    yield takeEvery(socket, handleMessages)
    yield take(WS_DISCONNECT_FOLLOW_TRANSACTIONS)
    socket.close()
  }
}

function connectWebSocket(props) {
  const { token } = props
  return eventChannel((emitter) => {
    if (!token) {
      return () => {}
    }

    const socket = new WebSocket(`ws://${URL_SOCKET_PATH}:${WS_TRANSACTIONS_PORT}?token=${token}`)

    socket.onopen = () => {
      // console.log('WebSocket connection opened')
      emitter({ type: TYPE_OPEN })
    }

    socket.onmessage = (event) => {
      // console.log('Received message:', event.data)
      emitter({ type: TYPE_MESSAGE, payload: JSON.parse(event.data) })
    }

    socket.onclose = () => {
      // console.log('WebSocket connection closed')
      emitter({ type: TYPE_CLOSE })

      // emitter.close() // Close the channel
    }

    socket.onerror = (error) => {
      console.error('WebSocket error:', error)
      emitter({ type: TYPE_ERROR, payload: error })
    }

    // Return unsubscribe function
    return () => {
      // console.log('Socket off')
      socket.close()
    }
  })
}
