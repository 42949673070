// Modules
import { useSelector } from 'react-redux'

// Store
import { userInfoSelector } from 'store/actions/User/selectors'

export const volumeValueDefault = 1

function useCheckUserRestrictions() {
  const userInfo = useSelector(userInfoSelector)

  const isRestricted = userInfo?.restrictions
  const isBlockedOpeningTrade = isRestricted?.block_lead_open_trade
  const isBlockedCloseTrade = isRestricted?.block_lead_close_trade
  const isBlockedWithdrawals = isRestricted?.block_lead_withdrawals
  const isBlockedLogin = isRestricted?.block_lead_login

  const isTradePlatformSettings = userInfo?.trade_platform

  const isTradeAllowed = isTradePlatformSettings?.allow_trade
  const defaultVolumeOnPlatform = isTradePlatformSettings?.default_volume_on_platform
    ? Number(isTradePlatformSettings?.default_volume_on_platform)
    : volumeValueDefault
  const allowOpenTradeInNonTradingTime =
    isTradePlatformSettings?.['allow_open_trade_in_non-trading_time']

  // TODO: implement when deposit will be ready
  const allowDepositWithoutVerification =
    isTradePlatformSettings?.allow_deposit_without_verification

  // not using in our platform yet
  const disablePlatformMessageHints = isTradePlatformSettings?.disable_platform_message_hints
  const showBonusesInUserProfile = isTradePlatformSettings?.show_bonuses_in_user_profile
  const isTradeAllowedWithoutFTD = isTradePlatformSettings?.allow_trade_without_ftd
  const disableEditingProfileDataOnTradingPlatform =
    isTradePlatformSettings?.disable_editing_profile_data_on_the_trading_platform

  return {
    isRestricted,
    isBlockedOpeningTrade,
    isBlockedCloseTrade,
    isBlockedWithdrawals,
    isBlockedLogin,

    isTradePlatformSettings,
    isTradeAllowed,
    defaultVolumeOnPlatform,
    allowOpenTradeInNonTradingTime,
    allowDepositWithoutVerification,
  }
}

export default useCheckUserRestrictions
