// Modules
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { FaMoneyBillTransfer } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'

// Components
import InfoModal from 'pages/Dashboard/components/InfoModal'
import LeftNavbar from 'pages/Dashboard/components/LeftNavbar'
import { NavList } from 'pages/Dashboard/components/LeftNavbar/constants'
import Main from 'pages/Dashboard/components/Main'
import Navbar from 'pages/Dashboard/components/Navbar'
import BordersBottomBar from 'pages/Dashboard/components/OrdersBottomBar'
import WrapperForDrawer from 'pages/Dashboard/components/WrapperForDrawer'

import {
  BottomBarHeight,
  LeftNavBarIconsWidth,
  LeftTabsWidth,
  RightMarketSidebar,
  ScreenWidthWithoutNavbar,
  isOpenBottomBarKey,
} from 'constants/index'

// Store
import {
  setIsBottomNavOpen,
  setIsLeftNavOpen,
  setIsRightNavOpen,
  setMainGraphBlockWidth,
} from 'store/actions/MainGraphBlockWidth/actions'
import {
  isBottomNavOpenSelector,
  isLeftNavOpenSelector,
  isRightNavOpenSelector,
} from 'store/actions/MainGraphBlockWidth/selectors'

// Ui
import { Box, Flex, Icon, IconButton } from '@chakra-ui/react'

const Dashboard = () => {
  const dispatch = useDispatch()

  const [isActiveLeftNav, setIsActiveLeftNav] = useState(NavList[0].title)
  const [windowSizeState, setWindowSizeState] = useState()
  const windowSize = useRef([window.innerWidth, window.innerHeight])
  const divRef = useRef(null)
  const windowWidth = windowSize?.current?.[0]

  const isBottomNavOpen = useSelector(isBottomNavOpenSelector)
  const isLeftNavOpen = useSelector(isLeftNavOpenSelector)
  const isRightNavOpen = useSelector(isRightNavOpenSelector)

  const mainWidthCalculation = useCallback(
    (currentWindowWidth) => {
      const leftTab = isLeftNavOpen ? LeftTabsWidth : 0
      const rightSidebar = isRightNavOpen ? RightMarketSidebar : 0
      return currentWindowWidth - LeftNavBarIconsWidth - leftTab - rightSidebar
    },
    [isLeftNavOpen, isRightNavOpen],
  )

  // window size
  useEffect(() => {
    dispatch(setMainGraphBlockWidth(mainWidthCalculation(windowWidth)))
    setWindowSizeState(windowWidth)
  }, [windowWidth, dispatch, mainWidthCalculation])

  useEffect(() => {
    dispatch(setMainGraphBlockWidth(mainWidthCalculation(windowSizeState)))
  }, [windowSizeState, isRightNavOpen, isLeftNavOpen, dispatch, mainWidthCalculation])

  // const mainBlockWidth = useMemo(() => mainWidthCalculation(windowWidth), [windowWidth])

  // console.log('windowSize', windowSize)
  // console.log('mainBlockWidth', mainBlockWidth)

  useEffect(() => {
    // Function to get the size of the div element
    const getSize = () => {
      const divElement = divRef.current
      if (divElement) {
        // Introduce a delay of 0.3 seconds before getting the bounding client rect
        // setTimeout(() => {
        const rect = divElement?.getBoundingClientRect()
        // console.log('Width:', rect.width)
        // console.log('Height:', rect.height)
        if (windowWidth !== rect.width) {
          setWindowSizeState(rect.width)
          // console.log('mainWidthCalculation(rect.width)', mainWidthCalculation(rect.width))
          dispatch(setMainGraphBlockWidth(mainWidthCalculation(rect.width)))
        }

        // setWindowSize(rect.width)
        // }, 400)
      }
    }

    // Call the getSize function initially
    getSize()

    // Attach an event listener for resizing, if needed
    window.addEventListener('resize', getSize)

    // Cleanup: Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', getSize)
    }
  }, [isLeftNavOpen, isRightNavOpen, windowWidth, dispatch])

  const onLeftNavOpen = useCallback(() => dispatch(setIsLeftNavOpen(true)), [dispatch])

  const onLeftNavClose = useCallback(() => dispatch(setIsLeftNavOpen(false)), [dispatch])

  const onRightBlockOpen = useCallback(() => dispatch(setIsRightNavOpen(true)), [dispatch])

  const onRightBlockClose = useCallback(() => dispatch(setIsRightNavOpen(false)), [dispatch])

  const onBottomBlockOpen = useCallback(() => {
    dispatch(setIsBottomNavOpen(true))
    localStorage.setItem(isOpenBottomBarKey, 'true')
  }, [dispatch])

  const onBottomBlockClose = useCallback(() => {
    dispatch(setIsBottomNavOpen(false))
    localStorage.setItem(isOpenBottomBarKey, 'false')
  }, [dispatch])

  return (
    <Box ref={divRef} overflow="hidden">
      <Navbar />
      <Flex
        height={
          isBottomNavOpen
            ? `calc(${ScreenWidthWithoutNavbar} - ${BottomBarHeight})`
            : ScreenWidthWithoutNavbar
        }
      >
        <LeftNavbar
          isActiveNav={isActiveLeftNav}
          setIsActiveNav={setIsActiveLeftNav}
          onLeftNavOpen={onLeftNavOpen}
          onLeftNavClose={onLeftNavClose}
        />
        <WrapperForDrawer
          leftNavTitle={isActiveLeftNav}
          openLeftNav={isLeftNavOpen}
          openBottomNav={isBottomNavOpen}
          openRightBlock={isRightNavOpen}
          onRightBlockOpen={onRightBlockOpen}
          onRightBlockClose={onRightBlockClose}
          onLeftNavOpen={onLeftNavOpen}
          onLeftNavClose={onLeftNavClose}
        >
          <Box p={2} pb={0} position="relative" w="100%">
            {!isRightNavOpen && (
              <Box position="absolute" top="0" right="0">
                <IconButton
                  icon={<Icon as={FaMoneyBillTransfer} w="22px" h="22px" />}
                  onClick={onRightBlockOpen}
                  aria-label="open market"
                  colorScheme="gray"
                  size="md"
                />
              </Box>
            )}
            <Main />
          </Box>
        </WrapperForDrawer>
      </Flex>
      <BordersBottomBar
        onBottomBlockOpen={onBottomBlockOpen}
        onBottomBlockClose={onBottomBlockClose}
      />
      <InfoModal />
      {/*<TransactionCreationSidebar />*/}
    </Box>
  )
}

export default memo(Dashboard)
