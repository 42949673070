// Modules
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectedThemeSelector } from 'store/actions/User/selectors'

// UI
import { useColorMode } from '@chakra-ui/react'

export const themeSwitcherKey = 'chakra-ui-color-mode'

export const themeColorMode = {
  light: 'light',
  dark: 'dark',
}

function useCurrentColorTheme() {
  const { colorMode } = useColorMode()

  const selectedTheme = useSelector(selectedThemeSelector)
  const isLightTheme = useMemo(() => colorMode === themeColorMode.light, [colorMode, selectedTheme])

  const isLightThemeSelector = useMemo(
    () => selectedTheme === themeColorMode.light,
    [selectedTheme],
  )

  const mainBgColor = useMemo(
    () => (isLightTheme ? 'white' : 'brand.bgColorDark'),
    [isLightTheme, selectedTheme],
  )

  const mainBorderColor = useMemo(
    () => (!isLightTheme ? 'gray.600' : 'brand.borderColorLight'),
    [isLightTheme, selectedTheme],
  )

  const mainTextColor = useMemo(
    () => (isLightTheme ? 'brand.textColor2Light' : 'white'),
    [isLightTheme, selectedTheme],
  )

  const highLightColor = useMemo(
    () => (isLightTheme ? 'brand.mainAccent1Light' : 'navy.300'),
    [isLightTheme, selectedTheme],
  )

  return {
    isLightTheme,
    mainBgColor,
    mainBorderColor,
    mainTextColor,
    highLightColor,
    selectedTheme,
    isLightThemeSelector,
  }
}

export default useCurrentColorTheme
