export const breakpoints = {
  '0xs': '10em', // < 320px
  xs: '20em', // 320px
  '1xs': '22.5em', // 360px
  '2xs': '25em', // 400px
  sm: '30em', // 480px
  '2sm': '40em', // 640px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280
  '2xl': '96em', // 1536
  '3xl': '120em', // 1920
  '4xl': '160em',
}
