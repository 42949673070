// Language
import {
  LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_PROFIT,
  LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_RATE,
} from 'locales/constants'

export const RADIO_BY_RATE = 1
export const RADIO_BY_PROFIT = 2

export const getRadioTitle = (val) => {
  switch (val) {
    case RADIO_BY_RATE:
      return LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_RATE
    case RADIO_BY_PROFIT:
      return LN_TRANSACTION_CALC_TAKE_PROFIT_STOP_LOSS_BY_PROFIT
  }
}
