// Modules
import moment from 'moment'
import { memo, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import {
  LN_TRANSACTION_NOTIFICATION_CURRENCY_NOT_CHOSEN,
  LN_TRANSACTION_NOTIFICATION_NO_USER_TRADING_ACCOUNT,
  LN_USER_RESTRICTIONS,
} from 'locales/constants'

// Components
import { toastConstructor, toastType } from 'components/ToastApp/constants'
import FormMarketMobileUI from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/FormMarketMobileUI'
import FormMarketUI from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket/FormMarketUI'

import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'
// Hooks
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Context
import { MarketCalcContext } from 'context/MarketCalcContext'

// Store
import { chosenActiveTradeItemSelector } from 'store/actions/ActiveTradeItems/selectors'
import { showToast } from 'store/actions/ToastsApp/actions'
import {
  createTransaction,
  createTransactionLoading,
} from 'store/actions/TransactionCreation/actions'
import {
  assertListLoadingSelector,
  transactionCreationLoadingSelector,
} from 'store/actions/TransactionCreation/selectors'
import { isMobileSelector, userInfoSelector } from 'store/actions/User/selectors'

const FormMarket = () => {
  // const { onSidebarClose } = props

  // const onChooseItem = useCallback((title: string) => {
  //   setIsActiveNav(title)
  //   onSidebarClose()
  // }, [])

  const { t } = useTranslation()
  const { userTradingAccountId } = useUserTradingAccountId()
  const {
    isBlockedOpeningTrade,
    isTradeAllowed,
    defaultVolumeOnPlatform,
    allowOpenTradeInNonTradingTime,
  } = useCheckUserRestrictions()
  const isMobile = useSelector(isMobileSelector)

  const dispatch = useDispatch()
  const transactionCreationLoading = useSelector(transactionCreationLoadingSelector)
  const assertListLoading = useSelector(assertListLoadingSelector)
  const chosenActiveTradeItem = useSelector(chosenActiveTradeItemSelector)

  const {
    volumeValue,
    setVolumeValue,
    currentCurrencySymbolPointValue,
    currentCurrencyPriceValue,
    assetPriceValue,
    assetPriceTouched,
    stopLossTouched,
    takeProfitTouched,
    takeProfitValue,
    takeProfitRadioValue,
    stopLossRadioValue,
    stopLossValue,
    currentCurrencyAssertValue,
  } = useContext(MarketCalcContext)

  const onChangeVolumeInput = useCallback(
    (e) => {
      setVolumeValue(e?.target?.value)
    },
    [setVolumeValue],
  )

  const onIncreaseValue = useCallback(() => {
    setVolumeValue((prevValue) => prevValue + currentCurrencySymbolPointValue)
  }, [setVolumeValue, currentCurrencySymbolPointValue])

  const onDecreaseValue = useCallback(() => {
    setVolumeValue((prevValue) => {
      const newV = prevValue - currentCurrencySymbolPointValue
      return newV > 0 ? newV : 0
    })
  }, [setVolumeValue, currentCurrencySymbolPointValue])

  const onResetValue = useCallback(() => {
    setVolumeValue(defaultVolumeOnPlatform)
  }, [setVolumeValue, defaultVolumeOnPlatform])

  const currentCurrencyId = chosenActiveTradeItem?.id
  // (buy = 2/sell = 1) => {}
  const onCreateTransaction = useCallback(
    (typeTransactionValue) => {
      if (isBlockedOpeningTrade || !isTradeAllowed) {
        dispatch(showToast(toastConstructor(toastType.error, t(LN_USER_RESTRICTIONS))))
      } else if (!currentCurrencyId) {
        dispatch(
          showToast(
            toastConstructor(toastType.error, t(LN_TRANSACTION_NOTIFICATION_CURRENCY_NOT_CHOSEN)),
          ),
        )
      } else if (!userTradingAccountId) {
        dispatch(
          showToast(
            toastConstructor(
              toastType.error,
              t(LN_TRANSACTION_NOTIFICATION_NO_USER_TRADING_ACCOUNT),
            ),
          ),
        )
      } else {
        dispatch(createTransactionLoading(true))
        // from pending popover - asset price
        const startAtPrice = assetPriceTouched ? { startAtPrice: assetPriceValue } : {}

        // from take profit popover - radio btn, Take Profit
        const closeAtProfit = takeProfitTouched
          ? { closeAtProfit: takeProfitValue, closeAtProfitTypeId: takeProfitRadioValue }
          : {}

        // from take profit popover - radio btn, Stop Loss
        const closeAtLoss = stopLossTouched
          ? { closeAtLoss: stopLossValue, closeAtLosTypeId: stopLossRadioValue }
          : {}

        dispatch(
          createTransaction({
            currencyId: currentCurrencyId,
            tradingAccountId: userTradingAccountId,
            volumeLots: volumeValue,
            commandId: typeTransactionValue, // buy/sell
            ...closeAtProfit,
            ...closeAtLoss,
            ...startAtPrice,
          }),
        )
      }
    },
    [
      dispatch,
      assetPriceTouched,
      assetPriceValue,
      stopLossTouched,
      takeProfitTouched,
      takeProfitValue,
      takeProfitRadioValue,
      stopLossRadioValue,
      stopLossValue,
      volumeValue,
      currentCurrencyId,
      userTradingAccountId,
      isBlockedOpeningTrade,
      isTradeAllowed,
      t,
    ],
  )

  const schedule = currentCurrencyAssertValue?.schedule

  // case MONDAY = 1;
  // case TUESDAY = 2;
  // case WEDNESDAY = 3;
  // case THURSDAY = 4;
  // case FRIDAY = 5;
  // case SATURDAY = 6;
  // case SUNDAY = 7;

  const isMarketOpenForCurrentCurrency = useMemo(() => {
    // return false
    if (!schedule) return false
    const currentDay = moment().isoWeekday().toString() // ISO weekday (1 for Monday, 7 for Sunday)
    const currentTime = moment()

    if (!schedule?.[currentDay]) return false

    const { open, close } = schedule?.[currentDay]

    const openTime = moment(open, 'HH:mm')
    const closeTime = moment(close, 'HH:mm')

    // Check if current time is between open and close time
    return currentTime?.isBetween(openTime, closeTime, null, '[)') ?? false
  }, [schedule])

  const scheduleToday = useMemo(() => {
    if (!schedule) return ''
    const currentDay = moment().isoWeekday().toString() // ISO weekday (1 for Monday, 7 for Sunday)

    if (!schedule?.[currentDay]) return ''

    return schedule?.[currentDay]
  }, [schedule])

  const isDisabled = useMemo(() => {
    if (transactionCreationLoading || isBlockedOpeningTrade || !isTradeAllowed) return true
    if (isMarketOpenForCurrentCurrency) {
      return false
    } else {
      if (allowOpenTradeInNonTradingTime) {
        return false
      } else {
        return true
      }
    }
  }, [
    isMarketOpenForCurrentCurrency,
    isTradeAllowed,
    transactionCreationLoading,
    allowOpenTradeInNonTradingTime,
    isBlockedOpeningTrade,
  ])

  return isMobile ? (
    <FormMarketMobileUI
      volumeValue={volumeValue}
      onChangeVolumeInput={onChangeVolumeInput}
      onIncreaseValue={onIncreaseValue}
      onDecreaseValue={onDecreaseValue}
      onResetValue={onResetValue}
      currentCurrencyPriceValue={currentCurrencyPriceValue}
      onCreateTransaction={onCreateTransaction}
      currentCurrencySymbolPointValue={currentCurrencySymbolPointValue}
      scheduleToday={scheduleToday}
      isMarketOpen={isMarketOpenForCurrentCurrency}
      allowOpenTradeInNonTradingTime={allowOpenTradeInNonTradingTime}
      scheduleLoading={assertListLoading}
      isBlockedOpeningTrade={isBlockedOpeningTrade || !isTradeAllowed}
      isDisabled={isDisabled}
    />
  ) : (
    <FormMarketUI
      volumeValue={volumeValue}
      onChangeVolumeInput={onChangeVolumeInput}
      onIncreaseValue={onIncreaseValue}
      onDecreaseValue={onDecreaseValue}
      onResetValue={onResetValue}
      currentCurrencyPriceValue={currentCurrencyPriceValue}
      onCreateTransaction={onCreateTransaction}
      currentCurrencySymbolPointValue={currentCurrencySymbolPointValue}
      scheduleToday={scheduleToday}
      isMarketOpen={isMarketOpenForCurrentCurrency}
      allowOpenTradeInNonTradingTime={allowOpenTradeInNonTradingTime}
      scheduleLoading={assertListLoading}
      isBlockedOpeningTrade={isBlockedOpeningTrade || !isTradeAllowed}
      isDisabled={isDisabled}
    />
  )
}

export default memo(FormMarket)
