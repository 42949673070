// Modules
import { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Languages
import { LN_DEPOSIT_TAB_HISTORY_NO_DATA, LN_DEPOSIT_TAB_HISTORY_TITLE } from 'locales/constants'

// Components
import { TextMd } from 'components'
import TableHistory from 'pages/User/components/Content/Deposit/DepositHistory/TableHistory'
import {
  depositTypeConstants,
  statusConstants,
} from 'pages/User/components/Content/Deposit/DepositHistory/TableHistory/constants'
import useHistoryTablePagination from 'pages/User/components/Content/Deposit/DepositHistory/TableHistory/useHistoryTablePagination'
import { MainTitle } from 'pages/User/components/Content/Withdrawal'

// Constants
import { financeTypeIconsConstants, financeTypeLanguageConstants } from 'constants/finance'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Store
import { getDepositUserHistory } from 'store/actions/UserDeposit/actions'
import {
  depositHistorySelector,
  loadingDepositHistorySelector,
} from 'store/actions/UserDeposit/selectors'

// UI
import { Box, Flex, Spinner } from '@chakra-ui/react'

const DepositHistory = () => {
  const { t } = useTranslation()
  const { highLightColor } = useCurrentColorTheme()

  const dispatch = useDispatch()
  const { userTradingAccountId } = useUserTradingAccountId()

  const depositHistory = useSelector(depositHistorySelector)
  const loadingDeposit = useSelector(loadingDepositHistorySelector)

  const listHistory = depositHistory?.data

  console.log('depositHistory', depositHistory)
  console.log('loadingDeposit', loadingDeposit)
  //
  useEffect(() => {
    dispatch(getDepositUserHistory({ userTradingAccountId }))
  }, [dispatch, userTradingAccountId])
  //
  const listTransform = useMemo(() => {
    if (!listHistory || listHistory?.length === 0) return []
    return listHistory?.map((el) => {
      return {
        id: el?.finance_id,
        depositType: depositTypeConstants[el?.finance_type?.id],
        date: el?.deposit_date,
        typeFinance: financeTypeLanguageConstants[el?.finance_type?.id],
        typeFinanceIcon: financeTypeIconsConstants[el?.finance_type?.id],
        amount: el?.deposit_amount,
        currency: el?.currency,
        status: statusConstants[el?.status?.id],
        description: el?.client_description,
      }
    })
  }, [listHistory])

  const { paginationInfo, onPreviousPage, onNextPage, onPage } = useHistoryTablePagination()

  return (
    <Box mt={3}>
      <MainTitle title={t(LN_DEPOSIT_TAB_HISTORY_TITLE)} pt={6} />
      {loadingDeposit ? (
        <Flex alignItems="center" justifyContent="center">
          <Spinner color={highLightColor} />
        </Flex>
      ) : !listTransform || listTransform?.length === 0 ? (
        <TextMd fontWeight={500}>{t(LN_DEPOSIT_TAB_HISTORY_NO_DATA)}</TextMd>
      ) : (
        <TableHistory
          list={listTransform}
          loading={loadingDeposit}
          paginationInfo={paginationInfo}
          onPreviousPage={onPreviousPage}
          onNextPage={onNextPage}
          onPage={onPage}
        />
      )}
    </Box>
  )
}

export default memo(DepositHistory)
