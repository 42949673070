// Components
import { throttle } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// Components
import ToastApp from 'components/ToastApp'
import WindowSpinner from 'components/WindowSpinner'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// Navigation
import Navigation from 'navigation'

// Store
import { onPushQueryUserActiveAt, onSetUserDeviceIsMobile } from 'store/actions/User/actions'

// UI
import { Box, useBreakpointValue } from '@chakra-ui/react'

const AppInner = () => {
  const isSmallScreen = useBreakpointValue({ base: true, '2xs': true, md: true, lg: false })
  // console.log('isSmallScreen', isSmallScreen)

  const { isLightTheme } = useCurrentColorTheme()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 991px)')

    const handleChange = (e) => {
      dispatch(onSetUserDeviceIsMobile(e.matches))
    }

    // Initial check
    handleChange(mediaQuery)

    // Listen for changes
    mediaQuery.addEventListener('change', handleChange)

    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, [])

  // update user active-at query every 5 min
  useEffect(() => {
    const fetchData = () => {
      dispatch(onPushQueryUserActiveAt())
    }

    // Throttle the request to every 5 minutes (300000 ms)
    const throttledFetchData = throttle(fetchData, 300000)

    // Call the function immediately and set up the interval
    throttledFetchData()
    const interval = setInterval(throttledFetchData, 300000)

    // Clear interval on component unmount
    return () => clearInterval(interval)
  }, [])

  return (
    <Box bg={!isLightTheme ? '#1A202C' : '#f6f7f9'}>
      {isLoading && <WindowSpinner />}

      <Navigation />
      <ToastApp />
    </Box>
  )
}

export default AppInner
