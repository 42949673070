// Modules
import { createSelector } from '@reduxjs/toolkit'

// Store
import selfSelector from './self'

export const showToastSelector = createSelector(selfSelector, (self) => self.showToast)

export const toastMessageSelector = createSelector(selfSelector, (self) => self.toastMessage)

export const toastTitleSelector = createSelector(selfSelector, (self) => self.toastTitle)

export const idSelector = createSelector(selfSelector, (self) => self.id)

export const toastTypeSelector = createSelector(selfSelector, (self) => self.toastType)
