// Types
import { GET_KYC_FIELD_LIST, SET_KYC_FIELD_LIST } from 'store/actions/KYC/types'

const initialState = {
  kycFieldList: null,
}

const kycReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KYC_FIELD_LIST:
      return {
        ...state,
        kycFieldList: action.payload,
      }
    case SET_KYC_FIELD_LIST:
      return {
        ...state,
        kycFieldList: action.payload,
      }

    default:
      return state
  }
}
export default kycReducer
