// Modules
import _ from 'lodash'

// Languages
import { LN_HISTORY_BUY, LN_HISTORY_SELL } from 'locales/constants'
import i18n from 'locales/i18n'

export const typeTransaction = {
  sell: 1,
  buy: 2,
}

export const getTypeTransactionTitle = (value) => {
  const t = i18n.t.bind(i18n)

  switch (value) {
    case typeTransaction.sell:
      return t(LN_HISTORY_SELL)
    case typeTransaction.buy:
      return t(LN_HISTORY_BUY)
    default:
      return null
  }
}

export const getCurrentCurrencyAssertValue = (assertList, chosenActiveTradeItem) => {
  if (assertList?.length > 0 && chosenActiveTradeItem) {
    return (
      assertList?.find(
        (el) =>
          el?.id === chosenActiveTradeItem?.id &&
          el?.symbol_description === chosenActiveTradeItem?.name,
      ) ?? null
    )
  } else {
    return null
  }
}

// OLD REQUIRED MARGIN: volume * currency_price * assets.symbol_contract_size /  leverage
// NEW REQUIRED MARGIN: (open_price * contract_size / laverage * volume) * RATE
export const getRequiredMargin = (volume, openPrice, contractSize, leverage, rate) => {
  if (!leverage || !volume || !rate) return 0
  // prettier-ignore
  const result = (openPrice * contractSize / leverage * volume) / rate
  return result ?? 0
}

// Old PROFIT FROM TP: ((stop_profit_price - Entry Price) * volume - spread) * -1/1(sell/buy)
// New PROFIT FROM TP: (stop_profit_price - Entry Pice) * RATE * (volume_lots * symbol_contract_size) - spread * -1/1(sell/buy)
export const getProfitFromTP = (
  stopProfitPrice,
  volume,
  entryPrice,
  spread,
  type = typeTransaction.sell,
  rate,
  contractSize,
) => {
  const result = (stopProfitPrice - entryPrice) * rate * (volume * contractSize) - spread * type
  return result ?? 0
}

// PROFIT: (current_price  - open_price) * volume * leverage * -1/1(sell/buy) - swap - spread
// New (close_price - open_price) * volume_lots * symbol_contract_size * 1/-1(buy/sell) - swap - spread;
export const getProfit = (
  currentPrice,
  openPrice,
  contractSize,
  volume,
  spread,
  swap,
  type = typeTransaction.sell,
) => {
  const result = (currentPrice - openPrice) * volume * contractSize * type - swap - spread
  // old const result = (currentPrice - openPrice) * volume * leverage * type - swap - spread
  return result ?? 0
}

// Old LOSS FROM SL:  ((stop_lose_price - Entry Price) * volume - spread) * -1/1(sell/buy)
// New LOSS FROM SL:  (stop_lose_price - Entry Pice) * RATE  * (volume_lots * symbol_contract_size) - spread * -1/1(sell/buy)
export const getLossFromSL = (
  stopLossPrice,
  volume,
  entryPrice,
  spread,
  type = typeTransaction.sell,
  rate,
  contractSize,
) => {
  const result = (stopLossPrice - entryPrice) * rate * (volume * contractSize) - spread * type
  // const result = ((stopLossPrice - entryPrice) * volume - spread) * type
  return result ?? 0
}

// ROE: PROFIT FROM TP / REQUIRED MARGIN * 100
export const getROE = (profitFromTP, requiredMargin) => {
  if (!requiredMargin) return 0
  const result = (profitFromTP / requiredMargin) * 100
  return result ?? 0
}

// Navbar - "margin info"

// Usage(margin): sum(lot * contract_size / leverage)
export const getUsage = (list, assertList, ratesList) => {
  if (!list || list?.length === 0) return 0
  const calculated = _.map(list, (row) => {
    if (!row?.leverage) return 0

    const assertValue = getCurrentCurrencyAssertValue(assertList, {
      id: row?.asset_id,
      name: row?.symbol,
    })

    const rateValue = assertValue?.currency ? ratesList?.[assertValue?.currency] : 1
    const value = getRequiredMargin(
      Number(row?.volume_lots ?? 1),
      Number(row?.open_price),
      assertValue?.symbol_contract_size,
      Number(row?.leverage),
      rateValue,
    )
    return value ?? 0
  })
  const totalSum = _.sum(calculated)
  return totalSum ?? 0
}

// Level: $equity / $margin * 100
export const getLevelValue = (equityValue, usageValue) => {
  if (equityValue && usageValue) {
    return (equityValue / usageValue) * 100 ?? 0
  } else {
    return 0
  }
}

// Available: $balance - sum($margin + P/L)
export const getAvailableValue = (balanceValue, usageValue, plValue) => {
  return balanceValue - (usageValue + plValue) ?? 0
}

// Equity: $balance + sum(P/L)
export const getEquityValue = (balanceValue, plValue) => {
  return (balanceValue || 0) + plValue
}

// P/L: SUM(profit)
export const getTotalPlValue = (rows) => _.sumBy(rows, (row) => Number(row?.profit))

export const addedProfitRows = (rows) =>
  _.map(rows, (row) => ({
    ...row,
    profit: getProfit(
      row?.open_price,
      row?.leverage,
      row?.volume_lots,
      row?.spread,
      row?.swap,
      row?.command_id,
    ),
  }))

export const getRateFromList = (rateList, symbol) => {}
