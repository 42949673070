// Modules
import { CiEdit } from 'react-icons/ci'
import { GoGift } from 'react-icons/go'
import { PiHandDeposit } from 'react-icons/pi'

// Languages
import {
  LN_FINANCE_TYPE_BONUS,
  LN_FINANCE_TYPE_CORRECTION,
  LN_FINANCE_TYPE_DEPOSIT,
  LN_FINANCE_TYPE_WITHDRAWAL,
} from 'locales/constants'

export const FinanceTypeIds = {
  withdrawal: 1,
  deposit: 2,
  correction: 3,
  bonus: 4,
}

export const financeTypeLanguageConstants = {
  [FinanceTypeIds.deposit]: LN_FINANCE_TYPE_DEPOSIT,
  [FinanceTypeIds.correction]: LN_FINANCE_TYPE_CORRECTION,
  [FinanceTypeIds.bonus]: LN_FINANCE_TYPE_BONUS,
  [FinanceTypeIds.withdrawal]: LN_FINANCE_TYPE_WITHDRAWAL,
}

export const financeTypeIconsConstants = {
  [FinanceTypeIds.deposit]: PiHandDeposit,
  [FinanceTypeIds.correction]: CiEdit,
  [FinanceTypeIds.bonus]: GoGift,
  [FinanceTypeIds.withdrawal]: '',
}
