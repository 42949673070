// Modules
import _ from 'lodash'

// Constants
import { defaultProps } from 'components/TVChartContainer/constants'

import { graphResolutionValueKey } from 'constants/index'

// Types
import {
  GET_CURRENCY_MARKET_LIST,
  SET_CURRENCY_MARKET_LIST,
  SET_CURRENCY_MARKET_LIST_LOADING,
  SET_INTERVAL_GRAPH_VALUE,
  WS_CURRENCY_MARKET_CHANGE_CURRENCY_VALUE,
} from 'store/actions/CurrencySocket/types'

const initialState = {
  currencyList: null,
  currencyListLoading: true,

  intervalGraphValue: localStorage.getItem(graphResolutionValueKey) || defaultProps.interval,
}

const currencySocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INTERVAL_GRAPH_VALUE:
      return {
        ...state,
        intervalGraphValue: action.payload,
      }
    case SET_CURRENCY_MARKET_LIST:
      return {
        ...state,
        currencyList: action.payload,
      }
    case GET_CURRENCY_MARKET_LIST:
      return {
        ...state,
        currencyListLoading: true,
      }
    case SET_CURRENCY_MARKET_LIST_LOADING:
      return {
        ...state,
        currencyListLoading: action.payload,
      }
    case WS_CURRENCY_MARKET_CHANGE_CURRENCY_VALUE:
      let transform = _.cloneDeep(state.currencyList)

      if (state.currencyList) {
        if (_.isArray(action?.payload)) {
          // Create a dictionary for faster lookup by label
          const pairMap = Object.values(transform)
            .flatMap((pairArr) => pairArr)
            .reduce((map, pair) => {
              map[pair?.label] = pair
              return map
            }, {})

          // Update prices by looking up the dictionary
          action.payload?.forEach((el) => {
            if (pairMap[el?.symbol]) {
              pairMap[el.symbol].price = el?.close
            }
          })
        } else {
          const allPairs = Object.values(transform).flatMap((pairArr) => pairArr)
          const pair = allPairs.find((p) => p?.name === action?.payload?.symbol)
          if (pair) {
            pair.price = action?.payload?.close
          }
        }
      }
      return {
        ...state,
        currencyList: transform,
      }
    default:
      return state
  }
}
export default currencySocketReducer
