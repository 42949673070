// Store
import { axiosInstance } from 'store/api/index'

export const getCurrencyListAPI = async () => {
  const response = await axiosInstance.get('/currencies/list')
  return response.data
}

export const getAssertListAPI = async () => {
  const response = await axiosInstance.get('/assert/list')
  return response?.data
}

export const createTransaction = async ({
  currencyId,
  tradingAccountId,
  volumeLots,
  commandId,
  closeAtProfit,
  closeAtProfitTypeId,
  closeAtLoss,
  closeAtLosTypeId,
  startAtPrice,
}) => {
  const startAtPriceValue = startAtPrice ? { start_at_price: Number(startAtPrice) } : {}
  const closeAtProfitValue = closeAtProfit
    ? {
        close_at_profit: Number(closeAtProfit),
        close_at_profit_type_id: Number(closeAtProfitTypeId),
      }
    : {}
  const closeAtLossValue = closeAtLoss
    ? { close_at_loss: Number(closeAtLoss), close_at_loss_type_id: Number(closeAtLosTypeId) }
    : {}

  const response = await axiosInstance.post('/transaction', {
    // const response = await axios.post(`${API_BASE_URL}/transaction`, {
    // all values must be number
    asset_id: currencyId, // required
    trading_account_id: tradingAccountId, // required
    volume_lots: Number(volumeLots), // required
    command_id: commandId, // required

    ...startAtPriceValue,
    ...closeAtProfitValue,
    ...closeAtLossValue,
  })
  // console.log('createTransaction response.data', response.data)
  return response.data
}

export const getTransactions = async ({
  tradingAccountId,
  positionId,
  page,
  perPage,
  commandId,
  search,
  period,
}) => {
  const positionIdValue = positionId ? { position_id: positionId } : {}
  const pageValue = page ? { page } : {}
  const perPageValue = perPage ? { per_page: perPage } : {}
  const commandIdValue = commandId ? { command_id: commandId } : {} // sell/buy
  const searchValue = search ? { search } : {}
  const periodValue = period ? { period } : {}

  const response = await axiosInstance.get('/transaction', {
    params: {
      trading_account_id: tradingAccountId,
      ...positionIdValue,
      ...pageValue,
      ...perPageValue,
      ...commandIdValue,
      ...searchValue,
      ...periodValue,
    },
  })
  return response?.data
}

export const closeTransaction = async ({ orderId }) => {
  const response = await axiosInstance.post('/transaction/close', {
    trade_id: orderId,
  })
  console.log('closeTransaction response.data', response.data)
  return response.data
}

export const closeAllTransactionsByPositionId = async ({ positionId }) => {
  const response = await axiosInstance.post('/transaction/close/all', {
    position_id: positionId,
  })
  console.log('closeAllTransactionsByPositionId response.data', response.data)
  return response.data
}
