export const toastConstructor = (toastType, message, title = '', isClosable = true) => {
  const milliseconds = new Date().getTime() // Get current milliseconds
  const random = Math.floor(Math.random() * 1000) // Generate random number between 0 and 999

  return {
    showToast: true,
    message,
    toastType,
    isClosable,
    title,
    id: `${milliseconds}${random}`,
  }
}
export const toastType = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  info: 'info',
}
