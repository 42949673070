// Modules
import { memo, useCallback } from 'react'

// Components
import { InfoBtn, PlusBtn } from 'components'

// UI
import { Stack } from '@chakra-ui/react'

const BtnsBlock = ({ onPlusHandler, onInfoHandler, isInfo = true }) => {
  const onPlusBtn = useCallback(
    (e) => {
      e.stopPropagation()
      onPlusHandler()
    },
    [onPlusHandler],
  )

  const onInfoBtn = useCallback(
    (e) => {
      e.stopPropagation()
      onInfoHandler()
    },
    [onInfoHandler],
  )

  return (
    <Stack gap="0.3em" direction="row">
      <PlusBtn onClick={onPlusBtn} />
      {isInfo && <InfoBtn onClick={onInfoBtn} />}
    </Stack>
  )
}

export default memo(BtnsBlock)
