// Modules
import { memo } from 'react'

// Components
import NavButton from 'pages/Dashboard/components/LeftNavbar/NavButton'
import { NavList } from 'pages/Dashboard/components/LeftNavbar/constants'

// Constants
import { LeftNavBarIconsMobileWidth, LeftNavBarIconsMobileXsWidth } from 'constants/index'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Flex, Stack } from '@chakra-ui/react'

const LeftNavbarMobile = (props) => {
  const { isActiveNav, onChooseItem } = props
  const { mainBorderColor, mainBgColor } = useCurrentColorTheme()

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection="column"
      w={{ base: LeftNavBarIconsMobileXsWidth + 'px', '1xs': LeftNavBarIconsMobileWidth + 'px' }}
      minW={{ base: LeftNavBarIconsMobileXsWidth + 'px', '1xs': LeftNavBarIconsMobileWidth + 'px' }}
      maxW={{ base: LeftNavBarIconsMobileXsWidth + 'px', '1xs': LeftNavBarIconsMobileWidth + 'px' }}
      h="100vh"
      position="fixed"
      borderRight="3px solid"
      borderRightColor={mainBorderColor}
      pb={5}
      bg={mainBgColor}
    >
      <Stack w="100%" direction="column" align="center" gap={0}>
        {NavList.map((el) => (
          <NavButton
            key={el.id}
            id={el.id}
            icon={el.icon}
            onChoose={onChooseItem}
            isActive={isActiveNav === el.title}
            iconW={4}
            iconH={4}
          />
        ))}
      </Stack>
    </Flex>
  )
}

export default memo(LeftNavbarMobile)
