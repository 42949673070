// Modules
import { memo } from 'react'

// Components
import { TextSm, TextXs } from 'components'
import WrapperInfoItem from 'components/InfoItem/WrapperInfoItem'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

import { useTheme } from '@chakra-ui/react'

const InfoItem = (props) => {
  const { title, value, ...rest } = props

  const { isLightTheme } = useCurrentColorTheme()

  return (
    <WrapperInfoItem {...rest}>
      <TextXs fontWeight="500">{title}</TextXs>
      <TextSm fontWeight="600" color={isLightTheme ? 'brand.textColor2Light' : 'white'}>
        {value ? value : '-'}
      </TextSm>
    </WrapperInfoItem>
  )
}

export default memo(InfoItem)
