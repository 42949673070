// Modules
import Cookies from 'js-cookie'
import _ from 'lodash'
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import Dashboard from 'pages/Dashboard/Dashboard'
import DashboardMobile from 'pages/Dashboard/DashboardMobile'
import { tabsList } from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs/constants'

// Constants
import { graphResolutionValueKey } from 'constants/index'

// Hooks
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Utils
import { searchByName } from 'utils/findElemInListCurrency'

// Store
import { chosenActiveTradeItemSelector } from 'store/actions/ActiveTradeItems/selectors'
import {
  getCurrencyMarketList,
  setIntervalGraphValue,
  wsConnectCurrencyMarket,
  wsDisconnectCurrencyMarket,
} from 'store/actions/CurrencySocket/actions'
import { intervalGraphValueSelector } from 'store/actions/CurrencySocket/selectors'
import { favouritesCurrencyListSelector } from 'store/actions/FavouritesCurrency/selectors'
import {
  wsConnectFollowProfits,
  wsConnectFollowTransactions,
  wsDisconnectFollowProfits,
  wsDisconnectFollowTransactions,
} from 'store/actions/OrderBar/actions'
import { getAssertList } from 'store/actions/TransactionCreation/actions'
import { getUserInfo, onGetUserBalance } from 'store/actions/User/actions'
import { isMobileSelector } from 'store/actions/User/selectors'
import {
  accordionSearchValueSelector,
  expandableAccordionIndsSelector,
  marketTopTabValueSelector,
  socketMarketParametersSelector,
} from 'store/actions/marketSidebar/selectors'
import { cookieAuthToken } from 'store/api'

const DashboardContainer = () => {
  const dispatch = useDispatch()

  const { userTradingAccountId } = useUserTradingAccountId()
  const isMobile = useSelector(isMobileSelector)
  // for currency web socket only for one opened tab in accordion and chosen trade item
  const expandableAccordionInds = useSelector(expandableAccordionIndsSelector)
  const chosenActiveTradeItem = useSelector(chosenActiveTradeItemSelector)
  // favourites/all
  const topTabValue = useSelector(marketTopTabValueSelector)
  const favouritesTrade = useSelector(favouritesCurrencyListSelector)
  const socketMarketParameters = useSelector(socketMarketParametersSelector)
  const accordionSearchValue = useSelector(accordionSearchValueSelector)
  const intervalGraphValue = useSelector(intervalGraphValueSelector)

  const token = Cookies.get(cookieAuthToken)

  // listen event of changing local storage value for currency socket
  useEffect(() => {
    const handleStorageChange = (event) => {
      const value = event?.srcElement?.localStorage?.[graphResolutionValueKey]
      if (value) {
        dispatch(setIntervalGraphValue(value))
      }
    }

    window.addEventListener('storage', handleStorageChange)

    // Cleanup on unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [dispatch])

  // user
  useEffect(() => {
    if (!token) return
    dispatch(getUserInfo())
  }, [dispatch, token])

  useEffect(() => {
    if (!token || !userTradingAccountId) return
    dispatch(onGetUserBalance(userTradingAccountId))
  }, [dispatch, token, userTradingAccountId])

  // sockets
  // currency
  useEffect(() => {
    dispatch(getCurrencyMarketList())
  }, [dispatch])

  useEffect(() => {
    // favourites
    let channelsObj = { channels: [] }
    let groupsObj = { group: [] }
    const inputValueTrimmed = accordionSearchValue?.trim()?.toLowerCase()
    const isInputValueTrimmedValid = inputValueTrimmed && inputValueTrimmed?.length >= 3

    if (chosenActiveTradeItem?.label) {
      channelsObj = { channels: [...channelsObj.channels, chosenActiveTradeItem?.name] }
      // channelsObj = { channels: [...channelsObj.channels, chosenActiveTradeItem?.label] }
    }

    // if favourites tab is opened
    if (topTabValue === tabsList[1].id) {
      // if search is not empty add to socket found channels not all favourites
      if (isInputValueTrimmedValid) {
        // const inputValueTrimmed = accordionSearchValue?.trim()?.toLowerCase()
        // if (inputValueTrimmed !== '' && accordionSearchValue?.length >= 3) {
        const filtered = searchByName(inputValueTrimmed, favouritesTrade)
        channelsObj = {
          channels: [...channelsObj.channels, ..._.map(filtered, (el) => el?.name)],
          // channels: [...channelsObj.channels, ..._.map(filtered, (el) => el?.label)],
        }
        // }
      } else {
        channelsObj = {
          channels: [...channelsObj.channels, ..._.map(favouritesTrade, (el) => el?.name)],
          // channels: [...channelsObj.channels, ..._.map(favouritesTrade, (el) => el?.label)],
        }
      }
    } // if tab all is opened
    else if (expandableAccordionInds) {
      // if search is not empty add to socket found channels not all groups
      if (isInputValueTrimmedValid) {
        channelsObj = { channels: [...channelsObj.channels, ...socketMarketParameters?.channels] }
      } else {
        groupsObj = { group: expandableAccordionInds?.[0] }
      }
    }

    if (groupsObj?.group || channelsObj?.channels) {
      dispatch(
        wsConnectCurrencyMarket({
          group: groupsObj?.group,
          channels: channelsObj?.channels,
          intervalGraph: intervalGraphValue,
        }),
      )
    }

    return () => {
      dispatch(wsDisconnectCurrencyMarket())
    }
  }, [
    dispatch,
    expandableAccordionInds,
    chosenActiveTradeItem,
    topTabValue,
    favouritesTrade,
    accordionSearchValue,
    intervalGraphValue,
    socketMarketParameters?.channels,
  ])

  // transactions
  useEffect(() => {
    if (!token) return
    dispatch(wsConnectFollowTransactions())
    return () => {
      dispatch(wsDisconnectFollowTransactions())
    }
  }, [dispatch, token])

  // profits
  useEffect(() => {
    if (!token || !userTradingAccountId) return
    dispatch(wsConnectFollowProfits())
    return () => {
      dispatch(wsDisconnectFollowProfits())
    }
  }, [dispatch, token, userTradingAccountId])

  useEffect(() => {
    if (!userTradingAccountId) return
    dispatch(getAssertList())
  }, [dispatch, userTradingAccountId])

  return isMobile ? <DashboardMobile /> : <Dashboard />
}

export default memo(DashboardContainer)
