// Modules
import { memo } from 'react'

// Components
import { TextSm } from 'components'

// UI
import { Box } from '@chakra-ui/react'

const InfoBtn = (props) => {
  const { onClick, title } = props

  return (
    <Box>
      <TextSm
        onClick={onClick}
        variant="text"
        textAlign="center"
        fontWeight={500}
        _hover={{
          cursor: 'pointer',
          color: 'blue.100',
          transition: '0.8s',
        }}
      >
        {title}
      </TextSm>
    </Box>
  )
}

export default memo(InfoBtn)
