// Modules
import Lottie from 'lottie-react'
import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowBack } from 'react-icons/io'
import { useHistory } from 'react-router-dom'

// Languages
import { LN_BACK_TO_DASHBOARD_BTN, LN_SORRY_PAGE_NOT_FOUND } from 'locales/constants'

// Components
import { TextMd, TextSm } from 'components'

// Navigation
import { routers } from 'navigation/constants'

// Ui
import { Box, Button, Icon } from '@chakra-ui/react'

// Assets
import animationData from './animation.json'

const NotFoundPage = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const navigateToDashboard = useCallback(() => {
    history.push('/' + routers.dashboard)
  }, [history])

  return (
    <Box
      h="100vh"
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="relative"
    >
      <Box>
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={{ height: '80vh', width: '100%' }}
        />
      </Box>
      <TextMd>{t(LN_SORRY_PAGE_NOT_FOUND)}</TextMd>
      <Box position="absolute" top="16px" left="22px">
        <Button
          w="full"
          display="flex"
          flexDirection="column"
          variant="actionBlue"
          size="md"
          onClick={navigateToDashboard}
        >
          <TextSm color="white" fontWeight="400" alignItems="center" display="flex">
            <Icon as={IoIosArrowBack} w={{ base: '13px', sm: '18px' }} />
            {t(LN_BACK_TO_DASHBOARD_BTN)}
          </TextSm>
        </Button>
      </Box>
    </Box>
  )
}

export default memo(NotFoundPage)
