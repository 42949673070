export const appLanguages = {
  en: 'en',
  de: 'de',
}

export const appLanguagesLocalStoreKey = 'appLanguages'
export const isUserEmailVerifiedKey = 'isUserEmailVerified'

export const appLanguagesList = [
  { value: appLanguages.en, title: 'En' },
  { value: appLanguages.de, title: 'De' },
]
