// Store
import { API_BASE_URL, GetAuthToken, axiosInstance } from './index'

export const getKYCFieldsListAPI = async ({ token }) => {
  console.log(token)
  const response = await axiosInstance.get('/kyc/fields')
  // console.log('onGetKYCFieldsListAPI response.data', response.data)
  return response.data
}

export const sendKYCFieldAPI = async ({ name, file }) => {
  const response = await axiosInstance.post('/kyc', { name, file })
  // console.log('sendKYCFieldAPI response.data', response.data)
  return response.data
}

export const deleteKYCFieldAPI = async ({ token, name }) => {
  const response = await axiosInstance.delete('/kyc', {
    headers: {
      ...GetAuthToken(token),
    },
    params: { name },
  })
  // console.log('sendKYCFieldAPI response.data', response.data)
  return response.data
}
