// Modules
import moment from 'moment'

// Constants
import { URL_SOCKET_PATH, WS_API_KEY, WS_PORT } from 'store/api'

const intervals = {
  1: 60,
  5: 300,
  15: 900,
  30: 1800,
  60: 3600,
  120: 7200,
  240: 14400,
  360: 21600,
  480: 28800,
  720: 43200,
  '1D': 86400,
  '1W': 604800,
  W: 604800,
}

export class WebSocketClient {
  constructor(channel, lastDailyBar, resolution, onRealtimeCallback, onResetCacheNeededCallback) {
    if (!channel) {
      throw new Error('channel is empty')
    }
    // console.log('WebSocketClient', channel)
    this.ws = new WebSocket(
      'ws://' +
        URL_SOCKET_PATH +
        ':' +
        WS_PORT +
        '?channels[]=' +
        channel +
        '&api_key=' +
        WS_API_KEY +
        '&interval=' +
        resolution,
    )

    this.ws.addEventListener('open', (event) => {
      // console.log('WebSocket connection established:', event)
    })

    this.ws.addEventListener('message', (event) => {
      const message = JSON.parse(event.data)

      const tradePrice = message.close
      let bar = {}
      let currentTime = this.roundTimestamp(message.time / 1000, resolution)
      let privTime = this.roundTimestamp(lastDailyBar.time, resolution)
      // console.log(currentTime, privTime)
      // console.log(currentTime - privTime, '>=', intervals[resolution] * 1000)
      if (currentTime - privTime >= intervals[resolution] * 1000) {
        bar = {
          time: currentTime,
          open: tradePrice,
          high: tradePrice,
          low: tradePrice,
          close: tradePrice,
        }
        // console.log('[socket] Generate new bar', bar)
      } else {
        bar = {
          ...lastDailyBar,
          high: Math.max(lastDailyBar.high, tradePrice),
          low: Math.min(lastDailyBar.low, tradePrice),
          close: tradePrice,
        }
        // console.log('[socket] Update the latest bar by price', tradePrice)
      }

      onRealtimeCallback(bar)
      lastDailyBar = bar
      // console.log(message.symbol, resolution, lastDailyBar)
      // console.log('Received message:', message)
    })

    this.ws.addEventListener('close', (event) => {
      // console.log('WebSocket connection closed:', event)
    })

    this.ws.addEventListener('error', (event) => {
      // console.error('WebSocket connection error:', event)
    })
  }

  close() {
    if (this.ws) {
      this.ws.close()
    }
  }

  timeConverter(UNIX_timestamp) {
    const a = new Date(UNIX_timestamp * 1000)
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ]
    const year = a.getFullYear()
    const month = months[a.getMonth()]
    const date = a.getDate()
    const hour = a.getHours()
    const min = a.getMinutes()
    const sec = a.getSeconds()
    const time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec
    return time
  }

  roundTimestamp(timestamp, interval) {
    if (interval === '1D') {
      return moment
        .utc(timestamp * 1000)
        .startOf('day')
        .unix()
    } else if (interval === '1M') {
      return moment
        .utc(timestamp * 1000)
        .startOf('month')
        .unix()
    } else if (interval === '60') {
      return moment
        .utc(timestamp * 1000)
        .startOf('hour')
        .unix()
    } else if (!isNaN(parseInt(interval))) {
      const minutes = parseInt(interval)
      const roundedMinutes = Math.floor(moment.utc(timestamp * 1000).minute() / minutes) * minutes
      return moment
        .utc(timestamp * 1000)
        .startOf('hour')
        .minute(roundedMinutes)
        .unix()
    } else {
      throw new Error('Invalid interval provided')
    }
  }
}
