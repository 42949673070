// Constants
import { tabsList } from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs/constants'

// Types
import {
  ADD_EXPANDABLE_ACCORDION_INDS,
  DELETE_EXPANDABLE_ACCORDION_INDS,
  SET_ACCORDION_SEARCH_VALUE,
  SET_ACTIVE_MARKET_TOP_TAB,
  SET_EXPANDABLE_ACCORDION_INDS,
  WS_SET_SOCKET_CURRENCY_PARAMETERS,
} from 'store/actions/marketSidebar/types'

const initialState = {
  expandableAccordionInds: [],

  // all //favourite tabs
  marketTopTabValue: tabsList[0].id,
  // for socket parameters groups['crypto'], channels['BTC]
  socketMarketParameters: { groups: [], channels: [] },
  accordionSearchValue: '',
}

const marketSidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EXPANDABLE_ACCORDION_INDS:
      return {
        ...state,
        expandableAccordionInds: action.payload,
      }
    case ADD_EXPANDABLE_ACCORDION_INDS:
      return {
        ...state,
        expandableAccordionInds: [...state.expandableAccordionInds, action.payload],
      }
    case DELETE_EXPANDABLE_ACCORDION_INDS:
      const itemIdToDelete = action.payload
      const filteredArr = state.expandableAccordionInds.filter((item) => item !== itemIdToDelete)
      return {
        ...state,
        expandableAccordionInds: [...filteredArr],
      }
    case SET_ACTIVE_MARKET_TOP_TAB:
      return {
        ...state,
        marketTopTabValue: action.payload,
      }
    case WS_SET_SOCKET_CURRENCY_PARAMETERS:
      return {
        ...state,
        socketMarketParameters: action.payload,
      }
    case SET_ACCORDION_SEARCH_VALUE:
      return {
        ...state,
        accordionSearchValue: action.payload,
      }
    default:
      return state
  }
}
export default marketSidebarReducer
