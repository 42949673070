// Modules
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsTrash } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'

import {
  LN_HISTORY_TABLE_CLOSE_BTN,
  LN_USER_RESTRICTIONS,
  LN_WARNING_ALERT_CANCEL_BTN,
} from 'locales/constants'

import { toastConstructor, toastType } from 'components/ToastApp/constants'
// Constants
import { TableTr } from 'pages/Dashboard/components/OrdersBottomBar/ContentBar/TableHeaderTh'
import {
  headerClosed,
  headerPending,
  headerPositions,
  sortListValue,
} from 'pages/Dashboard/components/OrdersBottomBar/ContentBar/constants'
import { mainTabsValues } from 'pages/Dashboard/components/OrdersBottomBar/HeaderBar/constants'

import useCheckUserRestrictions from 'hooks/useCheckUserRestrictions'

// Utils
import { formatDateToPrettierFullDateTime } from 'utils/dateTransform'
import {
  formatDigitsWithSpaces,
  roundThreeDigitsAfterDots,
  roundTwoDigitsAfterDots,
} from 'utils/formatDigitsWithSpaces'
import { handlingEmptyValuesForShowing } from 'utils/handlingEmptyValuesForShowing'
import { getProfit, getTypeTransactionTitle } from 'utils/transactionFormulas'

// Store
import { onCloseTransaction } from 'store/actions/OrderBar/actions'
import { mainTabsValueSelector } from 'store/actions/OrderBar/selectors'
import { showToast } from 'store/actions/ToastsApp/actions'

// UI
import { Badge, Icon, IconButton, Td, Tooltip, useDisclosure } from '@chakra-ui/react'

function useOrderBottomInfoContent(ordersList, size) {
  const {
    isOpen: isOpenDeleteItem,
    onOpen: onOpenDeleteItem,
    onClose: onCloseDeleteItem,
  } = useDisclosure()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isBlockedCloseTrade, isTradeAllowed } = useCheckUserRestrictions()

  const mainTabsValue = useSelector(mainTabsValueSelector)

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' })
  const [deleteItemAlert, setDeleteItemAlert] = useState({})

  const handleSort = (key) => {
    let direction = 'asc'
    if (sortConfig.key === key) {
      direction = sortConfig.direction === 'asc' ? 'desc' : 'asc'
    }
    setSortConfig({ key, direction })
  }

  const sortedData = useMemo(() => {
    if (!sortConfig?.key) return ordersList
    return ordersList?.sort((a, b) => {
      const columnA = a[sortConfig?.key]
      const columnB = b[sortConfig?.key]

      if (columnA < columnB) {
        return sortConfig?.direction === sortListValue.asc ? -1 : 1
      }
      if (columnA > columnB) {
        return sortConfig?.direction === sortListValue.asc ? 1 : -1
      }
      return 0
    })
  }, [sortConfig, ordersList])

  const onDeleteItemOpenAlert = useCallback(
    (el) => {
      if (isBlockedCloseTrade || !isTradeAllowed) {
        dispatch(showToast(toastConstructor(toastType.error, t(LN_USER_RESTRICTIONS))))
      } else {
        setDeleteItemAlert(el)
        onOpenDeleteItem()
      }
    },
    [setDeleteItemAlert, onOpenDeleteItem, isBlockedCloseTrade, isTradeAllowed, dispatch],
  )

  const headersList = useMemo(() => {
    switch (mainTabsValue) {
      case mainTabsValues?.positions:
        return headerPositions
      case mainTabsValues?.pendingOrders:
        return headerPending
      case mainTabsValues?.closedPositions:
        return headerClosed
    }
  }, [mainTabsValue])

  const onHandleDeleteItemBtn = useCallback(() => {
    dispatch(onCloseTransaction(deleteItemAlert?.id))
    onCloseDeleteItem()
  }, [dispatch, deleteItemAlert, onCloseDeleteItem])

  const fontSize = size === 'xs' ? '11px' : '14px'

  const getRow = useCallback(
    (row) => {
      switch (mainTabsValue) {
        case mainTabsValues?.positions:
          return (
            <TableTr key={row?.id + mainTabsValue}>
              <Td fontSize={fontSize}>{handlingEmptyValuesForShowing(row?.id)}</Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDateToPrettierFullDateTime(row?.created_at))}
              </Td>
              <Td fontSize={fontSize}>
                <strong>{handlingEmptyValuesForShowing(row?.label)}</strong>
              </Td>
              <Td fontSize={fontSize} textTransform="uppercase">
                {handlingEmptyValuesForShowing(getTypeTransactionTitle(row?.command_id))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(roundThreeDigitsAfterDots(row?.volume_lots))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.open_price))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.current_rate))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.close_at_profit))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.close_at_loss))}
              </Td>
              <Td fontSize={fontSize}>{handlingEmptyValuesForShowing(row?.swap)}</Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(
                  formatDigitsWithSpaces(roundTwoDigitsAfterDots(row?.profit)),
                )}
              </Td>
              <Td p={0}>
                <CloseBtn
                  disabled={isBlockedCloseTrade || !isTradeAllowed}
                  onClose={() => {
                    // prettier-ignore
                    (!isBlockedCloseTrade || isTradeAllowed) && onDeleteItemOpenAlert(row)
                  }}
                />
              </Td>
            </TableTr>
          )
        case mainTabsValues?.pendingOrders:
          return (
            <TableTr key={row?.id + mainTabsValue}>
              <Td fontSize={fontSize}>{handlingEmptyValuesForShowing(row?.id)}</Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDateToPrettierFullDateTime(row?.created_at))}
              </Td>
              <Td fontSize={fontSize}>
                <strong>{handlingEmptyValuesForShowing(row?.label)}</strong>
              </Td>
              <Td fontSize={fontSize} textTransform="uppercase">
                {handlingEmptyValuesForShowing(getTypeTransactionTitle(row?.command_id))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(roundThreeDigitsAfterDots(row?.volume_lots))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.current_rate))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.start_at_price))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.close_at_profit))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.close_at_loss))}
              </Td>
              <Td p={0}>
                <CloseBtn
                  disabled={isBlockedCloseTrade || !isTradeAllowed}
                  onClose={() =>
                    // prettier-ignore
                    (!isBlockedCloseTrade || isTradeAllowed) && onDeleteItemOpenAlert(row)
                  }
                />
              </Td>
            </TableTr>
          )
        case mainTabsValues?.closedPositions:
          return (
            <TableTr key={row?.id + mainTabsValue}>
              <Td fontSize={fontSize}>{handlingEmptyValuesForShowing(row?.id)}</Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDateToPrettierFullDateTime(row?.created_at))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDateToPrettierFullDateTime(row?.close_time))}
              </Td>
              <Td fontSize={fontSize}>
                <strong>{handlingEmptyValuesForShowing(row?.label)}</strong>
              </Td>
              <Td fontSize={fontSize} textTransform="uppercase">
                {handlingEmptyValuesForShowing(getTypeTransactionTitle(row?.command_id))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(roundThreeDigitsAfterDots(row?.volume_lots))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.open_price))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.close_price))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.close_at_profit))}
              </Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(formatDigitsWithSpaces(row?.close_at_loss))}
              </Td>
              <Td fontSize={fontSize}>{handlingEmptyValuesForShowing(row?.swap)}</Td>
              <Td fontSize={fontSize}>
                {handlingEmptyValuesForShowing(
                  formatDigitsWithSpaces(roundTwoDigitsAfterDots(row?.profit)),
                )}
              </Td>
            </TableTr>
          )
      }
    },
    [mainTabsValue, fontSize, onDeleteItemOpenAlert, isBlockedCloseTrade, isTradeAllowed],
  )

  return {
    deleteItemAlert,
    isOpenDeleteItem,
    onCloseDeleteItem,
    sortedData,
    handleSort,
    sortConfig,
    headersList,
    getRow,
    onHandleDeleteItemBtn,
  }
}

export default useOrderBottomInfoContent

export const CloseBtn = ({ onClose, disabled = false }) => {
  const { t } = useTranslation()

  const tooltipTitle = disabled ? t(LN_USER_RESTRICTIONS) : null

  return (
    <Tooltip label={tooltipTitle} aria-label={tooltipTitle}>
      <Badge
        disabled={disabled}
        colorScheme="red"
        textTransform="uppercase"
        borderRadius={2}
        bg="transparent"
        borderColor="red.800"
        border="1px solid"
        pl={2}
        pr={2}
        onClick={onClose}
        _hover={{
          cursor: 'pointer',
          color: 'white',
          bg: 'red.800',
          transition: '0.2s',
        }}
        _disabled={{
          cursor: 'not-allowed',
          color: 'white',
          bg: 'gray.400',
          transition: '0.2s',
        }}
      >
        {t(LN_HISTORY_TABLE_CLOSE_BTN)}
      </Badge>
    </Tooltip>
  )
}
