// Types
import {
  GET_CLOSED_TRANSACTIONS_LIST,
  GET_OPEN_TRANSACTIONS_LIST,
  GET_PENDING_TRANSACTIONS_LIST,
  GET_USER_TRANSACTIONS_LIST,
  ON_CLOSE_ALL_TRANSACTIONS,
  ON_CLOSE_TRANSACTION,
  SET_CLOSED_TRANSACTIONS_LIST,
  SET_CLOSED_TRANSACTIONS_LIST_LOADING,
  SET_CLOSED_TRANSACTIONS_LIST_PAGINATION,
  SET_IS_FULL_SIZE_MODAL,
  SET_MAIN_TABS_VALUE,
  SET_OPEN_TRANSACTIONS_LIST,
  SET_OPEN_TRANSACTIONS_LIST_LOADING,
  SET_OPEN_TRANSACTIONS_LIST_PAGINATION,
  SET_PENDING_ORDERS_LIST,
  SET_PENDING_ORDERS_LIST_PAGINATION,
  SET_PENDING_TRANSACTIONS_LIST_LOADING,
  SET_POSITIONS_LENGTH,
  SET_SEARCH_VALUE,
  SET_SECONDARY_TABS_VALUE,
  SET_SELECT_TIME_VALUE,
  SET_USER_TRANSACTIONS_LIST,
  SET_USER_TRANSACTIONS_LIST_LOADING,
  TRANSACTION_CONTENT_BAR_LOADING,
  WS_CONNECT_FOLLOW_PROFITS,
  WS_CONNECT_FOLLOW_TRANSACTIONS,
  WS_DISCONNECT_FOLLOW_PROFITS,
  WS_DISCONNECT_FOLLOW_TRANSACTIONS,
  WS_ERROR_FOLLOW_PROFITS,
  WS_ERROR_FOLLOW_TRANSACTIONS,
  WS_FOLLOW_PROFITS_CHANGE_VALUE,
  WS_FOLLOW_PROFITS_MESSAGE_RECEIVED,
  WS_FOLLOW_TRANSACTIONS_CHANGE_VALUE,
  WS_FOLLOW_TRANSACTIONS_MESSAGE_RECEIVED,
} from 'store/actions/OrderBar/types'

export const setPendingOrdersList = (payload) => ({
  type: SET_PENDING_ORDERS_LIST,
  payload: payload,
})

export const setPendingOrdersListPagination = (payload) => ({
  type: SET_PENDING_ORDERS_LIST_PAGINATION,
  payload: payload,
})

export const setMainTabsValue = (payload) => ({
  type: SET_MAIN_TABS_VALUE,
  payload: payload,
})

export const setSecondaryTabsValue = (payload) => ({
  type: SET_SECONDARY_TABS_VALUE,
  payload: payload,
})

export const setSearchValue = (payload) => ({
  type: SET_SEARCH_VALUE,
  payload: payload,
})

export const setSelectTimeValue = (payload) => ({
  type: SET_SELECT_TIME_VALUE,
  payload: payload,
})

export const setIsFullSizeModal = (payload) => ({
  type: SET_IS_FULL_SIZE_MODAL,
  payload: payload,
})

export const getUserTransactionsList = (payload) => ({
  type: GET_USER_TRANSACTIONS_LIST,
  payload: payload,
})

export const setUserTransactionsListLoading = (payload) => ({
  type: SET_USER_TRANSACTIONS_LIST_LOADING,
  payload: payload,
})

export const setUserTransactionsList = (payload) => ({
  type: SET_USER_TRANSACTIONS_LIST,
  payload: payload,
})

export const setPositionsLength = (payload) => ({
  type: SET_POSITIONS_LENGTH,
  payload: payload,
})

// open
export const getOpenTransactionsList = (payload) => ({
  type: GET_OPEN_TRANSACTIONS_LIST,
  payload: payload,
})

export const setOpenTransactionsListLoading = (payload) => ({
  type: SET_OPEN_TRANSACTIONS_LIST_LOADING,
  payload: payload,
})

export const setOpenTransactionsList = (payload) => ({
  type: SET_OPEN_TRANSACTIONS_LIST,
  payload: payload,
})

export const setOpenTransactionsListPagination = (payload) => ({
  type: SET_OPEN_TRANSACTIONS_LIST_PAGINATION,
  payload: payload,
})

// pending
export const getPendingTransactionsList = (payload) => ({
  type: GET_PENDING_TRANSACTIONS_LIST,
  payload: payload,
})

export const setPendingTransactionsListLoading = (payload) => ({
  type: SET_PENDING_TRANSACTIONS_LIST_LOADING,
  payload: payload,
})

// closed
export const getClosedTransactionsList = (payload) => ({
  type: GET_CLOSED_TRANSACTIONS_LIST,
  payload: payload,
})

export const setClosedTransactionsListLoading = (payload) => ({
  type: SET_CLOSED_TRANSACTIONS_LIST_LOADING,
  payload: payload,
})

export const setClosedTransactionsList = (payload) => ({
  type: SET_CLOSED_TRANSACTIONS_LIST,
  payload: payload,
})

export const setClosedTransactionsListPagination = (payload) => ({
  type: SET_CLOSED_TRANSACTIONS_LIST_PAGINATION,
  payload: payload,
})

export const onCloseTransaction = (payload) => ({
  type: ON_CLOSE_TRANSACTION,
  payload: payload,
})

export const onCloseAllTransactions = (payload) => ({
  type: ON_CLOSE_ALL_TRANSACTIONS,
  payload: payload,
})

export const onTransactionContentBarLoading = (payload) => ({
  type: TRANSACTION_CONTENT_BAR_LOADING,
  payload: payload,
})

// WEB SOCKET
// TRANSACTIONS
export const wsConnectFollowTransactions = () => ({ type: WS_CONNECT_FOLLOW_TRANSACTIONS })

export const wsDisconnectFollowTransactions = () => ({ type: WS_DISCONNECT_FOLLOW_TRANSACTIONS })

export const wsFollowTransactionsMessageReceived = (payload) => ({
  type: WS_FOLLOW_TRANSACTIONS_MESSAGE_RECEIVED,
  payload,
})

export const wsFollowTransactionsChangeValue = (payload) => ({
  type: WS_FOLLOW_TRANSACTIONS_CHANGE_VALUE,
  payload,
})

export const wsFollowTransactionsError = (payload) => ({
  type: WS_ERROR_FOLLOW_TRANSACTIONS,
  payload,
})

// PROFITS

export const wsConnectFollowProfits = () => ({ type: WS_CONNECT_FOLLOW_PROFITS })

export const wsDisconnectFollowProfits = () => ({ type: WS_DISCONNECT_FOLLOW_PROFITS })

export const wsFollowProfitsMessageReceived = (payload) => ({
  type: WS_FOLLOW_PROFITS_MESSAGE_RECEIVED,
  payload,
})

export const wsFollowProfitsChangeValue = (payload) => ({
  type: WS_FOLLOW_PROFITS_CHANGE_VALUE,
  payload,
})

export const wsFollowProfitsError = (payload) => ({
  type: WS_ERROR_FOLLOW_PROFITS,
  payload,
})
