// Modules
import { memo } from 'react'

// Components
import MarketAccordion from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/MarketAccordion'

// UI
import { ThemeProvider, createTheme } from '@mui/material/styles'

const theme = createTheme()

const MarketAccordionWrapper = (props) => (
  <ThemeProvider theme={theme}>
    <MarketAccordion {...props} />
  </ThemeProvider>
)

export default memo(MarketAccordionWrapper)
