// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import {
  LN_TRANSACTION_CALC_MODAL_CALCULATIONS_FOR,
  LN_TRANSACTION_CALC_MODAL_LEVERAGE,
  LN_TRANSACTION_CALC_MODAL_LOSS_SL,
  LN_TRANSACTION_CALC_MODAL_PIP,
  LN_TRANSACTION_CALC_MODAL_PROFIT_TP,
  LN_TRANSACTION_CALC_MODAL_REQUIRED_MARGIN,
  LN_TRANSACTION_CALC_MODAL_ROE,
} from 'locales/constants'

// Components
import { HeadingSm, TextXs } from 'components'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Utils
import { formatDigitsWithSpaces, roundTwoDigitsAfterDots } from 'utils/formatDigitsWithSpaces'

// UI
import { Card, CardBody, CardHeader, Flex, Stack, StackDivider } from '@chakra-ui/react'

const CalcDescription = (props) => {
  const { activeCurrencyName, leverage, pip, descValues } = props
  const { t } = useTranslation()

  const { userTradingAccountName } = useUserTradingAccountId()

  return (
    <Card flex="1">
      <CardHeader>
        <HeadingSm>
          {t(LN_TRANSACTION_CALC_MODAL_CALCULATIONS_FOR)} {activeCurrencyName}
        </HeadingSm>
      </CardHeader>

      <CardBody pt={0}>
        <Stack divider={<StackDivider borderColor="nsavy.20" />} spacing="2">
          <InfoText title={t(LN_TRANSACTION_CALC_MODAL_LEVERAGE)} value={leverage} />
          <InfoText
            title={t(LN_TRANSACTION_CALC_MODAL_REQUIRED_MARGIN)}
            value={
              formatDigitsWithSpaces(roundTwoDigitsAfterDots(descValues?.requiredMargin)) +
              ' ' +
              userTradingAccountName
            }
          />
          <InfoText
            title={t(LN_TRANSACTION_CALC_MODAL_PROFIT_TP)}
            value={descValues?.profitFromTP + ' ' + userTradingAccountName}
          />
          <InfoText
            title={t(LN_TRANSACTION_CALC_MODAL_LOSS_SL)}
            value={descValues?.lossFromSL + ' ' + userTradingAccountName}
          />
          <InfoText
            title={t(LN_TRANSACTION_CALC_MODAL_ROE)}
            value={roundTwoDigitsAfterDots(descValues?.roe) + '%'}
          />
          <InfoText
            title={t(LN_TRANSACTION_CALC_MODAL_PIP)}
            value={pip + ' ' + userTradingAccountName}
          />
        </Stack>
      </CardBody>
    </Card>
  )
}

export default memo(CalcDescription)

const InfoText = (props) => {
  const { title, value, ...args } = props
  const { mainTextColor } = useCurrentColorTheme()

  return (
    <Flex justifyContent="space-between">
      <TextXs fontWeight="700" textTransform="uppercase">
        {title}:
      </TextXs>
      <TextXs fontWeight="500" color={mainTextColor} {...args}>
        {value}
      </TextXs>
    </Flex>
  )
}
