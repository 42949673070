// Modules
import { memo } from 'react'
import { useSelector } from 'react-redux'

// Components
import { FavouritesCurrencyHint } from 'components'
import ItemList from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/MarketAccordion/ListPanel/ItemList'
import { tabsList } from 'pages/Dashboard/components/LeftNavbar/Tabs/MarketWatch/TopTabs/constants'

// Hooks
import { themeColorMode } from 'hooks/useCurrentColorTheme'

// Store
import { selectedThemeSelector } from 'store/actions/User/selectors'

// UI
import { Box, Stack } from '@chakra-ui/react'

const ListPanel = (props) => {
  const { list, onOpenInfo, favourite = false, topTabValue = '' } = props

  const selectedTheme = useSelector(selectedThemeSelector)

  if (!list || list?.length === 0) {
    if (topTabValue === tabsList[1].id) {
      return <FavouritesCurrencyHint />
    } else {
      return null
    }
  }

  return (
    <Box
      bg={
        selectedTheme === themeColorMode.light
          ? favourite
            ? '#E2E8F0'
            : '#f7fbff'
          : 'rgba(255, 255, 255, 0.04)'
      }
      // bg={isLightTheme ? (favourite ? 'gray.100' : 'brand.lightAccent2') : 'whiteAlpha.50'}
      p={4}
      pt={2}
      borderRadius="md"
      boxShadow="0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)"
    >
      <Stack gap="0.3em" direction="column">
        {list?.map((el) => (
          <ItemList key={el?.id} onOpenInfo={onOpenInfo} element={el} />
        ))}
      </Stack>
    </Box>
  )
}

export default ListPanel
