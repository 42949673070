import _ from 'lodash'

export const Currency = {
  BTCUSD: 'BTCUSD',
}

export const CurrencyGroups = {
  forex: 'forex',
  stocks: 'stocks',
  indices: 'indices',
  crypto: 'crypto',
  commodities: 'commodities',
  metals: 'metals',
}

export const FavouriteGroup = 'favourites'

export const currencyGroupsArray = _.values(CurrencyGroups)
