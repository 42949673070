// Types
import { HIDE_TOAST, SHOW_TOAST } from 'store/actions/ToastsApp/types'

const initialState = {
  showToast: false,
  toastMessage: '',
  toastTitle: '',
  id: '',
  isClosable: true,
  toastType: null, // 'success' | 'warning' | 'error' | 'info'
}

const toastsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        showToast: true,
        toastMessage: action.payload.message,
        toastTitle: action.payload.title,
        toastType: action.payload.toastType,
        id: action.payload.id,
        isClosable: action.payload.isClosable || true,
      }
    case HIDE_TOAST:
      return {
        ...state,
        showToast: false,
        toastMessage: '',
        toastTitle: '',
        toastType: null,
        isClosable: true,
      }
    default:
      return state
  }
}
export default toastsReducer
