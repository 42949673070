// Modules
import { memo } from 'react'
import BounceLoader from 'react-spinners/BounceLoader'

// Assets
import SpinnerSVG from 'assets/spinner'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react'

const WindowSpinner = () => {
  const { mainBgColor } = useCurrentColorTheme()
  const isSmallScreen = useBreakpointValue({ base: true, '2xs': true, md: false })

  return (
    <Box
      top={0}
      position="absolute"
      w="100%"
      h="100%"
      bg={mainBgColor}
      zIndex="2000000"
      minH="100vh"
    >
      <Box h="100%">
        <Flex position="relative" alignItems="center" justifyContent="center" h="100%">
          <Box position="absolute" zIndex="2">
            <SpinnerSVG w={isSmallScreen ? 50 : 70} h={isSmallScreen ? 50 : 70} />
          </Box>
          <Box zIndex="1">
            <BounceLoader
              color="#99b5ed8f"
              loading
              size={isSmallScreen ? 70 : 130}
              // size={130}
              speedMultiplier={0.5}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  )
}

export default memo(WindowSpinner)
