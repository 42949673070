// Class
import { WebSocketClient } from 'hooks/Graph/helpers/WebSocketClient'

// Constants
import { URL_REST_PATH } from 'store/api'

// Helpers
import { ChannelSubscriptionSingleton } from './channelMap'

const API_URL = 'http://' + URL_REST_PATH + ':' + process.env.REACT_APP_REST_GRAPH_PORT
const API_URL_GRAPH = API_URL + '/api'

// Make requests to CryptoCompare API
export async function getConfig() {
  try {
    const response = await fetch(API_URL_GRAPH + '/polygon/config')
    // console.log(response)
    return response.json()
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.status}`)
  }
}

// Make requests to CryptoCompare API
export async function getSymbolInfo(symbol) {
  try {
    const response = await fetch(API_URL_GRAPH + '/polygon/symbols?symbol=' + symbol)
    return response.json()
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.status}`)
  }
}

// Make requests to CryptoCompare API
export async function search(query, exchange, type) {
  try {
    const urlWithQuery = new URL(API_URL_GRAPH + '/polygon/search')
    urlWithQuery.search = new URLSearchParams({
      query,
      exchange,
      type,
    }).toString()

    const response = await fetch(urlWithQuery)
    return response.json()
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.status}`)
  }
}

// Make requests to CryptoCompare API
export async function getBars(pair, from, to, resolution) {
  try {
    const urlWithQuery = new URL(API_URL_GRAPH + '/polygon/history')
    urlWithQuery.search = new URLSearchParams({
      symbol: pair,
      resolution: resolution,
      from: from,
      to: to,
      countback: 301,
      currencyCode: pair,
    }).toString()

    const response = await fetch(urlWithQuery)
    return response.json()
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.msg}`)
  }
}

export async function makeApiRequest2() {
  try {
    const response = await fetch(API_URL + '/states/all')
    return response.json()
  } catch (error) {
    throw new Error(`CryptoCompare request error: ${error.msg}`)
  }
}

// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
  const short = `${fromSymbol}/${toSymbol}`
  return {
    short,
    full: `${exchange}:${short}`,
  }
}

export function parseFullSymbol(fullSymbol) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/)
  if (!match) {
    const patch = fullSymbol.match(/^(\w+):(\w+)$/)
    // debugger
    return {
      exchange: patch[1],
      fromSymbol: patch[2],
      toSymbol: '',
    }
  }

  return {
    exchange: match[1],
    fromSymbol: match[2],
    toSymbol: match[3],
  }
}

export function subscribeOnStream(
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  onResetCacheNeededCallback,
  lastDailyBar,
) {
  const channelToSubscription = ChannelSubscriptionSingleton.getInstance()
  // console.log('еун', channelToSubscription)
  channelToSubscription.set(
    subscribeUID,
    new WebSocketClient(
      symbolInfo.symbol,
      lastDailyBar,
      resolution,
      onRealtimeCallback,
      onResetCacheNeededCallback,
    ),
  )

  // console.log('channelToSubscription', channelToSubscription.get(subscribeUID))
  // console.log('[subscribeBars]: Subscribe to streaming. Channel:', subscribeUID, symbolInfo.symbol)
}

export function unsubscribeFromStream(subscriberUID) {
  const channelToSubscription = ChannelSubscriptionSingleton.getInstance()
  console.log(channelToSubscription.get(subscriberUID), subscriberUID)

  let client = channelToSubscription.get(subscriberUID)
  client.close()

  channelToSubscription.delete(subscriberUID)
}
