export const ChannelSubscriptionSingleton = (() => {
  // Private variable to hold the singleton instance
  let instance;

  // Private Map to hold channel subscriptions
  const channelToSubscription = new Map();

  // Private function to initialize the Map
  const init = () => {
    // Initialize the Map with some default values if needed
    // For example:
    // channelToSubscription.set('channel1', ['subscription1', 'subscription2']);
  };

  // Public method to get or create the singleton instance
  const getInstance = () => {
    // If instance doesn't exist, create it
    if (!instance) {
      init();
      instance = {
        // Public method to access the Map
        get: (key) => channelToSubscription.get(key),
        set: (key, val) => channelToSubscription.set(key, val),
        delete: (key)  => channelToSubscription.delete(key),
      };
    }
    // Return the singleton instance
    return instance;
  };

  // Expose the getInstance method as the public API
  return {
    getInstance
  };
})();