// Modules
import { memo } from 'react'

// Components
import Content from 'pages/User/components/Content'
import NavbarMobileUI from 'pages/User/components/Navbar/MobileUI/NavbarMobileUI'
import Sidebar, { sidebarWidth } from 'pages/User/components/Sidebar'

// UI
import { Box, Flex } from '@chakra-ui/react'

const UserMobileUI = () => {
  return (
    <Box>
      <NavbarMobileUI />
      <main>
        <Flex justifyContent="space-between" mt="54px">
          <Flex justifyContent="flex-end" w="100%">
            <Box minH="90vh" w="100%">
              <Content />
            </Box>
          </Flex>
        </Flex>
      </main>
    </Box>
  )
}

export default memo(UserMobileUI)
