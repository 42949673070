// Types
import { SET_BALANCE_VALUE } from 'store/actions/UserBalance/types'

const initialState = {
  marginInfoData: null,
  balance: '95844.45',
}

const balanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BALANCE_VALUE:
      return {
        ...state,
        balance: action.payload,
      }
    default:
      return state
  }
}
export default balanceReducer
