// Modules
import { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import UserMobileUI from 'pages/User/UserMobileUI'
import UserUI from 'pages/User/UserUI'

// Hooks
import useUserTradingAccountId from 'hooks/useUserTradingAccountId'

// Store
import { tokenSelector } from 'store/actions/Auth/selectors'
import { getUserInfo, onGetUserBalance } from 'store/actions/User/actions'
import { isMobileSelector } from 'store/actions/User/selectors'

const UserContainer = () => {
  const dispatch = useDispatch()
  const tokenAuth = useSelector(tokenSelector)

  const { userTradingAccountId } = useUserTradingAccountId()
  const isMobile = useSelector(isMobileSelector)

  useEffect(() => {
    if (!tokenAuth) return
    dispatch(getUserInfo())
  }, [dispatch, tokenAuth])

  useEffect(() => {
    if (!tokenAuth || !userTradingAccountId) return
    dispatch(onGetUserBalance(userTradingAccountId))
  }, [dispatch, tokenAuth, userTradingAccountId])

  return isMobile ? <UserMobileUI /> : <UserUI />
}

export default memo(UserContainer)
