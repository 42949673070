// Modules
import { memo } from 'react'

// Hooks
import useCurrentColorTheme from 'hooks/useCurrentColorTheme'

// UI
import { Link, styled } from '@chakra-ui/react'

const StyledLinkButton = styled(Link, {
  baseStyle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    outline: '2px solid transparent',
    outlineOffset: '2px',
    lineHeight: '1.2',
    borderRadius: 'md',
    fontWeight: 600,
    transitionProperty:
      'background-color,border-color,color,fill,stroke,opacity,box-shadow,transform',
    transitionDuration: '200ms',
    height: '2.5rem',
    minWidth: '2.5rem',
    fontSize: 'md',
    paddingInlineStart: 4,
    paddingInlineEnd: 4,
    // background: 'blue.500',
    color: 'white',
    textDecoration: 'none',
  },
})

const LinkButton = ({ children, href, target, ...args }) => {
  const { isLightTheme } = useCurrentColorTheme()

  return (
    <StyledLinkButton
      href={href}
      target={target}
      bg="blue.500"
      _hover={{
        backgroundColor: isLightTheme ? 'blue.600' : 'blue.600',
        textDecoration: 'none',
      }}
      {...args}
    >
      {children}
    </StyledLinkButton>
  )
}

export default memo(LinkButton)
