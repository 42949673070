// Modules
import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import ActiveTradeBlock from 'pages/Dashboard/components/Main/ActiveTradeBlock/ActiveTradeBlock'
import ActiveTradeBlockMobile from 'pages/Dashboard/components/Main/ActiveTradeBlock/ActiveTradeBlockMobile'
import MenuTradeModal from 'pages/Dashboard/components/Main/ActiveTradeBlock/MenuTradeModal'

// Store
import { setActiveTradeItem, setDeleteAllTradeItem } from 'store/actions/ActiveTradeItems/actions'
import {
  activeTradeItemsListSelector,
  chosenActiveTradeItemSelector,
} from 'store/actions/ActiveTradeItems/selectors'
import { isMobileSelector } from 'store/actions/User/selectors'

// UI
import { useDisclosure } from '@chakra-ui/react'

const ActiveTradeBlockContainer = ({ width }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobile = useSelector(isMobileSelector)

  const dispatch = useDispatch()

  const activeTradeItemsList = useSelector(activeTradeItemsListSelector)
  const chosenActiveTradeItem = useSelector(chosenActiveTradeItemSelector)

  const onAddNew = useCallback(() => {
    onOpen()
  }, [onOpen])

  const onActive = useCallback(
    (data) => {
      // console.log('getCurrencyCategoryByName', getCurrencyCategoryByName(data?.name))
      dispatch(setActiveTradeItem(data))
    },
    [dispatch],
  )

  const onCloseAll = useCallback(() => {
    dispatch(setDeleteAllTradeItem())
  }, [dispatch])

  return isMobile ? (
    <>
      <ActiveTradeBlockMobile
        activeTradeItemsList={activeTradeItemsList}
        chosenActiveTradeItem={chosenActiveTradeItem}
        onAddNew={onAddNew}
        onActive={onActive}
        onCloseAll={onCloseAll}
        width={width}
      />
      {/*<MenuTradeModal isOpen={isOpen} onClose={onClose} />*/}
    </>
  ) : (
    <>
      <ActiveTradeBlock
        activeTradeItemsList={activeTradeItemsList}
        chosenActiveTradeItem={chosenActiveTradeItem}
        onAddNew={onAddNew}
        onActive={onActive}
        onCloseAll={onCloseAll}
      />
      {/*<MenuTradeModal isOpen={isOpen} onClose={onClose} />*/}
    </>
  )
}

export default memo(ActiveTradeBlockContainer)
