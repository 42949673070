// Modules
import { memo } from 'react'
import { useTranslation } from 'react-i18next'

// Languages
import { LN_HISTORY_NO_DATA } from 'locales/constants'

// Components
import { TextSm } from 'components'
import Pagination from 'components/Table/Pagination'
import InfoModal from 'pages/User/components/Content/Deposit/DepositHistory/TableHistory/InfoModal'
import TableHeaderTh from 'pages/User/components/Content/Deposit/DepositHistory/TableHistory/TableHeaderTh'
import TableRow from 'pages/User/components/Content/Deposit/DepositHistory/TableHistory/TableRow'
import { columns } from 'pages/User/components/Content/Deposit/DepositHistory/TableHistory/constants'

// Hooks
import { paginationPerPage } from 'hooks/useOrderBottomPagination'

// UI
import { Box, Table, TableContainer, Tbody, Thead, Tr } from '@chakra-ui/react'

const TableHistory = (props) => {
  const { list, loading, paginationInfo, onPreviousPage, onNextPage, onPage } = props

  const { t } = useTranslation()

  return (
    <Box
      h="100%"
      w="100%"
      position="relative"
      borderTop="1px solid"
      borderTopColor="navy.20"
      mt={0.5}
      mr={2}
      p="2px 15px"
      pb="25px"
    >
      <Box>
        <TableContainer>
          <Table size="md">
            <Thead>
              <Tr>
                {columns?.map((header) => (
                  <TableHeaderTh key={header.name} title={header.title} />
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {!list || list?.length === 0 ? (
                <TextSm mt={2}>{t(LN_HISTORY_NO_DATA)}</TextSm>
              ) : (
                list?.map((el) => <TableRow key={el?.id} row={el} />)
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {paginationInfo?.total > paginationInfo?.per_page && !loading && (
        <Pagination
          gotoPage={onPage}
          nextPage={onNextPage}
          previousPage={onPreviousPage}
          total={paginationInfo?.total || 0}
          pageIndex={Number(paginationInfo?.page) || 1}
          pageSize={paginationInfo?.per_page || paginationPerPage}
        />
      )}
      <InfoModal />
    </Box>
  )
}

export default memo(TableHistory)
