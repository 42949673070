// Modules
import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Components
import FormMarket from 'pages/Dashboard/components/TransactionCreationSidebar/FormMarket'

// Context
import MarketCalcProvider from 'context/MarketCalcContext'

// Store
import { setShowMobileCreateTransactionModal } from 'store/actions/TransactionCreation/actions'
import { isMobileCreateTransactionModalOpenSelector } from 'store/actions/TransactionCreation/selectors'

// UI
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react'

const TransactionCreationModalMobile = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(isMobileCreateTransactionModalOpenSelector)

  const onCloseModal = useCallback(() => {
    dispatch(setShowMobileCreateTransactionModal(false))
  }, [dispatch])

  return (
    <MarketCalcProvider>
      <Modal isOpen={isOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent maxW="60%">
          <ModalCloseButton top={1} />
          <ModalBody pt={7} pb={4}>
            <FormMarket />
          </ModalBody>
        </ModalContent>
      </Modal>
    </MarketCalcProvider>
  )
}

export default memo(TransactionCreationModalMobile)
