// Modules
import { memo } from 'react'

// UI
import { Button } from '@chakra-ui/react'

const TabBtn = (props) => {
  const { title, onClick, active, ...args } = props

  return (
    <Button
      borderRadius="50px"
      pt={0.5}
      pl={2}
      pr={2}
      pb={0.5}
      colorScheme={active ? 'blue' : 'gray'}
      fontSize="11px"
      fontWeight={500}
      size="xs"
      onClick={onClick}
      h="20px"
      {...args}
    >
      {title}
    </Button>
  )
}

export default memo(TabBtn)
