// Helpers
import { graphResolutionValueKey } from 'constants/index'

import {
  getBars,
  getConfig,
  getSymbolInfo,
  search,
  subscribeOnStream,
  unsubscribeFromStream,
} from 'hooks/Graph/helpers'

import { setIntervalGraphValue } from 'store/actions/CurrencySocket/actions'

// Use it to keep a record of the most recent bar on the chart
const lastBarsCache = new Map()

function useGraphStructure() {
  // const dispatch = useDispatch()

  const chartApi = {
    onReady: async (callback) => {
      // console.log('[onReady]: Method call')
      const data = await getConfig()
      setTimeout(() => callback(data))
    },

    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
      const symbolInfo = await getSymbolInfo(symbolName)
      // console.log('[symbolInfo]:', symbolInfo)
      onSymbolResolvedCallback(symbolInfo)
    },

    getBars: async (
      symbolInfo,
      resolution,
      { from, to, countBack, firstDataRequest },
      onHistoryCallback,
      onErrorCallback,
    ) => {
      try {
        // const data = await makeApiRequest(`data/histoday?${query}`);
        const barsRes = await getBars(symbolInfo.name, from, to, resolution)

        if (barsRes.s !== 'ok' || !barsRes.c.length) {
          // console.log('nodata')
          // "noData" should be set if there is no data in the requested period.
          // debugger
          onHistoryCallback([], {
            noData: true,
          })

          return
        }

        let bars = []

        for (let i = 0; i < barsRes.c.length; i++) {
          bars = [
            ...bars,
            {
              time: parseFloat(barsRes.t[i] * 1000),
              low: parseFloat(barsRes.l[i]),
              high: parseFloat(barsRes.h[i]),
              open: parseFloat(barsRes.o[i]),
              close: parseFloat(barsRes.c[i]),
            },
          ]
        }

        if (firstDataRequest) {
          lastBarsCache.set(symbolInfo.full_name, {
            ...bars[bars.length - 1],
          })
        }

        // console.log(`[getBars]: returned ${bars.length} bar(s)`, bars)
        onHistoryCallback(bars, {
          noData: false,
        })
      } catch (error) {
        // debugger
        // console.log(b);
        //  console.log('[getBars]: Get error', error);
        onErrorCallback(error.msg)
      }
    },

    subscribeBars: (
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscribeUID,
      onResetCacheNeededCallback,
    ) => {
      // console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID)
      console.log('[subscribeBars]: Method call with resolution:', resolution)
      // initiate event of changing local storage value for currency socket
      localStorage.setItem(graphResolutionValueKey, resolution?.toString())

      window.dispatchEvent(new Event('storage'))
      subscribeOnStream(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback,
        lastBarsCache.get(symbolInfo.full_name),
      )
      // dispatch(setIntervalGraphValue(resolution))
    },

    unsubscribeBars: (subscriberUID) => {
      // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID)
      unsubscribeFromStream(subscriberUID)
    },

    searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
      // console.log('[searchSymbols]: Method call', userInput, exchange, symbolType)
      const symbols = await search(userInput, exchange, symbolType)
      onResultReadyCallback(symbols)
    },
  }

  return { chartApi }
}

export default useGraphStructure
