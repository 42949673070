export const buttonStyles = {
  Button: {
    baseStyle: () => ({
      // TODO: add color white, now not working
      color: 'white',
    }),
    variants: {
      text: {
        padding: 0,
        paddingInlineEnd: 0,
        paddingInlineStart: 0,
        minW: 'auto',
        h: 'auto',
      },
      lightBlue: {
        // backgroundColor: 'blue.500',
        // backgroundColor: 'brand.lightAccent',
        backgroundColor: 'brand.mainAccent1Light',
        _hover: {
          bg: 'brand.mainAccent2Light',
          // bg: 'blue.600',
          // bg: 'navy.200',
        },
      },
      darkBlue: {
        backgroundColor: 'brand.lightAccent',
        _hover: {
          bg: 'navy.400',
        },
      },
      actionBlue: {
        backgroundColor: '#4D6CEF',
        _hover: {
          bg: '#2e51eb',
        },
        _disabled: {
          backgroundColor: '#4D6CEF!important',
        },
      },
    },
  },
}
